import { createUseStyles } from 'react-jss';

import { colors } from '../colors';

import { ViboTheme } from 'types/theme';

export const inputsBySize = {
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 400,

  '&.sm': {
    fontSize: '13px',
    lineHeight: '18.2px',

    '&.withBorder': {
      height: '24px',

      '@media screen and (min-width: 1441px)': {
        height: '28px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },

  '&.lg': {
    fontSize: '13px',
    lineHeight: '19.5px',

    '&.withUnderline': {
      height: '20px',

      '@media screen and (min-width: 1441px)': {
        height: '24px',
      },
    },

    '&.withBorder': {
      height: '30px',

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  '&.xlg': {
    fontSize: '20px',
    lineHeight: '28px',

    '&.withBorder, &.withUnderline': {
      height: '28px',

      '@media screen and (min-width: 1441px)': {
        height: '34px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },
};

const useInputStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  timelineHeaderSearchBar: {
    width: '425px',
    margin: '0 auto',
    display: 'block',
    boxShadow: theme.shadows[4],
    borderRadius: '0px 0px 4px 4px',
    height: '36px',
    position: 'relative',
    zIndex: 1,

    '& svg': {
      width: '14px',
      height: '14px',
    },
  },

  signInInputs: {
    width: '100%',
    height: '36px',
    fontSize: '18px',
    background: theme.palette.background.default,
    borderRadius: '4px',
    border: '0',
    transition: 'unset',
    padding: '4px 36px',

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },

    '&:focus': {
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.primary,
    },
  },

  signInInputsError: {
    '& input': {
      border: `1px solid ${theme.palette.error.main}`,

      '&:hover, &:focus': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
  },

  inputGroup: {
    position: 'relative',
    margin: '0 0 20px 0',
  },

  signInPassword: {
    padding: '0!important',

    '& input': {
      width: '100%',
    },
  },

  signInPasswordError: {
    '& input': {
      border: `1px solid ${theme.palette.error.main} !important`,

      '&:hover, &:focus': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },

    '& span': {
      right: '20px !important',

      '& svg': {
        width: '20px !important',
        height: '20px !important',
      },
    },
  },

  switch: {
    display: 'flex',
    alignItems: 'baseline',

    '& input': {
      maxWidth: '50px',
      borderRadius: '4px',
      minHeight: '36px',
      border: '0',
      background: theme.palette.primary.main,
      textAlign: 'center',
      padding: '0',
    },
  },

  switchLabel: {
    display: 'flex',
    marginLeft: '16px',
    color: theme.palette.text.primary,
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '19.56px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  labelIcon: {
    marginRight: '8px',
    display: 'flex',
    alignSelf: 'center',

    '& svg': {
      width: '16px',
      height: '16px',
    },
  },

  indeterminate: {
    color: colors.gray,
  },

  checked: {
    color: theme.palette.primary.main,
  },

  content: {
    padding: '0 8px',
  },

  unchecked: {
    color: colors.gray,
  },

  // NEW STYLES
  viboUnderlineWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,

    '& .autoWidthSizer, & input, & textarea': {
      ...inputsBySize,
    },

    '&.errorsPresent': {
      '& .withBorder': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },

    '&:focus-within': {
      '& .limit, & .clearBtn': {
        opacity: 1,
      },
    },

    '&:hover': {
      '& .clearBtn': {
        opacity: 1,
      },
    },

    '& .limit': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      transform: 'translateY(100%)',
    },
  },

  underline: {
    '& .closeWrapper:after, &.react-tel-input:after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      bottom: '0',
      width: '0',
      height: '1px',
      transform: 'translateX(-50%)',
      transition: 'width 0.3s',
      backgroundColor: theme.palette.primary.light,
    },

    '&:focus-within, &:hover': {
      '& .closeWrapper:after, &.react-tel-input:after': {
        width: '100%',
      },
    },

    '&.react-tel-input': {
      '& .form-control': {
        background: 'transparent',
      },
    },

    '&:focus-within': {
      '& .closeWrapper:after': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&.errorsPresent': {
      '& .closeWrapper:after': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },

  autoWidth: {
    width: 'fit-content',

    '& textarea, & input': {
      minWidth: '15ch',
    },
  },

  withAutoWidth: {
    '&:after': {
      bottom: '22px',
    },
  },

  autoWidthSizer: {
    pointerEvents: 'none',
    opacity: '0',
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: '0',
    top: '0',
    padding: '0 11px',
  },

  disabled: {
    cursor: 'not-allowed',

    '&::placeholder': {
      color: `${theme.palette.text.disabled}!important`,
    },

    '& .viboIcon': {
      color: theme.palette.text.hint,
    },
  },

  withOverlayCheckbox: {
    '& .viboIcon': {
      position: 'relative',

      '&:before': {
        zIndex: 2,
        position: 'relative',
      },
    },

    '&:hover': {
      '&:not(.disabled)': {
        '& .viboIcon': {
          color: isDark ? colors.white : theme.palette.button.active,
        },
      },

      '& .viboIcon:after': {
        content: '""',
        position: 'absolute',
        width: '24px',
        height: '24px',
        left: '-5px',
        top: '-5px',
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.light,
        zIndex: 1,

        '@media screen and (min-width: 1441px)': {
          left: '-4px',
          top: '-4px',
        },
      },
    },
  },

  withUnderline: {
    border: 'none',
    backgroundColor: 'transparent',
  },

  withBorder: {
    '&.viboInput, &.viboTextarea, &.textPreview, &.form-control, & .quillEditor': {
      border: `1px solid ${theme.palette.text.hint}`,
      borderRadius: '4px',
      backgroundColor: 'transparent',
      padding: '0 11px',

      '&:focus, &:active': {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
      },

      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

export default useInputStyles;
