import copy from 'copy-to-clipboard';

import i18n from 'i18n';
import { EMOJI_REJEX } from 'services/constants';
import { toastNotify } from 'graphql/hooks/common';

import { ButtonIconType } from 'types/enums';

export const isCoincidence = (value?: string, string?: string): boolean =>
  (value && string && string.toLocaleLowerCase().includes(value.toLocaleLowerCase())) || false;

export const strippString = (string?: string) => string?.replace(/<(.|\n)*?>/g, '').trim() || '';

export const isOnOff = (value?: boolean) => {
  return value ? 'on' : 'off';
};

export const getDomain = (url: string) => {
  return new URL(url).hostname.replace('www.', '');
};

export const getDomainWithProtocol = (url: string) => {
  const matches = url.match(/^(https?:)?([^?#]+)(?:[?#]|$)/i);

  return matches && matches[1] ? matches[1] + matches[2] : matches?.[2];
};

export const copyToClipboard = (text: string) => {
  copy(text);

  toastNotify({
    text: i18n.t('copiedSuccessfully'),
    iconType: ButtonIconType.copy,
  });
};

export const hmacSha256Hex = async (value: string) => {
  const enc = new TextEncoder();
  const algorithm = { name: 'HMAC', hash: 'SHA-256' };
  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(process.env.REACT_APP_INTERCOM_VERIFY_SECRET),
    algorithm,
    false,
    ['sign', 'verify']
  );
  const hashBuffer = await crypto.subtle.sign(algorithm.name, key, enc.encode(value));
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const filterNoEmoji = (value: string) => {
  return value
    .replace(EMOJI_REJEX, '')
    .replace(/  +/g, ' ')
    .trim();
};

export const getFileNameFromLink = (link: string) => {
  if (!!link) {
    const segments = link.split('/');

    const result = segments[segments.length - 1];

    return result;
  }

  return '';
};
