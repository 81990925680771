import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Logout from './Logout';
import RoleInfo from './RoleInfo';
import ChangeCard from './ChangeCard';
import PaymentStatus from './PaymentStatus';
import DeleteAccount from './DeleteAccount';
import ChangePassword from './ChangePassword';
import WithRole from 'components/user/WithRole';
import ChangeNotification from './ChangeNotification';
import ContentArea from 'components/common/ContentArea';

import { useMe } from 'graphql/hooks/user';

import { UserRole } from 'types/enums';

import useStyles from './style';

const AccountSettings: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, isChildDj } = useMe();

  return (
    <ContentArea shadowed>
      <div className={classes.title}>{t('accountSettings')}</div>
      <WithRole roles={[UserRole.dj]}>
        <>
          <RoleInfo user={user} />
          {isChildDj ? null : <ChangeCard />}
          <PaymentStatus />
          <ChangeNotification />
        </>
      </WithRole>
      <ChangePassword />
      <DeleteAccount />
      <Logout />
    </ContentArea>
  );
};

export default AccountSettings;
