import { gql } from '@apollo/client';

export const GET_APPLE_MUSIC_DEVELOPER_TOKEN = gql`
  query getAppleMusicDeveloperToken {
    getAppleMusicDeveloperToken {
      developerToken
    }
  }
`;

export const CONNECT_APPLE_MUSIC = gql`
  mutation connectAppleMusic($appleMusicAuth: AppleMusicAuthInput) {
    connectAppleMusic(appleMusicAuth: $appleMusicAuth)
  }
`;

export const DISCONNECT_APPLE_MUSIC = gql`
  mutation disconnectAppleMusic {
    disconnectAppleMusic
  }
`;
