import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import IconButton from 'components/buttons/IconButton';
import { TimelineSongsContext } from '../TimelineSongsContext';

import { useToggleableUpdateFieldSong } from 'graphql/hooks/blocks';

import { ButtonIconType, ToggleableSongUpdateField } from 'types/enums';
import { FlagProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const Flag: FC<FlagProps> = ({ song }) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();

  const { songsOptions } = useContext(TimelineSongsContext);

  const { updateField } = useToggleableUpdateFieldSong({
    selectedIds: [song._id],
    field: ToggleableSongUpdateField.isFlagged,
    songs: songsOptions.songsList,
  });

  return (
    <IconButton
      className={classNames('flag', decorClasses.highlighted, classes.flag, {
        [`${classes.isFlagged} isFlagged`]: song?.isFlagged,
      })}
      onClick={e => {
        e.stopPropagation();
        updateField();
      }}
      type={song?.isFlagged ? ButtonIconType.flag : ButtonIconType.flaggedCount}
      placement="top"
      data-gtm-target-id="timeline-section-details-songs-flag-song"
    />
  );
};

export default Flag;
