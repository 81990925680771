import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  visibilityLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    height: '20px',
    padding: '0 6px 0 4.5px',
    fontWeight: 400,
    fontSize: '11px!important',
    lineHeight: '15.4px',

    '&.outline': {
      backgroundColor: theme.palette.background.paper,

      '&.dj-type': {
        border: `1px solid ${theme.palette.text.secondary}`,
        color: theme.palette.text.secondary,

        '& svg': {
          color: theme.palette.text.secondary,
        },
      },

      '&.host-type': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,

        '& svg': {
          color: theme.palette.primary.main,
        },
      },

      '&.public-type': {
        border: `1px solid ${theme.palette.success.main}`,
        color: theme.palette.success.main,

        '& svg': {
          color: theme.palette.success.main,
        },
      },
    },

    '&.fill': {
      '&, & svg': {
        color: colors.white,
      },

      '&.dj-type': {
        color: theme.palette.selection.whiteToBlack,
        backgroundColor: theme.palette.text.secondary,
      },

      '&.host-type': {
        backgroundColor: theme.palette.primary.main,
      },

      '&.public-type': {
        backgroundColor: theme.palette.success.main,
      },
    },

    '& .viboIcon': {
      marginRight: '2px',
    },

    '@media screen and (min-width: 1441px)': {
      height: '24px',
      fontSize: '14px!important',
      lineHeight: '21px',
    },
  },
}));

export default useStyles;
