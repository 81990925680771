import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSearchResultsIcon } from 'resources/img/svg/emptyStateNoSearchResults.svg';

const NoSearchResults: FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyState size="lg" icon={EmptyStateNoSearchResultsIcon}>
      {t('noResults')}
    </EmptyState>
  );
};

export default NoSearchResults;
