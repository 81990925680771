import React, { useEffect, createContext, useState, ReactNode } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_ME } from 'graphql/queries/user';
import {
  GET_APPLE_MUSIC_DEVELOPER_TOKEN,
  CONNECT_APPLE_MUSIC,
  DISCONNECT_APPLE_MUSIC,
} from '../../../graphql/mutations/appleMusic';

import { createMusicKitScript, removeMusicKitToken } from '../../../services/appleMusic/helpers';
import { openErrorNotification } from 'graphql/hooks/common';

interface TAppleMusicAuthContext {
  appleMusicLoading: boolean;
  isAppleMusicConnected: boolean;
  connectAppleMusic: () => void;
  disconnectAppleMusic: () => void;
}

// todo change it to undefined
export const AppleMusicAuthContext = createContext<TAppleMusicAuthContext>({
  appleMusicLoading: false,
  isAppleMusicConnected: false,
  connectAppleMusic: () => {},
  disconnectAppleMusic: () => {},
});

interface AppleMusicAuthProviderProps {
  autoConnect: boolean;
  children: ReactNode;
}

const AppleMusicAuthProvider = ({ autoConnect, children }: AppleMusicAuthProviderProps) => {
  const { t } = useTranslation();

  const [authLoading, setAuthLoading] = useState<boolean>(autoConnect);

  const { data, loading } = useQuery<UserResponse>(GET_ME);
  const user: Maybe<User> = data?.me;
  const isAppleMusicConnected: boolean = !!user?.appleMusicConnected;

  const [getAppleMusicDeveloperToken, { data: tokenData, loading: tokenLoading }] = useLazyQuery<
    DeveloperTokenResponse
  >(GET_APPLE_MUSIC_DEVELOPER_TOKEN, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      MusicKit.configure({
        developerToken: data.getAppleMusicDeveloperToken.developerToken,
      });

      const musicKit = MusicKit.getInstance();

      if (musicKit) {
        musicKit.authorize().then(musicUserToken => {
          setAuthLoading(false);

          return connect({
            variables: {
              appleMusicAuth: {
                musicUserToken,
                developerToken: tokenData?.getAppleMusicDeveloperToken.developerToken,
              },
            },
          });
        });
      }
    },
    onError: () => openErrorNotification(t('pleaseReconnectAppleMusic')),
  });

  const [connect] = useMutation<DeveloperTokenResponse>(CONNECT_APPLE_MUSIC, {
    refetchQueries: [
      {
        query: GET_ME,
      },
    ],
  });

  const [disconnect] = useMutation(DISCONNECT_APPLE_MUSIC, {
    onCompleted: () => {
      removeMusicKitToken();
    },
    refetchQueries: [
      {
        query: GET_ME,
      },
    ],
  });

  useEffect(() => {
    const script: HTMLScriptElement = createMusicKitScript();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isAppleMusicConnected) {
      return setAuthLoading(false);
    }
  }, [isAppleMusicConnected]);

  const connectAppleMusic = () => {
    getAppleMusicDeveloperToken();
  };

  const disconnectAppleMusic = () => {
    return disconnect();
  };

  useEffect(() => {
    if (autoConnect && !isAppleMusicConnected) {
      connectAppleMusic();
    }
  }, [autoConnect, isAppleMusicConnected]);

  return (
    <AppleMusicAuthContext.Provider
      value={{
        isAppleMusicConnected,
        appleMusicLoading: loading || tokenLoading || authLoading,
        connectAppleMusic,
        disconnectAppleMusic,
      }}
    >
      {children}
    </AppleMusicAuthContext.Provider>
  );
};

export default AppleMusicAuthProvider;
