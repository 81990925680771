import { FC, SVGProps } from 'react';

import { CommonModalProps } from 'vibo-ui/Modal';

export interface GenerateEventPdfModalProps extends CommonModalProps {
  isEventDj: boolean;
  eventId: string;
  loading?: boolean;
}

export interface InfoOptionProps {
  icon?: FC<SVGProps<SVGSVGElement>>;
  text: string;
}

export type PreparedPayloadProps = {
  params: CreateEventFileWebForm;
  isEventDj: boolean;
  fieldsToPick: string[];
  filterSelection?: boolean;
};

export enum pageLayout {
  portrait = 'Portrait',
  landscape = 'Landscape',
}

export enum pdfColor {
  grayscale = 'Grayscale',
  colorfull = 'Colorfull',
}
