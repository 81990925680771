import { InMemoryCache, makeVar } from '@apollo/client';
import { persistCache } from 'apollo3-cache-persist';

import { authStorage } from 'utils/helpers/auth';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
  },
  addTypename: true,
});

persistCache({
  cache,
  storage: window.localStorage,
});

export const isOnlineVar = makeVar<boolean>(false);
export const isLoadingVar = makeVar<boolean>(false);
export const isLoggedInVar = makeVar<boolean>(!!authStorage.token);
