import React, { FC, PropsWithChildren } from 'react';
import RcMenu from 'rc-menu';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { MenuProps } from './interfaces';

import useStyles from './style';

const Menu: FC<PropsWithChildren<MenuProps>> = ({
  children,
  className,
  selectedKeys,
  useRoutSelectKeys,
  getSelectedSidebarMenuKeys,
  styleModificator = 'default',
  skipRoutKeys = false,
  ...rest
}) => {
  const classes = useStyles();

  const history = useHistory();

  const selectedRoutKeys = skipRoutKeys
    ? selectedKeys
    : getSelectedSidebarMenuKeys?.(history?.location?.pathname);

  return !!children ? (
    <RcMenu
      className={classNames('viboMenu', classes.viboMenu, styleModificator, className)}
      {...rest}
      selectedKeys={selectedRoutKeys}
    >
      {children}
    </RcMenu>
  ) : null;
};

export default Menu;
