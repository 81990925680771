import React, { FC, Suspense, createContext, useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';

import ModalPlaceholder from 'components/Placeholders/ModalPlaceholder';

import { modalsConfig } from 'utils/modalWindow';

import { ModalWindowsResponse, ModalsSet, ModalType } from './interfaces';

export const ModalWindowsContext = createContext<ModalWindowsResponse>(undefined!);

export const ModalWindowContextProvider: FC = ({ children }) => {
  const [openedModals, setOpenedModals] = useState<ModalsSet>([]);

  const [openedModalKeys, setOpenedModalKeys] = useQueryParam<string | undefined>('mw');

  const getModalKeys = (modals: ModalsSet) =>
    modals
      .filter(modalOption => modalOption.type === ModalType.permanent)
      .map(modalOption => modalOption.key)
      .join(',');

  useEffect(() => {
    if (!!openedModals.length) {
      const keysToShow = getModalKeys(openedModals);
      !!keysToShow.length && setOpenedModalKeys(keysToShow);
    }
  }, [openedModals]);

  useEffect(() => {
    const preparedModals = (openedModalKeys
      ?.split(',')
      ?.filter(Boolean)
      .map(modalKey => ({ key: modalKey })) || []) as ModalsSet;
    setOpenedModals(preparedModals);
  }, [openedModalKeys]);

  useEffect(() => {
    return () => {
      setOpenedModals([]);
    };
  }, []);

  return (
    <ModalWindowsContext.Provider
      value={{
        openedModals,
        setOpenedModals,
      }}
    >
      {children}
      {openedModals.map(modal => (
        <Suspense
          fallback={<ModalPlaceholder name={modal.key} />}
          key={`modal-placeholder-${modal.key}`}
        >
          {modalsConfig[modal.key]?.(modal) || null}
        </Suspense>
      ))}
    </ModalWindowsContext.Provider>
  );
};
