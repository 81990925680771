import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router';
import { useQueryParam } from 'use-query-params';
import { useQuery, useMutation } from '@apollo/client';

import { EventContext } from 'components/context/EventContext';

import client, { cache } from 'graphql/client';
import { onError } from '../helpers';
import { hasBlock } from 'services/sections/helpers';
import { getSectionSongsData } from 'services/songs/helpers';
import { usePrevious } from 'services/common/dataProcessingHelpers';
import { handleUpdateSectionSortCache, updateSongFlag, updateSongMustPlay } from '../cache/songs';
import {
  INITIAL_LOAD_SONGS_COUNT,
  LOAD_MORE_SONGS_COUNT,
  PREDEFINED_SONG_PLACEHOLDERS_COUNT,
} from 'services/constants';

import { UPDATE_SECTION_SONGS } from '../mutations/songs';
import { GET_EVENT_SECTION_SONGS } from '../queries/songs';
import { SECTION_SONGS_SORT } from 'graphql/fragments/sections';

import { Fragment, Mutation } from 'types/graphql';
import { SectionBlockName, DndIndex, ToggleableSongUpdateField } from 'types/enums';

export type UseActiveDndProps = (
  dndIdx?: DndIndex
) => {
  activeDnd: DndIndex | undefined;
  handleActiveDnd: (idx: DndIndex) => void;
  clearActiveDnd: () => void;
  isActive: boolean;
};

export const useActiveDnd: UseActiveDndProps = dndIdx => {
  const [activeDnd, setActiveDnd] = useQueryParam<DndIndex | undefined>('activeDnd');

  const handleActiveDnd = (idx: DndIndex) => setActiveDnd(idx);

  const clearActiveDnd = () => setActiveDnd(undefined);

  const isActive = dndIdx === activeDnd;

  return {
    activeDnd,
    isActive,
    handleActiveDnd,
    clearActiveDnd,
  };
};

export const usePreventUpdateSongsBlock = () => {
  const [songsUpdatePrevented, setSongsUpdatePrevented] = useQueryParam<boolean | undefined>(
    'songsUpdatePrevent'
  );

  const handleSongsUpdatePrevented = (disabled: boolean) => setSongsUpdatePrevented(disabled);

  const clearSongsUpdatePrevented = () => setSongsUpdatePrevented(undefined);

  return {
    songsUpdatePrevented,
    handleSongsUpdatePrevented,
    clearSongsUpdatePrevented,
  };
};

export const useToggleableUpdateFieldSong = ({
  field,
  selectedIds,
  songs,
}: UseToggleableUpdateFieldSongProps): UseToggleableUpdateFieldSongReturn => {
  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const isEveryFieldSame = songs
    .filter(song => song && selectedIds.includes(song._id))
    .every(song => song?.[field as ToggleableSongUpdateField]);

  const [updateSongs] = useMutation<UpdateSectionSongsData, UpdateSectionSongsVariables>(
    UPDATE_SECTION_SONGS,
    {
      onError: error => {
        onError(error);
      },
      refetchQueries: ['getSectionSongsStats', 'getSectionSongs'],
    }
  );

  const handleUpdateField = () => {
    const payload = {
      [field]: !isEveryFieldSame,
    };

    updateSongs({
      variables: {
        eventId,
        sectionId,
        songIds: selectedIds,
        payload,
      },
    });

    selectedIds.forEach(id => {
      switch (field) {
        case ToggleableSongUpdateField.isFlagged:
          updateSongFlag(id, !isEveryFieldSame);
          return;
        case ToggleableSongUpdateField.isMustPlay:
          updateSongMustPlay(id, !isEveryFieldSame);
          return;
        default:
          return;
      }
    });
  };

  return {
    updateField: handleUpdateField,
  };
};

export const useSongs = ({
  variables,
  songsCount = PREDEFINED_SONG_PLACEHOLDERS_COUNT,
}: UseSongsProps): UseSongsReturn => {
  const { sectionId } = useParams<EventPageRouteParams>();

  const cachedSectionSort = cache.readFragment<TSort<SongsSortFields>>({
    id: `SectionSongsSort:${sectionId}`,
    fragment: SECTION_SONGS_SORT,
  });
  const cachedSort = !!cachedSectionSort?.field
    ? {
        field: cachedSectionSort.field,
        direction: cachedSectionSort.direction,
      }
    : null;

  const [filter, setFilter] = useState<SectionSongsFilter>({});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [sort, setSort] = useState<Nullable<TSort<SongsSortFields>>>(cachedSort);
  const [songsList, setSongsList] = useState<SectionSong[]>([]);
  const [totalCount, setTotalCount] = useState<Nullable<number>>(null);

  const eventContext = useContext(EventContext);

  const handleChangeSort = (sort: Nullable<TSort<SongsSortFields>>) => {
    setSort(sort);
    handleUpdateSectionSortCache(sort, sectionId, totalCount);
  };

  const extendedVariables = {
    ...variables,
    filter,
    sort: cachedSort,
    pagination: {
      skip: 0,
      limit: totalCount || cachedSectionSort?.songsCount || INITIAL_LOAD_SONGS_COUNT,
    },
  };

  const { data, loading, refetch, fetchMore } = useQuery<SectionSongsResponse>(
    GET_EVENT_SECTION_SONGS,
    {
      variables: extendedVariables,
      skip:
        !!eventContext?.isTemplate || !!eventContext?.isFavoriteSections || !variables?.sectionId,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const removeSongsById = (ids: string[]) => {
    if (ids) {
      setSongsList(songsList.filter(song => !ids.includes(song?._id ?? '')));
    }
  };

  const sectionSongsData: SectionSongsData = useMemo(() => getSectionSongsData(data), [data]);

  const prevFilter = usePrevious(filter);
  const prevSectionId = usePrevious(sectionId);

  const loadMoreSongs = useCallback(() => {
    if (!loading && data?.getSectionSongs.next) {
      fetchMore({
        variables: {
          ...variables,
          filter,
          sort,
          pagination: {
            ...data?.getSectionSongs.next,
            limit: LOAD_MORE_SONGS_COUNT,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return {
            getSectionSongs: {
              ...prev.getSectionSongs,
              songs: [...prev.getSectionSongs.songs, ...fetchMoreResult.getSectionSongs.songs],
              next: fetchMoreResult.getSectionSongs.next
                ? {
                    ...fetchMoreResult.getSectionSongs.next,
                    limit: LOAD_MORE_SONGS_COUNT,
                  }
                : null,
            },
          };
        },
      }).then(({ data }) => {
        const newArray = [...songsList.filter(Boolean), ...data?.getSectionSongs.songs];

        setTotalCount(newArray.length);
        setSongsList(newArray);
      });
    } else {
      data && setSongsList(data.getSectionSongs.songs);
    }
  }, [data?.getSectionSongs, loading, songsList, totalCount]);

  const clearSorting = () =>
    setSort({
      field: 'createdAt',
      direction: 'desc',
    });
  const clearFiltering = () => setFilter({});
  const clearSongs = () => setSongsList([]);

  useEffect(() => {
    if (prevFilter && !isEqual(prevFilter, filter)) {
      setIsFilterApplied(!!Object.keys(omit(filter, 'q')).length || !!filter?.q);
    }
  }, [filter, prevFilter]);

  useEffect(() => {
    if (!loading && data?.getSectionSongs) {
      const newSongs = data?.getSectionSongs?.songs ?? [];

      setSongsList(newSongs);
    }
  }, [data?.getSectionSongs.songs, loading]);

  useEffect(() => {
    if (!isEqual(sort, cachedSort)) {
      setSort(cachedSort);
    }
  }, [sectionId, cachedSort]);

  useEffect(() => {
    if (sectionId !== prevSectionId) {
      setTotalCount(null);
    }
  }, [sectionId]);

  useEffect(() => {
    setTotalCount(songsList.length);
  }, [sort]);

  useEffect(() => {
    if (!isEqual(sort, cachedSort)) {
      setSort(cachedSort);
    }
  }, []);

  return {
    variables: extendedVariables,
    songsList,
    totalCount,
    clearSorting,
    clearFiltering,
    clearSongs,
    sectionSongsData,
    isFilterApplied,
    songsFilter: filter,
    songsSort: cachedSort,
    songsLoading: loading,
    loadMoreSongs,
    refetchSongs: refetch,
    setSongsFilter: setFilter,
    setSongsSort: handleChangeSort,
    setTotalCount,
    setSongsList,
    removeSongsById,
  };
};

export const useSectionBlocksUpdate = (
  mutation: Mutation,
  fragment: Fragment,
  refetchQueries: string[],
  variables: SectionCommonVariables
) => {
  // todo better way to do this?
  const [updateSectionBlocksWithRefetch] = useMutation<
    UpdateSectionBlocksData,
    UpdateSectionBlocksVariables
  >(mutation, {
    onError,
    refetchQueries,
  });

  const [updateSectionBlocks] = useMutation<UpdateSectionBlocksData, UpdateSectionBlocksVariables>(
    mutation,
    {
      onError,
    }
  );

  const handleUpdateSectionBlocks = (
    blocks: SectionBlock[],
    shouldRefetch: boolean
  ): Promise<void> => {
    const cachedSection = client.readFragment<Nullable<SectionBlocksFragment>>({
      id: variables.sectionId,
      fragment,
    });

    if (cachedSection) {
      const isBlockRemoved: (blockName: SectionBlockName) => boolean = blockName => {
        return (
          hasBlock(cachedSection, blockName) !== blocks.some(block => block.name === blockName)
        );
      };
      const wasSongsBlockAddedOrRemoved: boolean = isBlockRemoved(SectionBlockName.songs);
      const wasNotesBlockAddedOrRemoved: boolean = isBlockRemoved(SectionBlockName.notes);

      client.writeFragment<SectionBlocksFragment>({
        id: variables.sectionId,
        fragment,
        data: {
          blocks,
          settings: {
            songsEnabled: wasSongsBlockAddedOrRemoved
              ? !cachedSection.settings.songsEnabled
              : cachedSection.settings.songsEnabled,
            notesEnabled: wasNotesBlockAddedOrRemoved
              ? !cachedSection.settings.notesEnabled
              : cachedSection.settings.notesEnabled,
            __typename: cachedSection.settings.__typename,
          },
          __typename: cachedSection.__typename,
        },
      });
    }

    if (shouldRefetch) {
      return updateSectionBlocksWithRefetch({
        variables: {
          ...variables,
          blocks,
        },
      }).then(() => {});
    }

    return updateSectionBlocks({
      variables: {
        ...variables,
        blocks,
      },
    }).then(() => {});
  };

  return { update: handleUpdateSectionBlocks };
};
