import React, { FC } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import Container from 'components/common/Container';
import EventPageHeader from 'components/headers/EventPageHeader';
import { EventContextProvider } from 'components/context/EventContext';

import { GET_EVENT } from 'graphql/queries/events';

import { EventLayoutProps } from './interfaces';

import useStyles from './style';

const EventLayout: FC<EventLayoutProps> = ({ className, children, page, isLoading = false }) => {
  const classes = useStyles();

  const { id: eventId } = useParams<EventPageRouteParams>();

  const { data, error, loading } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
    skip: !eventId,
  });

  const event = data?.event;

  if (error) {
    return null;
  }

  return (
    <div className={classNames(classes.eventLayout, className)}>
      <EventContextProvider value={{ isLoading: loading || isLoading, event, page }}>
        <EventPageHeader />
        <Container
          className={classNames('content-fade-in', {
            ready: !!event && !loading,
          })}
        >
          {!!event && !loading ? children : null}
        </Container>
      </EventContextProvider>
    </div>
  );
};

export default EventLayout;
