import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  modalPlaceholderOverlay: {
    position: 'fixed',
    background: 'rgba(55,55,55,.6)',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 99999,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalPlaceholder: {
    width: 'fit-content',
    borderRadius: '4px',
    overflow: 'hidden',

    '& .header': {
      height: '36px',
      backgroundColor: `${theme.palette.primary.main}!important`,
    },

    '& .sub-header': {
      height: '42px',

      '@media screen and (min-width: 1441px)': {
        height: '48px',
      },
    },

    '& .body': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& .footer': {
      padding: '8px 16px 16px',
      height: '54px',
      display: 'flex',
      gap: '16px',

      '& .brick': {
        width: '100%',
        height: '100%',
      },

      '@media screen and (min-width: 1441px)': {
        height: '60px',
      },
    },

    '& .body, & .footer, & .sub-header': {
      backgroundColor: theme.palette.background.paper,
    },

    '&.sm': {
      '& .body': {
        width: 'var(--modal-sm-w)',
        height: 'var(--modal-sm-h)',
      },
    },

    '&.md': {
      '& .body': {
        width: 'var(--modal-sm-w)',
        height: 'var(--modal-md-h)',
      },
    },

    '&.lg': {
      '& .body': {
        width: 'var(--modal-lg-w)',
        height: 'var(--modal-lg-h)',
      },
    },

    '&.xlg': {
      '& .body': {
        width: 'var(--modal-lg-w)',
        height: 'var(--modal-xlg-h)',
      },
    },

    '&.xxlg': {
      '& .body': {
        backgroundColor: theme.palette.selection.hover,
        width: 'var(--modal-xxlg-w)',
        height: 'var(--modal-xxlg-h)',
      },
    },
  },
}));

export default useStyles;
