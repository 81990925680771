import { cloneElement, ReactElement, FC, useContext } from 'react';

import { EventContext } from 'components/context/EventContext';

import { useMe } from 'graphql/hooks/user';

import { EventUserType } from 'types/enums';
import { WithEventRoleProps } from './interfaces';

const WithEventRole: FC<WithEventRoleProps> = ({
  children,
  role,
  extraCondition = true,
  roles = [],
  ...rest
}) => {
  const { user } = useMe();

  const eventContext = useContext(EventContext);
  const extractRole = eventContext?.event?.role || role;

  if (!user || !(roles.includes(extractRole as EventUserType) && extraCondition)) {
    return null;
  }

  return !!children ? cloneElement(children as ReactElement, rest) : null;
};

export default WithEventRole;
