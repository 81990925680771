import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SectionBlockInfo from 'components/sections/blocks/SectionBlock/SectionBlockInfo';
import AddTimelineQuestionButton from 'components/buttons/AddTimelineQuestionButton';
import { TimelineQuestionsContext } from '../TimelineQuestionsContext';

import { getSectionQuestionsInfo } from 'services/sections/helpers';
import { hasQuestions } from 'services/questions/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';

const QuestionsBlockHeader: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const { section } = timelineQuestionsContext;

  return (
    <div className={classNames('questionsBlockHeader', classes.questionsBlockHeader)}>
      {children}
      <SectionBlockInfo
        title={t('questions')}
        info={!hasQuestions(section) ? '' : getSectionQuestionsInfo(section)}
      />
      <AddTimelineQuestionButton
        prefixIcon={IconmoonFont['plus-16']}
        tooltipProps={{
          overlay: t('addQuestion'),
          fillOverlay: true,
          placement: 'top',
        }}
        shape="round"
        size="lg"
      />
    </div>
  );
};

export default QuestionsBlockHeader;
