import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'vibo-ui/Button';
import ViboLogo from 'components/common/ViboLogo';

import { ReactComponent as Heart } from 'resources/img/svg/Dashboard/MenuIcons/triggerHeart.svg';
import { ReactComponent as MadeWithIcon } from 'resources/img/svg/madeWith.svg';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SiderTriggerProps } from './interfaces';

import useStyles from './style';

const SiderExtra: FC<SiderTriggerProps> = ({ isClosed, toggleClose }) => {
  const classes = useStyles();

  return (
    <div className={classNames('siderExtra', classes.siderExtra)}>
      <Button
        onClick={() => toggleClose(!isClosed)}
        prefixIcon={IconmoonFont[isClosed ? 'caretRight-16' : 'caretLeft-16']}
        className={classes.collapseButton}
      />
      <Button
        className={classNames(classes.madeWithLoveButton, {
          closed: isClosed,
        })}
      >
        <ViboLogo className={classes.vibo} />
        {isClosed ? null : (
          <>
            <MadeWithIcon className={classes.madeWith} />
            <Heart className={classes.viboHeart} />
          </>
        )}
      </Button>
    </div>
  );
};

export default SiderExtra;
