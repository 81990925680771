import { ApolloError } from '@apollo/client';
import { openErrorNotification } from 'graphql/hooks/common';

interface ApolloErrorExtended extends Partial<ApolloError> {
  code?: string;
  message: string;
}

export const getGraphQLError = (e: ApolloError): ApolloErrorExtended => {
  if (e.networkError) {
    return {
      message: 'Something went wrong',
    };
  }

  return e.graphQLErrors[0];
};

export const onError = (e: ApolloError) => {
  const error = getGraphQLError(e);

  openErrorNotification(error.message);
};
