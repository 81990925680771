import { gql } from '@apollo/client';
import { QUESTION_V2 } from 'graphql/fragments/questions';

export const GET_EVENT_SECTION_QUESTIONS_V2 = gql`
  query getEventSectionQuestionsV2($eventId: ID!, $sectionId: ID!, $filter: QuestionsFilter) {
    getEventSectionQuestionsV2(eventId: $eventId, sectionId: $sectionId, filter: $filter) {
      questions {
        ...QuestionV2
      }
    }
  }
  ${QUESTION_V2}
`;

export const GET_TEMPLATE_SECTION_QUESTIONS_V2 = gql`
  query getTemplateSectionQuestionsV2($templateId: ID!, $sectionId: ID!, $filter: QuestionsFilter) {
    getTemplateSectionQuestionsV2(templateId: $templateId, sectionId: $sectionId, filter: $filter) {
      questions {
        ...QuestionV2
      }
    }
  }
  ${QUESTION_V2}
`;

export const GET_FAVORITE_SECTION_QUESTIONS_V2 = gql`
  query getFavoriteSectionQuestionsV2($favoriteSectionId: ID!) {
    getFavoriteSectionQuestionsV2(favoriteSectionId: $favoriteSectionId) {
      ...QuestionV2
    }
  }
  ${QUESTION_V2}
`;
