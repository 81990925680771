import { gql } from '@apollo/client';
import { SONG_LINKS, THUMBNAILS } from '../fragments/songs';

export const AUTOCOMPLETE = gql`
  query autocomplete($searchTerm: String, $limit: Int!) {
    autocomplete(searchTerm: $searchTerm, limit: $limit)
  }
`;

export const GET_PLAYLIST_SONGS = gql`
  query getPlaylistSongs(
    $playlistId: ID!
    $source: MusicImportSource!
    $pagination: PaginationInput
  ) {
    getPlaylistSongs(playlistId: $playlistId, source: $source, pagination: $pagination) {
      tracks {
        id
        title
        artist
        songUrl
        images {
          url
          width
          height
        }
      }
      total
      next {
        skip
        limit
      }
    }
  }
`;

export const GET_PLAYLISTS = gql`
  query getPlaylists(
    $source: MusicImportSource!
    $pagination: PaginationInput
    $filter: PlaylistsFilter
  ) {
    getPlaylists(source: $source, pagination: $pagination, filter: $filter) {
      playlists {
        id
        name
        total
        images {
          url
          width
          height
        }
      }
      total
      next {
        limit
        skip
      }
    }
  }
`;

export const GET_SONGS_FOR_SONG_IDEAS = gql`
  query getSongsForSongIdeas($songIdeasId: ID!, $filter: SongsFilter!, $limit: Int!) {
    getSongsForSongIdeas(songIdeasId: $songIdeasId, filter: $filter, limit: $limit) {
      viboSongId
      songUrl
      title
      artist
      thumbnails {
        ...Thumbnails
      }
      links {
        ...SongLinks
      }
      isInSongIdeas
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_SONGS = gql`
  query getSongs($eventId: ID!, $sectionId: ID!, $filter: SongsFilter!, $limit: Int!) {
    getSongs(eventId: $eventId, sectionId: $sectionId, filter: $filter, limit: $limit) {
      sectionSongId
      viboSongId
      songUrl
      title
      artist
      thumbnails {
        ...Thumbnails
      }
      links {
        ...SongLinks
      }
      isInSection
      isDontPlay
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_EVENT_SECTION_SONGS = gql`
  query getSectionSongs(
    $eventId: ID!
    $sectionId: ID!
    $filter: SectionSongsFilter
    $pagination: PaginationInput
    $sort: SongsSortInput
  ) {
    getSectionSongs(
      eventId: $eventId
      sectionId: $sectionId
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      songs {
        _id
        viboSongId
        artist
        title
        thumbnails {
          ...Thumbnails
        }
        isMustPlay
        isFlagged
        createdAt
        creator {
          _id
          firstName
          lastName
          image {
            cropped {
              small
              medium
            }
          }
        }
        comment
        likedByMe
        likesCount
        commentsCount
        isAddedByMe
        links {
          ...SongLinks
        }
        currentTime
        canRemove
      }
      next {
        limit
        skip
      }
      totalCount
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_SECTION_SONGS_STATS = gql`
  query getSectionSongsStats($eventId: ID!, $sectionId: ID!, $filter: SectionSongsStatsFilter) {
    getSectionSongsStats(eventId: $eventId, sectionId: $sectionId, filter: $filter) {
      totalCount
      mustPlayCount
      hostLikedCount
      withCommentsCount
      flaggedCount
    }
  }
`;
