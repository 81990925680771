import { gql } from '@apollo/client';

export const CLONE_FAVORITE_SECTION = gql`
  mutation cloneFavoriteSection($favoriteSectionId: ID!, $name: String!) {
    cloneFavoriteSection(favoriteSectionId: $favoriteSectionId, name: $name) {
      _id
      name
    }
  }
`;

export const CLONE_TEMPLATE_SECTION = gql`
  mutation cloneTemplateSection($templateId: ID!, $sectionId: ID!, $name: String) {
    cloneTemplateSection(templateId: $templateId, sectionId: $sectionId, name: $name) {
      _id
      name
    }
  }
`;

export const CLONE_EVENT_SECTION = gql`
  mutation cloneEventSection($eventId: ID!, $sectionId: ID!, $name: String) {
    cloneEventSection(eventId: $eventId, sectionId: $sectionId, name: $name) {
      _id
      name
    }
  }
`;

export const ADD_EVENT_SECTION_TO_FAVORITE = gql`
  mutation addSectionToFavoritesFromEvent($sectionId: ID!, $eventId: ID!, $name: String) {
    addSectionToFavoritesFromEvent(sectionId: $sectionId, eventId: $eventId, name: $name) {
      _id
      name
    }
  }
`;

export const REMOVE_SECTION_FROM_FAVORITES = gql`
  mutation removeSectionFromFavorites($favoriteSectionId: ID!) {
    removeSectionFromFavorites(favoriteSectionId: $favoriteSectionId)
  }
`;

export const ADD_SECTION_FROM_FAVORITE_EVENT = gql`
  mutation addSectionFromFavoritesToEvent(
    $favoriteSectionId: ID!
    $eventId: ID!
    $insertBeforeSectionId: ID
  ) {
    addSectionFromFavoritesToEvent(
      favoriteSectionId: $favoriteSectionId
      eventId: $eventId
      insertBeforeSectionId: $insertBeforeSectionId
    ) {
      _id
    }
  }
`;

export const ADD_SECTION_FROM_FAVORITE_TEMPLATE = gql`
  mutation addSectionFromFavoritesToTemplate(
    $favoriteSectionId: ID!
    $templateId: ID!
    $insertBeforeSectionId: ID
  ) {
    addSectionFromFavoritesToTemplate(
      favoriteSectionId: $favoriteSectionId
      templateId: $templateId
      insertBeforeSectionId: $insertBeforeSectionId
    ) {
      _id
    }
  }
`;

export const ADD_TEMPLATE_SECTION_TO_FAVORITE = gql`
  mutation addSectionToFavoritesFromTemplate($sectionId: ID!, $templateId: ID!, $name: String) {
    addSectionToFavoritesFromTemplate(sectionId: $sectionId, templateId: $templateId, name: $name) {
      _id
      name
    }
  }
`;

export const CHECK_FAVORITE_SECTION_EXISTS = gql`
  mutation checkFavoriteSectionNameAlreadyExists($name: String!) {
    checkFavoriteSectionNameAlreadyExists(name: $name) {
      isExists
      name
    }
  }
`;

export const ADD_SONG_IDEAS_TO_FAVORITE_SECTION = gql`
  mutation addSongIdeasToFavoriteSection(
    $favoriteSectionId: ID!
    $payload: AddSongIdeasToSectionInput!
  ) {
    addSongIdeasToFavoriteSection(favoriteSectionId: $favoriteSectionId, payload: $payload) {
      addedCount
      totalCount
    }
  }
`;

export const DELETE_SONG_IDEAS_FROM_FAVORITE_SECTION = gql`
  mutation deleteSongIdeasFromFavoriteSection(
    $favoriteSectionId: ID!
    $payload: DeleteSongIdeasFromSectionInput!
  ) {
    deleteSongIdeasFromFavoriteSection(favoriteSectionId: $favoriteSectionId, payload: $payload)
  }
`;

export const UPDATE_FAVORITE_SECTION = gql`
  mutation updateFavoriteSection($favoriteSectionId: ID!, $payload: UpdateFavoriteSectionInput!) {
    updateFavoriteSection(favoriteSectionId: $favoriteSectionId, payload: $payload) {
      _id
      name
    }
  }
`;

export const CREATE_FAVORITE_SECTION = gql`
  mutation createFavoriteSection($payload: CreateSectionInput!) {
    createFavoriteSection(payload: $payload) {
      _id
    }
  }
`;
