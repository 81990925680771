import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  layout: {
    width: '100%',
    height: '100vh',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',

    '& > .content': {
      width: '100%',
      overflow: 'hidden',
      height: '100%',
      position: 'relative',
    },
  },
}));

export default useStyles;
