import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import Button from 'vibo-ui/Button';
import Container from 'components/common/Container';
import ContentArea from 'components/common/ContentArea';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { CONNECT_SPOTIFY_VIA_CODE } from 'graphql/mutations/spotify';

import useStyles from './style';

const AuthSpotify: FC = () => {
  const classes = useStyles();

  const { location } = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code');

  const [connectSpotifyViaCode, { error }] = useMutation(CONNECT_SPOTIFY_VIA_CODE, {
    variables: {
      code,
    },
    onCompleted: () => {
      window.opener.parent.postMessage('refetchMe', '*');
      window.close();
    },
    onError: () => {},
  });

  useEffect(() => {
    connectSpotifyViaCode();
  }, [connectSpotifyViaCode]);

  return (
    <Container>
      {error ? (
        <ContentArea className={classes.authSpotify}>
          <Icon icon={IconmoonFont['stop-16']} disableAutoFontSize />
          <div>
            <h3>Connection error</h3>
            <span>Try again to connect Spotify</span>
            <Button onClick={() => window.close()} displayType="bordered" shape="round" size="lg">
              Close
            </Button>
          </div>
        </ContentArea>
      ) : (
        <LoadingOverlay />
      )}
    </Container>
  );
};

export default AuthSpotify;
