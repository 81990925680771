import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songsInfo: {
    minWidth: 'fit-content',
    width: '100%',

    '& .songPreview , & .songArtistNames': {
      color: 'inherit',
    },
  },

  songPreview: {
    height: 'fit-content',
    width: '100%',
    margin: '0',
    justifyContent: 'flex-start',

    '& .imageContainer': {
      ...theme.mixins.getStaticIconSize?.(30),
      margin: '0 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        ...theme.mixins.getStaticIconSize?.(36),
        margin: '0 8px 0 0',
      },
    },

    '& .songArtistNames': {
      color: 'inherit',
      maxWidth: '200px',

      '& .songName, & .artistName': {
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '14px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },

      '& .songName': {
        fontWeight: 500,
      },

      '@media screen and (min-width: 1441px)': {
        maxWidth: '280px',
      },
    },
  },
}));

export default useStyles;
