import React, { FC, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { ViboThemeEnum } from 'components/context/ViboThemeContext/interfaces';
import { ThemeModeNames } from './interfaces';

import useStyles from './style';

const ThemeControl: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isDark, isSysThemeUsing, setTheme, setIsSysThemeUsing } = useContext(ViboThemeContext);

  const handleUpdateTheme = useCallback(
    (theme: ViboThemeEnum) => {
      setTheme?.(theme);
      isSysThemeUsing && setIsSysThemeUsing(false);
    },
    [isSysThemeUsing]
  );

  const handleUseSysTheme = useCallback(() => {
    setIsSysThemeUsing(true);
  }, [isDark]);

  const activeKey = useMemo(
    () =>
      isSysThemeUsing ? ThemeModeNames.system : isDark ? ThemeModeNames.dark : ThemeModeNames.light,
    [isSysThemeUsing, isDark]
  );

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['sun-24']} className={classes.generalIcon} />
        {t('theme')}
        <Menu className="themeMenu" defaultSelectedKeys={[activeKey]} styleModificator="asTabsLg">
          <Menu.Item
            onClick={() => handleUpdateTheme(ViboThemeEnum.light)}
            key={ThemeModeNames.light}
          >
            {t('light')}
          </Menu.Item>
          <Menu.Item
            onClick={() => handleUpdateTheme(ViboThemeEnum.dark)}
            key={ThemeModeNames.dark}
          >
            {t('dark')}
          </Menu.Item>
          <Menu.Item onClick={handleUseSysTheme} key={ThemeModeNames.system}>
            {t('systemDefault')}
          </Menu.Item>
        </Menu>
      </div>
    </SettingsItem>
  );
};

export default ThemeControl;
