import { gql } from '@apollo/client';
import { USER_DATA } from '../fragments/user';

export const UPDATED_USER = gql`
  subscription updatedUser($userId: ID!) {
    userUpdated(userId: $userId) {
      ...UserData
    }
  }
  ${USER_DATA}
`;
