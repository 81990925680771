import queryString from 'querystring';

import { LoginSources } from 'types/enums';

export const interceptZapierLogin = (userId: string): boolean => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('source') === LoginSources.zapier) {
    const redirectUri = searchParams.get('redirect_uri');
    const state = searchParams.get('state');

    if (state && redirectUri) {
      const queryParams = {
        code: userId,
        state,
      };

      window.location.href = `${redirectUri}?${queryString.stringify(queryParams)}`;

      return true;
    }
  }

  return false;
};
