import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  section: {
    padding: '10px 0',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    fontWeight: 500,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    width: '100%',
    boxShadow: theme.shadows[5],

    '& .editableSectionName': {
      height: 'auto!important',
      whiteSpace: 'pre-wrap!important',
    },

    '&:not(.selected), &.headline': {
      '& .sectionTime.withTime': {
        color: theme.palette.text.primary,
      },

      '& .progressBtn:not(.done)': {
        color: theme.palette.primary.main,
      },

      '& .editableSectionName:hover': {
        color: theme.palette.text.primary,
      },
    },

    '& .sectionExtra': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',
      gap: '4px',
      height: '30px',

      '& .threeDotsIcon': {
        opacity: 0,
      },

      '& .iconButton': {
        color: 'inherit',
      },

      '& .sectionTime:not(.withTime) .viboInput:not(:focus)': {
        opacity: 0,
      },

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '&.dontPlay': {
      '&:not(.selected)': {
        '&:before': {
          backgroundColor: colors.carnation,
        },
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.selection.sectionHover,

      '& .sectionExtra': {
        '& .viboToggleDropdown': {
          color: theme.palette.primary.main,
        },

        '& .threeDotsIcon': {
          opacity: 1,
        },

        '& .sectionTime .viboInput': {
          opacity: '1!important',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '12px 0',
    },
  },

  selected: {
    '&:not(.headline)': {
      color: colors.white,
      backgroundColor: isDark ? theme.palette.primary.light : theme.palette.primary.main,

      '& .editableSectionName': {
        '&.textPreview, &.viboTextarea': {
          color: `${colors.white}!important`,

          '&:focus:not(.disabled):not(:disabled)': {
            color: `${theme.palette.text.primary}!important`,
            background: `${theme.palette.background.paper}!important`,
          },
        },
      },

      '& .dndSectionIcon, & .iconsWrapper, & .sectionExtra .viboToggleDropdown': {
        color: 'inherit',
      },

      '& .sectionTime': {
        '& .viboInput:not(:focus)': {
          '&, &::placeholder': {
            color: colors.white,
          },
        },

        '& .clearTimeWrapper:not(.focused)': {
          '& .clearIcon': {
            color: colors.white,
          },
        },
      },

      '&:not(.prepModeSection):not(.beatsourceSection)': {
        '&:hover': {
          '& .editableSectionName:hover:not(.disabled):not(:disabled):not(:focus)': {
            background: theme.palette.primary.main,
          },
        },
      },
    },

    '&:hover': {
      backgroundColor: isDark ? theme.palette.primary.light : theme.palette.button.active,

      '&:after': {
        borderLeftColor: `${
          isDark ? theme.palette.primary.light : theme.palette.button.active
        }!important`,
      },

      '& .text': {
        color: theme.palette.background.paper,
      },

      '& .editableSectionName': {
        '& > input': {
          background: theme.palette.background.paper,
        },
      },
    },

    '&.headline': {
      '&.section': {
        border: `1px solid ${theme.palette.primary.main}!important`,
        backgroundColor: theme.palette.selection.hover,
      },
    },

    '&:not(.headline):not(.prepModeSection):not(.dontPlay)': {
      '& .placeholder': {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },

  isDragging: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.light} !important`,
  },

  sectionTop: {
    padding: '0 12px 0 20px',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '13px',
    lineHeight: '18.2px',
    gap: '4px',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  middle: {
    margin: '10px 0 0 0',
    padding: '0 12px 0 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      margin: '12px 0 0 0',
    },
  },

  bottom: {
    margin: '10px 0 0 0',
    padding: '0 12px 0 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',

    '& > div:first-child': {
      width: 'fit-content',
      minWidth: '20px',
      display: 'flex',
      height: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 16px 0 26px',
      margin: '12px 0 0 0',
    },
  },
}));

export default useStyles;
