import React, {
  FC,
  MouseEvent,
  useEffect,
  useState,
  useCallback,
  HTMLProps,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import { Key } from 'ts-key-enum';

import Input from 'vibo-ui/Input';
import Tooltip from 'vibo-ui/Tooltip';
import Spinner from 'vibo-ui/Spinner';
import TimeOptions from './TimeOptions';
import Dropdown from 'vibo-ui/Dropdown';
import ClickAwayListener from 'vibo-ui/ClickAwayListener';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import {
  TIME_SUGGESTIONS,
  checkTimeRegexValid,
  getTimeSearchValue,
  parseIncorrectTime,
} from './timeHelper';

import { TimePickerProps } from './interfaces';

import useStyles from './style';

const TimePicker: FC<TimePickerProps & HTMLProps<HTMLInputElement>> = ({
  value,
  loading,
  disabled,
  withTooltip,
  wrapperClassName,
  tooltipClassName,
  autoFocus,
  onChangeTime,
  onChange,
  withUnderline = true,
  placeholder = 'hh:mm',
  tooltipIcon = <Icon className="tooltipIcon" icon={IconmoonFont['clock-24']} />,
  ...rest
}) => {
  const classes = useStyles();

  const [focused, setFocused] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>('');
  const [activeTime, setActiveTime] = useState<string>('');
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const inputRef = useRef<Nullable<HTMLInputElement>>(null);

  const handleSelectPrevTime = useCallback(() => {
    if (focused) {
      const currentIndex = suggestions.indexOf(activeTime);
      const nextIndex = (currentIndex - 1 + suggestions.length) % suggestions.length;

      setActiveTime(suggestions[nextIndex]);
    }
  }, [focused, activeTime, currentValue]);

  const handleSelectNextTime = useCallback(() => {
    if (focused) {
      const currentIndex = suggestions.indexOf(activeTime);
      const nextIndex = (currentIndex + 1) % suggestions.length;

      setActiveTime(suggestions[nextIndex]);
    }
  }, [focused, activeTime, currentValue]);

  const handleConfirmActiveTime = useCallback(() => {
    if (focused) {
      if (!!activeTime) {
        setCurrentValue(activeTime);
      } else {
        handleSetValidTime();
      }

      handleBlur();
      setFocused(false);
    }
  }, [activeTime, currentValue, focused]);

  const handleBlur = useCallback(() => {
    setActiveTime('');
    setFocused(false);
    inputRef.current?.blur();
  }, []);

  useKeyPressEvent(Key.ArrowUp, handleSelectPrevTime);
  useKeyPressEvent(Key.ArrowDown, handleSelectNextTime);
  useKeyPressEvent('Tab', handleBlur);
  useKeyPressEvent('w', handleSelectPrevTime);
  useKeyPressEvent('s', handleSelectNextTime);

  const handleBlurPicker = useCallback(() => {
    if (focused) {
      handleSetValidTime();

      handleBlur();
    }
  }, [focused, currentValue]);

  const handleSelectInput = (e: MouseEvent<HTMLInputElement>) => {
    setFocused(true);

    if (!focused) {
      // @ts-ignore
      e.target.select();
    }
  };

  const handleSetValidTime = useCallback(() => {
    const parsedTime = parseIncorrectTime(currentValue).trim();

    if (!currentValue || checkTimeRegexValid(parsedTime)) {
      setCurrentValue(parsedTime);
      onChangeTime?.(parsedTime);
    } else {
      setUpDefaultTime(true);
    }
  }, [currentValue]);

  const handleSelectTime = useCallback((e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const time = e.currentTarget.textContent;

    if (!!time) {
      setCurrentValue(time);
      onChangeTime?.(time);
      handleBlur();
    }
  }, []);

  const suggestions = useMemo(
    () =>
      TIME_SUGGESTIONS.filter(suggestion => {
        const searchSource = getTimeSearchValue(currentValue);
        const searchTarget = getTimeSearchValue(suggestion);

        return searchTarget.includes(searchSource);
      }),
    [currentValue]
  );

  const setUpDefaultTime = useCallback(
    (resetToEmpty?: boolean) => {
      const defaultValue = `${value || rest.defaultValue}`;

      if (checkTimeRegexValid(defaultValue)) {
        return setCurrentValue(defaultValue);
      }

      if (resetToEmpty) {
        setCurrentValue('');
      }
    },
    [value, rest.defaultValue]
  );

  useEffect(() => {
    setUpDefaultTime();
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      setFocused(true);
    }
  }, []);

  const loadingIndicator = loading ? <Spinner size={12} /> : null;

  const Picker = (
    <Dropdown
      overlayClassName={classNames('timePickerOverlay', classes.timePickerOverlay)}
      toggleClassName={classNames('timePickerToggle', classes.timePickerToggle)}
      overlay={
        <TimeOptions
          activeTime={activeTime}
          suggestions={suggestions}
          handleSelectTime={handleSelectTime}
        />
      }
      visible={focused && !disabled}
    >
      <div
        className={classNames('clearTimeWrapper', classes.clearTimeWrapper, {
          iconUnderlinePosition: withUnderline,
          focused,
        })}
      >
        <Input
          value={currentValue}
          onChange={e => {
            setCurrentValue(e.currentTarget.value);
            onChange?.(e);
          }}
          autoFocus={autoFocus}
          placeholder={activeTime || placeholder}
          onClick={handleSelectInput}
          tabIndex={-1}
          disabled={disabled}
          className={classNames(classes.viboTimeInput, rest.className)}
          withUnderline={withUnderline}
          onPressEnter={handleConfirmActiveTime}
          ref={inputRef}
          isTime
        />
        {loading ? (
          loadingIndicator
        ) : disabled || !value ? null : (
          <Icon
            onClick={() => {
              setCurrentValue('');
              onChangeTime?.('');
            }}
            className="clearIcon"
            icon={IconmoonFont['closeCircleFilled-16']}
          />
        )}
      </div>
    </Dropdown>
  );

  return (
    <ClickAwayListener className={wrapperClassName} onClickAway={handleBlurPicker}>
      {withTooltip ? (
        <Tooltip
          onVisibleChange={(visible: boolean) => {
            setTooltipVisible(visible);
            setFocused(visible);
          }}
          type="empty"
          trigger={['click']}
          overlay={Picker}
        >
          <div className={classNames(classes.tooltipContent, tooltipClassName)}>
            {tooltipVisible ? tooltipIcon : currentValue || tooltipIcon}
            {loadingIndicator}
          </div>
        </Tooltip>
      ) : (
        Picker
      )}
    </ClickAwayListener>
  );
};

export default TimePicker;
