import React from 'react';
import classNames from 'classnames';

import Like from '../Like';
import MustPlay from '../MustPlay';
import SongComment from '../SongComment';
import WithEventRole from 'components/user/WithEventRole';
import SongCreatorImage from 'components/image/SongCreatorImage';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';

import { EventUserType } from 'types/enums';
import { SongExtraProps } from './interfaces';

import useStyles from './style';

const SongExtra = <T extends SectionSong>({
  commentPopupRef,
  song,
  className,
}: SongExtraProps<T>) => {
  const classes = useStyles();

  return (
    <StopPropagationWrapper>
      <div className={classNames('songExtra', classes.songExtra, className)}>
        <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
          <>
            <SongComment song={song} ref={commentPopupRef} />
            <MustPlay song={song as SectionSong} />
          </>
        </WithEventRole>
        <SongCreatorImage
          song={song}
          isLazy={false}
          data-gtm-target-id="timeline-section-details-songs-song-added-by"
        />
        <Like song={song} data-gtm-target-id="timeline-section-details-songs-song-like" />
      </div>
    </StopPropagationWrapper>
  );
};

export default SongExtra;
