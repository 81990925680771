import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import TimePicker from 'vibo-ui/TimePicker';
import TimePreviewGuest from './TimePreviewGuest';
import WithEventRole from 'components/user/WithEventRole';

import { EventUserType } from 'types/enums';
import { SectionTimeProps } from './interfaces';

import useStyles from './style';

const SectionTime: FC<SectionTimeProps> = ({ onTimeChange, disabled = false, time = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleTimeChange = useCallback(
    debounce(newTime => {
      time !== newTime && onTimeChange?.(newTime);
    }, 200),
    [time]
  );

  return (
    <>
      {!!time ? (
        <WithEventRole roles={[EventUserType.guest]}>
          <TimePreviewGuest time={time} />
        </WithEventRole>
      ) : null}
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <div
          className={classNames('sectionTime', classes.sectionTime, {
            withTime: !!time,
          })}
        >
          <TimePicker
            value={time as string}
            onChangeTime={timeoption => {
              handleTimeChange(timeoption);
            }}
            disabled={disabled}
            placeholder={t('time')}
            withUnderline={false}
            data-gtm-target-id="timeline-section-time"
          />
        </div>
      </WithEventRole>
    </>
  );
};

export default SectionTime;
