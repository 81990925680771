import { TierFeatueName } from 'types/enums';

export const isBrandingActive = (features?: TierFeature[]) => {
  return features?.some(({ name }) => name === TierFeatueName.branding) || false;
};

export const isCoverPhotoActive = (features?: TierFeature[]) => {
  return features?.some(({ name }) => name === TierFeatueName.image_to_section) || false;
};

export const getPraparedEventWithTier = (event: Maybe<EventItem>, isBrandingAllowed: boolean) => {
  const preparedEvent = {
    ...event,
    eventColors: isBrandingAllowed
      ? event?.eventColors
      : {
          customEventLightModeColor: undefined,
          customEventDarkModeColor: undefined,
          defaultEventLightModeColor: undefined,
          defaultEventDarkModeColor: undefined,
        },
  };

  return preparedEvent;
};

export const getPraparedUserWithTier = (user: Maybe<User>, isBrandingAllowed: boolean) => {
  const preparedUser = !!user
    ? {
        ...user,
        applicationColors: isBrandingAllowed
          ? user.applicationColors
          : {
              customLightModeApplicationColor: undefined,
              customDarkModeApplicationColor: undefined,
              defaultLightModeApplicationColor: undefined,
              defaultDarkModeApplicationColor: undefined,
            },
      }
    : undefined;

  return preparedUser;
};
