import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  accountLayout: {
    background: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .content': {
      display: 'flex',
      flexDirection: 'row',

      '& .photos, & .info': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& .photos': {
        margin: '0 55px 0 0',

        '& .profilePicture': {
          borderRadius: '4px',

          '&:not(:last-child)': {
            margin: '0 0 20px 0',

            '@media screen and (min-width: 1441px)': {
              margin: '0 0 24px 0',
            },
          },
        },

        '@media screen and (min-width: 1441px)': {
          margin: '0 72px 0 0',
        },
      },

      '& .info': {
        width: '100%',

        '& .blockTitle': {
          fontSize: '13px',
          lineHeight: '19.5px',
          fontWeight: 500,
          color: theme.palette.text.secondary,
          margin: '0 0 10px 0',

          '@media screen and (min-width: 1441px)': {
            margin: '0 0 12px 0',
            fontSize: '16px',
            lineHeight: '24px',
          },
        },

        '& .row': {
          '&:not(:last-child)': {
            margin: '0 0 20px 0',

            '@media screen and (min-width: 1441px)': {
              margin: '0 0 24px 0',
            },
          },
        },

        '& .contentArea': {
          padding: '20px',

          '&:not(:last-child)': {
            margin: '0 0 20px 0',

            '@media screen and (min-width: 1441px)': {
              margin: '0 0 24px 0',
            },
          },

          '@media screen and (min-width: 1441px)': {
            padding: '24px',
          },
        },
      },
    },
  },

  container: {
    padding: '0 15px',
    height: 'calc(100% - 40px)',

    '& > .containerContent': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',

      '& > .viboScrollbar': {
        marginTop: 0,
        marginBottom: 0,
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100% - 48px)',
    },
  },
}));

export default useStyles;
