import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import Menu from 'vibo-ui/Menu';
import Tooltip from 'vibo-ui/Tooltip';
import Image from 'components/image/Image';
import PublicLabel from 'components/labels/PublicLabel';
import ContentArea from 'components/common/ContentArea';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { getRedirectPlaylistLink } from 'services/songIdeas/helpers';

import { CLONE_SONG_IDEAS } from 'graphql/mutations/songIdeas';

import { DeleteSongIdeasProps } from 'components/modals/DeleteSongIdeasModal';
import { CreatePlaylistModalProps } from 'components/modals/CreatePlaylistModal';
import { CloneModalProps, ClonePayloadProps } from 'components/modals/CloneModal/interfaces';
import { ButtonIconType, Modals, SongIdeasType } from 'types/enums';
import { PlaylistCardProps } from './interfaces';

import useStyles from './style';

const PlaylistCard: FC<PlaylistCardProps> = ({
  onClick,
  playlist,
  className,
  withActions = false,
  image = '',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { params } = useRouteMatch<{ type: SongIdeasType }>();

  const location = useLocation<SongIdeasLocationState>();
  const { push } = useHistory();

  const { openModal } = useModal();

  const openCloneModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: playlist.title,
          },
          onSubmit: (payload: ClonePayloadProps) => {
            cloneSongIdeas({
              variables: {
                songIdeasId: playlist._id,
                payload,
              },
            });
          },
          title: t('clonePlaylist'),
          label: t('playlistName'),
          closeOnSubmit: true,
        },
      }),
    [playlist.title]
  );
  const openCreatePlaylistModal = useCallback(
    () =>
      openModal<CreatePlaylistModalProps>({
        key: Modals.createPlaylist,
        props: {
          editMode: true,
          data: {
            title: playlist?.title,
            description: playlist.description || '',
            id: playlist._id,
            coverPhoto: playlist?.coverPhoto?.cropped?.medium,
          },
        },
      }),
    [playlist.title, playlist.description, playlist._id, playlist.coverPhoto]
  );
  const openDeletePlaylistModal = useCallback(
    () =>
      openModal<DeleteSongIdeasProps>({
        key: Modals.deletePlaylist,
        props: {
          playlistTitle: playlist.title,
          playlistId: playlist._id,
          refetchQueries: ['getUserSongIdeas'],
        },
      }),
    [playlist.title, playlist._id]
  );

  const [cloneSongIdeas] = useMutation(CLONE_SONG_IDEAS, {
    onCompleted: ({ cloneSongIdeas }) => {
      push(getRedirectPlaylistLink(cloneSongIdeas._id), location.state);
      toastNotify({
        text: t('playlistWasCopiedToMyIdeasSuccessfully'),
        iconType: ButtonIconType.copy,
      });
    },
    onError,
    refetchQueries: ['getUserSongIdeas'],
  });

  return (
    <ContentArea
      className={classNames('playlistCard', classes.playlistCard, className)}
      onClick={onClick}
      shadowed
    >
      <div className={classes.cardMiddle}>
        <Image src={image} token={playlist.title} />
        {!image ? (
          <Icon icon={IconmoonFont['musicNoteDoubleOutlined-24']} disableAutoFontSize />
        ) : null}
      </div>
      <div className="songIdeasTitle">{playlist.title}</div>
      <div className={classes.cardBottom}>
        <div className="songsCount">
          <Icon icon={IconmoonFont['musicNoteDoubleOutlined-16']} />
          {playlist.songsCount || null}
        </div>
        {withActions ? (
          <Tooltip
            trigger={['click']}
            className={classes.tooltipMenu}
            overlay={
              <Menu
                onClick={({ domEvent }) => domEvent.stopPropagation()}
                styleModificator="asDropdown"
                selectable={false}
              >
                <Menu.Item
                  onClick={openCloneModal}
                  icon={IconmoonFont['clone-16']}
                  key="clone-playlist-btn"
                >
                  {t('cloneToPlaylists')}
                </Menu.Item>
                {playlist.isOwner ? (
                  <Menu.Item
                    onClick={openCreatePlaylistModal}
                    icon={IconmoonFont['editPencil-16']}
                    key="edit-playlist-btn"
                  >
                    {t('editPlaylist')}
                  </Menu.Item>
                ) : null}
                {playlist.isOwner ? (
                  <Menu.Item
                    onClick={openDeletePlaylistModal}
                    icon={IconmoonFont['delete-16']}
                    key="delete-playlist-btn"
                  >
                    {t('deletePlaylist')}
                  </Menu.Item>
                ) : null}
              </Menu>
            }
          >
            <Icon onClick={e => e.stopPropagation()} icon={IconmoonFont['threeDots-16']} />
          </Tooltip>
        ) : null}
      </div>
      {playlist.isPublic && params.type !== SongIdeasType.vibo ? (
        <PublicLabel className={classNames('whiteBg', classes.publicLabel)} />
      ) : null}
    </ContentArea>
  );
};

export default PlaylistCard;
