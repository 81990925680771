import React, { FC, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { getRedirectPlaylistLink } from 'services/songIdeas/helpers';

import { CLONE_SONG_IDEAS } from 'graphql/mutations/songIdeas';

import { ButtonIconType, Modals } from 'types/enums';
import { CloneModalProps } from 'components/modals/CloneModal';
import { ClonePlaylistButtonProps } from './interfaces';

const ClonePlaylistButton: FC<ClonePlaylistButtonProps> = ({ playlist, ...rest }) => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const { openModal } = useModal();

  const openClonePlaylistModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: playlist.title,
          },
          onSubmit: payload => {
            cloneSongIdeas?.({
              variables: {
                songIdeasId: playlist._id,
                payload,
              },
            });
          },
          title: t(playlist.isOwner ? 'clonePlaylist' : 'addToMyPlaylists'),
          label: t('playlistName'),
          closeOnSubmit: true,
        },
      }),
    [playlist.isOwner, playlist._id, playlist.title]
  );

  const [cloneSongIdeas] = useMutation(CLONE_SONG_IDEAS, {
    variables: {
      songIdeasId: playlist._id,
      payload: {
        title: playlist.title,
      },
    },
    onCompleted: ({ cloneSongIdeas }) => {
      push(getRedirectPlaylistLink(cloneSongIdeas._id));

      toastNotify({
        text: t('playlistCopiedToMyIdeasSuccessfully'),
        iconType: ButtonIconType.clone,
      });
    },
    onError,
  });

  return (
    <Button onClick={openClonePlaylistModal} size="lg" {...rest}>
      {t(playlist.isOwner ? 'clonePlaylist' : 'addToMyPlaylists')}
    </Button>
  );
};

export default ClonePlaylistButton;
