import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineSongsHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0',
    zIndex: 5,
    position: 'sticky',
    top: '34px',

    '& > .stopPropagationWrapper': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },

    '& .action': {
      padding: 0,
      height: '24px',
      color: theme.palette.primary.main,

      '& .viboIcon': {
        margin: 'auto',
        color: 'inherit',

        '& svg': {
          margin: 'auto',
          color: 'inherit',
        },

        '@media screen and (min-width: 1441px)': {
          margin: '0 6px 0 0',
        },
      },

      '&:hover': {
        color: theme.palette.button.active,
      },

      '@media screen and (min-width: 1441px)': {
        height: '28px',
      },
    },

    '& .top, & .bottom': {
      padding: '0 12px 0 25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '@media screen and (min-width: 1441px)': {
        padding: '0 16px 0 28px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      top: '41px',
    },
  },

  top: {
    height: '40px',

    '& .searchInputWrapper': {
      width: 'fit-content',

      '& .iconPrefix': {
        left: 0,
      },

      '& .viboInput ': {
        width: '150px',
        border: 'none',
        lineHeight: '24px',
        paddingLeft: '24px!important',

        '@media screen and (min-width: 1441px)': {
          width: '170px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: '48px',
    },
  },

  bottom: {
    height: '36px',

    '&.highlighted': {
      backgroundColor: theme.palette.primary.lighty,
    },

    '@media screen and (min-width: 1441px)': {
      height: '40px',
    },
  },

  reorderBtn: {
    padding: '0 6px',
    margin: '0 6px 0 0',

    '@media screen and (min-width: 1441px)': {
      margin: '0 8px 0 0',
    },
  },

  selectedSongsCounter: {
    color: theme.palette.text.secondary,
    margin: '0 12px 0 6px',
    fontSize: '13px',
    lineHeight: '18.2px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 14px 0 10px',
    },
  },

  sortFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 0 auto',
    width: 'fit-content',

    '& .viboButton': {
      padding: '0 6px',

      '&:hover': {
        backgroundColor: theme.palette.selection.filterHover,
      },
    },

    '& .viboSelect': {
      '& .highlighted': {
        color: theme.palette.primary.main,
      },

      '&:hover': {
        '& .highlighted, & .viboSelect__indicator': {
          color: theme.palette.button.active,
        },
      },
    },
  },

  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > .confirmActionButton:not(:first-of-type), & > .action': {
      margin: '0 0 0 16px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 22px',
      },
    },

    //for gtm tracking propper target
    '& .action': {
      padding: '0',
      flexDirection: 'row',

      '& > *': {
        pointerEvents: 'none',
      },
    },
  },

  confirmAction: {
    '& button': {
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0.4px 5px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  },

  action: {
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.4px 5px',
    fontSize: '13px',
    lineHeight: '19.5px',
    fontWeight: 500,
    color: colors.white,

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '11px',
    },
  },
}));

export default useStyles;
