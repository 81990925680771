import React from 'react';
import { Redirect } from 'react-router';
import { useReactiveVar } from '@apollo/client';

import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';

import { isLoggedInVar } from 'graphql/cache';

import { LoginSources } from 'types/enums';

const AuthNotRequired = (props: any) => {
  const source = new URLSearchParams(window.location.search).get('source');

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  if (source === LoginSources.zapier) {
    return props.children;
  }

  return !isLoggedIn ? (
    <ModalWindowContextProvider>{props.children}</ModalWindowContextProvider>
  ) : (
    <Redirect to="/events/upcoming" />
  );
};

export default AuthNotRequired;
