import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Key } from 'ts-key-enum';

import Scrollbar from 'vibo-ui/Scrollbar';

import { TimeOptionProps, TimeOptionsProps } from './interfaces';

import useStyles from './style';

const TimeOption: FC<TimeOptionProps> = ({ handleSelectTime, activeTime = '', option = '' }) => {
  const timeOptionRef = useRef<Nullable<HTMLLIElement>>(null);

  useEffect(() => {
    if (activeTime === option) {
      timeOptionRef.current?.focus();
    }
  }, [activeTime, option]);

  return (
    <li
      className={classNames({
        active: activeTime === option,
      })}
      tabIndex={1}
      onMouseDown={handleSelectTime}
      onKeyDown={e => {
        if (e.key === Key.Enter) {
          // @ts-ignore
          handleSelectTime?.(e);
        }
      }}
      ref={timeOptionRef}
    >
      {option}
    </li>
  );
};

const TimeOptions: FC<TimeOptionsProps> = ({ activeTime, suggestions, handleSelectTime }) => {
  const classes = useStyles();

  return (
    <Scrollbar className={classes.optionsScroll} shiftThumbStyle="inset" disableKeyScrolling>
      <ul className={classes.timeOptions}>
        {suggestions.map(timeOption => (
          <TimeOption
            activeTime={activeTime}
            handleSelectTime={handleSelectTime}
            option={timeOption}
            key={timeOption}
          />
        ))}
      </ul>
    </Scrollbar>
  );
};

export default TimeOptions;
