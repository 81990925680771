import React, { FC } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import { openErrorNotification, toastNotify } from 'graphql/hooks/common';

import { RESTORE_EVENT } from 'graphql/mutations/events';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType } from 'types/enums';
import { RestoreEventButtonProps } from './interfaces';

const RestoreEventButton: FC<RestoreEventButtonProps> = ({ event, className }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [restoreEvent, { loading: restoreEventLoading }] = useMutation(RESTORE_EVENT, {
    onError: () => openErrorNotification(t('somethingWentWrong')),
    onCompleted: () => {
      toastNotify({ text: t('eventHasRestored'), iconType: ButtonIconType.plus });
      history.push('/events/upcoming');
    },
    variables: {
      eventId: event._id,
    },
  });

  return (
    <Button
      onClick={() => restoreEvent()}
      loading={restoreEventLoading}
      className={classNames('centrate', className)}
      prefixIcon={IconmoonFont['refresh-24']}
      displayType="link"
      shape="round"
      size="lg"
    >
      {t('restoreEvent')}
    </Button>
  );
};

export default RestoreEventButton;
