import React, { useMemo } from 'react';
import Tooltip from 'vibo-ui/Tooltip';
import classNames from 'classnames';

import CroppedUserImage from '../../user/images/CroppedUserImage';

import { getFullName } from 'services/users/helpers';
import { getDateAsTimeFromNow } from 'services/common/dateHelpers';

import { SongCreatorImageProps } from './interfaces';

import useStyles from './style';

const SongCreatorImage = <T extends SectionSong>({
  song,
  className,
  isLazy,
  ...rest
}: SongCreatorImageProps<T>) => {
  const classes = useStyles();
  const timeAgo = useMemo(() => getDateAsTimeFromNow(song?.createdAt), [song?.createdAt]);
  const fullName = useMemo(() => (song ? getFullName(song.creator) : ''), [song?.creator]);

  return (
    <Tooltip
      overlay={
        <div className={classes.creatorImageTooltip}>
          <span>{fullName}</span>
          <span>{timeAgo}</span>
        </div>
      }
      type="primary"
      placement="top"
      destroyTooltipOnHide
    >
      <div>
        <CroppedUserImage
          user={song?.creator}
          className={classNames('creatorImage', classes.creatorImage)}
          isLazy={isLazy}
          {...rest}
        />
      </div>
    </Tooltip>
  );
};

export default SongCreatorImage;
