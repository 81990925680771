import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionBlocksHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0 12px 0',

    '& .sectionSettingsBtn': {
      height: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 16px 0',
    },
  },

  sectionDescription: {
    fontSize: '13px',
    lineHeight: '19.5px',
    opacity: `var(--section-desc-opacity)`,
    marginBottom: '26px',

    '& a': {
      textDecoration: 'underline',

      '&, &:hover': {
        color: 'inherit',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '36px',
    },
  },

  settingsIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  blocksHeaderTitle: {
    width: '100%',
    margin: 'auto 0 0 0',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    padding: '0 20px 0 0',

    '& .eventTitle': {
      margin: '0',
      color: 'inherit',
      width: '100%',
      fontSize: '17px',
      lineHeight: '28px',
      maxWidth: '490px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '24px',
        lineHeight: '30px',
        maxWidth: '730px',
      },
    },

    '& .viboButton': {
      padding: '0',
    },

    '@media screen and (min-width: 1441px)': {
      gap: '12px',
      padding: '0 30px 0 0',
    },
  },
}));

export default useStyles;
