import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const paragraphStyle = {
  marginBottom: '4px',
  fontSize: '11px',
  lineHeight: '15.59px',

  '@media screen and (min-width: 1441px)': {
    fontSize: '14px',
    lineHeight: '19.84px',
  },
};

const useStyles = createUseStyles((theme: ViboTheme) => ({
  type: {
    display: 'flex',
    fontFamily: '"Roboto", sans-serif',
    fontSize: '13px',
    lineHeight: '19.5px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  cloneTemplateBtn: {
    marginTop: '20px!important',

    '@media screen and (min-width: 1441px)': {
      marginTop: '24px!important',
    },
  },

  selectEventType: {
    margin: '0 0 0 -6px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 -8px',
    },
  },

  location: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '& .viboToggleDropdown': {
      width: '100%',
    },

    '& input': {
      padding: '0px',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      fontSize: '13px',
      lineHeight: '19.5px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },

      '&:focus, &:active': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },

  timePicker: {
    fontSize: '13px',
    fontWeight: 'bold',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  createdBy: {
    marginTop: '10px',
    width: 'fit-content',

    '& p': {
      color: theme.palette.text.secondary,
      ...paragraphStyle,
    },

    '& .content': {
      margin: '0 0 0 16px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 12px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      marginTop: '17px',
    },
  },

  timeInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .time': {
      fontWeight: 700,
    },

    '& .timepicker': {
      lineHeight: '12px',
    },

    '& p': {
      margin: '0 9px 0 0',
    },
  },

  numberInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '19.5px',

    '& .viboInputWrapper': {
      margin: '0 0 0 auto',
    },

    '& span': {
      width: '100%',
      maxWidth: '267px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  settingsSwitcher: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    marginBottom: '13px',
    height: '36px',
    fontSize: '13px',
    lineHeight: '19.5px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },

    '& > div': {
      width: '290px',
    },

    '& > :first-child': {
      marginRight: '16px',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  invitation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 47px 0 0',

    '& > div > label:last-child': {
      marginBottom: '0',
    },

    '& > label': {
      flex: 'auto',
    },
  },
}));

export default useStyles;
