import tinycolor from 'tinycolor2';

import { DEFAULT_VIBO_COLOR_HEX } from 'components/context/ViboThemeContext/constants';
import { HEX_REGEX } from 'services/constants';

export const validateHex = (hex?: string) => {
  const result = !!hex ? HEX_REGEX.exec(hex) : null;

  return !!result;
};

export const getProgressBarColor = (progress: number): string => {
  if (progress <= 35) {
    return '#DB6234';
  }

  if (progress <= 55) {
    return '#D4AF37';
  }

  return '#66DC64';
};

export const getHslColor = (code: number[], lightness?: number) => {
  // code is a array from 4 numbers

  return `hsl(${code[0]}, ${code[1]}%, ${lightness || code[2]}%, ${code[3] || 1})`;
};

export const getHexHsl = (color: string) => {
  if (!color) {
    return { h: 0, s: 0, l: 0 };
  }

  const value = tinycolor(color);
  const { h, s, l } = value.toHsl();

  return {
    h: Math.round(h),
    s: Math.round(s),
    l: Math.round(l),
  };
};

export const getLighterColorByHue = (h: number) => {
  return getHslColor([h, 85, 60]);
};

export const getAppColorHue = (accentColor: string) => {
  const color = validateHex(accentColor) ? accentColor : DEFAULT_VIBO_COLOR_HEX;

  const { h } = getHexHsl(color);

  return h;
};

export const getRandomHexColor = (): string => {
  const randomColor = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
  };

  const calculateBrightness = (rgb: { r: number; g: number; b: number }) =>
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

  const rgbColor = hexToRgb(randomColor);
  const brightness = calculateBrightness(rgbColor);
  const isBright = brightness > 200;
  const isLight = brightness > 150 && brightness <= 200;
  const isDark = brightness < 100;
  const isVeryDark = brightness < 50;

  if (isBright || isLight || isDark || isVeryDark) {
    return getRandomHexColor();
  } else {
    return randomColor;
  }
};
