import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songIdeasDetails: {
    position: 'relative',
    background: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > .container': {
      height: 'calc(100vh - 40px)',

      '& .containerContent': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100vh - 48px)',
      },
    },
  },

  playlistHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0',
    padding: '8px',
    zIndex: 2,
    position: 'sticky',
    top: 0,

    '& .searchInputWrapper': {
      maxWidth: '220px',
      margin: '0 8px 0 0',
    },
  },

  songIdeasContent: {
    width: '100%',
    height: 'calc(100vh - 130px)',
    gap: '56px',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100vh - 140px)',
      gap: '72px',
    },
  },

  playlistContentArea: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    borderRadius: '4px',

    '& .stateIcon': {
      '& svg': {
        width: '100px',
        height: '100px',
      },
    },

    '& .emptyState': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .description': {
        margin: '0 0 0 7px',
        color: theme.palette.text.primary,
      },
    },

    '& > .viboScrollbar': {
      paddingLeft: 0,
      paddingTop: 0,
    },

    '& .songArtistNames': {
      maxWidth: '330px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '550px',
      },
    },
  },

  playlistContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '4px 16px 4px 4px',
    margin: '-4px -16px -4px -4px',

    '& .songItemIconContainer': {
      display: 'none',
    },
  },

  songsCont: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '27px',
    margin: '0 auto 0 0',

    '@media screen and (min-width: 1441px)': {
      fontSize: '18px',
    },
  },

  songIdeasSong: {
    cursor: 'pointer',
    boxShadow: 'none',

    '& .songTitleArtist': {
      maxWidth: '500px',
    },

    '& .extra': {
      opacity: 0,
    },

    '& .songImage': {
      maxWidth: '28px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '36px',
      },
    },

    '& .songIndex': {
      margin: '0 8px',
    },

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.selection.sectionHover,

      '& .extra': {
        opacity: 1,
      },
    },
  },

  loadMoreSpinner: {
    padding: '6px 0',
  },

  playlistSongModal: {
    '& .songArtistNames': {
      maxWidth: '430px',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '500px',
      },
    },
  },
}));

export default useStyles;
