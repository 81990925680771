import React, { FC, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router';

import Switch from 'vibo-ui/Switch';

import { onError } from 'graphql/helpers';
import { goAuthBeatsource } from 'pages/BeatsourcePage/constants';

import { DISCONNECT_BEATSOURCE } from 'graphql/mutations/beatsource';
import { IS_USER_LOGGED_IN_TO_BEATSOURCE } from 'graphql/queries/beatsource';

const BeatsourceSwitcher: FC = () => {
  const { location } = useHistory();

  const [disconnect, { loading: isDisconnectiong }] = useMutation(DISCONNECT_BEATSOURCE, {
    refetchQueries: ['isUserLoggedInToBeatsource'],
  });

  const { data, loading } = useQuery<IsUserLoggedInToBeatsourceResponse>(
    IS_USER_LOGGED_IN_TO_BEATSOURCE,
    {
      fetchPolicy: 'no-cache',
      onError,
    }
  );
  const isConntected = useMemo(() => data?.isUserLoggedInToBeatsource, [
    data?.isUserLoggedInToBeatsource,
  ]);

  const handleConnect = useCallback(() => {
    if (isConntected) {
      disconnect();
    } else {
      localStorage.setItem('bs-reserved-link', location.pathname);
      goAuthBeatsource();
    }
  }, [isConntected]);

  return (
    <Switch
      checked={isConntected}
      disabled={loading || isDisconnectiong}
      onChange={handleConnect}
    />
  );
};

export default BeatsourceSwitcher;
