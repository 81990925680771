import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GET_ME } from 'graphql/queries/user';
import { DISCONNECT_SPOTIFY } from 'graphql/mutations/spotify';
import { openSpotifyAuthWindow } from 'services/spotify/helpers';

// todo: rename to TSpotifyAuthContext
interface SpotifyAuth {
  spotifyLoading: boolean;
  isSpotifyConnected: boolean;
  connectSpotify: () => void;
  disconnectSpotify: () => void;
}

// todo change it to undefined
export const SpotifyAuthContext = createContext<SpotifyAuth>({
  spotifyLoading: false,
  isSpotifyConnected: false,
  disconnectSpotify: () => {},
  connectSpotify: () => {},
});

interface SpotifyAuthProviderProps {
  children: ReactNode;
  autoConnect: boolean;
}

const SpotifyAuthProvider = ({ autoConnect, children }: SpotifyAuthProviderProps) => {
  const [windowLoading, setWindowLoading] = useState<boolean>(autoConnect);

  const { data, loading, refetch } = useQuery(GET_ME);

  const [disconnect, { loading: disconnecting }] = useMutation(DISCONNECT_SPOTIFY, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleSpotifyLoginWindlowListener = (spotifyLoginWindow: Window) => {
    const loginSpotifyTimer = setInterval(() => {
      if (!spotifyLoginWindow || spotifyLoginWindow.closed) {
        setWindowLoading(false);
        clearInterval(loginSpotifyTimer);
      }
    }, 100);
  };

  const connectSpotify = () => {
    const spotifyLoginWondow = openSpotifyAuthWindow();

    setWindowLoading(true);

    if (spotifyLoginWondow) {
      handleSpotifyLoginWindlowListener(spotifyLoginWondow);
    }
  };

  const disconnectSpotify = () => {
    disconnect();
  };

  useEffect(() => {
    if (data && autoConnect) {
      if (!data.me.spotifyConnected) {
        connectSpotify();
      } else {
        setWindowLoading(false);
      }
    }
  }, [data, autoConnect]);

  const handleSpotifyConnected = useCallback(
    (e: MessageEvent) => {
      if (e.data === 'refetchMe') {
        setWindowLoading(false);
        return refetch();
      }
    },
    [refetch]
  );

  useEffect(() => {
    window.addEventListener('message', handleSpotifyConnected);

    return () => window.removeEventListener('message', handleSpotifyConnected);
  }, [handleSpotifyConnected]);

  return (
    <SpotifyAuthContext.Provider
      value={{
        spotifyLoading: disconnecting || loading || windowLoading,
        isSpotifyConnected: !!data?.me.spotifyConnected,
        disconnectSpotify,
        connectSpotify,
      }}
    >
      {children}
    </SpotifyAuthContext.Provider>
  );
};

export default SpotifyAuthProvider;
