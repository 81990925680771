import React, { FC, useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { GenerateEventPlaylistsModalProps } from 'components/modals/GenerateEventPlaylistsModal';

const M3UButton: FC = () => {
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  const { id: eventId, sectionId } = useParams<{ id: string; sectionId: string }>();

  const { openModal } = useModal();

  const handleClick = useCallback(() => {
    if (event?.title) {
      openModal<GenerateEventPlaylistsModalProps>({
        key: Modals.generatePlaylist,
        props: {
          eventTitle: event.title,
          eventId,
          sectionId,
        },
      });
    }
  }, [event?.title, eventId, sectionId]);

  return (
    <Button
      onClick={handleClick}
      prefixIcon={IconmoonFont['m3u-24']}
      className="m3UButton"
      displayType="bordered"
      shape="round"
      size="lg"
    >
      {t('downloadM3U')}
    </Button>
  );
};

export default M3UButton;
