import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  viboSpinner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '&.withSpace': {
      padding: ' 30px 0',
    },

    '& > .viboIcon': {
      display: 'block',
      animation: '1.2s linear infinite spin',
    },

    '&.smSize': {
      '& > .viboIcon': {
        fontSize: '16px',
      },

      '& viboIcon, & svg': {
        width: '16px',
        height: '16px',
      },
    },

    '&.mdSize': {
      '& > .viboIcon': {
        fontSize: '24px',
      },

      '& viboIcon, & svg': {
        width: '24px',
        height: '24px',
      },
    },

    '&.lgSize': {
      '& > .viboIcon': {
        fontSize: '34px',
      },

      '& .viboIcon, & svg': {
        width: '34px',
        height: '34px',
      },
    },
  },

  '@global': {
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },

      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
});

export default useStyles;
