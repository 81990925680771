import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header from 'components/common/Header';

import { AdminUsersPageHeaderProps } from './interfaces';

const AdminUsersPageHeader: FC<AdminUsersPageHeaderProps> = ({ usersCount }) => {
  const { t } = useTranslation();

  return (
    <Header>
      <div className="menuLabel">{`${t('users')} ${usersCount ? `(${usersCount})` : ''}`}</div>
    </Header>
  );
};

export default AdminUsersPageHeader;
