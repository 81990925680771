import React, { useMemo } from 'react';
import classNames from 'classnames';
import Tooltip from 'vibo-ui/Tooltip';

import { getSongLinksWithMetadata } from 'services/songs/helpers';

import useStyles from './style';

interface PrepModeMeidaLinksProps {
  songLinks: SongLinks;
}

const PrepModeMeidaLinks: React.FC<PrepModeMeidaLinksProps> = ({ songLinks }) => {
  const classes = useStyles();

  const links = useMemo(() => getSongLinksWithMetadata(songLinks), [songLinks]);

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={classNames('prepModeMeidaLinks', classes.prepModeMeidaLinks)}
    >
      {links.map((link, idx) => (
        <Tooltip overlay={<span>{link.text}</span>} type="primary" key={idx}>
          <div
            className={classNames('songLinkMenuItem', classes.songLinkMenuItem)}
            onClick={() => window.open(link.url, '_blank')}
          >
            <link.Icon className={link.key} />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default PrepModeMeidaLinks;
