import client, { cache } from '../client';

import { GET_SONG_IDEAS_BY_ID } from '../queries/songIdeas';
import { SONG_IDEAS_DETAILS, IS_IN_SONG_IDEAS } from '../fragments/songIdeas';

export const updateSongIdeasCache = (songIdeasId: string, payload: UpdateSongIdeasPayload) => {
  const cachedPlaylist = client.readFragment({
    id: `SongIdeas:${songIdeasId}`,
    fragment: SONG_IDEAS_DETAILS,
  });

  if (!!cachedPlaylist) {
    cache.modify({
      id: `SongIdeas:${songIdeasId}`,
      fields: {
        title: () => payload.title as string,
        description: () => payload.description as string,
        coverPhoto: () => ({
          url: payload.coverPhoto?.url,
        }),
      },
    });
  }
};

export const getIsInSongIdeas = (viboSongId: string): Nullable<IsInSongIdeasFragment> =>
  client.readFragment<IsInSongIdeasFragment>({
    id: `SearchedSongForSongIdeas:${viboSongId}`,
    fragment: IS_IN_SONG_IDEAS,
  });

export const updateIsInSongIdeas = (viboSongId: string, payload: IsInSongIdeasFragment): void => {
  const isInSongIdeasData = getIsInSongIdeas(viboSongId);

  if (isInSongIdeasData) {
    client.writeFragment({
      id: `SearchedSongForSongIdeas:${viboSongId}`,
      fragment: IS_IN_SONG_IDEAS,
      data: {
        ...payload,
        __typename: 'SearchedSongForSongIdeas',
      },
    });
  }
};

export const updatePlaylistPublic = (playlist: SongIdeas): void => {
  if (!!playlist) {
    client.writeQuery<SongIdeasByIdResponse>({
      query: GET_SONG_IDEAS_BY_ID,
      data: {
        getSongIdeasById: {
          ...playlist,
          isPublic: !playlist.isPublic,
        },
      },
    });
  }
};
