import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import DangerouslyInnerHTML from 'vibo-ui/DangerouslyInnerHTML';

import { TextPreviewProps } from './interfaces';

import useInputStyles from 'vibo-ui/Input/style';
import useStyles from './style';

const TextPreview: FC<TextPreviewProps> = ({ text, className, placeholder, onClick }) => {
  const inputClasses = useInputStyles();
  const classes = useStyles();

  const content = useMemo(() => text || placeholder, [text, placeholder]) as string;

  return (
    <DangerouslyInnerHTML
      className={classNames('textPreview', inputClasses.viboInput, classes.textPreview, className, {
        empty: !text,
      })}
      onClick={onClick}
      content={content}
    />
  );
};

export default TextPreview;
