import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  inputLabel: {
    color: theme.palette.text.secondary,

    '& .text': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '4px',
      fontSize: '13px',
      lineHeight: '19.56px',

      '& .viboIcon': {
        color: theme.palette.text.secondary,
        margin: '0 8px 0 0',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  disabled: {
    color: theme.palette.text.disabled,
  },

  req: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
