import { FormikProps } from 'formik';

export interface SectionSettingsProps extends SectionSettingsWithStep {
  variables: unknown;
  section?: SectionBase;
  formik: FormikProps<SectionSettingsForm>;
}

export interface SectionSettingsWithStep {
  initialStep?: SettingsSteps;
}

export interface SettingsWizardSiderProps {
  isEditMode: boolean;
}

export interface SectionSettingsForm extends CreateSectionForm, UpdateEventSectionForm {
  favoriteSectionId?: string;
  imagePreview?: string;
}

export enum SettingsSteps {
  default = 'default',
  headline = 'headline',
  fromFavorite = 'fromFavorite',
}
