import React, { FC, useEffect, useState, memo, useContext, useMemo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { useQueryParam } from 'use-query-params';

import Button from 'vibo-ui/Button';
import SongsList from './SongsList';
import Spinner from 'vibo-ui/Spinner';
import SongsBlockHeader from './SongsBlockHeader';
import TimelineSongsHeader from './TimelineSongsHeader';
import WithEventRole from 'components/user/WithEventRole';
import NoSongsInBlock from 'components/emptyStates/NoSongs';
import SectionBlock from 'components/sections/blocks/SectionBlock';
import SectionBlockFooter from 'components/sections/blocks/SectionBlock/SectionBlockFooter';
import { EventContext } from 'components/context/EventContext';
import { TimelineSongsContext } from './TimelineSongsContext';
import { SelectListProvider } from 'components/context/SelectListContext';

import { useSongs } from 'graphql/hooks/blocks';
import { usePrevious } from 'services/common/dataProcessingHelpers';
import { INITIAL_SONGS_LIMIT, LOAD_MORE_SONGS_COUNT } from 'services/constants';

import { isLoadingVar } from 'graphql/cache';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventTimelineTabKey, EventUserType } from 'types/enums';
import { ControlProps } from 'components/sections/blocks/SectionBlock/interfaces';
import { TimelineSongsBlockProps } from './interfaces';

import useStyles from './style';

const TimelineSongsBlock: FC<TimelineSongsBlockProps> = ({
  sections,
  variables,
  section,
  listQueries,
  mutations,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [controlRef, setControlRef] = useState<Nullable<ControlProps>>(null);

  const { isTemplate, isFavoriteSections, isLoading: isEventLoading } = useContext(EventContext);
  const isLoadingGlobal = useReactiveVar(isLoadingVar);

  const [, setQuery] = useQueryParam('tSongsQ');

  const { id: eventId } = useParams<EventPageRouteParams>();

  const prevSectionId = usePrevious(variables.sectionId);

  const songsOptions = useSongs({
    variables: {
      ...variables,
      sectionId: variables?.sectionId || '',
      eventId: variables?.eventId || eventId,
    },
    songsCount: section?.songsCount,
  });
  const {
    songsList,
    sectionSongsData,
    loadMoreSongs,
    refetchSongs,
    songsFilter,
    songsLoading,
    isFilterApplied,
    clearFiltering,
    clearSongs,
  } = songsOptions;

  const isShouldBlockShown = () =>
    !!songsFilter.q ||
    (songsList?.length < INITIAL_SONGS_LIMIT && songsList.length > 0) ||
    !songsList?.length;

  const fullBlockDisplay = useMemo(
    () =>
      isTemplate || isFavoriteSections || songsLoading || isLoadingGlobal
        ? false
        : isShouldBlockShown(),
    [songsList?.length, isLoadingGlobal, songsLoading, isFavoriteSections]
  );

  const isFiltersApplied = Object.keys(songsFilter).length !== 0;
  const prevIsFiltersApplied = usePrevious(isFiltersApplied);
  const prevIsSongsLoading = usePrevious(songsLoading);

  const handleCollapse = () => {
    clearFiltering();
  };

  const loadSongs = () => {
    if (controlRef?.isShown && songsList.length < sectionSongsData.totalCount) {
      loadMoreSongs();
    }
  };

  const loadDifference = sectionSongsData.totalCount - songsList.length;
  const loadMoreCount =
    loadDifference >= LOAD_MORE_SONGS_COUNT ? LOAD_MORE_SONGS_COUNT : loadDifference;

  useEffect(() => {
    if (prevSectionId && prevSectionId !== variables.sectionId) {
      clearFiltering();
      clearSongs();
    }
  }, [variables.sectionId, prevSectionId]);

  useEffect(() => {
    if (!controlRef?.isCollapsed) {
      clearFiltering();
    }
  }, [controlRef?.isCollapsed]);

  useEffect(() => {
    if (sectionSongsData.totalCount > 0 && controlRef?.isCollapsed && !prevIsFiltersApplied) {
      controlRef?.setIsCollapsed(false);
    }
  }, [sectionSongsData.totalCount]);

  useEffect(() => {
    if (!!songsFilter.q) {
      controlRef?.setIsShown(true);
    }
  }, [songsFilter.q]);

  useEffect(() => {
    if (isShouldBlockShown() && !prevIsSongsLoading && !songsLoading) {
      controlRef?.setIsShown(true);
    }
  }, [prevIsSongsLoading, songsLoading, controlRef?.isShown]);

  useEffect(() => {
    setQuery(undefined);
  }, []);

  return (
    <TimelineSongsContext.Provider
      value={{
        songsOptions,
        variables,
        refetchSongs,
        section,
        sections,
        listQueries,
        mutations,
        controlRef,
      }}
    >
      <SelectListProvider initialIds={songsList.map(({ _id }) => _id)}>
        <SectionBlock
          className={classNames('timelineSongsBlock content-fade-in', classes.timelineSongsBlock, {
            [classes.onlyOneSong]: section.songsCount === 1 && !isFilterApplied,
            [classes.shownContent]:
              songsList.length >= 1 && controlRef?.isShown && !controlRef.isCollapsed,
            blankEmptyState: songsLoading,
            isFilterApplied,
            noSongsFound: songsList.length === 0,
            'ready _1': (!!sectionSongsData && !songsLoading) || !isEventLoading,
          })}
          controlRef={setControlRef}
          onCollapse={handleCollapse}
          type={EventTimelineTabKey.songs}
          section={section}
          renderHeader={({ caretButton }) => <SongsBlockHeader>{caretButton}</SongsBlockHeader>}
          renderContent={({ isShown }) => (
            <>
              {!!songsOptions.songsList.length || isFilterApplied ? (
                <>
                  <WithEventRole
                    roles={[EventUserType.dj, EventUserType.host]}
                    extraCondition={!isTemplate}
                  >
                    <TimelineSongsHeader />
                  </WithEventRole>
                  <SongsList />
                </>
              ) : songsLoading ? (
                <Spinner />
              ) : (
                <NoSongsInBlock>{t('addYourFirstSong')}</NoSongsInBlock>
              )}
              {isFiltersApplied && sectionSongsData.totalCount === 0 ? null : (
                <SectionBlockFooter>
                  {isShown && !!loadMoreCount ? (
                    <Button onClick={() => loadSongs()} className="centrate" loading={songsLoading}>
                      {t('loadMore', { count: loadMoreCount })}
                    </Button>
                  ) : null}
                  {songsList.length > 0 && !isShouldBlockShown() ? (
                    <Button
                      onClick={() => {
                        controlRef?.isShown ? controlRef?.setIsShown(false) : loadSongs();
                      }}
                      loading={songsLoading}
                      sufixIcon={IconmoonFont[isShown ? 'caretUp-16' : 'caretDown-16']}
                      className="collapse"
                    >
                      {t(isShown ? 'collapse' : 'showSongs', {
                        count: isEmpty(songsOptions.songsFilter)
                          ? songsOptions.sectionSongsData.totalCount
                          : songsList.length,
                      })}
                    </Button>
                  ) : null}
                </SectionBlockFooter>
              )}
            </>
          )}
          defaultCollapsed={false}
          defaultShown={fullBlockDisplay}
          canCollapse={!isTemplate && !isFavoriteSections}
        />
      </SelectListProvider>
    </TimelineSongsContext.Provider>
  );
};

export default memo(TimelineSongsBlock);
