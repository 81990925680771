import React, { FC } from 'react';
import Tooltip from 'vibo-ui/Tooltip';
import { useTranslation } from 'react-i18next';

const SongsColumn: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="label">
      <Tooltip overlay={t('numberOfSongs')} type="primary" placement="top">
        <span>{t('songs')}</span>
      </Tooltip>
    </div>
  );
};
export default SongsColumn;
