import React, { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Link from './Link';
import Icon from 'vibo-ui/Icon';
import Spinner from 'vibo-ui/Spinner';
import Tooltip from 'vibo-ui/Tooltip';

import { ButtonProps } from './interfaces';

import useStyles from './style';

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      to,
      href,
      className,
      prefixIcon,
      sufixIcon,
      loading,
      tooltipProps,
      disabled = false,
      target = '_blank',
      shape = 'default',
      displayType = 'default',
      size = 'sm',
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const isDisabled = loading || disabled;

    const baseClassName = classNames(
      'viboButton',
      {
        [classes.alignedContent]: !!prefixIcon || !!sufixIcon,
        [classes.withPrefixIcon]: !!prefixIcon,
        [classes.withSufixIcon]: !!sufixIcon,
      },
      classes.viboButton,
      `${size}Size`,
      `${displayType}Display`,
      `${shape}Shape`,
      className
    );

    const content = (
      <>
        {loading ? (
          <Spinner
            size="sm"
            className={classNames('prefixIcon', classes.prefixIcon, {
              withChildren: !!children,
            })}
          />
        ) : null}
        {prefixIcon && !loading ? (
          <Icon
            icon={prefixIcon}
            className={classNames('prefixIcon', classes.prefixIcon, {
              withChildren: !!children,
            })}
            disableAutoFontSize
          />
        ) : null}
        {children}
        {sufixIcon ? (
          <Icon
            icon={sufixIcon}
            className={classNames('sufixIcon', classes.sufixIcon)}
            disableAutoFontSize
          />
        ) : null}
      </>
    );

    if (to || href) {
      return (
        // @ts-ignore
        <Tooltip type="primary" {...tooltipProps}>
          <Link
            className={baseClassName}
            target={target}
            to={to}
            href={href}
            disabled={isDisabled}
            {...rest}
          >
            {content}
          </Link>
        </Tooltip>
      );
    }

    return (
      // @ts-ignore
      <Tooltip type="primary" {...tooltipProps}>
        <button className={baseClassName} disabled={isDisabled} ref={ref} {...rest}>
          {content}
        </button>
      </Tooltip>
    );
  }
);

export default Button;
