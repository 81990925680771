import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';

import IconButton from '../IconButton';
import { toastNotify } from '../../../graphql/hooks/common';

import { ButtonIconType } from '../../../types/enums';

import useDecorStyles from '../IconButton/decorStyle';

interface CopyButtonProps {
  text: string;
  tooltipTitle: string;
  className?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text, tooltipTitle, className, ...props }) => {
  const decorClasses = useDecorStyles();
  const { t } = useTranslation();

  return (
    <CopyToClipboard text={text}>
      <IconButton
        type={ButtonIconType.copy}
        placement="top"
        title={tooltipTitle || t('copy')}
        className={classNames('hoverable-action copyButton', className, decorClasses.highlighted)}
        onClick={e => {
          e.stopPropagation();
          toastNotify({
            text: t('copiedToClipboard'),
            iconType: ButtonIconType.copy,
          });
        }}
        {...props}
      />
    </CopyToClipboard>
  );
};

export default CopyButton;
