import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import DjsPreview from 'components/common/DjsPreview';
import ContentArea from 'components/common/ContentArea';
import WithEventRole from 'components/user/WithEventRole';
import AppColoredBox from 'components/common/AppColoredBox';
import WithEventFeature from 'components/user/WithEventFeature';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import EventStatusIndicator from 'components/events/EventStatusIndicator';
import PickEventColorButton from 'components/buttons/PickEventColorButton';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';

import googleCalendar from 'services/googleCalendar';
import { useModal } from 'vibo-ui/Modal';
import { useEventColor } from 'graphql/hooks/event';
import { EVENT_TIME_FIELDS } from 'services/constants';
import { getDateWithTimezone } from 'services/common/dateHelpers';

import { EventTimeType, EventType, EventUserType, Modals, TierFeatueName } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { EventGeneralViewProps } from '../interfaces';

import useStyles from '../style';

const EventGeneralUserView: FC<EventGeneralViewProps> = ({ event }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventHost } = useContext(EventContext);

  const { setFieldValue } = useFormikContext<UpdateEventForm>();

  const { location } = useHistory();
  const routeState = location.state as HistoryRouteState;

  const { eventColor } = useEventColor({ event });

  const { openModal } = useModal();

  const openEventLockedModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('locked'),
          children: t('eventLockedForChanges'),
          submit: { text: t('ok'), onClick: () => {} },
        },
      }),
    []
  );

  const eventDate = getDateWithTimezone(event.date, event.timezone);
  const lockDate = getDateWithTimezone(event.lockDate, event.timezone);

  const isBrandingHidden = useMemo(() => isEventHost && !event.settings?.canHostChangeEventColor, [
    isEventHost,
    !event.settings?.canHostChangeEventColor,
  ]);

  const isDateVisible = useMemo(
    () =>
      (!!lockDate && !!event.daysCountBeforeLock) ||
      !!eventDate ||
      !!event.startTime ||
      !!event.endTime,
    [lockDate, event.daysCountBeforeLock, eventDate, event.startTime, event.endTime]
  );

  useEffect(() => {
    if (event.isLocked && routeState?.prevPage !== 'event') {
      openEventLockedModal();
    }
  }, []);

  return (
    <>
      <h3>{t('generalInfo')}</h3>
      <ContentArea shadowed>
        <div className="row status">
          {event.status ? <EventStatusIndicator event={event} /> : null}
        </div>
        {!!event.location?.name ? (
          <div className={classNames('row')}>
            <Icon icon={IconmoonFont['location-24']} className={classes.fieldIcon} />
            <div>{event.location.name}</div>
          </div>
        ) : null}
        <div className={classNames('row eventType', classes.eventType)}>
          <Icon
            className={classes.fieldIcon}
            icon={() => <EventTypeIcon type={event.type as EventType} />}
          />
          <div>{event.type}</div>
        </div>
        <div className={classNames('row djs')}>
          <Icon className={classes.fieldIcon} icon={IconmoonFont['headphones-24']} />
          <DjsPreview djs={event.djs} showDetails />
        </div>
      </ContentArea>
      {isDateVisible ? (
        <>
          <h3>{t('dateAndTime')}</h3>
          <ContentArea shadowed>
            {!!eventDate ? (
              <div className={classNames('row date')}>
                <Icon className={classes.fieldIcon} icon={IconmoonFont['calendar-24']} />
                <div>{moment(`${eventDate}`).format('ddd, MMM Do YYYY')}</div>
                <Button
                  onClick={() => googleCalendar.create(event)}
                  className={ButtonDecor.primaryColor}
                  displayType="link"
                >
                  {t('addToCalendar')}
                </Button>
              </div>
            ) : null}
            {!!lockDate && !!event.daysCountBeforeLock ? (
              <div className={classNames(classes.timeInputWrapper, 'row dateLock')}>
                <Icon className={classes.fieldIcon} icon={IconmoonFont['lockedCalendar-24']} />
                <p>{t('lockoutDate')}</p>
                <div>
                  {moment(`${lockDate}`).format('ddd, MMM Do YYYY')}
                  <span className="days">{`(${t('countDaysBefore', {
                    count: event?.daysCountBeforeLock || 0,
                  })})`}</span>
                </div>
              </div>
            ) : null}
            {EVENT_TIME_FIELDS.filter(({ key }) => key !== EventTimeType.arrivalTime).map(field =>
              !!event[field.key] ? (
                <div className={classes.timeInputWrapper} key={field.key}>
                  <Icon className={classes.fieldIcon} icon={field.icon} />
                  <p>{t(field.translationKey)}</p>
                  <div className="time">{event[field.key]}</div>
                </div>
              ) : null
            )}
          </ContentArea>
        </>
      ) : null}
      <WithEventRole roles={[EventUserType.host]} extraCondition={!isBrandingHidden}>
        <WithEventFeature feature={TierFeatueName.branding}>
          <>
            <h3>{t('branding')}</h3>
            <ContentArea shadowed>
              <div className="settingsRow">
                <AppColoredBox defaultColor={eventColor} />
                <PickEventColorButton
                  eventColors={event.eventColors}
                  handleUpdateColor={newColor => {
                    setFieldValue('eventColor', newColor);
                  }}
                  disabled={event.isLocked}
                  modalClassName="pickEventColorModal"
                />
              </div>
            </ContentArea>
          </>
        </WithEventFeature>
      </WithEventRole>
    </>
  );
};

export default EventGeneralUserView;
