import { DEVICE_KEY } from 'utils/constants';

export const deviceStorage = {
  get device() {
    const deviceStringified = localStorage.getItem(DEVICE_KEY) || '';

    return !!deviceStringified && JSON.parse(deviceStringified);
  },

  set device(value: Maybe<ScannerComputer>) {
    if (!!value) {
      localStorage.setItem(DEVICE_KEY, JSON.stringify(value));
    } else {
      this.clearDevice();
    }
  },

  clearDevice() {
    localStorage.removeItem(DEVICE_KEY);
  },
};
