import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sections: {
    height: 'calc(100% - 20px)',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',

    '& .icon': {
      height: '15px',
    },

    '& > button': {
      minWidth: 'unset',
      position: 'absolute',
      left: '39%',
      marginLeft: 'unset',
    },

    '& > .viboScrollbar': {
      height: 'inherit',
    },

    '& .emptyState': {
      margin: '12% 0 0 0',

      '& .description': {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',

        '& .viboButton': {
          width: '250px',
        },
      },
    },
  },

  sectionsTimeline: {
    position: 'relative',
    padding: '10px 0 0 0',
    margin: '0 0 10px 0',

    '& .timelineSection': {
      '& .section': {
        '&:not(.headline):not(.prepModeSection)': {
          '& .sectionMiddle': {
            height: '30px',

            '@media screen and (min-width: 1441px)': {
              height: '36px',
            },
          },

          '& .sectionBottom': {
            height: '26px',

            '@media screen and (min-width: 1441px)': {
              height: '30px',
            },
          },

          '& .sectionMiddle, & .sectionBottom': {
            transition: 'height .12s, opacity .1s',
          },
        },
      },

      '& .isStart': {
        '& .section:not(.headline)': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },
      },

      '& .isEnd': {
        '& .section:not(.headline)': {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        },
      },

      '&:first-of-type': {
        '& .headline': {
          margin: 0,
        },
      },
    },

    '&.collapsed': {
      '& .timelineSection': {
        '& .section': {
          minHeight: '42px',

          '& .sectionTop': {
            margin: 'auto 0',
          },

          '&:not(.headline):not(.prepModeSection)': {
            '& .sectionMiddle, & .sectionBottom': {
              height: 0,
              pointerEvents: 'none',
              opacity: 0,
              paddingTop: 0,
              paddingBottom: 0,
              margin: 0,
            },
          },

          '@media screen and (min-width: 1441px)': {
            minHeight: '52px',
          },
        },
      },
    },
  },

  nonEmptyList: {
    '& > button': {
      bottom: '59px',
    },
  },

  emptyList: {
    '& > button': {
      bottom: '45%',
    },
  },

  sectionsListHeader: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: '4px',
    padding: '7px 12px 5px',
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    margin: '0 10px 0 auto',
    width: '433px',
    textAlign: 'center',
  },
}));

export default useStyles;
