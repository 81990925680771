import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineSectionsHeader: {
    margin: '0',
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '288px',
    padding: '0 0 6px 0',

    '& .searchInputWrapper': {
      width: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      width: '384px',
      padding: '0 0 14px 0',
    },
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 0 8px',
    gap: '8px',

    '& .viboButton': {
      whiteSpace: 'nowrap',
    },
  },

  noButtons: {
    display: 'none',
  },
}));

export default useStyles;
