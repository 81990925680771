import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  authLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',

    '& .text': {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '25.5px',
      margin: '110px 0 140px',
      textAlign: 'center',
    },

    '& .goBack': {
      top: '0',
      left: '0',
      position: 'absolute',
      padding: '0',

      '& svg': {
        width: '20px',
        height: '20px',
      },
    },

    '& h3': {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28.34px',
      color: theme.palette.text.primary,
      margin: '41px 0 16px',
      width: '100%',
      textAlign: 'center',
    },

    '& p': {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '25.5px',
      width: '100%',
    },

    '& form': {
      width: '100%',
    },

    '& button.viboButton:not(.goBack), & .primaryDisplay': {
      padding: '7.5px 15px',
      textAlign: 'center',

      '&:not(.goBack)': {
        width: '100%',
      },
    },

    '& .contentArea > .content': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
    },

    '&.reset-password-success, &.forgot-password-success': {
      '& .viboButton': {
        margin: 'auto 0 0 0',
      },

      '& .text': {
        margin: 'auto 0',
      },
    },

    '&.reset, &.forgot-password': {
      '& form': {
        margin: 'auto 0 0 0',

        '& .viboButton': {
          margin: '20px 0 0 0',
        },
      },
    },
  },

  authLayoutCard: {
    boxShadow: '',
    padding: '36px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '392px',
    height: '444px',
    position: 'relative',

    '& .viboLogo': {
      margin: '0 auto 51px auto',
      color: theme.palette.primary.main,
      width: '96.6px',
      height: '37px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 auto 62px auto',
        width: '125.3px',
        height: '48px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '492px',
      height: '560px',
      padding: '48px',
    },
  },
}));

export default useStyles;
