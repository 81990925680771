import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import Switch from 'vibo-ui/Switch';
import Select from 'vibo-ui/Select';
import TimePicker from 'vibo-ui/TimePicker';
import ProLabel from 'components/labels/ProLabel';
import Location from 'components/common/Location';
import DjPreview from 'components/common/DjPreview';
import EventImages from 'components/events/EventImages';
import PublicLabel from 'components/labels/PublicLabel';
import ContentArea from 'components/common/ContentArea';
import FormInput from 'components/form/inputs/FormInput';
import CloneTemplateButton from '../CloneTemplateButton';
import FormSwitch from 'components/form/inputs/FormSwitch';
import DeleteTemplateButton from '../DeleteTemplateButton';
import AppColoredBox from 'components/common/AppColoredBox';
import WithEventFeature from 'components/user/WithEventFeature';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import PageContentHeader from 'components/common/PageContentHeader';
import FormNumberInput from 'components/form/inputs/FormNumberInput';
import ShareTemplateButton from 'components/buttons/ShareTemplateButton';
import PickEventColorButton from 'components/buttons/PickEventColorButton';
import EventGeneralInfoLayout from 'components/layouts/EventGeneralInfoLayout';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EditableContext } from 'components/common/EditableContext';

import { GET_USER_EVENT_TYPE } from 'graphql/queries/events';
import { GET_TEMPLATE } from 'graphql/queries/template';
import { UPDATE_TEMPLATE } from 'graphql/mutations/template';
import { useFormikAutoSave } from 'graphql/hooks/form';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { useEventColor } from 'graphql/hooks/event';
import { getInitialUpdateTemplateValues } from 'services/template/helpers';
import {
  MAX_SONG_LIMIT,
  MAX_EVENT_TITLE_LENGTH,
  EVENT_TIME_FIELDS,
  MAX_DAYS_BEFORE_LOCKING,
} from 'services/constants';
import { updateTemplateValidationSchema } from './constants';

import { EventType, Modals, TierFeatueName } from 'types/enums';
import { PickFilesModalProps } from 'components/modals/PickFilesModal';
import { EventTypeSelect, TemplateGeneralProps } from './interfaces';

import useEventStyles from 'pages/events/EventGeneral/style';
import useStyles from './style';

const TemplateGeneral: FC<TemplateGeneralProps> = ({ template }) => {
  const eventClasses = useEventStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { canEdit } = useContext(EditableContext);

  const { eventColor } = useEventColor({ event: template });

  const { openModal } = useModal();

  const openPickImageModal = () =>
    openModal<PickFilesModalProps>({
      key: Modals.pickImage,
      props: {
        fileSrc: template.image?.squareUrl,
        title: t('templatePhoto'),
        onChange: handlePictureChange,
      },
    });

  const { data: eventTypesData } = useQuery(GET_USER_EVENT_TYPE);

  const eventTypes: EventTypeSelect[] = eventTypesData?.eventTypes || [];

  const [updateTemplate, { loading: updateTemplateLoading }] = useMutation(UPDATE_TEMPLATE, {
    onError,
    refetchQueries: [
      {
        query: GET_TEMPLATE,
        variables: {
          templateId: template._id,
        },
      },
    ],
  });

  const handleUpdateTemplate = (payload: UpdateTemplateForm | UpdateTemplateImage) =>
    updateTemplate({
      variables: { templateId: template._id, payload },
    });

  const handlePictureChange = useCallback(
    (_, square) =>
      handleUpdateTemplate({
        image: {
          ...(!!square ? { square } : { squareUrl: '' }),
        },
      }),
    []
  );

  const { formik } = useFormikAutoSave<UpdateTemplateForm>(
    {
      initialValues: getInitialUpdateTemplateValues(template),
      onSubmit: values => handleUpdateTemplate(values),
      validationSchema: updateTemplateValidationSchema,
    },
    3000
  );

  const options = useMemo(
    () =>
      eventTypes.map(option => ({
        label: t(option.title),
        value: option._id,
      })),
    [eventTypes]
  );

  const handleChange = useCallback(option => {
    formik.setFieldValue('typeId', option.value);
  }, []);

  useEffect(() => {
    formik.setValues(getInitialUpdateTemplateValues(template));
  }, [template._id]);

  return (
    <FormikProvider value={formik}>
      <EventGeneralInfoLayout
        titleInput={
          <PageContentHeader
            extra={template?.canEdit ? <ShareTemplateButton template={template} /> : null}
          >
            <FormInput
              maxLength={MAX_EVENT_TITLE_LENGTH}
              disabled={!template.canEdit}
              name="title"
              className="bold"
              size="xlg"
              withUnderline
              showMaxLength
            />
          </PageContentHeader>
        }
        contentLeft={
          <>
            <h3>{t('templatePhoto')}</h3>
            <EventImages
              id={template._id}
              title={template.title}
              type={startCase(template.type) as EventType}
              squareUrl={template.image?.cropped?.medium || template.image?.squareUrl}
              onClick={() => {
                if (!canEdit) {
                  return;
                }

                openPickImageModal();
              }}
            />
            <CloneTemplateButton template={template} className={classes.cloneTemplateBtn} />
            {template.canEdit ? <DeleteTemplateButton template={template} /> : null}
          </>
        }
        contentRight={
          <>
            <h3>{t('generalSettings')}</h3>
            <ContentArea shadowed>
              <div className={eventClasses.labels}>
                {template.isPro ? <ProLabel size="lg" /> : null}
                {template.isPublic ? <PublicLabel size="lg" /> : null}
              </div>
              <div className={classNames(classes.type)}>
                <Icon
                  className={eventClasses.fieldIcon}
                  icon={() => <EventTypeIcon type={startCase(template.type) as EventType} />}
                />
                <Select
                  value={formik.values.typeId}
                  onChange={handleChange}
                  options={options}
                  className={classes.selectEventType}
                  menuShouldBlockScroll
                />
              </div>
              <div className={classes.location}>
                <Icon icon={IconmoonFont['location-24']} className={eventClasses.fieldIcon} />
                <Location
                  locationValue={template.location}
                  onLocationChange={location => formik.setFieldValue('location', location)}
                  placeholder={t('eventLocation')}
                  disabled={!template.canEdit}
                  withUnderline
                />
              </div>
              {template.canEdit ? null : (
                <div className={classes.createdBy}>
                  <DjPreview dj={template.user}>
                    <p>{t('createdBy')}</p>
                  </DjPreview>
                </div>
              )}
              {template.canEdit ? (
                <div className="settingsRow">
                  <Icon icon={IconmoonFont['bell-24']} className={eventClasses.fieldIcon} />
                  <div>{t('notifications')}</div>
                  <Switch
                    checked={!formik.values.settings?.disableAllPushNotifications}
                    onClick={newChecked =>
                      formik.setFieldValue('settings.disableAllPushNotifications', !newChecked)
                    }
                    disabled={!template.canEdit}
                  />
                </div>
              ) : null}
            </ContentArea>
            <h3>{t('dateAndTime')}</h3>
            <ContentArea shadowed>
              <label className={classes.numberInputWrapper}>
                <Icon className={eventClasses.fieldIcon} icon={IconmoonFont['lockedCalendar-24']} />
                <span>{t('numberDaysBeforeLockingEvent')}</span>
                <FormNumberInput
                  max={MAX_DAYS_BEFORE_LOCKING}
                  disabled={!template.canEdit}
                  name="daysCountBeforeLock"
                />
              </label>
              {EVENT_TIME_FIELDS.map(field => (
                <div className={classes.timeInputWrapper} key={field.key}>
                  <Icon className={eventClasses.fieldIcon} icon={field.icon} />
                  <p>{t(field.translationKey)}:</p>
                  <TimePicker
                    value={template[field.key] as string}
                    onChangeTime={time => formik.setFieldValue(field.key, time)}
                    loading={updateTemplateLoading}
                    disabled={!template.canEdit}
                    className={classes.timePicker}
                  />
                </div>
              ))}
            </ContentArea>
            <h3>{t('branding')}</h3>
            <ContentArea shadowed>
              <WithEventFeature feature={TierFeatueName.branding}>
                <>
                  <div className="settingsRow">
                    <AppColoredBox defaultColor={eventColor} />
                    <PickEventColorButton
                      handleUpdateColor={newColor => {
                        formik.setFieldValue('eventColor', newColor);
                      }}
                      eventColors={template.eventColors}
                      disabled={!canEdit}
                    >
                      {t('templateColor')}
                    </PickEventColorButton>
                  </div>
                  <div className="settingsRow">
                    <Icon
                      icon={IconmoonFont['hostChangeHeadlineColor-24']}
                      className={eventClasses.fieldIcon}
                    />
                    <div>{t('hostsCanChangeEventColor')}</div>
                    <FormSwitch
                      disabled={!template.canEdit}
                      name="settings.canHostChangeEventColor"
                    />
                  </div>
                </>
              </WithEventFeature>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['hostAddSectionPhoto-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('hostCanAddEventPhoto')}</div>
                <FormSwitch
                  disabled={!template.canEdit}
                  name="settings.canHostChangeEventCoverPhoto"
                />
              </div>
              <WithEventFeature feature={TierFeatueName.image_to_section}>
                <div className="settingsRow">
                  <Icon
                    icon={IconmoonFont['hostAddSectionPhoto-24']}
                    className={eventClasses.fieldIcon}
                  />
                  <div>{t('hostsCanAddPhotoNewSections')}</div>
                  <FormSwitch
                    disabled={!template.canEdit}
                    name="settings.canHostAddSectionImages"
                  />
                </div>
              </WithEventFeature>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['hostChangeHeadlineColor-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('canHostChangeHeadlineColor')}</div>
                <FormSwitch
                  disabled={!template.canEdit}
                  name="settings.canHostChangeHeadlineColors"
                />
              </div>
            </ContentArea>
            <h3>{t('permissions')}</h3>
            <ContentArea shadowed>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['hostsInviteGuests-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('hostsCanInviteGuests')}</div>
                <FormSwitch disabled={!template.canEdit} name="settings.canHostsInviteGuests" />
              </div>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['guestsInviteGuests-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('guestsCanInviteGuests')}</div>
                <FormSwitch disabled={!template.canEdit} name="settings.canGuestsInviteGuests" />
              </div>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['hostsAddSections-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('hostsCanAddNewSections')}</div>
                <FormSwitch disabled={!template.canEdit} name="settings.canHostCreateSections" />
              </div>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['hostsReorderSections-24']}
                  className={eventClasses.fieldIcon}
                />
                <div>{t('hostsCanReorderSections')}</div>
                <FormSwitch disabled={!template.canEdit} name="settings.canHostReorderSections" />
              </div>
            </ContentArea>
            <h3>{t('songsSettings')}</h3>
            <ContentArea shadowed>
              <div className="settingsRow">
                <Icon
                  icon={IconmoonFont['musicNoteDoubleOutlined-24']}
                  className={eventClasses.fieldIcon}
                />
                <span>{t('songLimitForNewSections')}</span>
                <FormNumberInput
                  max={MAX_SONG_LIMIT}
                  disabled={!template.canEdit}
                  name="settings.sectionSongsLimit"
                />
              </div>
              <div className="settingsRow">
                <Icon className={eventClasses.fieldIcon} icon={IconmoonFont['crown-24']} />
                <span>{t('mustPlaysLimitForNewSections')}</span>
                <FormNumberInput
                  max={MAX_SONG_LIMIT}
                  disabled={!template.canEdit}
                  name="settings.sectionMustPlayLimit"
                />
              </div>
            </ContentArea>
          </>
        }
      />
    </FormikProvider>
  );
};

export default TemplateGeneral;
