import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SettingsItem from './SettingsItem';
import WithDjType from 'components/user/WithDjType';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useMe } from 'graphql/hooks/user';
import { onError } from 'graphql/helpers';

import { RETRY_SUBSCRIPTION_PAYMENT } from 'graphql/mutations/user';

import { DjType } from 'types/enums';

import useStyles from './style';
import { UserContext } from 'components/context/UserContext';

const PaymentStatus: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, isActivated, isAmbassador } = useMe();

  const { refetchUser } = useContext(UserContext);

  const financeStatus = useMemo(() => user!.djSettings!.financeStatus || '-', [
    user?.djSettings?.financeStatus,
  ]);

  const isPaymentVisible = useMemo(
    () => !!user?.djSettings?.stripeId && !isAmbassador && !isActivated,
    [isAmbassador, user?.djSettings?.stripeId, isActivated]
  );

  const [retrySubscriptionPayment, { loading: isRetryingPayment }] = useMutation(
    RETRY_SUBSCRIPTION_PAYMENT,
    {
      onCompleted: () => {
        setTimeout(() => {
          refetchUser();
        }, 2000);
      },
      onError,
    }
  );

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['creditCardChecked-24']} className={classes.generalIcon} />
        <div className={classes.greyText}>{t('paymentStatus')}</div>
        <div
          className={classNames(classes.boldText, classes.role, {
            [classes.successText]: isActivated,
            [classes.failText]: !isActivated && !isAmbassador,
          })}
        >
          {t(financeStatus)}
        </div>
        <WithDjType types={[DjType.single]} extraCondition={isPaymentVisible}>
          <Button
            onClick={() => retrySubscriptionPayment()}
            loading={isRetryingPayment}
            displayType="primary"
            shape="round"
            size="lg"
          >
            {t('retryCharge')}
          </Button>
        </WithDjType>
      </div>
    </SettingsItem>
  );
};

export default PaymentStatus;
