import React, { FC, useContext, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FormSwitch from 'components/form/inputs/FormSwitch';
import PrintPdfQuestionSelectors from '../sections/PrintPdfQuestionSelectors';
import { SelectListContext } from 'components/context/SelectListContext';

import { PdfSettingsLayout } from 'types/enums';
import { ContentBlockHeaderProps, PrintQuestionTypes } from '../interfaces';

import useStyles from '../style';

const ContentBlockHeader: FC<ContentBlockHeaderProps> = ({ inputName, subFieldName, name }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectListContext = useContext(SelectListContext);

  const { values, setFieldValue } = useFormikContext<CreateEventFileWebForm>();

  const isSelected = !!get(values, inputName);

  const isTimeline = useMemo(() => subFieldName === PdfSettingsLayout.timeline, [subFieldName]);
  const isSections = useMemo(() => subFieldName === PdfSettingsLayout.sections, [subFieldName]);

  return (
    <div
      className={classNames('contentBlockHeader', classes.contentBlockHeader, {
        disabled: !isSelected,
      })}
      onClick={e => e.stopPropagation()}
    >
      <h3>{`${t(name)} ${t(isSelected ? 'on' : 'off')}`}</h3>
      <FormSwitch
        name={inputName}
        onChange={checked => {
          if (!!subFieldName) {
            const newList = values[subFieldName].map(option => {
              return { ...option, selected: checked };
            });

            selectListContext?.setSelectedIds(checked ? newList.map(({ _id }) => _id) : []);

            setFieldValue(subFieldName, newList);
          }
        }}
      />
      {isTimeline ? (
        <PrintPdfQuestionSelectors
          options={[PrintQuestionTypes.onlyAnswers, PrintQuestionTypes.questionsAnswers]}
          fieldName="timelinePrintQuestions"
        />
      ) : null}
      {isSections ? (
        <PrintPdfQuestionSelectors
          options={[PrintQuestionTypes.onlyAnswered, PrintQuestionTypes.allQuestions]}
          fieldName="printUnansweredQuestions"
        />
      ) : null}
    </div>
  );
};

export default ContentBlockHeader;
