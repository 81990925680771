import React, { FC } from 'react';

import EmptyState from '../EmptyState';

import { ReactComponent as EmptyStateNoSectionsIcon } from '../../../resources/img/svg/emptyStateNoSections.svg';

import { DefaultEmptyStateProps } from '../interfaces';

const NoSectionsYet: FC<DefaultEmptyStateProps> = ({ children }) => {
  return (
    <EmptyState size="lg" icon={EmptyStateNoSectionsIcon}>
      {children}
    </EmptyState>
  );
};

export default NoSectionsYet;
