import pick from 'lodash/pick';

import { ReactComponent as RobotoFontIcon } from 'resources/img/svg/robotoFont.svg';
import { ReactComponent as TimesNewRomanFontIcon } from 'resources/img/svg/timesNewRomanFont.svg';
import { ReactComponent as ArchivoNarrowIcon } from 'resources/img/svg/archivoNarrowFont.svg';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PdfFont, PdfScale } from 'types/enums';
import { PreparedPayloadProps, pageLayout } from './interfaces';

export const pdfFonts = {
  [PdfFont.Roboto]: RobotoFontIcon,
  [PdfFont.TimesNewRoman]: TimesNewRomanFontIcon,
  [PdfFont.ArchivoNarrow]: ArchivoNarrowIcon,
};

export const pdfLayoutIcons = {
  [pageLayout.portrait]: IconmoonFont['layoutPortrait-16'],
  [pageLayout.landscape]: IconmoonFont['layoutLandscape-16'],
};

export const PDF_UPDATE_LAYOUT_FIELDS = [
  '_id',
  'selected',
  'showSongs',
  'showNotes',
  'showQuestions',
  '__typename',
];

export const PDF_CREATE_FILE_FIELDS = ['_id', 'showSongs', 'showNotes', 'showQuestions'];

export const getPraparedPayload = ({
  params,
  isEventDj,
  fieldsToPick,
  filterSelection = true,
}: PreparedPayloadProps) => {
  return {
    ...pick(params, [
      'pdfScale',
      'isColorful',
      'landscape',
      'font',
      'timelinePrintQuestions',
      'printUnansweredQuestions',
      'withDJNotes',
      'withEventDetails',
    ]),
    withDJNotes: isEventDj ? params.withDJNotes : false,
    timeline: params.timeline
      .filter(v => (filterSelection ? v.selected : true))
      .map(option => pick(option, fieldsToPick)),
    sections: params.sections
      .filter(v => (filterSelection ? v.selected : true))
      .map(option => pick(option, fieldsToPick)),
    playlists: params.playlists
      .filter(v => (filterSelection ? v.selected : true))
      .map(option => pick(option, ['selected', ...fieldsToPick])),
  } as CreateEventFileWebForm;
};

export const RECOMMENDED_FONT_SIZE = PdfScale.medium;
