import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PdfSectionToggler from './PdfSectionToggler';
import VisibilityLabel from 'components/labels/VisibilityLabel';
import { EventContext } from 'components/context/EventContext';

import { PdfSettingsLayout, SectionBlockName, SectionVisibilityRoles } from 'types/enums';
import { NotesTogglerProps } from '../interfaces';

import useStyles from './style';

const NotesToggler: FC<NotesTogglerProps> = ({ section, someNotesEnabled, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventHost } = useContext(EventContext);

  return rest.layout !== PdfSettingsLayout.playlists ? (
    <PdfSectionToggler
      name={SectionBlockName.notes}
      content={
        !!section && section.notesEnabled ? (
          !(!section.notesVisibleForHosts && isEventHost) ? (
            <div className={classes.sectionsOptionInfo}>
              {isEventHost ? null : section.notesVisibleForHosts ? (
                <VisibilityLabel
                  type={SectionVisibilityRoles.host}
                  showTooltip={false}
                  useShortNames
                />
              ) : (
                <VisibilityLabel
                  type={SectionVisibilityRoles.dj}
                  showTooltip={false}
                  useShortNames
                />
              )}
            </div>
          ) : null
        ) : !section || section?.notesEnabled ? (
          <div className="toggle-content">{!section ? t('notes') : null}</div>
        ) : null
      }
      {...rest}
    />
  ) : null;
};

export default NotesToggler;
