import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import Content from './Content';
import NotesBlockHeader from './NotesBlockHeader';
import SectionBlock from 'components/sections/blocks/SectionBlock';
import { TimelineNotesContext, EditingNotesContext } from './TimelineNotesContext';

import { EventTimelineTabKey } from 'types/enums';
import { TimelineNotesBlockProps } from './interfaces';

import useStyles from './style';

const TimelineNotesBlock: FC<TimelineNotesBlockProps> = ({
  variables,
  section,
  sections,
  updateSection,
}) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [notesText, setNotesText] = useState<string>('');

  const handleCollapse = () => {
    setIsEditing(false);
    setNotesText('');
  };

  useEffect(() => {
    !!section.note && setNotesText(section.note);
  }, []);

  return (
    <TimelineNotesContext.Provider value={{ section, sections, variables, updateSection }}>
      <EditingNotesContext.Provider
        value={{
          isEditing,
          setIsEditing,
          notesText,
          setNotesText,
        }}
      >
        <SectionBlock
          className={classNames('content-fade-in ready _3', classes.timelineNotesBlock, {
            [classes.stickySubHeader]: isEditing,
          })}
          onCollapse={handleCollapse}
          type={EventTimelineTabKey.notes}
          section={section}
          renderHeader={({ caretButton }) => <NotesBlockHeader>{caretButton}</NotesBlockHeader>}
          renderContent={({ isShown, toggleShow }) => {
            return (
              <>
                <div onClick={isShown ? () => null : toggleShow}>
                  <Content />
                </div>
              </>
            );
          }}
          preventCollapse={isEditing}
          canCollapse
          defaultShown
        />
      </EditingNotesContext.Provider>
    </TimelineNotesContext.Provider>
  );
};

export default TimelineNotesBlock;
