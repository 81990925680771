import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { onError } from 'graphql/helpers';

import { USER_DATA } from '../fragments/user';
import { AUTH_RESPONSE_DATA, COMPLETE_SIGNUP_RESPONSE_DATA } from 'graphql/fragments/auth';

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        ...UserData
      }
    }
  }
  ${USER_DATA}
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($message: String!) {
    deleteAccount(appType: WEB, message: $message) {
      status
    }
  }
`;

export const LOG_USER_IN = gql`
  mutation login($accessToken: String!, $refreshToken: String!, $user: LoggedUserInput!) {
    logUserIn(accessToken: $accessToken, refreshToken: $refreshToken, user: $user) @client
  }
`;

export const SIGN_IN_FACEBOOK = gql`
  mutation signInFacebook($token: String!) {
    signInFacebook(token: $token) {
      accessToken
      refreshToken
      user {
        ...UserData
      }
    }
  }
  ${USER_DATA}
`;

export const SIGN_IN_GOOGLE = gql`
  mutation signInGoogle($token: String!, $clientId: String!) {
    signInGoogle(token: $token, clientId: $clientId) {
      accessToken
      refreshToken
      user {
        ...UserData
      }
    }
  }
  ${USER_DATA}
`;

export const SIGN_GOOGLE = gql`
  mutation signGoogle($token: String!, $clientId: String!) {
    signGoogle(token: $token, clientId: $clientId) {
      ...AuthResponseData
      ...CompleteSignUpResponseData
    }
  }
  ${AUTH_RESPONSE_DATA}
  ${COMPLETE_SIGNUP_RESPONSE_DATA}
`;

export const SIGN_IN_APPLE = gql`
  mutation signInApple($token: String!, $clientId: String!, $userData: UserDataFromApple) {
    signInApple(token: $token, clientId: $clientId, userData: $userData) {
      ...AuthResponseData
      ...CompleteSignUpResponseData
    }
  }
  ${AUTH_RESPONSE_DATA}
  ${COMPLETE_SIGNUP_RESPONSE_DATA}
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPasswordWeb(password: $password, token: $token)
  }
`;

export const SEND_RESET_PASSWORD_LINK = gql`
  mutation sendResetPasswordLink($email: String!) {
    sendResetPasswordLink(email: $email)
  }
`;

export const CHECK_EMAIL_EXIST = gql`
  mutation checkEmailExists($email: String!, $isMobile: Boolean) {
    checkEmailExists(email: $email, isMobile: $isMobile) {
      exists
      expiresAt
      userName
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation verifyViaCode($email: String!, $code: String!) {
    verifyViaCode(email: $email, code: $code) {
      verified
      token
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($user: RegistrationInput!, $token: String) {
    signUp(user: $user, token: $token) {
      accessToken
      refreshToken
      user {
        _id
      }
    }
  }
`;

export const COMPLETE_SIGN_UP = gql`
  mutation completeSignup($payload: CompleteSignupInput!, $token: String!) {
    completeSignup(payload: $payload, token: $token) {
      accessToken
      refreshToken
      user {
        _id
      }
    }
  }
`;

// MUTATION HOOKS //

export const useSignIn = () => {
  const [signInMutation, { loading, error }] = useMutation<SignInResponse, SignInMutationVariables>(
    SIGN_IN
  );

  const signIn = (options: MutationHookOptions<SignInResponse, SignInMutationVariables>) =>
    signInMutation({
      ...options,
      onError: error => {
        options.onError?.(error);
        onError(error);
      },
    });

  return {
    signIn,
    loading,
    error,
  };
};

// MUTATION INTERFACES //

export interface SignInResponse {
  signIn: AuthResponse;
}

export interface SignInMutationVariables {
  email: string;
  password: string;
}
