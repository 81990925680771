import { gql } from '@apollo/client';

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($payload: CreateTemplateInput!) {
    createTemplate(payload: $payload) {
      _id
    }
  }
`;

export const TOGGLE_PUBLIC_TEMPLATE = gql`
  mutation togglePublicTemplate($templateId: ID!) {
    togglePublicTemplate(templateId: $templateId) {
      isPublic
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($templateId: ID!, $payload: UpdateTemplateInput!) {
    updateTemplate(templateId: $templateId, payload: $payload) {
      title
      daysCountBeforeLock
      image {
        squareUrl
        rectUrl
      }
      settings {
        canHostsInviteGuests
        canGuestsInviteGuests
        sectionSongsLimit
        sectionMustPlayLimit
      }
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($templateId: ID!) {
    deleteTemplate(templateId: $templateId)
  }
`;

export const DELETE_TEMPLATE_SECTION = gql`
  mutation removeTemplateSection($templateId: ID!, $sectionId: ID!) {
    removeTemplateSection(templateId: $templateId, sectionId: $sectionId)
  }
`;

export const CLONE_TEMPLATE = gql`
  mutation cloneTemplate($templateId: ID!, $payload: CloneTemplatePayload!) {
    cloneTemplate(templateId: $templateId, payload: $payload) {
      _id
    }
  }
`;

export const CREATE_TEMPLATE_SECTION = gql`
  mutation create($payload: CreateSectionInput!, $templateId: ID!) {
    createTemplateSection(templateId: $templateId, payload: $payload) {
      _id
      settings {
        songsEnabled
      }
    }
  }
`;

export const UPDATE_TEMPLATE_SECTION = gql`
  mutation updateTemplateSection(
    $templateId: ID!
    $payload: UpdateTemplateSectionInput!
    $sectionId: ID!
  ) {
    updateTemplateSection(templateId: $templateId, payload: $payload, sectionId: $sectionId) {
      _id
      note
    }
  }
`;

export const REORDER_TEMPLATE_SECTIONS = gql`
  mutation reorderTemplateSections($templateId: ID!, $sourceSectionId: ID!, $targetSectionId: ID) {
    reorderTemplateSections(
      templateId: $templateId
      sourceSectionId: $sourceSectionId
      targetSectionId: $targetSectionId
    )
  }
`;

export const CREATE_TEMPLATE_SECTION_QUESTION = gql`
  mutation createTemplateSectionQuestion(
    $templateId: ID!
    $sectionId: ID!
    $payload: CreateQuestionInput!
  ) {
    createTemplateSectionQuestion(
      templateId: $templateId
      sectionId: $sectionId
      payload: $payload
    ) {
      question {
        _id
      }
    }
  }
`;

export const ADD_SONG_IDEAS_TO_TEMPLATE_SECTION = gql`
  mutation addSongIdeasToTemplateSection(
    $templateId: ID!
    $sectionId: ID!
    $payload: AddSongIdeasToSectionInput!
  ) {
    addSongIdeasToTemplateSection(
      templateId: $templateId
      sectionId: $sectionId
      payload: $payload
    ) {
      addedCount
      totalCount
    }
  }
`;

export const DELETE_SONG_IDEAS_FROM_TEMPLATE_SECTION = gql`
  mutation deleteSongIdeasFromTemplateSection(
    $templateId: ID!
    $sectionId: ID!
    $payload: DeleteSongIdeasFromSectionInput!
  ) {
    deleteSongIdeasFromTemplateSection(
      templateId: $templateId
      sectionId: $sectionId
      payload: $payload
    )
  }
`;

export const REORDER_TEMPLATE_SECTION_SONG_IDEAS = gql`
  mutation reorderTemplateSectionSongIdeas(
    $templateId: ID!
    $sectionId: ID!
    $sourceSongIdeasId: ID!
    $targetSongIdeasId: ID
  ) {
    reorderTemplateSectionSongIdeas(
      templateId: $templateId
      sectionId: $sectionId
      sourceSongIdeasId: $sourceSongIdeasId
      targetSongIdeasId: $targetSongIdeasId
    )
  }
`;

export const UPDATE_TEMPLATE_SECTION_BLOCKS = gql`
  mutation updateTemplateSectionBlocks(
    $templateId: ID!
    $sectionId: ID!
    $blocks: [SectionBlockInput!]!
  ) {
    updateTemplateSectionBlocks(templateId: $templateId, sectionId: $sectionId, blocks: $blocks)
  }
`;
