import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import TemplateRow from './TemplateRow';
import Scrollbar from 'vibo-ui/Scrollbar';
import TemplatesHeader from './TemplatesHeader';
import TemplatesVirtualized from './TemplatesVirtualized';
import NoTemplates from 'components/emptyStates/NoTemplates';
import TemplatesContentHeader from './TemplatesContentHeader';
import LoadingOverlay from 'components/common/LoadingOverlay';
import CreateTemplateButton from 'components/buttons/CreateTemplateButton';
import { TemplatesContextProvider } from 'components/context/TemplatesContext';

import { TEMPLATE_ROW_LG, TEMPLATE_ROW_SM } from './constants';

import { TemplatesType } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { TemplatesListProps } from './interfaces';

import useEventsStyles from 'components/events/EventsList/style';
import useEventViewsStyles from 'components/events/EventsList/views/style';
import useStyles from './style';

const TemplatesList: FC<TemplatesListProps> = ({
  fetchMore,
  queryKey,
  filter,
  templatesData,
  id,
  type,
  loading,
}) => {
  const eventsClasses = useEventsStyles();
  const eventViewsClasses = useEventViewsStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { push } = useHistory();

  const isTemplatesPublic = type === TemplatesType.vibo;
  const isUserTemplates = type === TemplatesType.user;

  const isFilterApplied = !!filter.q || !!filter.typeId;

  const openTemplate = useCallback(
    (templateId: string) => push(`/template/${templateId}/general`),
    []
  );

  const columns = [
    'title',
    `${isTemplatesPublic ? 'createdBy' : 'isPublic'}`,
    'type',
    'dateTemplate',
  ];

  return (
    <>
      <div
        className={classNames('templates', eventViewsClasses.eventsDjView, classes.templates, {
          templatesPublic: isTemplatesPublic,
        })}
        id={id}
      >
        {isFilterApplied || !!templatesData.totalCount || (!templatesData.totalCount && loading) ? (
          <>
            <TemplatesContentHeader
              templatesCount={templatesData.totalCount}
              templatesType={t(queryKey)}
            />
            {<TemplatesHeader columns={columns} />}
            {loading && !templatesData.totalCount ? (
              <LoadingOverlay fillOverlay={false} className={eventsClasses.eventsLoading} />
            ) : isFilterApplied && !templatesData.totalCount ? (
              <NoTemplates>{t('noResults')}</NoTemplates>
            ) : (
              <Scrollbar id="templates-scroll">
                <TemplatesContextProvider value={{ type, queryKey }}>
                  <TemplatesVirtualized
                    rowRenderer={template => (
                      <TemplateRow
                        template={template}
                        columns={columns}
                        onClick={() => openTemplate(template._id)}
                        key={`template-${template._id}`}
                      />
                    )}
                    fetchMore={fetchMore}
                    filter={filter}
                    queryKey={queryKey}
                    loading={loading}
                    templatesData={templatesData}
                    rowHeights={[TEMPLATE_ROW_SM, TEMPLATE_ROW_LG]}
                  />
                </TemplatesContextProvider>
              </Scrollbar>
            )}
          </>
        ) : (
          <NoTemplates>
            {t('noTemplatesYet')}
            {isUserTemplates ? (
              <>
                <CreateTemplateButton className="centrate" />
                <Button
                  to={`/templates/${TemplatesType.vibo}`}
                  prefixIcon={IconmoonFont['plus-24']}
                  className="centrate"
                  displayType="bordered"
                  shape="round"
                  size="lg"
                >
                  {t('addFromPublicTemplate')}
                </Button>
              </>
            ) : null}
          </NoTemplates>
        )}
      </div>
    </>
  );
};

export default TemplatesList;
