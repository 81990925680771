import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  colorBox: {
    width: '13px',
    height: '13px',
    borderRadius: '4px',

    '@media screen and (min-width: 1441px)': {
      width: '16px',
      height: '16px',
    },
  },
}));

export default useStyles;
