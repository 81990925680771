import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import Tooltip from 'vibo-ui/Tooltip';

import { sectionVisibility } from 'services/sections/constants';

import { SectionVisibilityRoles } from 'types/enums';
import { VisibilityLabelProps } from './interfaces';

import useStyles from './style';

const VisibilityLabel: FC<VisibilityLabelProps> = ({
  className,
  tooltipPlacement = 'top',
  type = '',
  showTooltip = true,
  useShortNames = false,
  fillMode = 'outline',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const role = sectionVisibility.find(option => option.id === type);

  const title =
    type === SectionVisibilityRoles.dj
      ? t('sectionVisibleForDj')
      : type === SectionVisibilityRoles.public
      ? t('sectionVisibleForAll')
      : '';

  return type ? (
    <Tooltip
      overlay={showTooltip ? title : null}
      align={{ offset: [6, 0] }}
      placement={tooltipPlacement}
      type="primary"
    >
      <div
        className={classNames(
          'visibilityLabel',
          `${type}-type`,
          classes.visibilityLabel,
          fillMode,
          className
        )}
      >
        <Icon icon={role?.icon} />
        {useShortNames ? role?.shortName || role?.name : role?.name}
      </div>
    </Tooltip>
  ) : null;
};

export default VisibilityLabel;
