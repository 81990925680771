import React, { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useQueryParam, StringParam } from 'use-query-params';

import Input from 'vibo-ui/Input';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { DEBOUNCE_WAIT_MS } from 'services/constants';

import { SearchInputProps } from './interfaces';

import useStyles from './style';

const SearchInput: FC<SearchInputProps> = ({
  className,
  wrapperClassName,
  queryName = 'q',
  withClearBtn = true,
  iconPrefix = <Icon icon={IconmoonFont['search-16']} />,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  const [q, setQuery] = useQueryParam(queryName, StringParam);

  const handleSearchQuery = useCallback(
    debounce((e, value) => {
      const newValue = value || '';

      !!queryName && setQuery(`${newValue}` || undefined);

      onChange?.(e, newValue.trim());
    }, DEBOUNCE_WAIT_MS.SEARCH),
    [queryName]
  );

  useEffect(() => {
    if (!!q) {
      // @ts-ignore
      onChange?.(null, q.trim());
    }
  }, []);

  return (
    <div className={classNames('searchInputWrapper', classes.searchInputWrapper, wrapperClassName)}>
      <Input
        onChange={handleSearchQuery}
        iconPrefix={iconPrefix}
        withClearBtn={withClearBtn}
        className={classNames('viboSearchInput', classes.viboSearchInput, className)}
        {...rest}
        value={rest.value || q || ''}
      />
    </div>
  );
};

export default SearchInput;
