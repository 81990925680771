import React from 'react';

import { ButtonIconType } from '../../types/enums';

import { ReactComponent as ReloadIcon } from 'resources/img/svg/common/reload.svg';
import { ReactComponent as CheckboxUncheckedIcon } from 'resources/img/svg/common/checkboxUnchecked.svg';
import { ReactComponent as PublicIcon } from 'resources/img/svg/common/public.svg';
import { ReactComponent as DownloadIcon } from 'resources/img/svg/common/download.svg';
import { ReactComponent as EditIcon } from 'resources/img/svg/common/edit.svg';
import { ReactComponent as DeleteIcon } from 'resources/img/svg/common/delete.svg';
import { ReactComponent as CloneIcon } from 'resources/img/svg/common/clone.svg';
import { ReactComponent as CopyIcon } from 'resources/img/svg/common/copy.svg';
import { ReactComponent as CommentIcon } from 'resources/img/svg/common/comment.svg';
import { ReactComponent as SpotifyIcon } from 'resources/img/svg/common/spotify.svg';
import { ReactComponent as SettingsGearIcon } from 'resources/img/svg/common/settingsGear.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'resources/img/svg/common/checkboxChecked.svg';
import { ReactComponent as CloseIcon } from 'resources/img/svg/common/close.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from 'resources/img/svg/common/checkboxIndeterminate.svg';
import { ReactComponent as FlagFilledIcon } from 'resources/img/svg/common/flagFilled.svg';
import { ReactComponent as FlagOutlinedIcon } from 'resources/img/svg/common/flagOutlined.svg';
import { ReactComponent as ExpandIcon } from 'resources/img/svg/common/expand.svg';
import { ReactComponent as CollapseIcon } from 'resources/img/svg/common/collapse.svg';
import { ReactComponent as SearchIcon } from 'resources/img/svg/common/search.svg';
import { ReactComponent as PlusIcon } from 'resources/img/svg/common/plus.svg';
import { ReactComponent as ComputerIcon } from 'resources/img/svg/prepMode/computer.svg';
import { ReactComponent as SongIdeasIcon } from 'resources/img/svg/common/songIdeas.svg';
import { ReactComponent as QuestionsIcon } from 'resources/img/svg/common/questionCircleOutlined.svg';
import { ReactComponent as NotesIcon } from 'resources/img/svg/common/noteWithWriting.svg';
import { ReactComponent as ArrowLeftIcon } from 'resources/img/svg/common/arrowLeft.svg';
import { ReactComponent as SettingsGear24Icon } from 'resources/img/svg/settingsGear-24.svg';
import { ReactComponent as ThreeDotsIcon } from 'resources/img/svg/threeDots.svg';
import { ReactComponent as UserIcon } from 'resources/img/svg/common/user.svg';
import { ReactComponent as HeartOutlinedIcon } from 'resources/img/svg/heartOutlinedWithoutFrame.svg';
import { ReactComponent as ArrowUpIcon } from 'resources/img/svg/common/arrowUp.svg';
import { ReactComponent as ArrowDownIcon } from 'resources/img/svg/common/arrowDown.svg';
import { ReactComponent as ArrowRightIcon } from 'resources/img/svg/common/arrowRight.svg';
import { ReactComponent as MusicNoteDoubleIcon } from 'resources/img/svg/musicNoteDouble.svg';
import { ReactComponent as MustPlayOutlinedIcon } from 'resources/img/svg/common/mustPlayOutlined.svg';
import { ReactComponent as InfoOutlinedIcon } from 'resources/img/svg/common/infoOutlined.svg';
import { ReactComponent as InfoOutlinedNotRotatedIcon } from 'resources/img/svg/infoOutlinedNotRotated.svg';
import { ReactComponent as CheckMarkOutlinedIcon } from 'resources/img/svg/checkMarkOutlined.svg';
import { ReactComponent as SendIcon } from 'resources/img/svg/common/send.svg';
import { ReactComponent as GIcon } from 'resources/img/svg/H.svg';
import { ReactComponent as HIcon } from 'resources/img/svg/G.svg';
import { ReactComponent as ThreeDotsGorizontalIcon } from 'resources/img/svg/threeDotsGorizontal.svg';
import { ReactComponent as StarOutlinedIcon } from 'resources/img/svg/starOutlined.svg';
import { ReactComponent as MustPlayFilledIcon } from 'resources/img/svg/mustPlayFilled.svg';

export const buttonIcons = {
  [ButtonIconType.threeDotsGorizontal]: ThreeDotsGorizontalIcon,
  [ButtonIconType.arrowRight]: ArrowRightIcon,
  [ButtonIconType.send]: SendIcon,
  [ButtonIconType.checkMarkOutlined]: CheckMarkOutlinedIcon,
  [ButtonIconType.infoNotRotated]: InfoOutlinedNotRotatedIcon,
  [ButtonIconType.close]: CloseIcon,
  [ButtonIconType.unchecked]: CheckboxUncheckedIcon,
  [ButtonIconType.info]: InfoOutlinedIcon,
  [ButtonIconType.comment]: CommentIcon,
  [ButtonIconType.clone]: CloneIcon,
  [ButtonIconType.copy]: CopyIcon,
  [ButtonIconType.delete]: DeleteIcon,
  [ButtonIconType.edit]: EditIcon,
  [ButtonIconType.download]: DownloadIcon,
  [ButtonIconType.public]: PublicIcon,
  [ButtonIconType.reload]: ReloadIcon,
  [ButtonIconType.spotify]: SpotifyIcon,
  [ButtonIconType.settings]: SettingsGearIcon,
  [ButtonIconType.mark]: CheckboxCheckedIcon,
  [ButtonIconType.unmark]: CheckboxIndeterminateIcon,
  [ButtonIconType.flag]: FlagFilledIcon,
  [ButtonIconType.unflag]: FlagOutlinedIcon,
  [ButtonIconType.expand]: ExpandIcon,
  [ButtonIconType.collapse]: CollapseIcon,
  [ButtonIconType.search]: SearchIcon,
  [ButtonIconType.plus]: PlusIcon,
  [ButtonIconType.computer]: ComputerIcon,
  [ButtonIconType.songs]: SongIdeasIcon,
  [ButtonIconType.questions]: QuestionsIcon,
  [ButtonIconType.notes]: NotesIcon,
  [ButtonIconType.back]: ArrowLeftIcon,
  [ButtonIconType.settings24]: SettingsGear24Icon,
  [ButtonIconType.threeDots]: ThreeDotsIcon,
  [ButtonIconType.user]: UserIcon,
  [ButtonIconType.heartOutlined]: HeartOutlinedIcon,
  [ButtonIconType.arrowUp]: ArrowUpIcon,
  [ButtonIconType.arrowDown]: ArrowDownIcon,
  [ButtonIconType.totalCount]: MusicNoteDoubleIcon,
  [ButtonIconType.mustPlayCount]: MustPlayOutlinedIcon,
  [ButtonIconType.hostLikedCount]: HeartOutlinedIcon,
  [ButtonIconType.withCommentsCount]: CommentIcon,
  [ButtonIconType.flaggedCount]: FlagOutlinedIcon,
  [ButtonIconType.H]: HIcon,
  [ButtonIconType.G]: GIcon,
  [ButtonIconType.starOutlined]: StarOutlinedIcon,
  [ButtonIconType.mustPlayFilled]: MustPlayFilledIcon,
};

interface ButtonIconProps {
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

export function getButtonIcon(type: ButtonIconType): React.FC<ButtonIconProps> {
  return buttonIcons[type] as React.FC<ButtonIconProps>;
}
