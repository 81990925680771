import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tooltip from 'vibo-ui/Tooltip';
import CustomLabel from '../CustomLabel';

import { useMe } from 'graphql/hooks/user';

import { CustomLabelProps, CustomLabelTypes } from '../CustomLabel/interfaces';

import useStyles from './style';

const ProLabel: FC<CustomLabelProps> = ({ ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isBrandingAllowed } = useMe();

  const title = useMemo(
    () =>
      isBrandingAllowed
        ? t('There are elements that are only available in the pro version.')
        : t(
            'There are elements that are only available in the pro version. Please upgrade your account to the pro version to use all the features.'
          ),
    [isBrandingAllowed]
  );

  return (
    <Tooltip
      overlay={title}
      className={classes.proLabelTooltip}
      mouseEnterDelay={1}
      type="primary"
      placement="top"
    >
      <div className={classNames('proLabel', classes.proLabel, rest.className)}>
        <CustomLabel
          {...rest}
          type={isBrandingAllowed ? CustomLabelTypes.primary : CustomLabelTypes.gray}
          className="whiteBg"
        >
          PRO
        </CustomLabel>
      </div>
    </Tooltip>
  );
};

export default ProLabel;
