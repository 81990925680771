import { gql } from '@apollo/client';

export const IMPORT_PLAYLIST_TO_SONG_IDEAS = gql`
  mutation importPlaylistToSongIdeas(
    $songIdeasId: ID!
    $playlistId: ID
    $source: MusicImportSource!
    $tracksToAdd: [ID!]
    $tracksToIgnore: [ID!]
  ) {
    importPlaylistToSongIdeas(
      songIdeasId: $songIdeasId
      playlistId: $playlistId
      source: $source
      tracksToAdd: $tracksToAdd
      tracksToIgnore: $tracksToIgnore
    ) {
      totalCount
      addedCount
      existingCount
    }
  }
`;

export const IMPORT_PLAYLIST_TO_SECTION_WEB = gql`
  mutation importPlaylistToSectionWeb(
    $eventId: ID!
    $sectionId: ID!
    $playlistId: ID
    $source: MusicImportSource!
    $tracksToAdd: [ID!]
    $tracksToIgnore: [ID!]
  ) {
    importPlaylistToSectionWeb(
      eventId: $eventId
      sectionId: $sectionId
      playlistId: $playlistId
      tracksToAdd: $tracksToAdd
      tracksToIgnore: $tracksToIgnore
      source: $source
    ) {
      totalCount
      addedCount
      existingCount
      dontPlayCount
    }
  }
`;

export const UPDATE_SECTION_SONGS = gql`
  mutation updateSectionSongs(
    $eventId: ID!
    $sectionId: ID!
    $songIds: [ID!]!
    $payload: UpdateSectionSongInput
  ) {
    updateSectionSongs(
      eventId: $eventId
      sectionId: $sectionId
      songIds: $songIds
      payload: $payload
    ) {
      _id
      isMustPlay
      isFlagged
      comment
    }
  }
`;

export const TOGGLE_LIKE = gql`
  mutation toggleLike($eventId: ID!, $sectionId: ID!, $songId: ID!, $liked: Boolean!) {
    toggleLike(eventId: $eventId, sectionId: $sectionId, songId: $songId, liked: $liked) {
      liked
    }
  }
`;

export const MOVE_SECTION_SONGS = gql`
  mutation moveSectionSongs(
    $eventId: ID!
    $sourceSectionId: ID!
    $targetSectionId: ID!
    $songIds: [ID!]!
  ) {
    moveSectionSongs(
      eventId: $eventId
      sourceSectionId: $sourceSectionId
      targetSectionId: $targetSectionId
      songIds: $songIds
    )
  }
`;

export const DELETE_SECTION_SONGS = gql`
  mutation removeSectionSongs($eventId: ID!, $sectionId: ID!, $songIds: [ID!]!) {
    removeSectionSongs(eventId: $eventId, sectionId: $sectionId, songIds: $songIds)
  }
`;

export const ADD_SONG_TO_SECTION = gql`
  mutation addSongToSection($eventId: ID!, $sectionId: ID!, $payload: AddSongToSectionInput!) {
    addSongToSection(eventId: $eventId, sectionId: $sectionId, payload: $payload) {
      totalCount
      added
      songId
    }
  }
`;

export const REORDER_SONGS = gql`
  mutation reorderSongs($eventId: ID!, $sectionId: ID!, $sourceSongId: ID!, $targetSongId: ID) {
    reorderSongs(
      eventId: $eventId
      sectionId: $sectionId
      sourceSongId: $sourceSongId
      targetSongId: $targetSongId
    )
  }
`;

export const GENERATE_PLAYLIST = gql`
  mutation generatePlaylist(
    $eventId: ID!
    $sectionIds: [ID!]!
    $computerId: ID!
    $sort: SongsSortInput
    $filter: SectionSongsFilter
    $options: GeneratePlaylistOptions
  ) {
    generatePlaylist(
      eventId: $eventId
      sectionIds: $sectionIds
      computerId: $computerId
      sort: $sort
      filter: $filter
      options: $options
    ) {
      name
      files
    }
  }
`;
export const UPDATE_PREP_MODE_SONGS = gql`
  mutation updatePrepModeSongs(
    $eventId: ID!
    $sectionId: ID!
    $computerId: ID!
    $payload: UpdatePrepModeSongsInput!
    $filter: PrepModeSongsFilter
  ) {
    updatePrepModeSongs(
      eventId: $eventId
      sectionId: $sectionId
      computerId: $computerId
      payload: $payload
      filter: $filter
    )
  }
`;
