export enum SpinnerShapes {
  circle = 'circle',
  dots = 'dots',
}

export enum ProgressValue {
  'middle' = 40,
  'full' = 70,
}

export enum SectionVisibilityRoles {
  dj = 'dj',
  host = 'host',
  public = 'public',
}

export enum SpinnerSize {
  small = 'small',
  default = 'default',
  large = 'large',
}

export enum DndIndex {
  section = 'section',
  singleSong = 'singleSong',
  sectionBlock = 'sectionBlock',
  question = 'question',
}

export enum SectionType {
  simple = 'simple',
  headline = 'headline',
  dontPlay = 'dontPlay',
}

export enum QuestionType {
  text = 'text',
  checkbox = 'checkbox',
  header = 'header',
  radio = 'radio',
  select = 'select',
  imageAnswer = 'imageAnswer',
  pdfAnswer = 'pdfAnswer',
  locationAnswer = 'locationAnswer',
  phoneNumberAnswer = 'phoneNumberAnswer',
}

export enum SongIdeasSourceEnum {
  vibo = 'vibo',
  user = 'user',
  company = 'company',
}

export enum SongsSource {
  spotify = 'spotify',
  searchField = 'searchField',
}

export enum EventUserType {
  host = 'host',
  guest = 'guest',
  dj = 'dj',
}

export enum EventStatus {
  pending = 1,
  active = 2,
  locked = 3,
}

export enum EventStatusEnum {
  active = 'active',
  pending = 'pending',
  locked = 'locked',
  finalized = 'finalized',
}

export enum ButtonIconType {
  threeDotsGorizontal = 'threeDotsGorizontal',
  close = 'close',
  unchecked = 'unchecked',
  threeDots = 'threeDots',
  settings24 = 'settings24',
  reload = 'reload',
  public = 'public',
  download = 'download',
  edit = 'edit',
  delete = 'delete',
  clone = 'clone',
  copy = 'copy',
  comment = 'comment',
  spotify = 'spotify',
  settings = 'settings',
  mark = 'mark',
  unmark = 'unmark',
  flag = 'flag',
  unflag = 'unflag',
  expand = 'expand',
  collapse = 'collapse',
  search = 'search',
  plus = 'plus',
  computer = 'computer',
  songs = 'songs',
  questions = 'questions',
  notes = 'notes',
  back = 'back',
  user = 'user',
  heartOutlined = 'heartOutlined',
  arrowUp = 'arrowUp',
  arrowDown = 'arrowDown',
  arrowRight = 'arrowRight',
  totalCount = 'totalCount',
  mustPlayCount = 'mustPlayCount',
  mustPlayFilled = 'mustPlayFilled',
  hostLikedCount = 'hostLikedCount',
  withCommentsCount = 'withCommentsCount',
  flaggedCount = 'flaggedCount',
  info = 'info',
  infoNotRotated = 'infoNotRotated',
  checkMarkOutlined = 'checkMarkOutlined',
  send = 'send',
  H = 'H',
  G = 'G',
  starOutlined = 'starOutlined',
}

export enum EventType {
  birthday = 'Birthday',
  corporate = 'Corporate',
  mitzvah = 'Mitzvah',
  party = 'Party',
  quinceanera = 'Quinceanera',
  schoolDance = 'School Dance',
  sweet16 = 'Sweet 16',
  wedding = 'Wedding',
}

export enum Page {
  prepMode = 'prepMode',
  timeline = 'timeline',
  people = 'people',
  home = 'home',
  eventsUpcoming = 'eventsUpcoming',
  eventsHistory = 'eventsHistory',
  eventPdf = 'eventPdf',
  myDjs = 'myDjs',
  profileInfo = 'profileInfo',
  childDjProfile = 'childDjProfile',
  template = 'template',
  beatsource = 'beatsource',
  favoriteSections = 'favoriteSections',
}

export enum PrepModeSongExpansion {
  less = 'less',
  more = 'more',
  all = 'all',
}

export enum DjType {
  child = 'child',
  single = 'single',
  company = 'company',
}

export enum TemplatesType {
  user = 'user',
  vibo = 'vibo',
  company = 'company',
}

export enum SongIdeasType {
  me = 'me',
  vibo = 'vibo',
  company = 'company',
}

export enum ChangeUserImageStep {
  select = 'select',
  progress = 'progress',
  crop = 'crop',
  confirm = 'confirm',
  view = 'view',
}

export enum ChangeEventImageStep {
  select = 'select',
  progress = 'progress',
  cropSquare = 'cropSquare',
  confirmSquare = 'confirmSquare',
  // cropRect = 'cropRect',
  // confirmRect = 'confirmRect',
  view = 'view',
  finalView = 'finalView',
}

export enum KeyCode {
  backspace = 8,
  enter = 13,
  space = 32,
  arrowLeft = 37,
  arrowUp = 37,
  arrowRight = 39,
  arrowDown = 40,
}

export enum ScannerFileAction {
  play = 'play',
  locate = 'locate',
}

export enum SectionTimelineItemType {
  noMusic = 'noMusic',
  specialMoment = 'specialMoment',
  songList = 'songList',
  dontPlay = 'dontPlay',
}

export enum SectionIconType {
  noMusic = 'noMusic',
  specialMoment = 'specialMoment',
  songList = 'songList',
  public = 'public',
  dontPlay = 'dontPlay',
}

export enum ToggleableSongUpdateField {
  isMustPlay = 'isMustPlay',
  isFlagged = 'isFlagged',
}

export enum SongItemMenuKey {
  comment = 'comment',
  mustPlay = 'mustPlay',
  dontPlay = 'dontPlay',
  moveToSub = 'moveToSub',
  delete = 'delete',
}

export enum EventTimelineTabKey {
  songs = 'songs',
  questions = 'questions',
  notes = 'notes',
}

export enum SongSearchModalTabKey {
  search = 'search',
  song = 'song',
  songIdeasSongs = 'songIdeasSongs',
}

export enum PdfSettingsTabs {
  dj = 'dj',
  intro = 'introductions',
  hosts = 'hosts',
  vendor = 'vendor',
}

export enum PdfSettingOptions {
  eventInfo = 'eventInfo',
  djNotes = 'djNotes',
  timelineLayout = 'timelineLayout',
  sectionDetails = 'sectionDetails',
  playlists = 'playlists',
}

export enum UserRole {
  user = 'user',
  dj = 'dj',
  admin = 'admin',
}

export enum SongsSortOptionKeyEnum {
  topVoted = 'top_voted',
  oldest = 'oldest',
  newest = 'newest',
  custom = 'custom',
}

export enum GenerateEventPlaylistsSectionsFilter {
  all = 'all',
  done = 'done',
}

export enum GenerateEventPlaylistsSplit {
  split = 'split',
  one = 'one',
}

export enum GenerateSectionPlaylistFilter {
  all = 'all',
  flagged = 'flagged',
}

export enum PdfScale {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum EventFileType {
  docx = 'docx',
  pdf = 'pdf',
}

export enum LoginSources {
  zapier = 'zapier',
}

export enum ViboUserRole {
  user = 'user',
  company = 'company',
  single = 'single',
  child = 'child',
}

export enum DjFinanceStatus {
  activated = 'activated',
  limited = 'limited',
  failedCharge = 'failedCharge',
  ambassador = 'ambassador',
}

export enum MusicImportSource {
  spotify = 'spotify',
  appleMusic = 'appleMusic',
}

export enum SongButtonType {
  addSong = 'addSong',
  dontPlay = 'dontPlay',
  removeSong = 'removeSong',
}

// todo: enum with all errors
export enum ImportErrorCode {
  sectionSongsLimitRaised = 'SECTION_SONGS_LIMIT_RAISED',
  playlistTooLarge = 'PLAYLIST_TOO_LARGE',
}

export enum LabelPosition {
  top = 'top',
  right = 'right',
  left = 'left',
}

export enum LayoutCheckField {
  selected = 'selected',
  showSongs = 'showSongs',
  showNotes = 'showNotes',
  showQuestions = 'showQuestions',
}

export enum LayoutBlockField {
  questionsEnabled = 'questionsEnabled',
  songsEnabled = 'songsEnabled',
  notesEnabled = 'notesEnabled',
  selected = 'selected',
}

export enum PdfSettingsLayout {
  timeline = 'timeline',
  sections = 'sections',
  playlists = 'playlists',
}

export enum AccountPlatform {
  facebook = 'Facebook',
  spotify = 'Spotify',
  youtube = 'YouTube',
  soundCloud = 'SoundCloud',
  mixcloud = 'Mixcloud',
  tikTok = 'TikTok',
  instagram = 'Instagram',
}

export enum SectionBlockName {
  songs = 'songs',
  questions = 'questions',
  notes = 'notes',
  visibility = 'visibility',
}

export enum QuestionFocus {
  question = 'question',
  answer = 'answer',
  none = 'none',
}

export enum EventContactStatus {
  contact = 'contact',
  joined = 'joined',
}

export enum EventTimeType {
  'arrivalTime' = 'arrivalTime',
  'startTime' = 'startTime',
  'endTime' = 'endTime',
}

export enum EventsType {
  historyEvents = 'historyEvents',
  upcomingEvents = 'upcomingEvents',
  upcomingEventsWhereDj = 'upcomingEventsWhereDj',
}

export enum SectionSettingsOption {
  'headline' = 'headline',
  'songs' = 'songs',
  'questions' = 'questions',
  'notes' = 'notes',
  'time' = 'time',
  'visibility' = 'visibility',
  'hostDelete' = 'hostDelete',
  'showSection' = 'showSection',
}

export enum Modals {
  pickImage = 'pickImage',
  createEvent = 'createEvent',
  djNotes = 'djNotes',
  generatePdfFile = 'generatePdfFile',
  chooseComputer = 'chooseComputer',
  generatePlaylist = 'generatePlaylist',
  addEventContact = 'addEventContact',
  editEventContact = 'editEventContact',
  changeLanguage = 'changeLanguage',
  createQuestion = 'createQuestion',
  confirmAction = 'confirmAction',
  clone = 'clone',
  createPlaylist = 'createPlaylist',
  deletePlaylist = 'deletePlaylist',
  deleteAccount = 'deleteAccount',
  djDetails = 'djDetails',
  favoriteSections = 'favoriteSections',
  joinEvent = 'joinEvent',
  leaveEvent = 'leaveEvent',
  logOut = 'logOut',
  manageDjs = 'manageDjs',
  removeEventContact = 'removeEventContact',
  removeFavoriteSection = 'removeFavoriteSection',
  sectionSongIdeas = 'sectionSongIdeas',
  playlistSongs = 'playlistSongs',
  sendEventInvite = 'sendEventInvite',
  share = 'share',
  createSection = 'createSection',
  sectionSettings = 'sectionSettings',
  createTemplate = 'createTemplate',
  changeUserCard = 'changeUserCard',
  updateUser = 'updateUser',
  assignMultiOp = 'assignMultiOp',
  changePassword = 'changePassword',
  changeNotification = 'changeNotification',
  deleteSection = 'deleteSection',
  song = 'song',
  exportToSpotify = 'exportToSpotify',
  exportSongIdeasToSpotify = 'exportSongIdeasToSpotify',
  addSongsToSectionFromSearch = 'addSongsToSectionFromSearch',
  playlistSongsPreview = 'playlistSongsPreview',
  addSongsToPlaylistFromSearch = 'addSongsToPlaylistFromSearch',
  addSongsFromMusicSource = 'addSongsFromMusicSource',
  changeUserImage = 'changeUserImage',
  addSongFromLink = 'addSongFromLink',
  assignPlaylist = 'assignPlaylist',
  exportToBeatsource = 'exportToBeatsource',
  hotKeysPlayer = 'hotKeysPlayer',
  pickFiles = 'pickFiles',
  imagePreview = 'imagePreview',
  eventsPreview = 'eventsPreview',
  addYoutubeVideoByLink = 'addYoutubeVideoByLink',
  videoPreiew = 'videoPreiew',
  createAcademyLesson = 'createAcademyLesson',
  reorderCourses = 'reorderCourses',
  addLessonVideo = 'addLessonVideo',
  changeEventTemplate = 'changeEventTemplate',
  pickBrandingColor = 'pickBrandingColor',
}

export enum PdfFont {
  Roboto = 'Roboto',
  TimesNewRoman = 'TimesNewRoman',
  ArchivoNarrow = 'ArchivoNarrow',
}

export enum UserAction {
  COMPLETE_EMAIL = 'COMPLETE_EMAIL',
  COMPLETE_SIGN_UP = 'COMPLETE_SIGN_UP',
}

export enum TierByName {
  'user' = 'user',
  'PRO' = 'PRO',
}

export enum TierFeatueName {
  'branding' = 'branding',
  'image_to_section' = 'image_to_section',
}
