import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { MAX_EVENT_TITLE_LENGTH } from 'services/constants';
import { openErrorNotification, toastNotify } from 'graphql/hooks/common';

import { SAVE_EVENT_AS_TEMPLATE } from 'graphql/mutations/events';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { CloneModalProps } from 'components/modals/CloneModal';
import { SaveAsTemplateButtonProps } from './interfaces';

const SaveAsTemplateButton: FC<SaveAsTemplateButtonProps> = ({ event, className }) => {
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openCloneTamplateModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: event.title,
          },
          onSubmit: payload =>
            saveAsTemplate?.({
              variables: {
                eventId: event._id,
                payload,
              },
            }),
          maxLength: MAX_EVENT_TITLE_LENGTH,
          title: t('saveEventTemplate'),
          label: t('templateName'),
          closeOnSubmit: true,
        },
      }),
    [event._id, event.title]
  );

  const [saveAsTemplate, { loading: restoreEventLoading }] = useMutation(SAVE_EVENT_AS_TEMPLATE, {
    onError: () => openErrorNotification(t('somethingWentWrong')),
    onCompleted: () => {
      toastNotify({ text: t('copiedSuccessfully') });
    },
  });

  return (
    <Button
      onClick={openCloneTamplateModal}
      loading={restoreEventLoading}
      className={classNames(ButtonDecor.centrate, className)}
      prefixIcon={IconmoonFont['copyText-16']}
      displayType="bordered"
      shape="round"
      size="lg"
    >
      {t('saveAsTemplate')}
    </Button>
  );
};

export default SaveAsTemplateButton;
