import React from 'react';
import classNames from 'classnames';

import UserLogo from 'components/user/images/UserLogo';

import { useMe } from 'graphql/hooks/user';

import useStyles from './style';

const SiderLogo: React.FC<{}> = () => {
  const classes = useStyles();

  const { user } = useMe();

  return (
    <UserLogo
      user={user}
      className={classNames('content-fade-in', classes.logoContainer, {
        'ready _1': !!user,
      })}
    />
  );
};

export default SiderLogo;
