import React, { FC, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import pick from 'lodash/pick';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import Button from 'vibo-ui/Button';
import EventLayout from '../EventLayout';
import Sections from 'components/sections/Sections';
import M3UButton from 'components/buttons/M3UButton';
import WithEventRole from 'components/user/WithEventRole';
import TimelineLayout from 'components/layouts/TimelineLayout';
import EventTitle from 'components/events/Timeline/EventTitle';
import PrepModeSection from 'components/sections/PrepModeSection';
import PageContentHeader from 'components/common/PageContentHeader';
import ExportMusicButton from 'components/buttons/ExportMusicButton';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import PrepModeSectionSongs from 'components/events/PrepMode/PrepModeSectionSongs';
import PrepModeSectionBlocksHeader from 'components/sections/PrepModeSectionBlocksHeader';
import { EditableContext } from 'components/common/EditableContext';
import { SelectListProvider } from 'components/context/SelectListContext';
import { SortContext, SortContextProvider } from 'components/context/SortContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';
import { PrepModeSongsFilterableContext } from 'components/events/PrepMode/PrepModeSongsContext';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { deviceStorage } from 'utils/helpers/devices';
import { EVENT_PAGE_TAB_KEYS } from 'services/constants';
import { updateSectionIdFromRoute } from 'services/sections/helpers';

import { GET_EVENT } from 'graphql/queries/events';
import { UPDATE_PREP_MODE_SONGS } from 'graphql/mutations/songs';
import { GET_PREP_MODE_SECTIONS, GET_PREP_MODE_SONGS } from 'graphql/queries/prepMode';

import { IconmoonFont } from 'vibo-ui/Icon';
import { FilteredSortedPaginated } from 'types/graphql';
import { EventUserType, Modals, Page } from 'types/enums';
import { ChooseComputerModalProps } from 'components/modals/ChooseComputerModal';

import useStyles from './style';

const PrepModePage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<Nullable<SectionsFilter>>(null);
  const [selectedSection, setSelectedSection] = useState<Nullable<PrepModeSection>>(null);

  const [songsFilter, setSongsFilter] = useState<PrepModeSectionSongsFilter>({ q: '' });
  const [sectionsFilter, setSectionsFilter] = useState<Nullable<SectionsFilter>>({
    songsEnabled: true,
  });

  const history = useHistory();
  const { id: eventId, sectionId, page } = useParams<EventPageRouteParams>();
  const [songsQ, setSongsQuery] = useQueryParam('songsQ');

  const { openModal } = useModal();

  const { data: eventData } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
  });

  const event: Nullable<EventItem> = eventData?.event || null;

  const { sort } = useContext(SortContext);

  const scannerComputer = useMemo(() => deviceStorage.device, [deviceStorage.device?.id]);

  const { data, loading, refetch } = useQuery<PrepModeSectionsResponse, PrepModeSectionsVariables>(
    GET_PREP_MODE_SECTIONS,
    {
      variables: { eventId, computerId: scannerComputer?.id, filter: sectionsFilter },
      skip: !scannerComputer?.id,
      fetchPolicy: 'cache-and-network',
    }
  );
  const sections: PrepModeSection[] = data?.getPrepModeSections || [];

  const updateSongsVariables = {
    eventId,
    sectionId: selectedSection?._id || '',
    computerId: scannerComputer?.id || '',
  };

  const songsQueryVariables: FilteredSortedPaginated<
    PrepModeSectionSongsFilter,
    SongsSortFields,
    PrepModeSongsVariables
  > = {
    ...updateSongsVariables,
    filter: songsFilter,
    sort,
    pagination: {
      skip: 0,
      limit: 50,
    },
  };

  const [updatePrepModeSongs, { loading: isUpdatingSongs }] = useMutation<
    UpdatePrepModeSongsResponse,
    UpdatePrepModeSongsVariables
  >(UPDATE_PREP_MODE_SONGS, {
    onCompleted: () => refetch(),
    onError,
    refetchQueries: [
      {
        query: GET_PREP_MODE_SECTIONS,
        variables: pick(updateSongsVariables, ['eventId', 'computerId']),
      },
      {
        query: GET_PREP_MODE_SONGS,
        variables: songsQueryVariables,
      },
    ],
  });

  const handleUpdateSongs = (
    payload: UpdatePrepModeSongsInput,
    filter: PrepModeSectionSongsFilter
  ) => {
    updatePrepModeSongs({
      variables: {
        ...updateSongsVariables,
        filter,
        payload,
      },
    });
  };

  const openChooseCompModal = useCallback(
    () =>
      openModal<ChooseComputerModalProps>({
        key: Modals.chooseComputer,
        props: {
          eventId,
        },
      }),
    [eventId]
  );

  const handleClearSongsSearch = useCallback(() => {
    if (!!songsFilter.q || !!songsQ) {
      setSongsQuery(undefined);
      setSongsFilter({
        q: '',
      });
    }
  }, [songsFilter.q]);

  useEffect(() => {
    updateSectionIdFromRoute(selectedSection, setSelectedSection, sectionId, sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, sectionId]);

  useEffect(() => {
    if (selectedSection && selectedSection._id !== sectionId) {
      history.push(
        `/event/${eventId}/${EVENT_PAGE_TAB_KEYS.prepMode}/${selectedSection._id}/${history.location.search}`
      );
      handleClearSongsSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, page]);

  useEffect(() => {
    if (!scannerComputer) {
      openChooseCompModal();
    }
  }, [scannerComputer, page]);

  useEffect(() => {
    handleClearSongsSearch();
  }, []);

  return (
    <EventLayout page={Page.prepMode}>
      <PageContentHeader
        extra={
          <>
            <Button
              onClick={openChooseCompModal}
              prefixIcon={IconmoonFont['personalComputer-16']}
              className={classes.selectComputerBtn}
              shape="round"
              size="lg"
              key="select-computer-button"
            >
              <div className="content">
                <span className="label">{t('device')}:</span>
                <span className="name">{scannerComputer?.name || t('chooseDevice')}</span>
              </div>
            </Button>
            <WithEventRole roles={[EventUserType.dj]}>
              <ExportMusicButton />
            </WithEventRole>
            <M3UButton key="m3u-button" />
          </>
        }
      >
        <EventTitle name={event?.title} />
      </PageContentHeader>
      <TimelineLayout
        className={classes.prepModePage}
        left={
          <SectionsFilterableContext.Provider value={{ filter, setFilter }}>
            <EditableContext.Provider value={{ canEdit: false }}>
              <Sections<PrepModeSection>
                sections={sections}
                renderTimelineItem={(content, section, isDontPlay) => (
                  <div
                    className={classNames('timelineSection', {
                      dontPlaySection: isDontPlay,
                    })}
                    key={section._id}
                  >
                    {content}
                  </div>
                )}
                renderHeader={() => (
                  <TimelineSectionsHeader
                    numSections={sections.length}
                    onSearch={q =>
                      setSectionsFilter((prev: Nullable<SectionsFilter>) => ({ ...prev, q }))
                    }
                    loading={loading}
                  />
                )}
                renderSection={({ section, isDragging }): JSX.Element => (
                  <PrepModeSection
                    section={section}
                    isSelected={!!selectedSection && section._id === selectedSection._id}
                    isDragging={isDragging}
                    onClick={() => setSelectedSection(section)}
                  />
                )}
                isSectionsLoading={loading}
              />
            </EditableContext.Provider>
          </SectionsFilterableContext.Provider>
        }
        right={
          selectedSection ? (
            <PrepModeSongsFilterableContext.Provider
              value={{ filter: songsFilter, setFilter: setSongsFilter }}
            >
              <SelectListProvider>
                <PrepModeSectionBlocksHeader section={selectedSection} />
                <PrepModeSectionSongs
                  variables={{
                    sectionId: selectedSection._id,
                    eventId,
                    computerId: scannerComputer?.id || '',
                  }}
                  handleUpdateSongs={handleUpdateSongs}
                  isUpdatingSongs={isUpdatingSongs}
                />
              </SelectListProvider>
            </PrepModeSongsFilterableContext.Provider>
          ) : null
        }
      />
    </EventLayout>
  );
};

const PrepModePageWrapper: FC = () => (
  <SortContextProvider>
    <PrepModePage />
  </SortContextProvider>
);

export default PrepModePageWrapper;
