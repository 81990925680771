import { VIBO_REFRESH_TOKEN_KEY, VIBO_TOKEN_KEY } from 'utils/constants';
import { setMe } from './users';

import client from 'graphql/client';
import { IS_LOGGED_IN } from 'graphql/queries/auth';
import { cache, isLoggedInVar } from 'graphql/cache';
import { deviceStorage } from './devices';

export const authStorage = {
  get token() {
    return localStorage.getItem(VIBO_TOKEN_KEY) || '';
  },

  set token(value: string) {
    localStorage.setItem(VIBO_TOKEN_KEY, value);
  },

  get refreshToken() {
    return localStorage.getItem(VIBO_REFRESH_TOKEN_KEY) || '';
  },

  set refreshToken(value: string) {
    localStorage.setItem(VIBO_REFRESH_TOKEN_KEY, value);
  },

  clearTokens() {
    localStorage.removeItem(VIBO_TOKEN_KEY);
    localStorage.removeItem(VIBO_REFRESH_TOKEN_KEY);
  },
};

export const logout = () => {
  isLoggedInVar(false);

  setTimeout(() => {
    authStorage.clearTokens();
    deviceStorage.clearDevice();
    client.resetStore();
    cache.reset();
  }, 1000);
};

export const handleSignInCompleted = ({ accessToken, refreshToken, user }: AuthResponse) => {
  authStorage.token = accessToken;
  authStorage.refreshToken = refreshToken;

  setMe(user);
  isLoggedInVar(true);

  cache.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      isLoggedIn: true,
    },
  });
};
