import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  eventUserView: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',

    '&:hover': {
      backgroundColor: theme.palette.primary.lighty,
    },

    '& .djsPreview.hideNames': {
      '& .content': {
        display: 'none',
      },
    },

    '& .eventUserViewDesc': {
      '& .row': {
        '&:not(:last-child)': {
          margin: '0 0 19px 0',

          '@media screen and (min-width: 1441px)': {
            margin: '0 0 21px 0',
          },
        },
      },
    },

    '& .eventTitle': {
      maxWidth: '600px',
      lineHeight: '21px',
      margin: '0 0 13px 0',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '900px',
        lineHeight: '26px',
        margin: '0 0 18px 0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '24px',
    },
  },

  eventImage: {
    borderRadius: '4px',
    width: '215px',
    height: '215px',

    '@media screen and (min-width: 1441px)': {
      width: '249px',
      height: '249px',
    },
  },

  eventUserViewDesc: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 0 12px',

    '& .row': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '13px',
      lineHeight: '19.5px',

      '&:not(:last-child)': {
        margin: '0 0 20px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 24px 0',
        },
      },

      '& .viboIcon, & .eventTypeIcon': {
        color: theme.palette.text.secondary,
        margin: '0 12px 0 0',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 0 0 16px',
    },
  },

  eventsHeaderDj: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '15.23px',
    margin: '0 0 14px 0',
    position: 'relative',
    padding: '0 0 0 8px',

    '& .cellName': {
      color: theme.palette.text.secondary,

      '& > div': {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
      },
    },

    '& .djs': {
      left: '118px',
      position: 'absolute',

      '@media screen and (min-width: 1441px) ': {
        left: '150px',
      },
    },

    '& .hoverable-action:hover': {
      color: theme.palette.primary.main,
    },

    '& .statusColumn, & .djsColumn, & .typeColumn': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .label': {
        margin: '0 6px 0 0',
      },

      '& .dj-name': {
        color: theme.palette.primary.main,
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '18.75px',
      margin: '0 0 16px 0',
    },
  },

  eventsDjView: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .viboScrollbar': {
      maxHeight: 'calc(100vh - 150px)!important',

      '@media screen and (min-width: 1441px)': {
        maxHeight: 'calc(100vh - 180px)!important',
      },
    },

    '& .cellName': {
      width: '100%',

      '&:not(:last-child)': {
        margin: '0 8px 0 0',
      },

      '&.status': {
        minWidth: '382px',
        maxWidth: '382px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '571px',
          maxWidth: '571px',
        },
      },

      '&.date, &.dateTemplate': {
        maxWidth: '120px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '156px',
        },
      },

      '&.lastModified': {
        maxWidth: '96px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '124px',
        },
      },

      '&.users, &.songs': {
        fontSize: '13px',
        lineHeight: '15.23px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          lineHeight: '18.75px',
        },
      },

      '&.users': {
        maxWidth: '40px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '65px',
        },
      },

      '&.songs': {
        maxWidth: '39px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '61px',
        },
      },
    },

    '& .eventDjRow': {
      '& .djs': {
        display: 'none',
      },

      '& .newStatus': {
        padding: '0',
        position: 'relative',
        wordBreak: 'break-all',
      },
    },
  },

  userViewScrollbar: {
    maxHeight: 'calc(100vh - 110px)!important',

    '@media screen and (min-width: 1441px)': {
      maxHeight: 'calc(100vh - 130px)!important',
    },
  },

  eventDjRow: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px',

    '& .djsPreview': {
      '& .djName': {
        lineHeight: '14px',

        '@media screen and (min-width: 1441px)': {
          lineHeight: '16px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '8px 16px',
    },
  },
}));

export default useStyles;
