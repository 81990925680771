import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import VisibilityLabel from 'components/labels/VisibilityLabel';
import BlockInfo from 'components/sections/TimelineSection/SectionBlocksInfo/BlockInfo';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionBlockName, SectionVisibilityRoles } from 'types/enums';
import { PrepModeSectionSongsMatchedProps } from './interfaces';

import useTimelineSectionStyles from 'components/sections/TimelineSection/style';

const PrepModeSectionSongsMatched: FC<PrepModeSectionSongsMatchedProps> = ({ section }) => {
  const timelineSectionClasses = useTimelineSectionStyles();
  const { t } = useTranslation();

  const songsContent = t('songMatched', {
    count: section.preparedSongsCount,
    songs: section.songsCount,
  });

  return (
    <div className={classNames('iconsWrapper', timelineSectionClasses.iconsWrapper)}>
      <BlockInfo
        icon={IconmoonFont['musicNoteDoubleOutlined-16']}
        description={songsContent}
        content={songsContent}
      />
      {section.settings.visibleForGuests ? (
        <BlockInfo
          className={SectionBlockName.visibility}
          description={t('sectionVisibleForAll')}
          content={<VisibilityLabel type={SectionVisibilityRoles.public} showTooltip={false} />}
        />
      ) : null}
    </div>
  );
};

export default PrepModeSectionSongsMatched;
