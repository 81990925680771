import React, { FC } from 'react';
import classNames from 'classnames';

import TopBar from './TopBar';
import Container from '../Container';
import ContentArea from '../ContentArea';

import { HeaderProps } from './interfaces';

import useStyles from './style';

const Header: FC<HeaderProps> = ({ className, children }) => {
  const classes = useStyles();

  return (
    <ContentArea className={classNames('header', classes.header, className)}>
      <Container>
        {children}
        <TopBar />
      </Container>
    </ContentArea>
  );
};

export default Header;
