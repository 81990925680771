import React, { useContext, useEffect, useMemo } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import Sider from 'components/layouts/Sider';
import CookiesLayout from 'components/common/Cookies';
import MobileDetect from 'components/auth/MobileDetect';
import AuthRequired from 'components/auth/AuthRequired';
import UserSubscription from 'components/user/UserSubscription';
import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { usePrevious } from 'services/common/dataProcessingHelpers';
import { isColorDependsPath } from './constants';

import useStyles from './style';

const DashboardLayout = ({ component: Component, ...rest }: RouteProps) => {
  const classes = useStyles();

  const { resetToAppColor } = useContext(ViboThemeContext);

  const { location } = useHistory();

  const firstPath = useMemo(() => location.pathname.split('/').filter(Boolean)[0], [
    location.pathname,
  ]);
  const prevFirstPath = usePrevious(firstPath);

  useEffect(() => {
    if (!isColorDependsPath(firstPath) && isColorDependsPath(prevFirstPath)) {
      resetToAppColor();
    }
  }, [firstPath, location.pathname]);

  return (
    <Route
      {...rest}
      render={renderProps => (
        <MobileDetect>
          <AuthRequired>
            <div className={classes.layout}>
              <Sider />
              {/* @ts-ignore */}
              <div className="content">{Component ? <Component {...renderProps} /> : null}</div>
            </div>
            <CookiesLayout />
            <UserSubscription />
          </AuthRequired>
        </MobileDetect>
      )}
    />
  );
};

export default DashboardLayout;
