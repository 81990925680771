import { gql } from '@apollo/client';
import { VIBO_USER } from '../fragments/admin';

export const GET_USERS = gql`
  query getUsers($filter: UsersFilter, $pagination: PaginationInput) {
    getUsers(filter: $filter, pagination: $pagination) {
      users {
        ...ViboUser
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${VIBO_USER}
`;

export const GET_HEALTH_CHECK_DATA = gql`
  query getHealthcheckData($filter: HealthcheckFilter) {
    getHealthcheckData(filter: $filter) {
      _id
      startTime
      endTime
      type
      createdAt
    }
  }
`;

export const GET_TIERS = gql`
  query getTiers($pagination: PaginationInput, $filter: TiersFilterInput) {
    getTiers(pagination: $pagination, filter: $filter) {
      tiers {
        _id
        name
        createdAt
      }
      totalCount
    }
  }
`;
