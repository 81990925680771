import { gql } from '@apollo/client';

export const NOTIFICATION = gql`
  fragment NotificationFragment on Notification {
    _id
    imageUrl
    header
    body
    isRead
    notificationType
    createdAt
    metadata {
      eventId
    }
  }
`;

export const NOTIFICATION_READ = gql`
  fragment NotificationReadFragment on Notification {
    isRead
  }
`;
