import React, { FC, PropsWithChildren, SyntheticEvent } from 'react';
import Dialog from 'rc-dialog';
import classNames from 'classnames';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal/useModal';

import { ModalProps } from './interfaces';

import useStyles from './style';

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  onClose,
  modalName,
  children,
  preventDefaultClosing,
  size = 'sm',
  ...rest
}) => {
  const classes = useStyles();

  const { closeModal, isModalOpened } = useModal();

  const handleClose = (e: SyntheticEvent<Element, Event>) => {
    onClose?.(e);
    !preventDefaultClosing && closeModal(modalName);
  };

  return (
    <Dialog
      visible={isModalOpened(modalName)}
      {...rest}
      classNames={{ wrapper: classes.viboModalWrap }}
      className={classNames('modal', `${size}Modal`, classes.modal, rest.className)}
      onClose={handleClose}
      closeIcon={<Icon icon={IconmoonFont['close-16']} />}
      destroyOnClose
    >
      {children}
    </Dialog>
  );
};

export default Modal;
