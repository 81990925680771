import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
    borderRadius: '0',
    padding: '0px 15px',
    position: 'relative',
    overflow: 'hidden',
    height: '40px',
    minHeight: '40px',
    zIndex: 2,
    boxShadow: theme.shadows[5],

    '& .viboButton': {
      whiteSpace: 'nowrap',
    },

    '& .container': {
      height: '100%',
    },

    '& .containerContent': {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .menuLabel': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 400,
        color: theme.palette.text.primary,
      },

      '& .viboMenu': {
        width: '100%',
      },
    },

    '@media screen and (min-width: 1441px) ': {
      height: '48px',
      minHeight: '48px',
    },
  },

  goBack: {
    padding: '0 12px',
    minWidth: '48px',
    left: '0',
    height: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
  },
}));

export default useStyles;
