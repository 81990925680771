import { timeRegexAmPmFormat } from './constants';

const MAX_HOUR = 12;
const MAX_MINUTE = 59;
const MINUTES_INTERVAL = 5;

export const checkTimeExist = (time?: string): boolean => {
  if (!!time) {
    return (
      TIME_SUGGESTIONS.some(searchOption => searchOption === time) && checkTimeRegexValid(time)
    );
  }
  return false;
};

export const getTimeSearchValue = (time: string) => time.match(/[1-9amp]+/g)?.join('') || '';

export const getTimeSuggestions = (minutesInterval: number): string[] => {
  const suggestions: string[] = [];
  const amPm = ['pm', 'am'];
  const maxIntervalMinute = MAX_MINUTE - minutesInterval + 1;

  for (const meridiem of amPm) {
    for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
      const hours = `0${MAX_HOUR}`.slice(-2);
      const minutes = `0${minute}`.slice(-2);

      suggestions.push(`${hours}:${minutes} ${meridiem}`);
    }

    for (let hour = 1; hour < MAX_HOUR; hour++) {
      for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
        const hours = `0${hour}`.slice(-2);
        const minutes = `0${minute}`.slice(-2);

        suggestions.push(`${hours}:${minutes} ${meridiem}`);
      }
    }
  }

  return suggestions;
};

export const TIME_SUGGESTIONS = getTimeSuggestions(MINUTES_INTERVAL);

export const checkTimeRegexValid = (time?: string): boolean => {
  if (!!time) {
    return new RegExp(timeRegexAmPmFormat).test(time);
  }
  return false;
};

export const parseIncorrectTime = (incorrectTime: string): string => {
  const timeRegex = /^([01]?[0-9]|2[0-3])(?:(?::|)([0-5][0-9]))?(?:\s*([aApP][mM]))?[^\d]*$/;

  const match = incorrectTime.match(timeRegex);

  if (!match && !!incorrectTime.replace(':', '').trim().length) {
    return '';
  }

  if (!!match) {
    const hoursStr = match[1];
    const minutesStr = match[2];

    const hours = parseInt(hoursStr, 10);
    const minutes = minutesStr ? parseInt(minutesStr, 10) : 0;

    const correctedHours = hours % 12 === 0 ? 12 : hours % 12;

    let period = hours >= 12 ? 'pm' : 'am';
    if (incorrectTime.toLowerCase().includes('p')) {
      period = 'pm';
    } else if (incorrectTime.toLowerCase().includes('a')) {
      period = 'am';
    }

    const correctedTime = `${correctedHours
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return correctedTime.toLocaleLowerCase();
  }

  return '';
};
