import React, { FC, useContext, useMemo } from 'react';
import omit from 'lodash/omit';

import WithEventRole from 'components/user/WithEventRole';
import AddTimelineSectionButton from 'components/buttons/AddTimelineSectionButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { EventUserType } from 'types/enums';

import { CREATE_EVENT_SECTION } from 'graphql/mutations/events';
import { CREATE_TEMPLATE_SECTION } from 'graphql/mutations/template';
import { CREATE_FAVORITE_SECTION } from 'graphql/mutations/sections';

import { CreateSectionButtonProps } from './interfaces';

import { IconmoonFont } from 'vibo-ui/Icon';

const CreateSectionButton: FC<CreateSectionButtonProps> = ({ sectionId, ...rest }) => {
  const { event, isTemplate, isFavoriteSections } = useContext(EventContext);
  const sectionsContext = useContext(SectionsFilterableContext);

  const filter = omit(sectionsContext.filter, 'q');

  const createSectionMutation = useMemo(
    () =>
      isTemplate
        ? CREATE_TEMPLATE_SECTION
        : isFavoriteSections
        ? CREATE_FAVORITE_SECTION
        : CREATE_EVENT_SECTION,
    [isTemplate, isFavoriteSections]
  );

  const variables = useMemo(
    () =>
      isFavoriteSections
        ? {}
        : isTemplate
        ? { templateId: event?._id, filter }
        : { eventId: event?._id, filter },
    [isTemplate, isFavoriteSections, event?._id, filter]
  );

  const refetchQueries = useMemo(
    () => [
      isTemplate ? 'templateSections' : isFavoriteSections ? 'getFavoriteSections' : 'sections',
    ],
    [isTemplate, isFavoriteSections]
  );

  return !!event ? (
    <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
      <AddTimelineSectionButton
        createSectionMutation={createSectionMutation}
        refetchQueries={refetchQueries}
        eventSettings={event.settings as EventSettings}
        insertBeforeSectionId={sectionId}
        prefixIcon={IconmoonFont['plus-16']}
        {...rest}
        variables={variables}
      />
    </WithEventRole>
  ) : null;
};

export default CreateSectionButton;
