import React, { FC } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { getIds } from 'services/common/arrHelpers';
import { getFormattedSongArtistAndTitle } from 'services/songs/helpers';

import { DELETE_SECTION_SONGS } from 'graphql/mutations/songs';

import { ButtonIconType, Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { DeleteProps } from './interfaces';

const Delete: FC<DeleteProps> = ({ songs, onCompleted, render }) => {
  const { t } = useTranslation();
  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const { openModal } = useModal();

  const openConfirmDeleteSongModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('deleteSong', { count: songs.length }),
        children:
          songs.length > 1 ? (
            <span>{t('deleteAmountSong', { count: songs.length })}</span>
          ) : (
            <span>
              {t('deleteAmountSong', { count: songs.length })}
              <br />
              <strong>{getFormattedSongArtistAndTitle(get(songs, '[0]', null))}</strong>
              {'?'}
            </span>
          ),
        submit: {
          text: t('delete'),
          loading: isDeletingSong,
          onClick: handleDeleteSongs,
        },
      },
    });

  const [deleteSong, { loading: isDeletingSong }] = useMutation(DELETE_SECTION_SONGS, {
    onCompleted: () => {
      onCompleted?.();
      toastNotify({
        text: t('songDeleted', { count: songs.length }),
        iconType: ButtonIconType.delete,
      });
    },
    onError,
    refetchQueries: ['sections', 'getSectionSongs', 'getSectionSongsStats'],
  });

  const handleDeleteSongs = () => {
    return deleteSong({
      variables: {
        eventId,
        sectionId,
        songIds: getIds(songs),
      },
    });
  };

  return <div onClick={openConfirmDeleteSongModal}>{render()}</div>;
};

export default Delete;
