import React, { FC, useEffect, useState, useCallback, useMemo, memo } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import { useMutation, useQuery } from '@apollo/client';

import Song from 'components/songs/Song';
import NoSongs from 'components/emptyStates/NoSongs';
import IconButton from 'components/buttons/IconButton';
import VirtualizedListDnd from 'components/common/VirtualizedList/VirtualizedListDnd';

import client from 'graphql/client';
import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { SONGS_GAP, SONG_HEIGHT } from './constants';

import { GET_SONG_IDEAS_SONGS } from 'graphql/queries/songIdeas';
import { REMOVE_SONG_FROM_SONG_IDEAS, REORDER_SONG_IDEAS_SONGS } from 'graphql/mutations/songIdeas';

import { DragResponse } from 'vibo-ui/List';
import { ButtonIconType, Modals } from 'types/enums';
import { SongModalProps } from 'components/modals/SongModal';
import { PlaylistSongsProps } from './interfaces';

import useDecorStyle from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const PlaylistSongs: FC<PlaylistSongsProps> = ({
  songIdeasId,
  isMySongIdeas,
  onSongsCountUpdate,
}) => {
  const decorClasses = useDecorStyle();
  const classes = useStyles();
  const { t } = useTranslation();

  const [plyingSongId, setPlyingSongId] = useState<Maybe<string>>(undefined);

  const [playlistSongsQ = '', setPlaylistSongsQ] = useQueryParam<string | undefined>(
    'playlistSongsQ'
  );

  const filter = useMemo(
    () => ({
      q: playlistSongsQ,
    }),
    [playlistSongsQ]
  );

  const { data: songData, fetchMore, loading: songsLoading } = useQuery<SongIdeasSongsResponse>(
    GET_SONG_IDEAS_SONGS,
    {
      variables: {
        filter,
        songIdeasId,
        pagination: { skip: 0, limit: 50 },
      },
      fetchPolicy: 'cache-and-network',
      onError,
    }
  );
  const songs: SongData[] = songData?.getSongIdeasSongs.songs || [];
  const songsTotalCount = useMemo(() => songData?.getSongIdeasSongs?.totalCount || 0, [
    songData?.getSongIdeasSongs.totalCount,
  ]);
  const playlistHeaderSongsCount = useMemo(
    () => (!!playlistSongsQ ? songs.length : songsTotalCount),
    [playlistSongsQ, songs.length, songsTotalCount]
  );

  const [reorderSongIdeasSongs] = useMutation(REORDER_SONG_IDEAS_SONGS, {
    onError,
    refetchQueries: ['getSongIdeasSongs'],
  });

  const [removeSongFromSongIdeas] = useMutation(REMOVE_SONG_FROM_SONG_IDEAS, {
    onCompleted: () => {
      toastNotify({
        text: t('songRemovedFromPlaylistSuccessfully'),
        iconType: ButtonIconType.delete,
      });
    },
    refetchQueries: ['getSongIdeasSongs'],
    onError,
  });

  const removeSong = (viboSongId: string) =>
    removeSongFromSongIdeas({
      variables: {
        songIdeasId,
        viboSongId,
      },
    });

  const handleDragChange = (
    reorderedSongs: SongData[],
    { removedIndex, addedIndex }: DragResponse
  ) => {
    if (!isEqual(reorderedSongs, songs)) {
      const targetIndex = addedIndex - (addedIndex < removedIndex ? 1 : 0);

      const sourceSongId = songs[removedIndex]?.viboSongId || null;
      const targetSongId = songs[targetIndex]?.viboSongId || null;

      reorderSongIdeasSongs({
        variables: { songIdeasId, sourceSongId, targetSongId },
      });

      client.writeQuery({
        query: GET_SONG_IDEAS_SONGS,
        variables: {
          filter: { q: '' },
          songIdeasId,
          pagination: { skip: 0, limit: 50 },
        },
        data: {
          getSongIdeasSongs: {
            ...songData?.getSongIdeasSongs,
            songs: reorderedSongs,
          },
        },
      });
    }
  };
  const moveSongUp = useCallback(
    (e, song, index) => {
      e.stopPropagation();
      handleDragChange(
        [song, ...songs.filter(({ viboSongId }) => viboSongId !== song.viboSongId)],
        {
          removedIndex: index,
          addedIndex: 0,
        }
      );
    },
    [songs]
  );
  const moveSongDown = useCallback(
    (e, song, index) => {
      e.stopPropagation();
      handleDragChange(
        [...songs.filter(({ viboSongId }) => viboSongId !== song.viboSongId), song],
        {
          removedIndex: index,
          addedIndex: songs.length - 1,
        }
      );
    },
    [songs]
  );

  const loadMore = useCallback(() => {
    !songsLoading &&
      songData?.getSongIdeasSongs.next &&
      fetchMore({
        variables: {
          filter,
          pagination: songData?.getSongIdeasSongs.next,
        },
        updateQuery: (prev: SongIdeasSongsResponse, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            getSongIdeasSongs: {
              ...prev.getSongIdeasSongs,
              songs: [...prev.getSongIdeasSongs.songs, ...fetchMoreResult.getSongIdeasSongs.songs],
              next: fetchMoreResult.getSongIdeasSongs.next,
            },
          };
        },
      });
  }, [
    songData?.getSongIdeasSongs.next?.limit,
    songData?.getSongIdeasSongs.next?.skip,
    songsLoading,
    filter,
  ]);

  const { openModal } = useModal();

  const openSongModal = (songId: string) =>
    openModal<SongModalProps>({
      key: Modals.song,
      props: {
        className: classes.playlistSongModal,
        removeSong,
        onPlaySong: setPlyingSongId,
        songId,
        canEdit: isMySongIdeas,
        queryKey: 'getSongIdeasSongs.songs',
        query: GET_SONG_IDEAS_SONGS,
        variables: {
          filter,
          songIdeasId,
          pagination: { skip: 0, limit: songsTotalCount },
        },
      },
    });

  useEffect(() => {
    onSongsCountUpdate?.(playlistHeaderSongsCount);
  }, [playlistHeaderSongsCount]);

  useEffect(() => {
    setPlaylistSongsQ(undefined);

    return () => setPlaylistSongsQ(undefined);
  }, []);

  return (
    <>
      {!songs.length && !songsLoading ? (
        <NoSongs>{t(!!playlistSongsQ ? 'songsNotFound' : 'addSongs')}</NoSongs>
      ) : null}
      <VirtualizedListDnd<SongData>
        rowRenderer={(song, index) => (
          <Song
            song={song}
            index={index}
            onClick={() => openSongModal(song.viboSongId)}
            extra={
              <>
                {isMySongIdeas ? (
                  <>
                    <IconButton
                      type={ButtonIconType.arrowUp}
                      className={decorClasses.highlighted}
                      placement="top"
                      title={t('moveToTop')}
                      onClick={e => moveSongUp(e, song, index)}
                    />
                    <IconButton
                      type={ButtonIconType.arrowDown}
                      className={decorClasses.highlighted}
                      placement="top"
                      title={t('moveToBottom')}
                      onClick={e => moveSongDown(e, song, index)}
                    />
                    <IconButton
                      type={ButtonIconType.delete}
                      className={decorClasses.highlighted}
                      placement="top"
                      title={t('deleteSong')}
                      onClick={e => {
                        e.stopPropagation();

                        return removeSong(song.viboSongId!);
                      }}
                    />
                  </>
                ) : null}
              </>
            }
            className={classNames(classes.songIdeasSong, {
              highlighted: plyingSongId === song.viboSongId,
            })}
            key={`playlist-details-song-${song.viboSongId}`}
          />
        )}
        data={songs.map(song => ({
          ...song,
          _id: song.viboSongId,
        }))}
        rowHeights={[SONG_HEIGHT, SONG_HEIGHT]}
        gaps={[SONGS_GAP, SONGS_GAP]}
        totalRowsCount={songsTotalCount}
        loadMore={loadMore}
        onDropCallback={handleDragChange}
        scrollId="playlist-details-songs"
        droppableId="playlist-songs"
      />
    </>
  );
};

export default memo(PlaylistSongs);
