import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Image from 'vibo-ui/Image';
import Linkify from 'components/common/Linkify';
import TextPreviewField from 'vibo-ui/TextPreviewField';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';
import { MAX_QUESTION_LENGTH } from 'services/constants';

import { Modals } from 'types/enums';
import { ImagePreviewModalProps } from 'components/modals/ImagePreviewModal';
import { MultiplyOptionContentProps } from './interfaces';

import useStyles from '../../common/style';

const MultiplyOptionContent: FC<MultiplyOptionContentProps> = ({ value, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { question, canAnswer } = useContext(QuestionContext);

  const { openModal } = useModal();

  const optionImg = useMemo(() => value.images?.[0]?.imageUrl || '', [value.images?.[0]?.imageUrl]);

  const openImagePreviewModal = () =>
    openModal<ImagePreviewModalProps>({
      key: Modals.imagePreview,
      props: {
        imageUrl: optionImg,
      },
    });

  return (
    <>
      {question.settings.optionImagesEnabled ? (
        <Image onClick={openImagePreviewModal} src={optionImg} />
      ) : null}
      {value.isOther ? (
        // <QuillEditor
        //   value={formik.values.text}
        //   onChange={e => {
        //     formik.setFieldValue('text', e);
        //   }}
        //   toolbarSchema={[]}
        //   moduleFormats={[]}
        //   placeholder={placeholder}
        //   toolbarId={`toolbar-${question._id}`}
        //   readOnly={!canAnswer}
        // />
        <TextPreviewField.TextArea
          defaultValue={value.title}
          onChange={e => {
            const newValue = e.currentTarget.value;

            formik.setFieldValue(`answer.otherOptionTitle`, newValue);

            if (!newValue) {
              formik.setFieldValue(
                `answer.selectedOptions`,
                question.answer?.selectedOptions.filter(option => option !== `${index}`)
              );
            }
          }}
          placeholder={`${t('other')}...`}
          wrapperClassName={classes.textQuestionInput}
          className={classNames('textQuestionInput', {
            withAnswer: !!value.title,
          })}
          disabled={!canAnswer}
          maxLength={MAX_QUESTION_LENGTH}
          showMaxLength
        />
      ) : (
        <Linkify>
          <div
            className={classNames('textQuestionAnswer', classes.textQuestionAnswer, {
              canAnswer,
            })}
          >
            {value.title}
          </div>
        </Linkify>
      )}
    </>
  );
};

export default MultiplyOptionContent;
