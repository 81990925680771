import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  confirmActionModal: {
    '& .modalBody': {
      margin: '0 auto',

      '& .viboScrollbar': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& .content': {
        fontSize: '15px',
        lineHeight: '22.5px',
        textAlign: 'center',

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
          lineHeight: '27px',
        },
      },
    },
  },

  stopPropagation: {
    position: 'absolute',
  },
}));

export default useStyles;
