import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import SignInWithLink from 'components/auth/SignInWithLink';
import HeadingWithImage from 'components/common/HeadingWithImage';
import DeepLinkActionButton from 'components/auth/SignInWithLink/DeepLinkActionButton';
import JoinByDeepLinkPlaceholder from 'components/Placeholders/JoinByDeepLinkPlaceholder';
import { WizardState } from 'vibo-ui/Wizard/WizardStepContext';
import { DeepLinkContextProvider } from 'components/context/DeepLinkContext';

import { CLONE_TEMPLATE } from 'graphql/mutations/template';
import { GET_TEMPLATE_DEEP_LINK_PREVIEW } from 'graphql/queries/template';

const SharedTemplatePage: FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const templateId = useSearchParam('id') || '';

  const { data } = useQuery<GetTemplateDeepLinkPreview>(GET_TEMPLATE_DEEP_LINK_PREVIEW, {
    variables: {
      templateId,
    },
    skip: !templateId,
  });
  const templatePreview = data?.getTemplateDeepLinkPreview as TemplateDeepLinkPreview;

  const previewData = {
    title: templatePreview?.title,
    photo: { url: templatePreview?.image?.squareUrl, token: templateId },
    ...(templatePreview?.isBrandingAllowed && {
      eventColors: templatePreview?.eventColors,
    }),
  };

  const [cloneTemplate, { loading }] = useMutation(CLONE_TEMPLATE, {
    onCompleted: data => {
      push(`/template/${data.cloneTemplate._id}/general`);
    },
    variables: {
      templateId,
      payload: {
        title: templatePreview?.title,
      },
    },
  });

  const handleOpenTemplate = () => {
    if (!!templateId) {
      cloneTemplate();
    }
  };

  return (
    <WizardState>
      <DeepLinkContextProvider
        action={!!templateId ? handleOpenTemplate : null}
        previewData={previewData}
      >
        <SignInWithLink>
          {!!templatePreview ? (
            <HeadingWithImage {...previewData} />
          ) : (
            <JoinByDeepLinkPlaceholder />
          )}
          <DeepLinkActionButton loading={!templatePreview || loading}>
            {t('openTemplate')}
          </DeepLinkActionButton>
        </SignInWithLink>
      </DeepLinkContextProvider>
    </WizardState>
  );
};

export default SharedTemplatePage;
