import { createUseStyles } from 'react-jss';
import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const sectionDescXGapSm = 22;
const sectionDescXGapLg = 27.5;

const useStyles = createUseStyles((theme: ViboTheme) => ({
  droppable: {
    padding: '2px 0',
    margin: '0 0 0 -1px',
  },

  lastBlock: {
    '& > .customContentArea': {
      '& > .timeline-icon': {
        '&.block-collapsed': {
          '& > .colored-line': {
            display: 'none',
          },
        },

        '& > .colored-line': {
          height: 'calc(100% - 34px)',

          '&:before': {
            display: 'block',
          },
        },
      },
    },
  },

  sectionCoverImgWrapper: {
    color: colors.white,
    position: 'relative',
    minHeight: '226px',
    height: '226px',
    overflow: 'hidden',
    borderRadius: '4px',
    opacity: 0,
    animationName: 'fade-in',
    animation: '1s ease-out 0.2s forwards',
    marginBottom: '26px',

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 2,
      width: '100%',
      height: '100%',
      background: `linear-gradient(to bottom, transparent 50%, rgb(0 0 0 / 79%))`,
    },

    '& .sectionDesc': {
      position: 'absolute',
      zIndex: 3,
      left: `${sectionDescXGapSm}px`,
      bottom: '18px',
      width: `calc(100% - ${sectionDescXGapSm * 2}px)`,
      margin: 0,

      '@media screen and (min-width: 1441px)': {
        left: `${sectionDescXGapLg}px`,
        bottom: '22px',
        width: `calc(100% - ${sectionDescXGapLg * 2}px)`,
      },
    },

    '@media screen and (min-width: 1441px)': {
      marginBottom: '36px',
      minHeight: '334px',
      height: '334px',
    },
  },

  sectionCoverImg: {
    width: '100%!important',
    objectFit: 'cover',
    height: '100%',
  },
}));

export default useStyles;
