import React, { FC, useContext } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Action from '../Action';
import Tooltip from 'vibo-ui/Tooltip';
import MoveToSectionsMenu from '../../SongActions/MoveTo/MoveToSectionsMenu';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { ReactComponent as MoveToIcon } from 'resources/img/svg/common/arrowRight.svg';

import { onError } from 'graphql/helpers';

import { MOVE_SECTION_SONGS } from 'graphql/mutations/songs';

import { MoveToProps } from './interfaces';

import useStyles from './style';

const MoveTo: FC<MoveToProps> = ({ songIds, onCompleted, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { songsOptions, sections, refetchSongs } = useContext(TimelineSongsContext);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const [moveSongs, { loading: loadingMoveSong }] = useMutation(MOVE_SECTION_SONGS, {
    onCompleted: () => {
      onCompleted?.();
      songsOptions.removeSongsById(songIds);
      refetchSongs();
    },
    onError: e => {
      onError(e);
    },
    refetchQueries: ['sections', 'getSectionSongsStats'],
  });

  const handleClick = (targetSection: TimelineSection) => {
    if (targetSection && !loadingMoveSong) {
      moveSongs({
        variables: {
          eventId,
          sourceSectionId: sectionId,
          targetSectionId: targetSection._id,
          songIds,
        },
      });
    }
  };

  const menuContent = (
    <MoveToSectionsMenu
      sections={sections as EventSection[]}
      sectionId={sectionId}
      onSelectSection={section => {
        handleClick(section);
      }}
      numSongs={songsOptions.songsList.filter(song => songIds.includes(song._id)).length}
    />
  );

  return (
    <Tooltip
      className={classes.moreToolsSectionsMenu}
      overlay={menuContent}
      trigger={['click']}
      align={{
        offset: [0, 0],
      }}
      placement="leftTop"
    >
      <Action icon={MoveToIcon} text={t('moveTo')} songIds={songIds} {...rest} />
    </Tooltip>
  );
};

export default MoveTo;
