import { cloneElement, ReactElement, FC, useContext } from 'react';

import { EventContext } from 'components/context/EventContext';

import { WithEventFeatureProps } from './interfaces';

const WithEventFeature: FC<WithEventFeatureProps> = ({
  children,
  feature,
  extraCondition = true,
  ...rest
}) => {
  const eventContext = useContext(EventContext);

  if (
    !extraCondition ||
    !eventContext.event?.allowedFeatures.some(({ name }) => feature === name)
  ) {
    return null;
  }

  return !!children ? cloneElement(children as ReactElement, rest) : null;
};

export default WithEventFeature;
