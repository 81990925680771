import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/buttons/IconButton';

import { useToggleableUpdateFieldSong } from 'graphql/hooks/blocks';

import { ButtonIconType, ToggleableSongUpdateField } from 'types/enums';

import { TimelineSongsContext } from '../TimelineSongsContext';
import { MustPlayProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const MustPlay: FC<MustPlayProps> = ({ song }) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { songsOptions } = useContext(TimelineSongsContext);

  const { updateField } = useToggleableUpdateFieldSong({
    selectedIds: [song._id],
    field: ToggleableSongUpdateField.isMustPlay,
    songs: songsOptions.songsList,
  });

  return (
    <IconButton
      className={classNames('mustPlayIcon', decorClasses.highlighted, {
        [`isMustPlay ${classes.isMustPlay}`]: song.isMustPlay,
      })}
      onClick={() => updateField()}
      title={t(song.isMustPlay ? 'unmarkMustPlay' : 'mustPlay')}
      type={song.isMustPlay ? ButtonIconType.mustPlayFilled : ButtonIconType.mustPlayCount}
      placement="top"
      data-gtm-target-id="timeline-section-details-songs-song-mustplay"
    />
  );
};

export default MustPlay;
