import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  isMustPlay: {
    '& svg path': {
      fill: colors.corn,
    },
  },
}));

export default useStyles;
