import { useContext } from 'react';

import { UserContext } from 'components/context/UserContext';

import { DjFinanceStatus, DjType, UserRole } from 'types/enums';

export const useMe = () => {
  const { user, isBrandingAllowed, allowedFeatures } = useContext(UserContext);

  const isDj = user?.role === UserRole.dj;

  const isAdmin = user?.djSettings?.isAdmin;

  const isChildDj = user?.djSettings?.type === DjType.child;

  const isCompanyDj = user?.djSettings?.type === DjType.company;

  const isJustUser = user?.role === UserRole.user;

  const isSingleDj = user?.role === DjType.single;

  const isAmbassador = user?.djSettings?.financeStatus === DjFinanceStatus.ambassador;
  const isActivated = user?.djSettings?.financeStatus === DjFinanceStatus.activated;

  return {
    user,
    isDj,
    isChildDj,
    isCompanyDj,
    isSingleDj,
    isAdmin,
    isJustUser,
    isActivated,
    isAmbassador,
    isBrandingAllowed,
    allowedFeatures,
  };
};
