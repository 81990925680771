import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PdfSectionToggler from './PdfSectionToggler';

import { PdfSettingsLayout, SectionBlockName } from 'types/enums';
import { QuestionsTogglerProps } from '../interfaces';

const QuestionsToggler: FC<QuestionsTogglerProps> = ({ section, ...rest }) => {
  const { t } = useTranslation();

  const isPlaylists = useMemo(() => rest.layout === PdfSettingsLayout.playlists, [rest.layout]);
  const isTimeline = useMemo(() => rest.layout === PdfSettingsLayout.timeline, [rest.layout]);

  return isPlaylists ? null : (
    <PdfSectionToggler
      name={SectionBlockName.questions}
      content={
        !section || !!section?.questionsEnabled ? (
          <div className="toggle-content">
            {!section
              ? t('questions')
              : `${section?.answeredQuestionsCount ?? ''}${
                  !!section?.questionsCount && !isTimeline ? `/${section?.questionsCount}` : ''
                }`}
          </div>
        ) : null
      }
      {...rest}
    />
  );
};

export default QuestionsToggler;
