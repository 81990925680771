import React, { FC, memo } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import Tooltip from 'vibo-ui/Tooltip';
import startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';

import Image from 'components/image/Image';
import ProLabel from 'components/labels/ProLabel';
import DjsPreview from 'components/common/DjsPreview';
import WithEventRole from 'components/user/WithEventRole';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import ImageDefaultPlaceholder from 'components/image/ImageDefaultPlaceholder';
import Icon, { IconFontSize, IconmoonFont } from 'vibo-ui/Icon';

import { getEventStatus } from 'services/events/helpers';
import { columnsConfig } from 'services/events/definitions';
import { DJS_LIMIT_USER_EVENT_VIEW } from 'services/constants';

import { DjType, EventType, EventUserType } from 'types/enums';
import { EventStatusProps } from './interfaces';

import useStyles from '../../style';

export const EventStatus: FC<EventStatusProps> = ({ value, role }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames('status', classes.status)}>
      <WithEventRole roles={[EventUserType.dj]} role={role}>
        <Tooltip
          overlay={t(value)}
          type={value === 'active' ? 'success' : value === 'pending' ? 'warning' : 'danger'}
          placement="top"
        >
          {!!value ? (
            <div className={classNames(value, classes.statusCircle, classes.eventStatusBg)} />
          ) : (
            <></>
          )}
        </Tooltip>
      </WithEventRole>
    </div>
  );
};

const StatusCell: React.FC<CellProps<EventListItem>> = ({ data, user, field }) => {
  const classes = useStyles();

  const isTitleField = isEqual(field, columnsConfig.title.field);

  const isMasterDj = user?.djSettings?.type === DjType.company;
  const djsCount = data?.djs?.length || 0;

  if (!data) {
    return null;
  }

  const eventStatusName = getEventStatus(data as EventListItem);

  return (
    <div className={classNames('newStatus', classes.newStatus)}>
      <EventStatus value={eventStatusName} role={data.role} />
      <Image
        token={data._id}
        src={`${data.image?.cropped?.small || data?.image?.squareUrl}`}
        alt={data.title}
        className={classNames(classes.eventTitleIcon, classes.eventImage)}
        label={data.isPro ? <ProLabel size="sm" /> : null}
      >
        <ImageDefaultPlaceholder
          icon={<EventTypeIcon type={startCase(data.type) as EventType} />}
        />
      </Image>
      <div className="info">
        {isMasterDj && !!data?.djs ? (
          <DjsPreview
            djs={data?.djs}
            className={classNames(classes.users, {
              hideNames: djsCount > DJS_LIMIT_USER_EVENT_VIEW,
            })}
          />
        ) : null}
        <div className={classNames('eventName', classes.eventName)}>
          {isTitleField ? null : (
            <EventTypeIcon
              type={data.type as EventType}
              className={classes.eventTypeIcon}
              size={IconFontSize.regular}
            />
          )}
          <span>{data.title}</span>
        </div>
        {data?.location?.name && !isTitleField ? (
          <div className={classNames('location', classes.location)}>
            <Icon icon={IconmoonFont['location-16']} />
            <span>{data.location.name}</span>
          </div>
        ) : null}
        {isTitleField ? (
          <div className={classNames('eventType', classes.eventType)}>
            <EventTypeIcon type={startCase(data.type) as EventType} />
            <div>{startCase(data.type)}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(StatusCell);
