import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  templates: {
    width: '100%',
    display: 'block!important',
    '& .cellName': {
      '& .status, & .eventType': {
        display: 'none',
      },

      '&.createdBy': {
        minWidth: '120px',
        maxWidth: '120px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '160px',
          maxWidth: '160px',
        },
      },

      '&.dateTemplate': {
        maxWidth: '95px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '110px',
        },
      },

      '&.type': {
        minWidth: '128px',
        maxWidth: '128px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '155px',
          maxWidth: '155px',
        },
      },

      '&.isPublic': {
        minWidth: '96px',
        maxWidth: '96px',

        '& .customLabel': {
          margin: '0 auto',
        },

        '@media screen and (min-width: 1441px)': {
          minWidth: '170px',
          maxWidth: '170px',
        },
      },

      '&.title': {
        '& .eventName span': {
          display: '-webkit-box',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
          whiteSpace: 'pre-wrap',
        },
      },
    },

    '&#my-templates': {
      '& .cellName': {
        '&.title': {
          minWidth: '440px',
          maxWidth: '440px',

          '@media screen and (min-width: 1441px)': {
            minWidth: '620px',
            maxWidth: '620px',
          },
        },
      },
    },

    '&#vibo-templates, &#company-templates': {
      '& .cellName': {
        '&.title': {
          minWidth: '330px',
          maxWidth: '330px',
          paddingRight: '10px',

          '@media screen and (min-width: 1441px)': {
            minWidth: '570px',
            maxWidth: '570px',
          },
        },
      },
    },

    '& .simplebar-content > div': {
      marginLeft: '2px',
      marginRight: '4px',
    },
  },

  templatesHeader: {
    '& .cellName': {
      '&.createdBy': {
        color: theme.palette.text.secondary,
      },
    },
  },

  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 0 auto',

    '&.cellName': {
      width: 'fit-content!important',
    },

    '& .iconButton': {
      '&:not(:last-child)': {
        margin: '0 4px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 8px 0 0',
        },
      },

      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },

    '& .viboButton': {
      minWidth: 0,
      width: 'auto',
      maxWidth: 'none',
    },
  },

  templateRow: {
    cursor: 'pointer',
    padding: '8px 10px 8px 8px',

    '& .djPreview': {
      '& .djName': {
        ...theme.mixins.longText,
        maxWidth: '66px',

        '@media screen and (min-width: 1441px)': {
          maxWidth: '100px',
        },
      },
    },

    '& .newStatus': {
      '& .viboImage': {
        minWidth: '80px',
        maxWidth: '80px',
        height: '80px!important',
        width: '80px!important',

        '@media screen and (min-width: 1441px)': {
          minWidth: '92px',
          maxWidth: '92px',
          height: '92px!important',
          width: '92px!important',
        },
      },
    },

    '& .info': {
      width: '100%',

      '& span': {
        maxWidth: '100%!important',
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.selection.sectionHover,

      '& .newStatus:after': {
        background: theme.palette.primary.lighty,
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '8px 20px 8px 8px',
    },
  },

  modalText: {
    padding: '0 20%',
  },

  wrap: {
    height: 'calc(100vh - 111px)',

    '& > div': {
      height: 'inherit !important',
    },
  },

  title: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: '4px',
    padding: '8px 12px',
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 500,
    position: 'relative',
    zIndex: 1,
  },

  iconWrap: {
    display: 'flex',
    '& svg:last-child': {
      marginLeft: '10px',
    },
  },

  template: {
    minHeight: '64px',
    padding: '0',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    color: theme.palette.text.primary,
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    marginTop: '3px',
    boxShadow: theme.shadows[4],
    justifyContent: 'space-between',
    cursor: 'pointer',

    '& .item': {
      color: theme.palette.text.primary,
      padding: '20px 0 20px 24px',
    },

    '& svg path': {
      fill: theme.palette.primary.main,
    },

    '&:hover': {
      boxShadow: theme.shadows[8],

      '& .copy': {
        opacity: '1',
      },

      '& .item': {
        color: `${theme.palette.text.primary} !important`,
      },
    },

    '& svg': {
      minWidth: '24px',
      marginRight: '16px',
    },
  },

  templateName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  item: {
    display: 'flex',
    width: 'calc( 100% - 58px)',
  },

  selectedTemplate: {
    border: `2px solid ${theme.palette.primary.light}`,
  },
}));

export default useStyles;
