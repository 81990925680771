import React, { FC } from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './i18n';
import 'react-virtualized/styles.css';
import 'resources/styles/main.css';

const render = (App: FC) =>
  ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

render(App);
