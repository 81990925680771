import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import Spinner from 'vibo-ui/Spinner';
import Brick from 'components/Placeholders/Brick';

import { MODAL_PLACEHOLDERS } from './constans';

import { ModalPlaceholderProps } from './interfaces';

import useStyles from './style';

const ModalPlaceholder: FC<ModalPlaceholderProps> = ({ name }) => {
  const classes = useStyles();

  const info = useMemo(() => MODAL_PLACEHOLDERS[name], [name]);

  return (
    <div className={classes.modalPlaceholderOverlay}>
      <div className={classNames(classes.modalPlaceholder, info?.size)}>
        <div className="header" />
        {info?.withSubHeader ? <div className="sub-header" /> : null}
        <div className="body">
          <Spinner />
        </div>
        {info?.withFooter ? (
          <div className="footer">
            <Brick />
            <Brick />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalPlaceholder;
