import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  timelineNotesBlock: {
    '& .sectionBlockContent': {
      pointerEvents: 'all',
      cursor: 'pointer',
    },

    '& .quillToolbar': {
      bottom: '-4px',
    },
  },

  savingOverlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 99,
  },

  stickySubHeader: {
    '& .sectionBlockHeader': {
      cursor: 'default',
    },

    '& .saveNotes, & .quillEditor': {
      zIndex: 100,
    },

    '& .sectionBlockContent': {
      overflow: 'visible',

      '& .rdw-editor-toolbar': {
        position: 'sticky',
        bottom: '-1px',
        zIndex: 99,
      },
    },
  },

  invisible: {
    opacity: '0',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },

  content: {
    display: 'flex',
    position: 'relative',
    minHeight: '76px',

    '& .preview': {
      width: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '92px',
    },
  },
}));

export default useStyles;
