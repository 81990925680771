import React, { FC, useContext, useEffect, memo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Sections from 'components/sections/Sections';
import TimelineSection from 'components/sections/TimelineSection';
import CreateSectionButton from 'components/buttons/CreateSectionButton';
import ColorfulSectionWrapper from 'components/events/ColorfulSectionWrapper';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import CollapseExpandSectionsButton from 'components/buttons/CollapseExpandSectionsButton';
import { EditableContext } from 'components/common/EditableContext';
import { SectionsFilterableContext } from '../SectionsFilterContext';

import { useModal } from 'vibo-ui/Modal';
import { hasSectionsFilterValue } from 'services/sections/helpers';

import {
  REORDER_TEMPLATE_SECTIONS,
  DELETE_TEMPLATE_SECTION,
} from 'components/../graphql/mutations/template';
import { TEMPLATE_SECTIONS } from 'components/../graphql/queries/template';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { DeleteSectionModalProps } from 'components/modals/DeleteSectionModal/interfaces';
import { TemplateSectionsProps } from './interfaces';

const TemplateSections: FC<TemplateSectionsProps> = ({
  template,
  sections,
  selectedSection,
  refetch,
  isSectionsLoading,
  updateSection,
  onSectionClick,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const templateId: string = template._id;

  const { filter, setFilter } = useContext(SectionsFilterableContext);

  const { canEdit } = useContext(EditableContext);

  const { openModal } = useModal();

  const openDeleteSectonModal = useCallback(
    section =>
      openModal<DeleteSectionModalProps>({
        key: Modals.deleteSection,
        props: {
          delteSectionMutation: DELETE_TEMPLATE_SECTION,
          refetchQueries: ['templateSections'],
          variables: {
            templateId,
            sectionId: section._id,
          },
        },
      }),
    [templateId]
  );
  const openConfirmReorderingModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchReorderSections'),
          submit: {
            text: t('clear'),
            onClick: handleClearReordering,
          },
        },
      }),
    []
  );

  const [reorderSections] = useMutation(REORDER_TEMPLATE_SECTIONS, {
    refetchQueries: ['templateSections'],
  });

  const handleClearReordering = useCallback(() => {
    setFilter(null);
    refetch({ templateId, filter });
  }, [templateId, filter?.q]);

  useEffect(() => {
    if (!selectedSection || (!sections.includes(selectedSection) && sections.length)) {
      onSectionClick(sections[0]);
    }
  }, [sections, selectedSection, onSectionClick]);

  return (
    <Sections<TemplateSection, TemplateSectionsVariables>
      sections={sections}
      reorderProps={{
        // TODO handle types issue and remove ts-ignore
        // @ts-ignore
        onReorder: reorderSections,
        variables: { templateId, filter },
        listQuery: TEMPLATE_SECTIONS,
        listCacheKey: 'templateSections',
        onBeforeCapture: () => {
          if (hasSectionsFilterValue(filter)) {
            return openConfirmReorderingModal();
          }
        },
      }}
      renderSection={({ section, isDragging }): JSX.Element => (
        <ColorfulSectionWrapper headlineColors={section.headlineColors}>
          <TimelineSection
            section={section}
            isSelected={section._id === selectedSection?._id}
            isDragging={isDragging}
            onClick={onSectionClick}
            onEditClick={onEditClick}
            onDeleteClick={section => {
              openDeleteSectonModal(section);
            }}
            onTimeChange={debounce(
              time =>
                updateSection({
                  variables: {
                    templateId,
                    sectionId: section._id,
                    payload: {
                      time,
                    },
                  },
                }),
              500
            )}
            onNameChange={debounce(
              name =>
                updateSection({
                  variables: {
                    templateId,
                    sectionId: section._id,
                    payload: { name },
                  },
                }),
              1000
            )}
          />
        </ColorfulSectionWrapper>
      )}
      renderHeader={() => (
        <TimelineSectionsHeader
          numSections={sections.length}
          onSearch={q => {
            setFilter(prev => ({ ...prev, q }));
          }}
          buttons={[
            canEdit ? (
              <CreateSectionButton
                eventSettings={template.settings}
                tooltipProps={{
                  overlay: t('addSection'),
                  fillOverlay: true,
                  placement: 'top',
                }}
                shape="round"
                size="lg"
                key="add-section-btn"
              />
            ) : null,
            !!sections.length ? (
              <CollapseExpandSectionsButton key="expand-collapse-sections-btn" />
            ) : null,
          ]}
        />
      )}
      isSectionsLoading={isSectionsLoading}
    />
  );
};

export default memo(TemplateSections);
