import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { SpinnerProps } from './interfaces';

import useStyles from './style';

const Spinner: FC<SpinnerProps> = ({ icon, className = '', size = 'md' }) => {
  const classes = useStyles();

  const isCustomSize = useMemo(() => isNumber(size), [size]);

  return (
    <div
      className={classNames(
        'viboSpinner',
        {
          [`${size}Size`]: !isCustomSize,
        },
        classes.viboSpinner,
        className
      )}
    >
      <Icon
        icon={icon || IconmoonFont['spinner']}
        style={{ fontSize: `${isCustomSize && size}px` }}
      />
    </div>
  );
};

export default Spinner;
