import React, { FC } from 'react';
import classNames from 'classnames';
import useLocalStorage from '@rehooks/local-storage';

import Container from 'components/common/Container';

import { useActiveDnd } from 'graphql/hooks/blocks';

import { DndIndex } from 'types/enums';
import { TimelineLayoutProps } from './interfaces';

import useStyles from './style';

const TimelineLayout: FC<TimelineLayoutProps> = ({ left, right, className }) => {
  const classes = useStyles();

  const { isActive: dndSectionActive } = useActiveDnd(DndIndex.section);
  const { isActive: dndSongActive } = useActiveDnd(DndIndex.singleSong);
  const { isActive: dndBlockActive } = useActiveDnd(DndIndex.sectionBlock);
  const { isActive: dndQuestionActive } = useActiveDnd(DndIndex.question);

  const [isSiderClosed] = useLocalStorage<boolean>('isSiderClosed', false);

  return (
    <div className={classNames('timelineLayout', classes.timelineLayout, className)}>
      <Container>
        <div className={classes.content}>
          <div
            className={classNames(classes.left, {
              activeDnd: dndSectionActive,
            })}
          >
            {left}
          </div>
          <div
            className={classNames(classes.right, 'subTitle', {
              'sider-opened': !isSiderClosed,
              activeDnd: dndSongActive || dndBlockActive || dndQuestionActive,
            })}
          >
            {right}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TimelineLayout;
