import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';

import SearchInput from 'vibo-ui/SearchInput';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { TimelineSectionsHeaderProps } from './interfaces';

import useStyles from './style';

const TimelineSectionsHeader: FC<TimelineSectionsHeaderProps> = ({
  numSections,
  buttons,
  onSearch,
  loading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [sectionsQ] = useQueryParam('sectionsQ');

  const { setFilter } = useContext(SectionsFilterableContext);

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, q: string) => {
    setFilter({ q });
    onSearch(q);
  };

  return (
    <div className={classNames('timelineSectionsHeader', classes.timelineSectionsHeader)}>
      <SearchInput
        onChange={handleChange}
        placeholder={t('searchSections', { count: numSections })}
        loading={loading}
        disabled={!numSections && !sectionsQ}
        queryName="sectionsQ"
        data-gtm-target-id="timeline-sections-search"
      />
      {!!buttons ? (
        <div
          className={classNames('buttons', classes.buttons, {
            [classes.noButtons]: !buttons?.length,
          })}
        >
          {buttons.filter(Boolean)}
        </div>
      ) : null}
    </div>
  );
};

export default TimelineSectionsHeader;
