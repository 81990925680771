import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songsInfoWithPreview: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '& .songPreview': {
      overflow: 'visible',
      margin: '0',
    },
  },
}));

export default useStyles;
