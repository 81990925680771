import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Image from 'components/image/Image';
import ContentArea from 'components/common/ContentArea';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import ImageDefaultPlaceholder from 'components/image/ImageDefaultPlaceholder';
import { EditableContext } from 'components/common/EditableContext';

import { ChangeEventImageStep } from 'types/enums';
import { EventImagesProps } from './interfaces';

import useStyles from './style';

const EventImages: FC<EventImagesProps> = ({
  onClick,
  id,
  title,
  type,
  isUpdating,
  squareUrl = '',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const editableContext = useContext(EditableContext);

  return (
    <ContentArea className={classes.eventImages} shadowed>
      <Image
        onClick={() => onClick?.(ChangeEventImageStep.cropSquare)}
        src={squareUrl}
        token={id}
        alt={title}
        className={classNames(classes.imgSquare, {
          photo: !!onClick && !!editableContext?.canEdit,
        })}
        isUpdating={isUpdating}
      >
        <ImageDefaultPlaceholder
          icon={type ? <EventTypeIcon type={type} /> : <span />}
          text={t('eventPhoto')}
          className={classes.placeholder}
        />
      </Image>
    </ContentArea>
  );
};

export default EventImages;
