import React, { FC, useState, FocusEvent, useRef, MouseEventHandler } from 'react';
import classNames from 'classnames';

import ViboTextarea from 'vibo-ui/Textarea';
import TextPreview from './TextPreview';

import { TextPreviewTextareaProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';

const TextArea: FC<TextPreviewTextareaProps> = ({ previewClassName, ...rest }) => {
  const inputClasses = useInputStyles();

  const [showTextArea, setShowTextArea] = useState<boolean>(false);

  const handleShowTextArea: MouseEventHandler = e => {
    !rest.disabled && e.stopPropagation();
    setShowTextArea(true);
  };
  const handleHideTextArea = () => {
    setShowTextArea(false);
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    handleHideTextArea();
    rest.onBlur?.(e);
  };

  const textContent = rest.value || rest.defaultValue || '';

  const textareaRef = useRef(null);
  const isFocused = document.activeElement === textareaRef.current;
  const isPreviewEnabled = Boolean((!showTextArea && textContent && !isFocused) || rest.disabled);

  return isPreviewEnabled ? (
    <TextPreview
      className={classNames(previewClassName, rest.className, 'lg', rest.disabled, {
        disabled: rest.disabled,
        [`withUnderline ${inputClasses.withUnderline}`]: rest.withUnderline && !rest.disabled,
        [`withBorder ${inputClasses.withBorder}`]: !rest.withUnderline && !rest.disabled,
      })}
      onClick={handleShowTextArea}
      text={textContent}
      placeholder={rest.placeholder}
    />
  ) : (
    <ViboTextarea ref={textareaRef} autoFocus={isPreviewEnabled} {...rest} onBlur={handleBlur} />
  );
};

export default TextArea;
