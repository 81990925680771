import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import ConfirmActionModal from 'components/modals/ConfirmActionModal';

import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { getIds } from 'services/common/arrHelpers';
import { usePreventUpdateSongsBlock } from 'graphql/hooks/blocks';
import { isSingleSongSectionWithSong } from 'services/songs/helpers';

import { MOVE_SECTION_SONGS } from 'graphql/mutations/songs';

import { ButtonIconType } from 'types/enums';
import { MoveToProps, RouteParams } from './interfaces';

const MoveTo: FC<MoveToProps> = ({ songs, onCompleted, render, handleCallback, onDiscard }) => {
  const { t } = useTranslation();
  const { id: eventId, sectionId } = useParams<RouteParams>();

  const { handleSongsUpdatePrevented, clearSongsUpdatePrevented } = usePreventUpdateSongsBlock();

  const [isMoveModalVisible, setIsMoveModalVisible] = useState<boolean>(false);
  const [targetSection, setTargetSection] = useState<Nullable<EventSection>>(null);

  const isSongsLimitReached: boolean =
    !!targetSection && isSingleSongSectionWithSong(targetSection);

  const [moveSongs, { loading }] = useMutation(MOVE_SECTION_SONGS, {
    onCompleted: () => {
      handleCallback?.(songs.map(song => song._id).filter(Boolean));
      clearSongsUpdatePrevented();
      handleCloseMovingModal();
      onCompleted?.();
      !!targetSection &&
        toastNotify({
          text: t('songMovedToSection', {
            count: songs.length,
            sectionName: targetSection.name,
          }),
          iconType: ButtonIconType.arrowRight,
        });
    },
    onError: e => {
      clearSongsUpdatePrevented();
      handleCloseMovingModal();
      onError(e);
    },
    refetchQueries: ['sections', 'getSectionSongs', 'getSectionSongsStats'],
  });

  const handleMoveSongs = useCallback(() => {
    if (targetSection) {
      return moveSongs({
        variables: {
          eventId,
          sourceSectionId: sectionId,
          targetSectionId: targetSection._id,
          songIds: getIds(songs),
        },
      });
    }
  }, [targetSection?._id, eventId, sectionId]);

  const handleCloseMovingModal = useCallback(() => {
    setIsMoveModalVisible(false);
    onDiscard?.();
  }, []);

  const handeConfirmMoveSongs = useCallback(() => {
    handleSongsUpdatePrevented(true);
    handleMoveSongs();
  }, [handleMoveSongs]);

  return (
    <div>
      {render(setIsMoveModalVisible, setTargetSection)}
      {isMoveModalVisible ? (
        <ConfirmActionModal
          title={!isSongsLimitReached ? t('moveSong', { count: songs.length }) : t('replaceSong')}
          children={
            <>
              {!isSongsLimitReached
                ? t('moveSongToSection', { count: songs.length })
                : t('replaceSongIn')}
              <br />
              <strong>{targetSection?.name}?</strong>
            </>
          }
          cancel={{ onClick: handleCloseMovingModal }}
          submit={{
            onClick: handeConfirmMoveSongs,
            text: !isSongsLimitReached ? t('confirm') : t('replace'),
            loading,
          }}
          onClose={handleCloseMovingModal}
          closable
          visible
        />
      ) : null}
    </div>
  );
};

export default MoveTo;
