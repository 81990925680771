import React, { FC, useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Select from 'vibo-ui/Select';
import DjPreview from '../DjPreview';

import { useMe } from 'graphql/hooks/user';

import { GET_CHILD_DJS } from 'graphql/queries/user';

import { SelectChildDjsProps } from './intefaces';

import useStyles from './style';

const SelectChildDjs: FC<SelectChildDjsProps> = ({ djId, handleChangeDjId, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, isCompanyDj } = useMe();

  const { data: childDjsData } = useQuery<ChildDjResponse>(GET_CHILD_DJS, {
    fetchPolicy: 'cache-and-network',
    skip: !isCompanyDj,
  });

  const childDjs = childDjsData?.getChildDjs?.djs || [];

  const options = useMemo(
    () => [
      {
        label: t('all'),
        value: undefined,
      },
      {
        label: t('me'),
        value: user?._id,
        dj: {
          ...user,
          firstName: t('me'),
          lastName: null,
        },
      },
      ...childDjs.map(dj => ({
        label: dj.firstName,
        value: dj._id,
        dj,
      })),
    ],
    [childDjs, user?._id]
  );

  return (
    <Select
      value={djId}
      onChange={handleChangeDjId as (newValue: unknown) => void}
      components={{
        Option: props => {
          const data = get(props, 'data') as { dj: UserPreview };

          if (!!data.dj) {
            return (
              <Select.Option {...props}>
                <DjPreview className={classes.djSortPreview} dj={data.dj} showTooltip={false} />
              </Select.Option>
            );
          }

          return <Select.Option {...props}>{props.label}</Select.Option>;
        },
      }}
      options={options}
      styleModificator="asLink"
      hideDropdownIndicator
      menuShouldBlockScroll
      {...rest}
    />
  );
};

export default SelectChildDjs;
