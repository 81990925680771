import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Section from '../Section';
import Tooltip from 'vibo-ui/Tooltip';
import PrepModeSectionSongsInfo from '../PrepModeSectionSongsInfo';
import TextPreviewField from 'vibo-ui/TextPreviewField';
import PrepModeSectionSongsMatched from 'components/events/PrepMode/PrepModeSectionSongsMatched';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import client from 'graphql/client';
import { onError } from 'graphql/helpers';

import { UPDATE_EVENT_SECTION } from 'graphql/mutations/events';
import { PREP_MODE_SECTION_IS_DONE } from 'graphql/fragments/prepMode';

import { PrepModeSectionProps } from './interfaces';

import useTimelineSectionStyles from '../TimelineSection/style';
import useStyles from './style';

const PrepModeSection: FC<PrepModeSectionProps> = ({
  section,
  isSelected,
  isDragging,
  onClick,
  children,
}) => {
  const timelineSectionClasses = useTimelineSectionStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { id: eventId } = useParams<EventPageRouteParams>();

  const [updateSection, { loading: updateSectionLoading }] = useMutation<
    UpdateSectionResponse,
    EventSectionCommonVariables & { payload: UpdateEventSectionForm }
  >(UPDATE_EVENT_SECTION, {
    onError,
  });

  const handleDoneClick = useCallback(
    (section: PrepModeSection) => {
      const cachedSection = client.readFragment<Nullable<PrepModeSectionIsDoneFragment>>({
        id: `PrepModeSection:${section._id}`,
        fragment: PREP_MODE_SECTION_IS_DONE,
      });

      if (cachedSection) {
        client.writeFragment<PrepModeSectionIsDoneFragment>({
          id: `PrepModeSection:${section._id}`,
          fragment: PREP_MODE_SECTION_IS_DONE,
          data: {
            isDone: !section.isDone,
            __typename: cachedSection.__typename,
          },
        });
      }

      return updateSection({
        variables: {
          eventId,
          sectionId: section._id,
          // @ts-ignore
          payload: { isDone: !section.isDone },
        },
      });
    },
    [eventId]
  );

  const handleDoneClickDebounced = debounce(handleDoneClick, 200, {
    leading: true,
    trailing: false,
  });

  return (
    <div className={timelineSectionClasses.timelineSection}>
      <Section
        content={{
          top: (
            <TextPreviewField.TextArea
              className={classNames(
                'editableSectionName',
                timelineSectionClasses.editableSectionName
              )}
              value={section.name}
              disabled
            />
          ),
          middle: section.songsCount === 1 ? <PrepModeSectionSongsInfo section={section} /> : null,
          bottom: (
            <>
              <PrepModeSectionSongsMatched section={section} />
              <Tooltip
                placement="bottom"
                overlay={section.isDone ? t('markAsUndone') : t('markAsDone')}
                type={section.isDone ? 'primary' : 'success'}
                className="fillOverlay"
              >
                <div
                  onClick={e => {
                    e.stopPropagation();
                    !updateSectionLoading && handleDoneClickDebounced(section);
                  }}
                  className={classNames('progressBtn', classes.progressBtn, {
                    loading: updateSectionLoading,
                    done: section.isDone,
                  })}
                >
                  <Icon
                    className={classNames(classes.isDoneIcon, {
                      isDone: section.isDone,
                    })}
                    icon={
                      IconmoonFont[section.isDone ? 'checkedCircleFilled-16' : 'radioButtonOff-16']
                    }
                  />
                  {section.isDone ? t('done') : t('markAsDone')}
                </div>
              </Tooltip>
            </>
          ),
        }}
        isSelected={isSelected}
        isDragging={isDragging}
        onClick={onClick}
        classes={{
          root: classNames('prepModeSection', classes.prepModeSection),
          middle: timelineSectionClasses.sectionMiddle,
        }}
      >
        {children}
      </Section>
    </div>
  );
};

export default PrepModeSection;
