import React, { useEffect, useState, useMemo, FC, useCallback, useContext } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import EventLayout from '../EventLayout';
import WithEventRole from 'components/user/WithEventRole';
import DjNotesButton from 'components/buttons/DjNotesButton';
import EventTitle from 'components/events/Timeline/EventTitle';
import TimelineLayout from 'components/layouts/TimelineLayout';
import PageContentHeader from 'components/common/PageContentHeader';
import EventSections from 'components/events/Timeline/EventSections';
import SectionBlocks from 'components/sections/blocks/SectionBlocks';
import ExportMusicButton from 'components/buttons/ExportMusicButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import {
  SECTIONS,
  GET_EVENT_SECTION_COMPANY_SONG_IDEAS,
  GET_EVENT_SECTION_SONG_IDEAS,
  GET_EVENT_SECTION_USER_SONG_IDEAS,
  GET_EVENT_SECTION_VIBO_SONG_IDEAS,
  SECTIONS_NO_IMAGES_ALLOWED,
} from 'graphql/queries/events';
import { GET_EVENT_SECTION_SONG_IDEAS_SONGS } from 'graphql/queries/songIdeas';
import {
  UPDATE_EVENT_SECTION,
  UPDATE_SECTION_BLOCKS,
  ADD_SONG_IDEAS_TO_EVENT_SECTION,
  DELETE_SONG_IDEAS_FROM_EVENT_SECTION,
  REORDER_EVENT_SECTION_SONG_IDEAS,
} from 'graphql/mutations/events';
import { GET_EVENT_SECTION_QUESTIONS_V2 } from 'graphql/queries/questions';
import {
  CREATE_EVENT_SECTION_QUESTION_V2,
  CLONE_EVENT_SECTION_QUESTION,
  UPDATE_EVENT_SECTION_QUESTION_V2,
  REORDER_EVENT_SECTION_QUESTION,
  DELETE_EVENT_SECTION_QUESTION,
  ANSWER_EVENT_SECTION_QUESTION_V2,
} from 'graphql/mutations/questions';

import { updateEventSectionDetails } from 'graphql/cache/sections';
import { onError } from 'graphql/helpers';
import { updateSectionIdFromRoute } from 'services/sections/helpers';
import { EVENT_PAGE_TAB_KEYS } from 'services/constants';
import { toastNotify } from 'graphql/hooks/common';
import { useModal } from 'vibo-ui/Modal';

import { SectionSettingsModalProps } from 'components/modals/SectionSettingsModal';
import { EventUserType, Page, Modals } from 'types/enums';

const EventTimeline: FC = () => {
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState<Nullable<EventSection>>(null);
  const [filter, setFilter] = useState<Nullable<SectionsFilter>>(null);

  const eventContext = useContext(EventContext);
  const { event, isCoverPhotoAllowed, isLoading } = eventContext;

  const history = useHistory();
  const { id: eventId, sectionId, page } = useParams<EventPageRouteParams>();

  const baseTimelineRoute: string = `/event/${eventId}/${EVENT_PAGE_TAB_KEYS.timeline}`;

  const { openModal } = useModal();

  const openSectionSettinsModal = useCallback(
    section =>
      openModal<SectionSettingsModalProps<TimelineSection>>({
        key: Modals.sectionSettings,
        props: {
          refetchQueries: ['sections'],
          section: section as TimelineSection,
          updateSectionMutation: UPDATE_EVENT_SECTION,
          variables: { eventId, sectionId: section._id },
          eventContext,
        },
      }),
    [eventContext.event?._id]
  );

  const { data, loading: sectionsLoading, refetch } = useQuery<
    EventSectionsResponse,
    EventSectionsVariables
  >(isCoverPhotoAllowed ? SECTIONS : SECTIONS_NO_IMAGES_ALLOWED, {
    variables: {
      eventId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: isLoading,
    onError,
  });

  const sections: EventSection[] = useMemo(() => get(data, 'sections', []), [data]);

  const shouldRenderComponentsForSection: boolean = useMemo(
    () => !!selectedSection && !!sections.length,
    [selectedSection, sections.length]
  );

  const [updateSection] = useMutation<
    UpdateEventSectionResponse,
    Partial<EventSectionCommonVariables> & { payload: UpdateSectionForm }
  >(UPDATE_EVENT_SECTION, {
    onCompleted: result => {
      updateEventSectionDetails(result.updateSection._id, {
        name: result.updateSection.name,
        description: selectedSection?.description,
      });
      toastNotify({ text: t('changesSavedSuccessfully') });
    },
    onError,
    refetchQueries: ['sections'],
  });

  const defaultBlockProps = {
    variables: {
      eventId,
      sectionId,
    },
  };
  const songsBlockProps = {
    ...defaultBlockProps,
    listQueries: {
      user: {
        key: 'getEventSectionUserSongIdeas',
        query: GET_EVENT_SECTION_USER_SONG_IDEAS,
      },
      vibo: {
        key: 'getEventSectionViboSongIdeas',
        query: GET_EVENT_SECTION_VIBO_SONG_IDEAS,
      },
      company: {
        key: 'getEventSectionCompanySongIdeas',
        query: GET_EVENT_SECTION_COMPANY_SONG_IDEAS,
      },
      section: {
        key: 'getEventSectionSongIdeas',
        query: GET_EVENT_SECTION_SONG_IDEAS,
      },
      songs: {
        key: 'getEventSectionSongIdeasSongs',
        query: GET_EVENT_SECTION_SONG_IDEAS_SONGS,
      },
    },
    mutations: {
      add: ADD_SONG_IDEAS_TO_EVENT_SECTION,
      delete: DELETE_SONG_IDEAS_FROM_EVENT_SECTION,
      reorder: REORDER_EVENT_SECTION_SONG_IDEAS,
    },
  };
  const notesBlockProps = {
    ...defaultBlockProps,
  };
  const questionsBlockProps = {
    ...defaultBlockProps,
    canAnswer: true,
    listQuery: GET_EVENT_SECTION_QUESTIONS_V2,
    listCacheKey: 'getEventSectionQuestionsV2',
    mutations: {
      create: CREATE_EVENT_SECTION_QUESTION_V2,
      clone: CLONE_EVENT_SECTION_QUESTION,
      update: UPDATE_EVENT_SECTION_QUESTION_V2,
      answer: ANSWER_EVENT_SECTION_QUESTION_V2,
      reorder: REORDER_EVENT_SECTION_QUESTION,
      delete: DELETE_EVENT_SECTION_QUESTION,
      updateBlocks: UPDATE_SECTION_BLOCKS,
    },
    refetchQueries: ['sections', 'getEventSectionQuestionsV2'],
  };

  useEffect(() => {
    updateSectionIdFromRoute(selectedSection, setSelectedSection, sectionId, sections);
  }, [sections, sectionId]);

  useEffect(() => {
    if (selectedSection && selectedSection._id !== sectionId) {
      history.push({
        search: history.location.search,
        pathname: `${baseTimelineRoute}/${selectedSection._id}`,
      });
    }
  }, [selectedSection, page]);

  return !!event ? (
    <>
      <PageContentHeader
        extra={
          <>
            <WithEventRole roles={[EventUserType.dj]}>
              <>
                <DjNotesButton data-gtm-target-id="timeline-my-notes" />
                <ExportMusicButton />
              </>
            </WithEventRole>
          </>
        }
      >
        <EventTitle name={event.title} />
      </PageContentHeader>
      <TimelineLayout
        left={
          <span
            className={classNames('content-fade-in ', {
              'ready _1': (!!sections && !sectionsLoading) || !isLoading,
            })}
          >
            <SectionsFilterableContext.Provider
              value={{
                filter,
                setFilter,
              }}
            >
              <EventSections
                event={event}
                sections={sections}
                selectedSection={selectedSection}
                onSectionClick={setSelectedSection}
                updateSection={updateSection}
                onEditClick={section => {
                  openSectionSettinsModal(section);
                }}
                refetch={refetch}
                isSectionsLoading={sectionsLoading}
              />
            </SectionsFilterableContext.Provider>
          </span>
        }
        right={
          shouldRenderComponentsForSection && selectedSection ? (
            <SectionBlocks<EventSection>
              // handle it "updateSection"
              // @ts-ignore
              updateSection={updateSection}
              sections={sections}
              section={selectedSection}
              onEditClick={() => {
                openSectionSettinsModal(selectedSection);
              }}
              questionsBlockProps={questionsBlockProps}
              songsBlockProps={songsBlockProps}
              notesBlockProps={notesBlockProps}
            />
          ) : null
        }
      />
    </>
  ) : null;
};

const EventTimelineWrapper: FC = () => (
  <EventLayout page={Page.timeline}>
    <EventTimeline />
  </EventLayout>
);
export default EventTimelineWrapper;
