import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Button from 'vibo-ui/Button';
import Spinner from 'vibo-ui/Spinner';
import ContentArea from 'components/common/ContentArea';

import { ReactComponent as AppleIcon } from 'resources/img/svg/common/apple.svg';
import { ReactComponent as Windows10Icon } from 'resources/img/svg/common/windows10.svg';

import { GET_SCANNER_INFO } from 'graphql/queries/scanner';

import useStyles from './style';

const DownloadBlock: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useQuery<ScannerInfoResponse>(GET_SCANNER_INFO, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <ContentArea shadowed>
      <div className="blockTitle">{t('download')}</div>
      <div className={classes.scannerDescription}>
        {t('scannerMakesPreparingMusicEasy')}
        <br />
        {t('bestMatchesForEachSong')}
        <ol className={classes.scannerSteps}>
          <li>{t('downloadTheScannerBelow')}</li>
          <li>{t('onceInstalledAddFolders')}</li>
          <li>{t('weRecommendSelectingYourFolders')}</li>
        </ol>
      </div>
      {!!data ? (
        <div className={classes.downloadButtonContainer}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                onClick={() => window.open(process.env.REACT_APP_SCANNER_DOWNLOAD_LINK_WINDOWS)}
                prefixIcon={Windows10Icon}
                className="centrate"
                displayType="bordered"
                shape="round"
                size="lg"
              >{`${t('downloadFor')} Windows - v${data?.getScannerInfo.winVersion}`}</Button>
              <Button
                onClick={() => window.open(process.env.REACT_APP_SCANNER_DOWNLOAD_LINK_MAC)}
                prefixIcon={AppleIcon}
                className="centrate"
                displayType="bordered"
                shape="round"
                size="lg"
              >{`${t('downloadFor')} Mac - v${data?.getScannerInfo.macVersion}`}</Button>
            </>
          )}
        </div>
      ) : null}
    </ContentArea>
  );
};

export default DownloadBlock;
