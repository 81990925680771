import React, { FC, useContext, useCallback } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Header from 'components/common/Header';
import GoBack from 'components/buttons/GoBack';
import { UserContext } from 'pages/users/UserContext';
import { IntercomContext } from 'components/context/IntercomContext';

import { useModal } from 'vibo-ui/Modal';
import { useMe } from 'graphql/hooks/user';
import { ACCOUNT_PAGE_MENU_ITEMS } from 'services/constants';
import { getSelectedAccountMenuKeys } from 'services/common/routesHelper';

import { IconmoonFont } from 'vibo-ui/Icon';
import { DjType, Modals, Page } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { ProfileHeaderProps } from './interfaces';

import useHeaderStyles from 'components/common/Header/style';

const ProfileHeader: FC<ProfileHeaderProps> = ({ page }) => {
  const headerClasses = useHeaderStyles();
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { toggleShowChat } = useContext(IntercomContext);

  const { isCompanyDj } = useMe();

  const { openModal } = useModal();

  const openAddNewDjModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('addDj'),
          children: t('ifWantAddNewDjPleaseContactViboSupport'),
          submit: {
            text: t('openViboSupport'),
            onClick: toggleShowChat,
          },
        },
      }),
    []
  );

  const { push } = useHistory();

  const { id: djId } = useParams<{ id: string }>();

  const isMasterDj = user?.djSettings?.type === DjType.company && !djId;

  const menuItems = isMasterDj ? ACCOUNT_PAGE_MENU_ITEMS : [];

  return (
    <Header>
      {isCompanyDj && page === Page.childDjProfile ? (
        <GoBack className={headerClasses.goBack} to="/account" />
      ) : null}
      <Menu
        getSelectedSidebarMenuKeys={getSelectedAccountMenuKeys}
        styleModificator="siderTop"
        mode="horizontal"
      >
        {menuItems.map(({ name, route }) => (
          <Menu.Item onClick={() => push(`/${route}`)} key={route}>
            {t(name)}
          </Menu.Item>
        ))}
      </Menu>
      {page === Page.myDjs ? (
        <Button
          onClick={openAddNewDjModal}
          prefixIcon={IconmoonFont['plus-16']}
          shape="round"
          size="lg"
        >
          {t('addNewDj')}
        </Button>
      ) : null}
    </Header>
  );
};

export default ProfileHeader;
