import React, { FC, useContext } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SearchInput from 'vibo-ui/SearchInput';
import IconButton from 'components/buttons/IconButton';
import { PrepModeSongsFilterableContext } from '../PrepModeSongsContext';

import { ButtonIconType } from 'types/enums';
import { PrepModeSongsHeaderProps } from './interfaces';

import useSongsHeaderStyles from '../../Timeline/TimelineSongsBlock/TimelineSongsHeader/style';
import useStyles from './style';

const PrepModeSongsHeader: FC<PrepModeSongsHeaderProps> = ({ onUpdateSongs }) => {
  const songsHeaderClasses = useSongsHeaderStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(PrepModeSongsFilterableContext);

  const onSearch = debounce((_, q) => {
    setFilter(prev => ({ ...prev, q }));
  });

  return (
    <div className={classes.prepModeSongsHeader}>
      <Button
        onClick={() => onUpdateSongs({ selectBestMatches: true }, filter)}
        displayType="primary"
        shape="round"
      >
        {t('markBestMatches')}
      </Button>
      <IconButton
        className={songsHeaderClasses.action}
        onClick={() => onUpdateSongs({ selectBestMatches: false }, { q: '' })}
        title={t('unmarkAll')}
        type={ButtonIconType.unmark}
      />
      <IconButton
        className={songsHeaderClasses.action}
        onClick={() => onUpdateSongs({ isFlagged: true }, filter)}
        title={t('flagAll')}
        type={ButtonIconType.flag}
      />
      <IconButton
        className={songsHeaderClasses.action}
        onClick={() => onUpdateSongs({ isFlagged: false }, { q: '' })}
        title={t('unflagAll')}
        type={ButtonIconType.unflag}
      />
      <SearchInput
        onChange={onSearch}
        wrapperClassName={classes.prepModeSongsSearch}
        placeholder={t('Search list')}
        queryName="songsQ"
        size="sm"
      />
    </div>
  );
};

export default PrepModeSongsHeader;
