import { gql } from '@apollo/client';
import { USER_DATA } from '../fragments/user';

export const SEND_SUPPORT_MESSAGE = gql`
  mutation sendSupportMessage($payload: SupportMessageInput!) {
    sendSupportMessage(payload: $payload) {
      sent
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($payload: UpdateProfileInput!) {
    updateProfile(payload: $payload) {
      ...UserData
    }
  }
  ${USER_DATA}
`;

export const UPDATE_CHILD_DJ = gql`
  mutation updateChildDj($payload: UpdateProfileInput!, $userId: ID!) {
    updateChildDj(payload: $payload, userId: $userId) {
      ...UserData
    }
  }
  ${USER_DATA}
`;

export const CHANGE_NOTIFICATION = gql`
  mutation updataNotificationSettings($newsAndUpdates: Boolean!, $eventsReminding: Boolean!) {
    updateProfile(
      payload: {
        subscriptions: { newsAndUpdates: $newsAndUpdates, eventsReminding: $eventsReminding }
      }
    ) {
      subscriptions {
        newsAndUpdates
        eventsReminding
      }
    }
  }
`;

export const UPDATE_PROFILE_IMAGE = gql`
  mutation updateProfileLogo($image: Upload!) {
    updateProfile(payload: { image: $image }) {
      imageUrl
    }
  }
`;

export const UPDATE_PROFILE_LOGO = gql`
  mutation updateProfileLogo($image: Upload!) {
    updateProfile(payload: { logo: $image }) {
      logoUrl
    }
  }
`;

export const UPDATE_CHILD_DJ_IMAGE = gql`
  mutation updateChildDj($userId: ID!, $image: Upload!) {
    updateChildDj(payload: { image: $image }, userId: $userId) {
      imageUrl
    }
  }
`;

export const UPDATE_CHILD_DJ_LOGO = gql`
  mutation updateChildDj($userId: ID!, $image: Upload!) {
    updateChildDj(payload: { logo: $image }, userId: $userId) {
      logoUrl
    }
  }
`;

export const DELETE_CHILD_DJ = gql`
  mutation deleteChildDj($userId: ID!) {
    deleteChildDj(userId: $userId)
  }
`;

export const UPDATE_ALLOW_UPDATE_PROFILE_FOR_CHILD = gql`
  mutation updateAllowUpdateProfileForChild($allowUpdateProfileForChild: Boolean!) {
    updateProfile(
      payload: { djSettings: { allowUpdateProfileForChild: $allowUpdateProfileForChild } }
    ) {
      _id
      djSettings {
        allowUpdateProfileForChild
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $newPassword: String!) {
    changePassword(payload: { password: $password, newPassword: $newPassword }) {
      _id
    }
  }
`;

export const CHANGE_CARD = gql`
  mutation changeCard($payload: ChangeCardPayload!) {
    changeCard(payload: $payload) {
      last4
      month
      year
    }
  }
`;

export const UPDATE_UPLICATION_COLOR = gql`
  mutation updateApplicationColor($payload: UpdateApplicationColorInput!) {
    updateApplicationColor(payload: $payload) {
      applicationColors {
        customLightModeApplicationColor
        customDarkModeApplicationColor
      }
    }
  }
`;

export const RETRY_SUBSCRIPTION_PAYMENT = gql`
  mutation retrySubscriptionPayment {
    retrySubscriptionPayment
  }
`;
