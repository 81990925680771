import { Modals } from 'types/enums';

import { ModalPlaceholderInfo } from './interfaces';

export const MODAL_PLACEHOLDERS: ModalPlaceholderInfo = {
  [Modals.sectionSettings]: {
    size: 'xxlg',
    withFooter: true,
  },
  [Modals.createSection]: {
    size: 'xxlg',
    withFooter: true,
  },
  [Modals.createQuestion]: {
    size: 'xxlg',
    withFooter: true,
  },
  [Modals.song]: {
    size: 'xlg',
  },
  [Modals.sectionSongIdeas]: {
    size: 'xlg',
    withSubHeader: true,
  },
  [Modals.addSongsToSectionFromSearch]: {
    size: 'xlg',
    withSubHeader: true,
  },
  [Modals.addSongsFromMusicSource]: {
    size: 'xlg',
    withSubHeader: true,
  },
  [Modals.exportToSpotify]: {
    size: 'xlg',
    withSubHeader: true,
    withFooter: true,
  },
  [Modals.djNotes]: {
    size: 'xlg',
  },
  [Modals.clone]: {
    size: 'sm',
    withFooter: true,
  },
  [Modals.pickImage]: {
    size: 'xlg',
  },
  [Modals.pickFiles]: {
    size: 'xlg',
  },
};
