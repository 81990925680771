import { gql } from '@apollo/client';

export const CONNECT_SPOTIFY = gql`
  mutation connectSpotify($accessToken: String!, $refreshToken: String!, $expiresIn: Int!) {
    connectSpotify(accessToken: $accessToken, refreshToken: $refreshToken, expiresIn: $expiresIn)
  }
`;

export const CONNECT_SPOTIFY_VIA_CODE = gql`
  mutation connectSpotifyViaCode($code: String!) {
    connectSpotifyViaCode(code: $code)
  }
`;

export const DISCONNECT_SPOTIFY = gql`
  mutation disconnectSpotify {
    disconnectSpotify
  }
`;
