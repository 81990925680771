import React, { FC } from 'react';

import Brick from '../Brick';
import ContentArea from 'components/common/ContentArea';

import useStyles from './style';

const DjCardPlaceholder: FC = () => {
  const classes = useStyles();

  return (
    <ContentArea className={classes.djCard} shadowed>
      <Brick className={classes.djPhoto} />
      <div className={classes.djNameWrapper}>
        <Brick className={classes.djName} />
      </div>
    </ContentArea>
  );
};

export default DjCardPlaceholder;
