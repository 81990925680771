import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import Tools from '../Tools';
import QuestionIcon from '../QuestionIcon';
import Linkify from 'components/common/Linkify';
import CopyQuestionAnswer from '../CopyQuestionAnswer';
import WithEventRole from 'components/user/WithEventRole';
import { EditableContext } from 'components/common/EditableContext';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { EventUserType } from 'types/enums';

import useStyles from './style';

const QuestionHeader: FC = () => {
  const classes = useStyles();

  const { canEdit } = useContext(EditableContext);
  const { question } = useContext(QuestionContext);

  return (
    <div className={classNames('questionHeader', classes.header)}>
      <QuestionIcon question={question} />
      <div className={classNames('questionTitle', classes.questionTitle)}>
        <Linkify>
          <span className={classes.text}>{question.question.title}</span>
        </Linkify>
        <CopyQuestionAnswer value={question.question.title} />
      </div>
      {canEdit ? (
        <WithEventRole roles={[EventUserType.dj]}>
          <Tools />
        </WithEventRole>
      ) : null}
    </div>
  );
};

export default QuestionHeader;
