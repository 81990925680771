import React, { FC, lazy, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router';
import { useQueryParam, StringParam } from 'use-query-params';

import Wizard from 'vibo-ui/Wizard';
import AuthLayout from '../AuthLayout';
import { WizardState } from 'vibo-ui/Wizard/WizardStepContext';
import { SignInContextProvider } from 'components/context/SignInContext';

import { authStorage } from 'utils/helpers/auth';

import { isLoggedInVar } from 'graphql/cache';

import { SignUpSteps } from './interfaces';

import useStyles from './style';

const SignInStep = lazy(() => import('./steps/SignInStep'));
const UserNameStep = lazy(() => import('./steps/UserNameStep'));
const PlanEventStep = lazy(() => import('./steps/PlanEventStep'));
const AddPictureStep = lazy(() => import('./steps/AddPictureStep'));
const CheckEmailStep = lazy(() => import('./steps/CheckEmailStep'));
const OpenDancingStep = lazy(() => import('./steps/OpenDancingStep'));
const InvitePeopleStep = lazy(() => import('./steps/InvitePeopleStep'));
const ConfirmEmailStep = lazy(() => import('./steps/ConfirmEmailStep'));
const CreatePasswordStep = lazy(() => import('./steps/CreatePasswordStep'));

const SignIn: FC = () => {
  const classes = useStyles();

  const [token] = useQueryParam('id_token', StringParam);
  const [success] = useQueryParam('success', StringParam);

  const { push } = useHistory();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  useEffect(() => {
    const localstorageSearch = localStorage.getItem('appleLoginSearch');

    if (!!localstorageSearch) {
      push(`/join-event?url=${localstorageSearch}&success=${success}&id_token=${token}`);
    }

    if (!isLoggedIn) {
      authStorage.clearTokens();
    }
  }, [token, success, isLoggedIn]);

  return (
    <AuthLayout>
      <WizardState>
        <SignInContextProvider>
          <Wizard className={classes.signUpWizard}>
            <Wizard.Step stepName={SignUpSteps.checkEmail}>
              <CheckEmailStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.signIn}>
              <SignInStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.confirmEmail}>
              <ConfirmEmailStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.createPassword}>
              <CreatePasswordStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.userName}>
              <UserNameStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.addPicture}>
              <AddPictureStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.plan}>
              <PlanEventStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.invite}>
              <InvitePeopleStep />
            </Wizard.Step>
            <Wizard.Step stepName={SignUpSteps.dancing}>
              <OpenDancingStep />
            </Wizard.Step>
          </Wizard>
        </SignInContextProvider>
      </WizardState>
    </AuthLayout>
  );
};

export default SignIn;
