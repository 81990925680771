import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';

import { IconmoonFont } from 'vibo-ui/Icon';

const CollapseExpandSectionsButton: FC<ButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSectionCollapsed = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  const sectionsTimeline = document.getElementById('sectionsTimeline');

  useEffect(() => {
    isCollapsed
      ? sectionsTimeline?.classList.add('collapsed')
      : sectionsTimeline?.classList.remove('collapsed');
  }, [isCollapsed]);

  return (
    <Button
      onClick={toggleSectionCollapsed}
      prefixIcon={IconmoonFont[isCollapsed ? 'expand-16' : 'colapse-16']}
      tooltipProps={{
        overlay: t(isCollapsed ? 'Expand sections' : 'Collapse sections'),
        fillOverlay: true,
        placement: 'top',
      }}
      shape="round"
      size="lg"
      {...rest}
    />
  );
};

export default CollapseExpandSectionsButton;
