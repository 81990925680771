import React, { FC } from 'react';
import classNames from 'classnames';

import SectionSongPreview from 'components/sections/SectionSongPreview';

import { isEventSection } from 'services/sections/helpers';

import { TimelineSectionSongsInfoProps } from './interfaces';

import useStyles from './style';

const TimelineSectionSongsInfo: FC<TimelineSectionSongsInfoProps> = ({ section }) => {
  const classes = useStyles();

  return isEventSection(section) ? (
    <div className={classNames('songsInfo', classes.songsInfo)}>
      <SectionSongPreview section={section} className={classes.songPreview} />
    </div>
  ) : null;
};

export default TimelineSectionSongsInfo;
