import React, { FC, useEffect, useState, useCallback, useContext } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';

import TimelineLayout from 'components/layouts/TimelineLayout';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';
import SectionBlocks from 'components/sections/blocks/SectionBlocks';
import TemplateSections from 'components/events/Timeline/TemplateSections';
import { EventContext, EventContextProvider } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { TEMPLATE_PAGE_TAB_KEYS } from 'services/constants';
import { updateSectionIdFromRoute } from 'services/sections/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { useModal } from 'vibo-ui/Modal';

import {
  TEMPLATE_SECTIONS,
  GET_TEMPLATE_SECTION_COMPANY_SONG_IDEAS,
  GET_TEMPLATE_SECTION_SONG_IDEAS,
  GET_TEMPLATE_SECTION_USER_SONG_IDEAS,
  GET_TEMPLATE_SECTION_VIBO_SONG_IDEAS,
  TEMPLATE_SECTIONS_NO_IMAGES_ALLOWED,
} from 'graphql/queries/template';
import {
  UPDATE_TEMPLATE_SECTION,
  UPDATE_TEMPLATE_SECTION_BLOCKS,
  ADD_SONG_IDEAS_TO_TEMPLATE_SECTION,
  DELETE_SONG_IDEAS_FROM_TEMPLATE_SECTION,
  REORDER_TEMPLATE_SECTION_SONG_IDEAS,
} from 'graphql/mutations/template';
import { GET_TEMPLATE_SECTION_QUESTIONS_V2 } from 'graphql/queries/questions';
import {
  ANSWER_EVENT_SECTION_QUESTION_V2,
  CLONE_TEMPLATE_SECTION_QUESTION,
  CREATE_TEMPLATE_SECTION_QUESTION_V2,
  DELETE_TEMPLATE_SECTION_QUESTION,
  REORDER_TEMPLATE_SECTION_QUESTION,
  UPDATE_TEMPLATE_SECTION_QUESTION_V2,
} from 'graphql/mutations/questions';
import { GET_TEMPLATE_SECTION_SONG_IDEAS_SONGS } from 'graphql/queries/songIdeas';
import { onError } from 'graphql/helpers';

import { EventUserType, Modals, Page } from 'types/enums';
import { SectionSettingsModalProps } from 'components/modals/SectionSettingsModal';
import { TemplateTimelineProps, RouteParams } from './interfaces';

import useStyles from './style';

const TemplateTimeline: FC<TemplateTimelineProps> = ({ template }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState<Nullable<TemplateSection>>(null);
  const [filter, setFilter] = useState<Nullable<SectionsFilter>>(null);

  const eventContext = useContext(EventContext);

  const history = useHistory();
  const match = useRouteMatch<RouteParams>();

  const templateId: string = match.params.id;
  const sectionId: string = match.params.sectionId;
  const baseTimelineRoute: string = `/template/${templateId}/${TEMPLATE_PAGE_TAB_KEYS.timeline}`;

  const { openModal } = useModal();

  const openSectionSettinsModal = useCallback(
    section =>
      openModal<SectionSettingsModalProps<TimelineSection>>({
        key: Modals.sectionSettings,
        props: {
          section,
          updateSectionMutation: UPDATE_TEMPLATE_SECTION,
          variables: {
            templateId,
            sectionId: section._id,
          },
          refetchQueries: ['templateSections'],
          eventContext,
        },
      }),
    [eventContext.event?._id]
  );

  const refetchQueries: string[] = ['templateSections'];

  const { data, loading, refetch } = useQuery<TemplateSectionsResponse, TemplateSectionsVariables>(
    eventContext.isCoverPhotoAllowed ? TEMPLATE_SECTIONS : TEMPLATE_SECTIONS_NO_IMAGES_ALLOWED,
    {
      variables: { templateId, filter },
      fetchPolicy: 'cache-and-network',
      skip: eventContext.isLoading,
      onError,
    }
  );
  const sections: TemplateSection[] = get(data, 'templateSections', []);

  const shouldRenderComponentsForSection: boolean = !!selectedSection && !!sections.length;

  const [updateSection] = useMutation<
    UpdateEventSectionResponse,
    Partial<TemplateSectionCommonVariables> & { payload?: UpdateSectionForm }
  >(UPDATE_TEMPLATE_SECTION, {
    onCompleted: () => {
      toastNotify({ text: t('changesSavedSuccessfully') });
    },
    variables: {
      templateId,
      sectionId,
    },
    onError,
    refetchQueries,
  });

  const defaultBlockProps = {
    variables: {
      templateId,
      sectionId,
    },
  };
  const songsBlockProps = {
    ...defaultBlockProps,
    listQueries: {
      user: {
        key: 'getTemplateSectionUserSongIdeas',
        query: GET_TEMPLATE_SECTION_USER_SONG_IDEAS,
      },
      vibo: {
        key: 'getTemplateSectionViboSongIdeas',
        query: GET_TEMPLATE_SECTION_VIBO_SONG_IDEAS,
      },
      company: {
        key: 'getTemplateSectionCompanySongIdeas',
        query: GET_TEMPLATE_SECTION_COMPANY_SONG_IDEAS,
      },
      section: {
        key: 'getTemplateSectionSongIdeas',
        query: GET_TEMPLATE_SECTION_SONG_IDEAS,
      },
      songs: {
        key: 'getTemplateSectionSongIdeasSongs',
        query: GET_TEMPLATE_SECTION_SONG_IDEAS_SONGS,
      },
    },
    mutations: {
      add: ADD_SONG_IDEAS_TO_TEMPLATE_SECTION,
      delete: DELETE_SONG_IDEAS_FROM_TEMPLATE_SECTION,
      reorder: REORDER_TEMPLATE_SECTION_SONG_IDEAS,
    },
  };
  const notesBlockProps = {
    ...defaultBlockProps,
  };
  const questionsBlockProps = {
    ...defaultBlockProps,
    canAnswer: false,
    listCacheKey: 'getTemplateSectionQuestionsV2',
    listQuery: GET_TEMPLATE_SECTION_QUESTIONS_V2,
    mutations: {
      answer: ANSWER_EVENT_SECTION_QUESTION_V2,
      create: CREATE_TEMPLATE_SECTION_QUESTION_V2,
      clone: CLONE_TEMPLATE_SECTION_QUESTION,
      update: UPDATE_TEMPLATE_SECTION_QUESTION_V2,
      reorder: REORDER_TEMPLATE_SECTION_QUESTION,
      delete: DELETE_TEMPLATE_SECTION_QUESTION,
      updateBlocks: UPDATE_TEMPLATE_SECTION_BLOCKS,
    },
    refetchQueries: ['templateSections', 'getTemplateSectionQuestionsV2'],
  };

  useEffect(() => {
    updateSectionIdFromRoute(selectedSection, setSelectedSection, sectionId, sections);
  }, [sections, sectionId]);

  useEffect(() => {
    if (selectedSection && selectedSection._id !== sectionId) {
      history.push({
        search: history.location.search,
        pathname: `${baseTimelineRoute}/${selectedSection._id}`,
      });
    }
  }, [selectedSection, match.params.page]);

  return (
    <div className={classes.templateTimeline}>
      <PageContentHeader>
        <EventTitle name={template.title} />
      </PageContentHeader>
      <TimelineLayout
        left={
          <SectionsFilterableContext.Provider value={{ filter, setFilter }}>
            <TemplateSections
              template={template}
              sections={sections}
              selectedSection={selectedSection}
              onSectionClick={setSelectedSection}
              // handle it "updateSection"
              // @ts-ignore
              updateSection={updateSection}
              onEditClick={(section: TimelineSection) => {
                openSectionSettinsModal(section);
              }}
              refetch={refetch}
              isSectionsLoading={loading}
            />
          </SectionsFilterableContext.Provider>
        }
        right={
          shouldRenderComponentsForSection &&
          selectedSection && (
            <>
              <SectionBlocks<TemplateSection>
                // handle it "updateSection"
                // @ts-ignore
                updateSection={updateSection}
                sections={sections}
                section={selectedSection}
                onEditClick={() => {
                  openSectionSettinsModal(selectedSection);
                }}
                questionsBlockProps={questionsBlockProps}
                songsBlockProps={songsBlockProps}
                notesBlockProps={notesBlockProps}
              />
            </>
          )
        }
      />
    </div>
  );
};

const TemplateTimelineWrapper: FC<TemplateTimelineProps> = props => {
  const extendedTemplate = ({ ...props.template, role: EventUserType.dj } as Partial<
    EventItem
  >) as EventItem;

  return (
    <EventContextProvider value={{ event: extendedTemplate, page: Page.timeline }}>
      <TemplateTimeline {...props} />
    </EventContextProvider>
  );
};
export default TemplateTimelineWrapper;
