import get from 'lodash/get';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, omitDeep } from '@apollo/client/utilities';
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, from, split } from '@apollo/client';

import { isLoadingVar, isOnlineVar } from './cache';
import { gaSendGqlError } from 'services/googleAnalytics';
import { interceptZapierLogin } from 'services/common/loginHelper';
import { FORBIDDEN_RESPONSE_OPERATION_NAMES } from 'services/constants';
import { VIBO_REFRESH_TOKEN_KEY, VIBO_TOKEN_KEY } from 'utils/constants';
import { authStorage, handleSignInCompleted, logout } from 'utils/helpers/auth';

const typeDefs = require('./schema.graphql');

const resolvers = {
  Mutation: {
    logUserIn: (_: unknown, response: AuthResponse) => {
      if (interceptZapierLogin(response.user._id)) {
        return null;
      }

      handleSignInCompleted(response);
      return null;
    },
  },
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URI,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_URI!,
  })
);

const forbiddenLink = onError(({ graphQLErrors, operation, forward }) => {
  const { operationName } = operation;
  const code = get(graphQLErrors, '[0].code');
  const goForbiddenPage = () => window?.location?.assign('/forbidden');

  gaSendGqlError(graphQLErrors?.[0]);

  isLoadingVar(false);

  if (code === 'FORBIDDEN' && FORBIDDEN_RESPONSE_OPERATION_NAMES.includes(operationName)) {
    return goForbiddenPage();
  }

  return;
});

const errorLink = onError(({ graphQLErrors, operation, response, forward }) => {
  const code = get(graphQLErrors, '[0].code');
  const pathname = window.location.pathname;
  const goNotFoundPage = () => window?.location?.assign('/not-found');

  gaSendGqlError(graphQLErrors?.[0]);

  isLoadingVar(false);

  if (code === 'UNAUTHORIZED' && pathname !== '/connect-applemusic') {
    if (response) {
      response.errors = undefined;
      response.data = {};
    }

    logout();
  }

  if (code === 'CANNOT_GET_EVENT') {
    return goNotFoundPage();
  }

  return;
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,

      ...(!!authStorage.token ? { 'x-token': authStorage.token } : {}),
      ...(!!authStorage.refreshToken ? { [VIBO_REFRESH_TOKEN_KEY]: authStorage.refreshToken } : {}),
    },
  }));

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers) {
      const token = headers.get(VIBO_TOKEN_KEY);
      const refreshToken = headers.get(VIBO_REFRESH_TOKEN_KEY);

      if (token) {
        authStorage.token = token;
      }

      if (refreshToken) {
        authStorage.refreshToken = refreshToken;
      }
    }

    return response;
  })
);

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => {
    return data;
  });
});

const loadingLink = new ApolloLink((operation, forward) => {
  isLoadingVar(true);

  return forward?.(operation).map(response => {
    isLoadingVar(false);

    return response;
  });
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([
    loadingLink,
    authMiddleware,
    cleanTypeName,
    errorLink,
    forbiddenLink,
    httpLink,
    afterwareLink,
  ])
);

export const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'SectionSong':
      case 'PrepModeSong':
      case 'Section':
      case 'TemplateSection': {
        return object._id;
      }
      case 'PrepModeSection': {
        return `PrepModeSection:${object._id}`;
      }
      case 'SearchedSong': {
        return object.viboSongId
          ? `SearchedSong:${object.viboSongId}`
          : `SearchedSong:${object.songUrl}`;
      }
      case 'SearchedSongForSongIdeas': {
        return object.viboSongId
          ? `SearchedSongForSongIdeas:${object.viboSongId}`
          : `SearchedSongForSongIdeas:${object.songUrl}`;
      }
      case 'PlaylistSongsResponse':
      case 'EventFileTimelineOptions':
      case 'EventFileSectionsOptionsWeb':
      case 'EventFilePlaylistsOptionsWeb':
      case 'PrintSectionOptions': {
        return null;
      }
      default:
        return defaultDataIdFromObject(object);
    }
  },
  addTypename: true,
});

const client = new ApolloClient({
  link,
  cache,
  resolvers,
  typeDefs,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

window.addEventListener('load', () => {
  isOnlineVar(navigator.onLine);
});

window.addEventListener('online', () => {
  isOnlineVar(true);
});

window.addEventListener('offline', () => {
  isOnlineVar(false);
});

export default client;
