import React, { FC } from 'react';
import classNames from 'classnames';

import Linkify from 'components/common/Linkify';

import { SectionDescriptionProps } from './interfaces';

import useStyles from './style';

const SectionDescription: FC<SectionDescriptionProps> = ({ description }) => {
  const classes = useStyles();

  return (
    <Linkify>
      <div className={classNames('sectionDesc', classes.sectionDescription)}>{description}</div>
    </Linkify>
  );
};

export default SectionDescription;
