import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songExtra: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .creatorImage': {
      height: '20px',
      width: '20px',

      '&:not(img)': {
        position: 'relative',
        overflow: 'visible',
        margin: '0 8px',

        '&:hover': {
          '&:before': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            content: '""',
            backgroundColor: theme.palette.action.hover,
            width: 'calc(100% + 3px)',
            height: 'calc(100% + 3px)',
            borderRadius: '50%',
          },

          '& img': {
            zIndex: 5,
          },

          '& svg': {
            color: theme.palette.background.paper,
          },
        },

        '@media screen and (min-width: 1441px)': {
          margin: '0 12px',
        },
      },

      //for gtm tracking propper target
      '& .imageDefaultPlaceholder': {
        pointerEvents: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        height: '24px',
        width: '24px',
      },
    },
  },
}));

export default useStyles;
