import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  customLabel: {
    width: 'fit-content',
    borderRadius: '4px',
    fontSize: '11px',
    lineHeight: '1px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .viboIcon': {
      marginRight: '2px',
    },

    '&.default': {
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
    },

    '&.success': {
      color: theme.palette.success.main,
      border: `1px solid ${theme.palette.success.main}`,

      '&.filled': {
        color: colors.white,
        backgroundColor: theme.palette.success.main,
      },
    },

    '&.whiteBg': {
      backgroundColor: theme.palette.background.dropDown,
    },

    '&.gray': {
      color: theme.palette.text.hint,
      border: `1px solid ${theme.palette.text.hint}`,

      '&.filled': {
        color: colors.white,
        backgroundColor: theme.palette.text.hint,
      },
    },

    '&.primary': {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&.filled': {
        color: colors.white,
        backgroundColor: theme.palette.primary.main,
      },
    },

    '&.sm': {
      height: '14px',
      fontSize: '9px',

      '@media screen and (min-width: 1441px)': {
        height: '16px',
        fontSize: '12px',
      },
    },

    '&.lg': {
      padding: '0 6px',
      height: '26px',
      fontSize: '11px',
      lineHeight: '14px',

      '@media screen and (min-width: 1441px)': {
        height: '30px',
        fontSize: '14px',
        lineHeight: '18px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
    },
  },
}));

export default useStyles;
