import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import ProfileLayout from '../ProfileLayout';
import MyInfo from 'components/user/Profile/MyInfo';
import { UserContext } from '../UserContext';

import {
  UPDATE_CHILD_DJ,
  UPDATE_CHILD_DJ_IMAGE,
  UPDATE_CHILD_DJ_LOGO,
} from 'graphql/mutations/user';
import { GET_USER } from 'graphql/queries/user';

import { Page } from 'types/enums';

const ChildProfile: FC = () => {
  const { id: userId } = useParams<{ id: string }>();

  const { data: userData } = useQuery(GET_USER, {
    variables: {
      userId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const user: Maybe<User> = userData?.['user'];

  return (
    <UserContext.Provider value={{ user }}>
      <ProfileLayout page={Page.childDjProfile}>
        <MyInfo
          user={user}
          userQueryKey={'user'}
          userQuery={GET_USER}
          variables={{ userId }}
          mutations={{
            updateProfile: { mutation: UPDATE_CHILD_DJ, key: 'updateChildDj' },
            updateProfileImage: { mutation: UPDATE_CHILD_DJ_IMAGE, key: 'updateChildDj' },
            updateProfileLogo: { mutation: UPDATE_CHILD_DJ_LOGO, key: 'updateChildDj' },
          }}
        />
      </ProfileLayout>
    </UserContext.Provider>
  );
};

export default ChildProfile;
