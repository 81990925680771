import React, { FC } from 'react';

import MyAccount from './MyAccount';
import ProfileLayout from 'pages/users/ProfileLayout';
import { UserContext } from 'pages/users/UserContext';

import { useMe } from 'graphql/hooks/user';

import { Page } from 'types/enums';

const Account: FC = () => {
  const { user } = useMe();

  return (
    <UserContext.Provider value={{ user }}>
      <ProfileLayout page={Page.myDjs}>
        <MyAccount />
      </ProfileLayout>
    </UserContext.Provider>
  );
};

export default Account;
