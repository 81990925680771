import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineLayout: {
    background: theme.palette.background.default,
    height: 'calc(100% - 60px)',

    '& .container': {
      height: '100%',

      '& .containerContent': {
        height: '100%',
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100% - 90px)',
    },
  },

  content: {
    margin: '0',
    boxSizing: 'border-box',
    height: '100%',
    display: 'grid',
    maxHeight: 'calc(100vh - 120px)',
    gridTemplateColumns: 'minmax(288px, 288px) 1fr',
    gap: '30px',

    '@media screen and (min-width: 1441px) ': {
      gap: '56px',
      maxHeight: 'calc(100vh - 140px)',
      gridTemplateColumns: 'minmax(384px, 384px) 1fr',
    },
  },

  left: {
    position: 'relative',
    maxHeight: 'inherit',
  },

  right: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',

    '& > .viboScrollbar': {
      height: 'calc(100% - 66px)',
      display: 'flex',
      flexDirection: 'column',

      '& .emptyState': {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '12px',

        '& .stateIcon svg': {
          width: '180px',
          height: '180px',

          '@media screen and (min-width: 1441px)': {
            width: '256px',
            height: '256px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          gap: '16px',
        },
      },

      '& .sectionBlock': {
        marginBottom: '26px',

        '@media screen and (min-width: 1441px) ': {
          marginBottom: '36px',
        },
      },
    },
  },
}));

export default useStyles;
