import React, { FC } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router';

import Button from 'vibo-ui/Button';
import AuthLayout from '../AuthLayout';
import PasswordInput from '../PasswordInput';
import GoBack from 'components/buttons/GoBack';
import ViboLogo from 'components/common/ViboLogo';

import { onError } from 'graphql/helpers';
import { passwordValidationSchema } from './constants';

import { RESET_PASSWORD } from 'graphql/mutations/auth';

const RestorePassword: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { token } = useParams<{ token: string }>();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => history.push('/reset-password-success'),
    onError,
  });

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={passwordValidationSchema}
      onSubmit={values =>
        resetPassword({
          variables: {
            password: values.password,
            token,
          },
        })
      }
    >
      {({ errors, handleSubmit }) => (
        <AuthLayout className="reset">
          <div className="content">
            <ViboLogo />
            <GoBack to="/login" />
            <h3>{t('createNewPassword')}</h3>
            <span>{t('minPasswordCharactersLength')}</span>
            <form onSubmit={handleSubmit}>
              <PasswordInput />
              <Button
                loading={loading}
                disabled={!!errors.password}
                displayType="primary"
                type="submit"
                shape="round"
                size="lg"
              >
                {t('changePassword')}
              </Button>
            </form>
          </div>
        </AuthLayout>
      )}
    </Formik>
  );
};

export default RestorePassword;
