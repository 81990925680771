import { gql } from '@apollo/client';

export const QUESTION_IMAGE = gql`
  fragment QuestionImageFragment on QuestionImagesResponse {
    imageUrl
    imageId
  }
`;

export const QUESTION_FILE = gql`
  fragment QuestionFileFragment on QuestionFilesResponse {
    fileUrl
    fileId
    fileName
  }
`;

export const QUESTION_OPTIONS = gql`
  fragment QuestionOptionsFragment on QuestionV2QuestionOption {
    _id
    title
    isOther
    images {
      ...QuestionImageFragment
    }
  }
  ${QUESTION_IMAGE}
`;

export const QUESTION_V2_QUESTION = gql`
  fragment QuestionV2QuestionFragment on QuestionV2Question {
    title
    images {
      ...QuestionImageFragment
    }
    files {
      ...QuestionFileFragment
    }
    options {
      ...QuestionOptionsFragment
    }
    youtubeLink {
      link
      thumbnail
    }
  }
  ${QUESTION_IMAGE}
  ${QUESTION_FILE}
  ${QUESTION_OPTIONS}
`;

export const QUESTION_V2_ANSWER = gql`
  fragment QuestionV2AnswerFragment on QuestionV2Answer {
    selectedOptions
    text
    images {
      ...QuestionImageFragment
    }
    files {
      ...QuestionFileFragment
    }
    phoneNumber {
      phoneCode
      phoneNumber
      country
    }
    location {
      name
      lat
      lng
    }
  }
  ${QUESTION_IMAGE}
  ${QUESTION_FILE}
`;

export const QUESTION_V2 = gql`
  fragment QuestionV2 on QuestionV2 {
    _id
    isAnswered
    question {
      ...QuestionV2QuestionFragment
    }
    answer {
      ...QuestionV2AnswerFragment
    }
    settings {
      type
      hasOther
      optionImagesEnabled
    }
  }
  ${QUESTION_V2_QUESTION}
  ${QUESTION_V2_ANSWER}
`;
