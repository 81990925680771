import { gql } from '@apollo/client';

export const SECTION_SONGS_SORT = gql`
  fragment SectionSongsSort on SectionSongsSort {
    field
    direction
    songsCount
    __typename
  }
`;

export const SECTION_DETAILS = gql`
  fragment SectionDetails on Section {
    name
    description
  }
`;

export const SECTION_ANSWERS_INFO = gql`
  fragment SectionDetails on Section {
    answeredCount
  }
`;

export const EVENT_SECTION = gql`
  fragment EventSection on Section {
    _id
    name
    time
    note
    description
    type
    wasSongsReordered
    hasNote
    hasComments
    hasSongs
    hasQuestions
    songsCount
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const TEMPLATE_SECTION = gql`
  fragment TemplateSection on TemplateSection {
    _id
    name
    time
    note
    description
    type
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const FAVORITE_SECTION = gql`
  fragment FavoriteSection on FavoriteSection {
    _id
    name
    time
    note
    description
    type
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const SECTION_BLOCKS = gql`
  fragment SectionBlocks on Section {
    blocks {
      name
      isExpanded
    }
    settings {
      songsEnabled
      notesEnabled
    }
    __typename
  }
`;

export const TEMPLATE_SECTION_BLOCKS = gql`
  fragment TemplateSectionBlocks on TemplateSection {
    blocks {
      name
      isExpanded
    }
    settings {
      songsEnabled
    }
    __typename
  }
`;
