import { SECTION_SONGS_SORT } from 'graphql/fragments/sections';
import isEqual from 'lodash/isEqual';
import client, { cache } from '../client';
import {
  BEATSOURCE_SONGS_STATE,
  SECTION_SEARCHED_SONG_DATA,
  SONG_COMMENT,
  SONG_IS_FLAGGED,
  SONG_IS_MUST_PLAY,
  SONG_LIKES_INFO,
} from '../fragments/songs';

export const getSearchedSongById = (viboSongId: string): Nullable<SearchedSongDataFragment> =>
  client.readFragment<SearchedSongDataFragment>({
    id: `SearchedSong:${viboSongId}`,
    fragment: SECTION_SEARCHED_SONG_DATA,
  });

export const updateSearchedSong = (
  viboSongId: string,
  payload: Omit<SearchedSongDataFragment, '__typename'>
): void => {
  const searchedSong = getSearchedSongById(viboSongId);

  if (searchedSong) {
    client.writeFragment({
      id: `SearchedSong:${viboSongId}`,
      fragment: SECTION_SEARCHED_SONG_DATA,
      data: {
        ...payload,
        __typename: 'SearchedSong',
      },
    });
  }
};

export const updateSongComment = (viboSongId: string, payload: string): void => {
  const cachedSong = client.readFragment<Nullable<SongLikesFragment>>({
    id: viboSongId,
    fragment: SONG_LIKES_INFO,
  });

  if (cachedSong) {
    client.writeFragment<SongCommentFragment>({
      id: viboSongId,
      fragment: SONG_COMMENT,
      data: {
        comment: payload,
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const updateSongLike = (viboSongId: string, liked: boolean): void => {
  const cachedSong = client.readFragment<Nullable<SongLikesFragment>>({
    id: viboSongId,
    fragment: SONG_LIKES_INFO,
  });

  if (cachedSong) {
    client.writeFragment<SongLikesFragment>({
      id: viboSongId,
      fragment: SONG_LIKES_INFO,
      data: {
        likesCount: cachedSong.likesCount + (liked ? 1 : -1),
        likedByMe: liked,
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const updateSongFlag = (viboSongId: string, isFlagged: boolean): void => {
  const cachedSong = client.readFragment<Nullable<SongIsFlaggedFragment>>({
    id: viboSongId,
    fragment: SONG_IS_FLAGGED,
  });

  if (cachedSong) {
    client.writeFragment<SongIsFlaggedFragment>({
      id: viboSongId,
      fragment: SONG_IS_FLAGGED,
      data: {
        isFlagged,
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const updateSongMustPlay = (viboSongId: string, isMustPlay: boolean): void => {
  const cachedSong = client.readFragment<Nullable<SongIsMustPlayFragment>>({
    id: viboSongId,
    fragment: SONG_IS_MUST_PLAY,
  });

  if (cachedSong) {
    client.writeFragment<SongIsMustPlayFragment>({
      id: viboSongId,
      fragment: SONG_IS_MUST_PLAY,
      data: {
        isMustPlay,
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const getBeatsourceSectionCache = (sectionId: string) => {
  return cache.readFragment<Nullable<BeatsourceSongsStateFragment>>({
    id: `BeatsourceSection:${sectionId}`,
    fragment: BEATSOURCE_SONGS_STATE,
  });
};

export const updateBeatsourceSongState = (sectionId: string, ids: string[]): void => {
  const cachedSection = getBeatsourceSectionCache(sectionId);

  if (ids !== cachedSection?.ids) {
    client.writeFragment<BeatsourceSongsStateFragment>({
      id: `BeatsourceSection:${sectionId}`,
      fragment: BEATSOURCE_SONGS_STATE,
      data: {
        ids,
        __typename: 'BeatsourceSongsState',
      },
    });
  }
};

export const handleUpdateSectionSortCache = (
  sort: Nullable<TSort<SongsSortFields>>,
  sectionId: string,
  songsCount?: Nullable<number>
) => {
  const fragmentId = `SectionSongsSort:${sectionId}`;

  const cachedSectionSort = cache.readFragment<TSort<SongsSortFields>>({
    id: fragmentId,
    fragment: SECTION_SONGS_SORT,
  });

  if (!!sectionId && !isEqual(sort, cachedSectionSort)) {
    const isCustomOrder = !sort;

    client.writeFragment({
      id: fragmentId,
      fragment: SECTION_SONGS_SORT,
      data: isCustomOrder
        ? {
            field: null,
          }
        : { ...sort, songsCount, __typename: 'SectionSongsSort' },
    });
  }
};
