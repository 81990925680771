import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  flag: {
    margin: '0 8px',

    '@media screen and (min-width: 1500px)': {
      margin: '0 12px',
    },
  },

  isFlagged: {
    '& svg path': {
      fill: theme.palette.error.main,
    },
  },
}));

export default useStyles;
