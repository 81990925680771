import React from 'react';
import { Redirect } from 'react-router';
import { useReactiveVar } from '@apollo/client';

import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';
import { BeatsourceContextProvider } from 'components/context/BeatsourceContext';

import { isLoggedInVar } from 'graphql/cache';

const AuthRequired = (props: any) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return isLoggedIn ? (
    <ModalWindowContextProvider>
      <BeatsourceContextProvider>{props.children}</BeatsourceContextProvider>
    </ModalWindowContextProvider>
  ) : (
    <Redirect to="/login" />
  );
};

export default AuthRequired;
