import React, { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';

import Scrollbar from 'vibo-ui/Scrollbar';
import NotesContent from './content/NotesContent';
import WithEventRole from 'components/user/WithEventRole';
import PlaylistsContent from './content/PlaylistsContent';
import EventInfoContent from './content/EventInfoContent';
import TimelineLayoutContent from './content/TimelineLayoutContent';
import SectionDetailsContent from './content/SectionDetailsContent';
import PageContentHeader from 'components/common/PageContentHeader';
import DownloadPdfButton from 'components/buttons/DownloadPdfButton';

import { EventUserType } from 'types/enums';

import useStyles from './style';

const PdfContent: FC = () => {
  const classes = useStyles();

  const { values } = useFormikContext();

  return (
    <div className={classes.content}>
      <PageContentHeader className={classes.pdfContentHeader}>
        <DownloadPdfButton />
      </PageContentHeader>
      <Scrollbar id="pdf-blocks-scroll">
        {isEmpty(values) ? null : (
          <>
            <EventInfoContent />
            <WithEventRole roles={[EventUserType.dj]}>
              <NotesContent />
            </WithEventRole>
            <TimelineLayoutContent />
            <SectionDetailsContent />
            <PlaylistsContent />
          </>
        )}
      </Scrollbar>
    </div>
  );
};

export default PdfContent;
