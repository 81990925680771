import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import Spinner from 'vibo-ui/Spinner';

import { isLoadingVar } from 'graphql/cache';

import useStyles from './style';

const SavingLoadingDataIndicator: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);

  const isLoading = useReactiveVar(isLoadingVar);

  const cancelLoading = useCallback(
    debounce(() => setIsActive(false), 600),
    [isActive]
  );

  const startLoading = useCallback(
    debounce(() => setIsActive(true), 200),
    [isActive]
  );

  useEffect(() => {
    if (isLoading) {
      startLoading();
    } else {
      cancelLoading();
    }

    return () => {
      cancelLoading.cancel();
      startLoading.cancel();
    };
  }, [isLoading]);

  return isActive ? (
    <div className={classNames('savingLoadingDataIndicator', classes.savingLoadingDataIndicator)}>
      <Spinner size={12} />
      <span className="text">{t('loading')}</span>
    </div>
  ) : null;
};

export default memo(SavingLoadingDataIndicator);
