import pick from 'lodash/pick';

import { EventUserType } from 'types/enums';

const ALLOWED_FIELDS_UPDATE_HOST = ['eventColor', 'image'];

export const getPreparedEventPayload = (payload: UpdateEventForm, role: EventUserType) => {
  switch (role) {
    case EventUserType.dj:
      return payload;
    case EventUserType.host:
      return pick(payload, ALLOWED_FIELDS_UPDATE_HOST);
    default:
      return {};
  }
};
