import { gql } from '@apollo/client';

import { SONG_FILE, SONG_LINKS, THUMBNAILS } from '../fragments/songs';

export const GET_PREP_MODE_SECTIONS = gql`
  query getPrepModeSections($eventId: ID!, $computerId: ID!, $filter: SectionsFilterInput) {
    getPrepModeSections(eventId: $eventId, computerId: $computerId, filter: $filter) {
      _id
      name
      type
      settings {
        songsLimit
        visibleForGuests
        visibleForHosts
        songsEnabled
      }
      songsCount
      preparedSongsCount
      isDone
      wasSongsReordered
      coverSong {
        artist
        title
        thumbnails {
          ...Thumbnails
        }
      }
      selectedFilesCount
      selectedFilesForFlaggedSongsCount
    }
  }
  ${THUMBNAILS}
`;

export const GET_PREP_MODE_SONGS = gql`
  query getPrepModeSongs(
    $eventId: ID!
    $sectionId: ID!
    $computerId: ID!
    $filter: PrepModeSongsFilter
    $pagination: PaginationInput
    $sort: SongsSortInput
  ) {
    getPrepModeSongs(
      eventId: $eventId
      sectionId: $sectionId
      computerId: $computerId
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      songs {
        _id
        viboSongId
        artist
        title
        thumbnails {
          ...Thumbnails
        }
        isFlagged
        likesCount
        links {
          ...SongLinks
        }
        files {
          ...SongFile
        }
      }
      next {
        limit
        skip
      }
      totalCount
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
  ${SONG_FILE}
`;
