import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Scrollbar from 'vibo-ui/Scrollbar';
import Header from 'components/common/Header';
import AccountSettings from './AccountSettings';
import GeneralSettings from './GeneralSettings';
import WithDjType from 'components/user/WithDjType';
import Container from '../../components/common/Container';
import StripeButton from 'components/buttons/StripeButton';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';

import { useMe } from 'graphql/hooks/user';

import { DjType } from 'types/enums';

import useStyles from './style';

const SettingsPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { user, isAmbassador } = useMe();

  const isPaymentVisible = useMemo(() => !!user?.djSettings?.stripeId && !isAmbassador, [
    isAmbassador,
    user?.djSettings?.stripeId,
  ]);

  return (
    <div className={classes.settingsPage}>
      <Header />
      <Container className={classes.container}>
        <Scrollbar>
          <PageContentHeader>
            <EventTitle name={t('settings')} />
            <WithDjType types={[DjType.single]} extraCondition={isPaymentVisible}>
              <StripeButton />
            </WithDjType>
          </PageContentHeader>
          <div className={classes.content}>
            <GeneralSettings />
            <AccountSettings />
          </div>
        </Scrollbar>
      </Container>
    </div>
  );
};

export default SettingsPage;
