import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboDatePicker: {
    '&.react-datepicker': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
    },

    '& .react-datepicker__navigation': {
      '& > span:before': {
        borderColor: theme.palette.primary.main,
      },

      '&:hover': {
        '& > span:before': {
          borderColor: theme.palette.action.hover,
        },
      },
    },

    '& .react-datepicker__day-names > .react-datepicker__day-name': {
      userSelect: 'none',
      color: theme.palette.primary.light,
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },

    '& .react-datepicker__month-container': {
      '& .react-datepicker__header': {
        border: 'none',
        background: 'transparent',
        padding: 0,
      },

      '& > .react-datepicker__month': {
        minHeight: '14rem',

        '& .react-datepicker__day': {
          userSelect: 'none',
          borderRadius: '100%',
          color: theme.palette.text.primary,

          '&.react-datepicker__day--outside-month': {
            color: theme.palette.text.hint,
          },

          '&.react-datepicker__day--today': {
            color: theme.palette.primary.main,
          },

          '&.react-datepicker__day--selected': {
            color: colors.white,
          },

          '&.react-datepicker__day--disabled': {
            color: theme.palette.text.disabled,
            opacity: '0.6',
          },

          '&:not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):hover': {
            color: theme.palette.text.hint,
            backgroundColor: theme.palette.primary.lighty,
          },

          '&.react-datepicker__day--keyboard-selected': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
          },
        },

        '@media screen and (min-height: 551px)': {
          minHeight: '20rem',
        },
      },
    },

    '& .react-datepicker__children-container': {
      margin: 0,
      padding: 0,
      width: '100%',
    },
  },

  customInput: {
    paddingLeft: '0',
    backgroundColor: 'transparent',
  },

  customInputWrapper: {
    '& .iconPrefix': {
      left: '0',
      pointerEvents: 'none',
    },
  },

  customHeader: {
    borderRadius: '4px 4px 0 0',
    overflow: 'hidden',
    fontSize: '1rem',

    '& .head': {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      height: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',

      '@media screen and (min-height: 551px)': {
        height: '36px',
      },
    },

    '& .controls': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 8px',
      color: theme.palette.text.primary,

      '@media screen and (min-height: 551px)': {
        padding: '10px 16px',
      },
    },

    '& .date': {
      cursor: 'pointer',
    },
  },

  clearButton: {
    zIndex: 2,
    display: 'flex',
    height: 'fit-content',
    top: '50%',
    transform: 'translateY(-50%)',

    '&:after': {
      width: '14px',
      height: '14px',
      fontSize: '13px',
      lineHeight: '14px',
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      color: theme.palette.selection.whiteToBalticSeaDark,
    },
  },

  footer: {
    padding: '0 8px 8px 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '9px',

    '& > .viboButton': {
      padding: '0 12px',
      fontSize: '13px',
      width: '100%',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },
  },

  monthsContainer: {
    width: '260px',
  },

  months: {
    padding: '16px',
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '15rem',
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(4, 36px)',
    gap: '9px',

    '& .viboButton': {
      width: '100%',

      alignSelf: 'start',
      justifySelf: 'start',
    },

    '@media screen and (min-height: 551px)': {
      height: '21rem',
    },
  },

  '@global': {
    '.react-datepicker__portal': {
      zIndex: 99,
    },

    '.react-datepicker__input-container': {
      display: 'flex!important',
      flexDirection: 'row',
      alignItems: 'center',

      '& > .viboIcon': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export default useStyles;
