import React, { FC, useMemo, useCallback } from 'react';
import uniqBy from 'lodash/uniqBy';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import List from 'vibo-ui/List';
import Button from 'vibo-ui/Button';
import Notification from './Notification';
import Scrollbar from 'vibo-ui/Scrollbar';
import NoNotifications from 'components/emptyStates/NoNotifications';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';

import { onError } from 'graphql/helpers';
import { NOTIFICATIONS_PAGINATION } from './contstants';

import { GET_NOTIFICATIONS } from 'graphql/queries/notifications';

import { GetNotificationsResponse } from './interface';

import useStyles from './style';

const Notifications: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading, fetchMore } = useQuery<GetNotificationsResponse>(GET_NOTIFICATIONS, {
    variables: {
      pagination: NOTIFICATIONS_PAGINATION,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError,
  });
  const notifications = data?.getNotifications?.notifications || [];

  const totalCount = useMemo(() => data?.getNotifications.totalCount || 0, [
    data?.getNotifications.totalCount,
  ]);

  const loadNextCount = useMemo(() => {
    if (!!data?.getNotifications.next) {
      const currentDifference = totalCount - notifications?.length;

      const nextCountToLoad =
        currentDifference > NOTIFICATIONS_PAGINATION.limit
          ? NOTIFICATIONS_PAGINATION.limit
          : currentDifference;

      return nextCountToLoad;
    }

    return null;
  }, [data?.getNotifications?.next, totalCount]);

  const handleLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        pagination: data?.getNotifications.next,
      },
      updateQuery: (prev: GetNotificationsResponse, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const newNotifications = uniqBy(
          [
            ...prev.getNotifications.notifications,
            ...fetchMoreResult.getNotifications.notifications,
          ],
          '_id'
        );

        return {
          getNotifications: {
            ...prev.getNotifications,
            notifications: newNotifications,
            next: fetchMoreResult.getNotifications.next,
          },
        };
      },
    });
  }, [data?.getNotifications.next?.skip]);

  return (
    <>
      {!totalCount ? (
        <NoNotifications />
      ) : (
        <>
          <Scrollbar
            className={classNames(classes.scrollbar, {
              withContent: !!totalCount,
            })}
            shiftThumbStyle="inset"
            alwaysShowThumb
          >
            <List
              renderItem={notification => (
                <Notification {...notification} key={`notification-${notification._id}`} />
              )}
              data={notifications}
              loading={loading}
            />
          </Scrollbar>
          {!!loadNextCount ? (
            <div className={classes.notificationsFooter}>
              <StopPropagationWrapper>
                <Button onClick={handleLoadMore} loading={loading} displayType="link">
                  {t('showMoreAmount', { count: loadNextCount })}
                </Button>
              </StopPropagationWrapper>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default Notifications;
