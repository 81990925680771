import React, { FC, useState, useEffect, useContext, useMemo } from 'react';
import classNames from 'classnames';
import usePrevious from 'react-use-previous-hook';
import { useParams } from 'react-router';

import Button from 'vibo-ui/Button';
import SectionBlockHeader from './SectionBlockHeader';
import ContentArea from 'components/common/ContentArea';
import SectionBlockContent from './SectionBlockContent';
import { EventContext } from 'components/context/EventContext';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionBlockProps } from './interfaces';

import useStyles from './style';

const SectionBlock: FC<SectionBlockProps> = ({
  className,
  onCollapse,
  renderContent,
  renderHeader,
  controlRef,
  preventCollapse = false,
  defaultCollapsed = true,
  canCollapse = true,
  defaultShown = false,
  type,
}) => {
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState<boolean>(defaultCollapsed);
  const [isShown, setIsShown] = useState<boolean>(defaultShown);

  const { isEventGuest } = useContext(EventContext);

  const { sectionId } = useParams<EventPageRouteParams>();
  const prevSectionId = usePrevious(sectionId);

  const toggleCollapse = () => {
    if (canCollapse && !preventCollapse) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const toggleShow = () => setIsShown(!isShown);

  const CaretButton = useMemo(
    () => (
      <Button
        prefixIcon={IconmoonFont[isCollapsed ? 'caretDown-16' : 'caretUp-16']}
        className={classNames('caret', classes.caret)}
        displayType="link"
        data-gtm-target-id={`timeline-section-details-collapse-${type}`}
      />
    ),
    [isCollapsed]
  );

  const renderContentProps = {
    toggleCollapse,
    toggleShow,
    isCollapsed,
    isShown,
    caretButton: CaretButton,
  };

  useEffect(() => {
    setIsShown(defaultShown);
    setIsCollapsed(defaultCollapsed);
  }, []);

  useEffect(() => {
    if (defaultShown) {
      setIsCollapsed(false);
    }
  }, [defaultShown]);

  useEffect(() => {
    if (isCollapsed) {
      onCollapse?.();
    }
  }, [isCollapsed]);

  useEffect(() => {
    controlRef?.({
      isCollapsed,
      isShown,
      setIsCollapsed,
      setIsShown,
    });
  }, [isCollapsed, isShown]);

  useEffect(() => {
    if (!canCollapse) {
      setIsCollapsed(true);
    }
  }, [canCollapse]);

  useEffect(() => {
    if (prevSectionId && prevSectionId !== sectionId) {
      setIsShown(defaultShown);
    }
  }, [sectionId, prevSectionId, defaultShown]);

  return (
    <div
      className={classNames(
        'sectionBlock',
        isCollapsed ? 'collapsed' : 'expanded',
        classes.sectionBlock,
        className,
        { isEventGuest }
      )}
    >
      <SectionBlockHeader handleClick={toggleCollapse} key={`section-block-header-${sectionId}`}>
        {renderHeader?.(renderContentProps)}
      </SectionBlockHeader>
      {isCollapsed ? null : (
        <ContentArea shadowed>
          {!canCollapse ? null : (
            <SectionBlockContent isShown={isShown} toggleShow={toggleShow}>
              {renderContent?.(renderContentProps)}
            </SectionBlockContent>
          )}
        </ContentArea>
      )}
    </div>
  );
};

export default SectionBlock;
