import React, { FC, useContext } from 'react';

import { ViboThemeContext } from 'components/context/ViboThemeContext';

import { AppColoredBoxProps } from './interfaces';

import useStyles from './style';

const AppColoredBox: FC<AppColoredBoxProps> = ({ defaultColor }) => {
  const classes = useStyles();

  const { appColor } = useContext(ViboThemeContext);

  return (
    <div
      className={classes.colorBox}
      style={{
        backgroundColor: defaultColor || appColor,
      }}
    />
  );
};

export default AppColoredBox;
