import React, { FC, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Dropdown from 'vibo-ui/Dropdown';
import IconButton from 'components/buttons/IconButton';
import WithEventRole from 'components/user/WithEventRole';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import { EventContext } from 'components/context/EventContext';

import { onError } from 'graphql/helpers';
import { useModal } from 'vibo-ui/Modal';
import { openErrorNotification, toastNotify } from 'graphql/hooks/common';
import { isDontPlaySection, isHeadlineSection } from 'services/sections/helpers';

import { CHECK_FAVORITE_SECTION_EXISTS } from 'graphql/mutations/sections';

import { IconmoonFont } from 'vibo-ui/Icon';
import { CloneModalProps } from 'components/modals/CloneModal';
import { ButtonIconType, EventUserType, Modals } from 'types/enums';
import { SectionToolsProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const SectionTools: FC<SectionToolsProps> = ({ section, onEditClick, onDeleteClick }) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    event,
    isEventDj,
    isEventHost,
    isTemplate,
    mutations,
    timelineRoute,
    isFavoriteSections,
    showSectionInfo = false,
    canAddSection = false,
    canEditSection = false,
  } = useContext(EventContext);

  const { push } = useHistory();

  const { openedModals, openModal } = useModal();

  const isHeadline = useMemo(() => isHeadlineSection(section), [section.type, section._id]);
  const isDontPlay = useMemo(() => isDontPlaySection(section), [section.type, section._id]);

  const hasDeleteIcon: boolean = useMemo(
    () =>
      !isDontPlay &&
      canEditSection &&
      (isEventHost ? section.settings.canHostDeleteSection : showSectionInfo),
    [
      isDontPlay,
      canEditSection,
      isEventHost,
      showSectionInfo,
      section.settings.canHostDeleteSection,
    ]
  );

  const [cloneSection, { loading: isCloning }] = useMutation(mutations.cloneSection, {
    onCompleted: cloneResponse => {
      const newSection = Object.values(cloneResponse)[0] as Maybe<TCloneEventSection>;

      toastNotify({
        text: t('sectionClonedSuccessfully'),
      });

      if (!!newSection) {
        push(`${timelineRoute}/${newSection._id}`);
      }
    },
    refetchQueries: [
      isFavoriteSections ? 'getFavoriteSections' : isTemplate ? 'templateSections' : 'sections',
    ],
    onError,
  });
  const [checkFavoriteSectionNameAlreadyExists, { loading: isCheckFavNameLoading }] = useMutation<
    TCheckFavSectionExistsResponse
  >(CHECK_FAVORITE_SECTION_EXISTS, {
    onCompleted: ({ checkFavoriteSectionNameAlreadyExists }) => {
      const { isExists, name } = checkFavoriteSectionNameAlreadyExists;

      if (isExists) {
        openErrorNotification(t('favoriteSectionNameAlreadyExists'));
      } else {
        addSectionToFavorite({
          variables: {
            [isTemplate ? 'templateId' : 'eventId']: event?._id,
            sectionId: section._id,
            name,
          },
        });
      }
    },
    onError,
  });
  const [addSectionToFavorite, { loading: isAddingFavSectionLoading }] = useMutation(
    mutations.addSectionToFavorite,
    {
      onCompleted: () => {
        toastNotify({
          text: t('sectionAddedToFavorites'),
        });
      },
      onError,
    }
  );

  const openCloneSectionModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: section.name,
          },
          onSubmit: payload =>
            cloneSection({
              variables: isFavoriteSections
                ? {
                    favoriteSectionId: section._id,
                    name: payload.title,
                  }
                : {
                    [isTemplate ? 'templateId' : 'eventId']: event?._id,
                    sectionId: section._id,
                    name: payload.title,
                  },
            }),
          title: t('cloneSection'),
          label: t('sectionTitle'),
          closeOnSubmit: true,
        },
      }),
    [section._id, section.name, isTemplate, event?._id, isFavoriteSections, openedModals]
  );
  const openCloneFavoriteModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: section.name,
          },
          onSubmit: payload =>
            checkFavoriteSectionNameAlreadyExists({
              variables: {
                name: payload.title,
              },
            }),
          title: t('addToFavorites'),
          label: t('addToFavorites'),
          closeOnSubmit: true,
        },
      }),
    [openedModals]
  );

  return (
    <WithEventRole
      roles={[EventUserType.dj, EventUserType.host]}
      extraCondition={(isEventHost && !isDontPlay) || isEventDj}
    >
      <StopPropagationWrapper>
        <Dropdown
          overlay={
            <Menu selectable={false} className={classes.sectionToolsMenu}>
              {!!onEditClick && canEditSection && showSectionInfo ? (
                <Menu.Item
                  onClick={() => onEditClick(section)}
                  icon={IconmoonFont['settings-16']}
                  data-gtm-target-id="timeline-section-settings"
                  key="sectionSettings"
                >
                  {t(isHeadline ? 'headlineSettings' : 'sectionSettings')}
                </Menu.Item>
              ) : null}
              {isEventDj && !isHeadline && !isDontPlay && canAddSection ? (
                <>
                  <Menu.Item
                    onClick={openCloneSectionModal}
                    disabled={isCloning}
                    icon={IconmoonFont['clone-16']}
                    data-gtm-target-id="timeline-clone-section"
                    key="cloneSection"
                  >
                    {t('cloneSection')}
                  </Menu.Item>
                  {isFavoriteSections ? null : (
                    <Menu.Item
                      onClick={openCloneFavoriteModal}
                      disabled={isAddingFavSectionLoading || isCheckFavNameLoading}
                      icon={IconmoonFont['star-16']}
                      data-gtm-target-id="timeline-add-section-to-favorite"
                      key="addToFavorites"
                    >
                      {t('addToFavorites')}
                    </Menu.Item>
                  )}
                </>
              ) : null}
              {hasDeleteIcon ? (
                <Menu.Item
                  onClick={() => onDeleteClick(section)}
                  icon={IconmoonFont['delete-16']}
                  key="deleteSection"
                >
                  {t(isHeadline ? 'deleteHeadline' : 'deleteSection')}
                </Menu.Item>
              ) : null}
            </Menu>
          }
          trigger="click"
          overlayClassName={classes.sectionToolsOverlay}
          toggleClassName="threeDotsIcon"
          disableToggleClass
        >
          <IconButton
            className={classNames(decorClasses.highlighted, classes.triggerIcon)}
            type={ButtonIconType.threeDots}
          />
        </Dropdown>
      </StopPropagationWrapper>
    </WithEventRole>
  );
};

export default SectionTools;
