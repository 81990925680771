import { gql } from '@apollo/client';

export const SONG_IDEAS_RESPONSE = gql`
  fragment SongIdeasResponseFragment on SongIdeasResponse {
    songIdeas {
      _id
      title
      songsCount
      creatorId
      isOwner
      isPublic
      description
      coverPhoto {
        cropped {
          medium
          small
        }
      }
    }
    next {
      skip
      limit
    }
    totalCount
  }
`;

export const SONG_IDEAS_DETAILS = gql`
  fragment SongIdeasFragment on SongIdeas {
    title
    description
    coverPhoto {
      url
    }
  }
`;

export const SONG_IDEAS_PREVIEW = gql`
  fragment SongIdeasTitle on SongIdeasPreview {
    title
  }
`;

export const IS_IN_SONG_IDEAS = gql`
  fragment IsInSongIdeas on SearchedSongForSongIdeas {
    isInSongIdeas
  }
`;
