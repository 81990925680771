import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CellName from 'components/common/CellName';
import WithEventRole from 'components/user/WithEventRole';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';
import { ModalWindowsContext } from 'vibo-ui/Modal/ModalWindowContext';

import { useModal } from 'vibo-ui/Modal';
import { useMe } from 'graphql/hooks/user';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SendEventInviteModalProps } from 'components/modals/SendEventInviteModal';
import { EditEventContactModalProps } from 'components/modals/EditEventContactModal';
import { RemoveEventContactModalProps } from 'components/modals/RemoveEventContactModal';
import { EventContactStatus, EventUserType, Modals } from 'types/enums';
import { ContactControlsProps } from '../interfaces';

import useStyles from '../style';

const ContactControls: FC<ContactControlsProps> = ({ contact }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { event } = useContext(EventContext);
  const { openedModals } = useContext(ModalWindowsContext);

  const { user } = useMe();

  const { openModal } = useModal();

  const openEditEventContactModal = useCallback(() => {
    openModal<EditEventContactModalProps>({
      key: Modals.editEventContact,
      props: {
        contactId: contact._id,
        values: {
          ...contact,
        },
      },
    });
  }, [contact, openedModals.length]);
  const openLeaveEventModal = useCallback(
    () =>
      openModal({
        key: Modals.leaveEvent,
      }),
    [openedModals.length]
  );
  const openRemoveEventUserModal = useCallback(
    () =>
      openModal<RemoveEventContactModalProps>({
        key: Modals.removeEventContact,
        props: {
          contact,
        },
      }),
    [contact, openedModals.length]
  );
  const openSendEventInviteModal = useCallback(
    () =>
      !!event?._id &&
      openModal<SendEventInviteModalProps>({
        key: Modals.sendEventInvite,
        props: {
          eventId: event._id,
          values: {
            role: contact.role,
            email: contact.email,
            emailMessage: event?.emailMessage,
          },
        },
      }),
    [event?._id, contact.role, contact.email, event?.emailMessage, openedModals.length]
  );

  const isJoined = contact.status === EventContactStatus.joined;
  const isMe = contact.email === user?.email;

  return (
    <CellName name="controls" className={classes.controls}>
      {isJoined ? null : (
        <WithEventRole roles={[EventUserType.dj]}>
          <>
            <Button
              onClick={openSendEventInviteModal}
              prefixIcon={IconmoonFont['send-24']}
              className={ButtonDecor.primaryColor}
              displayType="link"
            >
              {t('inviteByEmail')}
            </Button>
            <Button
              onClick={openEditEventContactModal}
              prefixIcon={IconmoonFont['editPencil-24']}
              className={ButtonDecor.primaryColor}
              displayType="link"
            >
              {t('editContact')}
            </Button>
          </>
        </WithEventRole>
      )}
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]} extraCondition={!isMe}>
        <Button
          onClick={openRemoveEventUserModal}
          prefixIcon={IconmoonFont['close-16']}
          className={ButtonDecor.primaryColor}
          displayType="link"
        >
          {t('removeContact')}
        </Button>
      </WithEventRole>
      <WithEventRole roles={[EventUserType.host, EventUserType.guest]} extraCondition={isMe}>
        <Button
          onClick={openLeaveEventModal}
          prefixIcon={IconmoonFont['close-16']}
          className={ButtonDecor.primaryColor}
          displayType="link"
        >
          {t('leaveEvent')}
        </Button>
      </WithEventRole>
    </CellName>
  );
};

export default ContactControls;
