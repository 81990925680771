import React, { useState, ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';
import compact from 'lodash/compact';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Textarea from 'vibo-ui/Textarea';
import ContentArea from 'components/common/ContentArea';

import { toastNotify } from 'graphql/hooks/common';
import { updateSongComment } from 'graphql/cache/songs';
import { MAX_SONG_COMMENT_LENGTH } from 'services/constants';

import { UPDATE_SECTION_SONGS } from 'graphql/mutations/songs';

import { ContentProps } from './interfaces';

import useStyles from './style';

const Content = <T extends SectionSong>({ song, handleClose }: ContentProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [text, setText] = useState<string>('');

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const [updateSong] = useMutation<UpdateSectionSongsData, UpdateSectionSongsVariables>(
    UPDATE_SECTION_SONGS,
    {
      onCompleted: () => {
        toastNotify({ text: t('songCommentSaved') });
      },
      refetchQueries: compact([(!song?.comment || !text) && 'getSectionSongsStats']),
    }
  );

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleSubmit = () => {
    if (song && text !== song.comment) {
      updateSongComment(song._id, text as string);
      updateSong({
        variables: {
          eventId,
          sectionId,
          payload: {
            comment: text as string,
          },
          songIds: [song._id],
        },
      });
    }
    handleClose?.();
  };

  useEffect(() => {
    if (song) {
      setText(song.comment ?? '');
    }
  }, [song?.comment]);

  return (
    <ContentArea className={classNames('songComment', classes.songComment)} shadowed>
      <Textarea
        value={text}
        onChange={handleChange}
        maxLength={MAX_SONG_COMMENT_LENGTH}
        placeholder={t('commentAboutThisSong')}
        withUnderline
        showMaxLength
        autoFocus
      />
      <div className={classes.commentFooter}>
        <Button onClick={handleClose} displayType="bordered" shape="round">
          {t('close')}
        </Button>
        <Button onClick={handleSubmit} displayType="primary" shape="round">
          {t('save')}
        </Button>
      </div>
    </ContentArea>
  );
};

export default Content;
