import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import WithEventRole from 'components/user/WithEventRole';
import EventTitle from 'components/events/Timeline/EventTitle';
import { EventContext } from 'components/context/EventContext';

import { isDontPlaySection, isHeadlineSection } from 'services/sections/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType } from 'types/enums';
import { SectionBlocksHeaderProps } from './interfaces';

import useStyles from './style';

const SectionBlocksHeader: FC<SectionBlocksHeaderProps> = ({ onEditClick, section, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventDj, isEventHost, canEditSection = false } = useContext(EventContext);
  const isDontPlay = useMemo(() => isDontPlaySection(section), [section.type]);
  const isHeadline = useMemo(() => isHeadlineSection(section), [section.type]);

  return (
    <div
      {...rest}
      className={classNames('sectionBlocksHeader', classes.sectionBlocksHeader, rest.className)}
    >
      <div className={classNames('blocksHeaderTitle', classes.blocksHeaderTitle)}>
        <EventTitle name={section.name} />
      </div>
      <WithEventRole
        roles={[EventUserType.dj, EventUserType.host]}
        extraCondition={(isEventHost && !isDontPlay) || isEventDj}
      >
        {!!onEditClick && canEditSection ? (
          <Button
            onClick={onEditClick}
            tooltipProps={{
              overlay: t(isHeadline ? 'headlineSettings' : 'sectionSettings'),
              fillOverlay: true,
              placement: 'top',
            }}
            className="sectionSettingsBtn"
            prefixIcon={IconmoonFont['settings-16']}
            shape="round"
            size="lg"
            data-gtm-target-id="timeline-section-details-settings"
          />
        ) : null}
      </WithEventRole>
    </div>
  );
};

export default SectionBlocksHeader;
