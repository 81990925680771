import { createUseStyles } from 'react-jss';

import { inputsBySize } from 'resources/styles/inputs/style';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  textPreview: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...inputsBySize,

    '&.empty': {
      color: theme.palette.text.hint,
    },
  },
}));

export default useStyles;
