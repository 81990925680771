import React, { FC } from 'react';
import classNames from 'classnames';

import CreateSectionButton from 'components/buttons/CreateSectionButton';

import { ReactComponent as PlusCircleFilledIcon } from 'resources/img/svg/common/plusCircleFilled.svg';

import { AddSectionInMiddleProps } from './interfaces';

import useStyles from './style';

const AddSectionInMiddle: FC<AddSectionInMiddleProps> = ({ sectionId }) => {
  const classes = useStyles();

  return (
    <CreateSectionButton
      insertBeforeSectionId={sectionId}
      className={classNames('addInTheMiddleBtn', classes.addInTheMiddleBtn, {
        withId: !sectionId,
      })}
      prefixIcon={PlusCircleFilledIcon}
      displayType="link"
    />
  );
};

export default AddSectionInMiddle;
