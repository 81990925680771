import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  question: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 12px 20px 22px',
    position: 'relative',
    background: theme.palette.background.paper,
    borderRadius: 0,

    '& .ql-toolbar': {
      opacity: 0,
      margin: '0 0 0 4px',
    },

    '& .multiplyOption, & .questionTitle, & .textAnswer, & .locationAnswer': {
      '& .copyButton, & .ql-toolbar': {
        opacity: 0,
      },

      '&:hover': {
        '& .copyButton, & .ql-toolbar': {
          opacity: 1,
        },
      },
    },

    '&.textQuestion': {
      '& .textQuestionAnswer': {
        minHeight: '27px',
        display: 'block',
      },
    },

    '& .textQuestionAnswer': {
      borderBottom: `1px solid transparent`,
    },

    '& .textQuestionAnswer, & .viboTextarea': {
      fontSize: '13px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },

    '&.headerQuestion': {
      '& .questionHeader': {
        margin: '0',
      },

      '& .questionMediaPreview ': {
        margin: '16px 0 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '20px 0 0 0',
        },
      },

      '& .questionTitle': {
        transform: 'none',
      },
    },

    '& .dndIcon': {
      display: 'none',
      position: 'absolute',
      top: '50%',
      left: '4px',
      cursor: 'move',
      color: theme.palette.primary.main,
      transform: 'translateY(-50%)',
      zIndex: 101,

      '@media screen and (min-width: 1441px)': {
        left: '6px',
      },
    },

    '& .content': {
      '& .viboInput, & .viboTextarea': {
        '&.withBorder:not(:focus)': {
          border: '1px solid transparent',
        },
      },
    },

    '&:hover': {
      '& .tools': {
        opacity: 1,
      },

      '& .dndIcon': {
        display: 'block!important',
      },

      '& .content': {
        '& .viboInput, & .viboTextarea': {
          '&.withBorder:not(:focus)': {
            border: `1px solid ${theme.palette.text.hint}`,
          },
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '24px 12px 24px 28px',
    },
  },

  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& .textAnswer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .viboTextarea': {
        lineHeight: '28px',

        '@media screen and (min-width: 1441px)': {
          lineHeight: '34px',
        },
      },

      '& .limit': {
        bottom: '-2px',
      },
    },
  },
}));

export default useStyles;
