import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  eventGeneralLayout: {
    '& .row': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '&.dateLock': {
        '& .days': {
          color: theme.palette.text.hint,
          margin: '0 0 0 4px',
        },
      },

      '&.leave': {
        '& .viboButton': {
          padding: '0',
        },
      },

      '&.djs': {
        '& .djPreview': {
          cursor: 'pointer',
        },
      },

      '&.status': {
        '& .statusDot': {
          margin: '0 20px 0 6px',

          '@media screen and (min-width: 1441px)': {
            margin: '0 18px 0 6px',
          },
        },
      },

      '&.date': {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '27px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
          lineHeight: '28.34px',
        },
      },
    },
  },

  labels: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '6px',
    position: 'absolute',
    top: '20px',
    right: '20px',

    '@media screen and (min-width: 1441px)': {
      top: '24px',
      right: '24px',
    },
  },

  status: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '13px',
    lineHeight: '19.5px',

    '& .viboSelect__control': {
      height: '20px!important',

      '@media screen and (min-width: 1441px)': {
        height: '24px!important',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  progressInfoBlock: {
    margin: '20px 0',

    '& .progressRow': {
      '&:not(:last-child)': {
        margin: '0 0 14px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 16px 0',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      margin: '24px 0',
    },
  },

  eventType: {
    display: 'flex',

    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },

  location: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '& .viboToggleDropdown': {
      width: '100%',
    },
  },

  date: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .react-datepicker__input-container': {
      '& .viboInput': {
        fontWeight: 'bold',
      },

      '& > .viboIcon': {
        color: theme.palette.text.secondary,
        marginRight: '16px',

        '@media screen and (min-width: 1441px)': {
          marginRight: '12px',
        },
      },
    },
  },

  fieldIcon: {
    color: theme.palette.text.secondary,
    marginRight: '16px',

    '& > svg': {
      width: '20px',
      height: '20px',

      '& path': {
        fill: theme.palette.text.secondary,
      },

      '@media screen and (min-width: 1441px)': {
        width: '24px',
        height: '24px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      marginRight: '12px',
    },
  },

  daysBeforeLock: {
    display: 'flex',
    alignItems: 'center',

    '& span': {
      '&:last-child': {
        marginLeft: 'auto',
      },
    },
  },

  timePicker: {
    fontSize: '13px',
    fontWeight: 'bold',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  template: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .viboButton': {
      margin: '0 0 0 12px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 16px',
      },
    },
  },

  timeInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .time': {
      fontWeight: 700,
    },

    '& .timepicker': {
      lineHeight: '12px',
    },

    '& p': {
      margin: '0 9px 0 0',
    },
  },

  settingsCell: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: '16px',
  },

  numberInput: {
    width: 'fit-content',
    color: theme.palette.text.primary,

    '& > div': {
      width: '250px',
    },
  },

  switch: {
    color: theme.palette.text.primary,

    '& > div, & .label': {
      minWidth: '298px',
    },

    '& > :nth-child(2)': {
      marginRight: '8px',
    },
  },

  actionBtn: {
    height: '100%',

    '& svg': {
      marginRight: '8px',
    },
  },

  selectedDj: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .djPreview': {
      cursor: 'pointer',
    },

    '& .djImage': {
      borderRadius: '50%',
      width: '24px',
      minWidth: '24px',
      height: '24px',
      margin: '0 8px 0 0',
    },
  },

  progressRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .viboIcon': {
      color: theme.palette.primary.main,
    },
  },

  progressBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& *': { borderRadius: '2px' },
  },

  progressBarTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  progressBarInfo: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  progressBarLabel: {
    color: theme.palette.text.secondary,
  },

  eventProgress: {
    '&, & .progressBar': {
      width: '100%',
    },

    '& .progressBar': {
      borderRadius: '2px',
      backgroundColor: theme.palette.background.paper,
      height: '10px',
      border: `1px solid ${colors.frenchPass}`,

      '& .percentage': {
        display: 'none',
      },
    },
  },

  progressLow: {
    '& .fill': {
      backgroundColor: `${theme.palette.progress.low}!important`,
    },
  },

  progressMiddle: {
    '& .fill': {
      backgroundColor: `${theme.palette.progress.middle}!important`,
    },
  },

  progressFull: {
    '& .fill': {
      backgroundColor: `${theme.palette.progress.full}!important`,
    },
  },
}));

export default useStyles;
