import { colors } from 'resources/styles/colors';
import { DEFAULT_VIBO_COLOR_HUE } from 'components/context/ViboThemeContext/constants';

export const WAVE_CONFIG = {
  responsive: true,
  cursorWidth: 0,
  height: 40,
  barGap: 1,
  barWidth: 2,
};

export const VOLUME_STEP = 0.05;

export const HALF_VOLUME = 0.5;

export const MIN_VOLUME = 0;

export const MAX_VOLUME = 1;

export const handleVolumeUi = (
  target: Nullable<HTMLInputElement>,
  progress: number,
  color: string
) => {
  if (!!target) {
    const percentProgress = progress * 100;

    target.style.background = `linear-gradient(to right, ${color} ${percentProgress}%, ${colors.tropicalBlue(
      210
    )} ${percentProgress}%)`;
  }
};

export const normilizeVolume = (volume: number) => {
  if (volume > MAX_VOLUME) {
    return MAX_VOLUME;
  }

  if (volume < MIN_VOLUME) {
    return MIN_VOLUME;
  }

  return volume;
};

export const getWaveColor = (isDark: boolean) =>
  isDark ? colors.toryBlue(DEFAULT_VIBO_COLOR_HUE) : colors.hawkesBlue(DEFAULT_VIBO_COLOR_HUE);
