import React, { FC } from 'react';
import Tooltip from 'vibo-ui/Tooltip';
import { useTranslation } from 'react-i18next';

const PeopleColumn: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="label">
      <Tooltip overlay={t('peopleJoined')} type="primary" placement="top">
        <span>{t('people')}</span>
      </Tooltip>
    </div>
  );
};
export default PeopleColumn;
