import React, { useRef } from 'react';
import classNames from 'classnames';

import Copy from '../Copy';
import Flag from '../Flag';
import SongIndex from '../SongIndex';
import SongExtra from '../SongExtra';
import CommentPreview from '../CommentPreview';
import SongImage from 'components/image/SongImage';
import WithEventRole from 'components/user/WithEventRole';
import SongArtistNames from 'components/songs/SongArtistNames';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import SelectCheckbox from 'components/context/SelectListContext/SelectCheckbox';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { EventUserType } from 'types/enums';
import { TimelineSongProps } from './interfaces';

import useStyles from './style';

const TimelineSong = <T extends SectionSong>({
  onClick,
  song,
  index,
  className,
  ...rest
}: TimelineSongProps<T>) => {
  const classes = useStyles();

  const commentPopupRef = useRef<Nullable<HTMLDivElement>>(null);

  return (
    <div
      className={classNames(
        'song',
        {
          [classes.withComment]: !!song.comment,
        },
        classes.song,
        className
      )}
      onClick={() => onClick?.(song)}
      {...rest}
    >
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <StopPropagationWrapper>
          <Icon icon={IconmoonFont['dnd-16']} className="dndSongIcon" />
          <SelectCheckbox id={song._id} />
        </StopPropagationWrapper>
      </WithEventRole>
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <Flag song={song} />
      </WithEventRole>
      <SongImage song={song} className={classes.songImage} isLazy={false} />
      <SongIndex index={index + 1} className={classes.songIndex} />
      <SongArtistNames song={song} />
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <>
          <Copy song={song} data-gtm-target-id="timeline-section-details-songs-copy-song-title" />
          <CommentPreview song={song} commentPopupRef={commentPopupRef} />
        </>
      </WithEventRole>
      <SongExtra song={song} commentPopupRef={commentPopupRef} />
    </div>
  );
};

export default TimelineSong;
