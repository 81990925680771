import React, { FC, useContext, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import ProgressInfoBlock from './ProgressInfoBlock';
import EventImages from 'components/events/EventImages';
import FormInput from 'components/form/inputs/FormInput';
import WithEventRole from 'components/user/WithEventRole';
import EventGeneralDjView from './views/EventGeneralDjView';
import DjNotesButton from 'components/buttons/DjNotesButton';
import EventGeneralUserView from './views/EventGeneralUserView';
import PageContentHeader from 'components/common/PageContentHeader';
import DeleteEventButton from 'components/events/buttons/DeleteEventButton';
import RestoreEventButton from 'components/events/buttons/RestoreEventButton';
import EventGeneralInfoLayout from 'components/layouts/EventGeneralInfoLayout';
import SaveAsTemplateButton from 'components/events/buttons/SaveAsTemplateButton';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { useMe } from 'graphql/hooks/user';
import { useFormikAutoSave } from 'graphql/hooks/form';
import { MAX_EVENT_TITLE_LENGTH } from 'services/constants';
import { getUpcomingStatus } from 'services/events/helpers';

import { updateEventCache } from 'graphql/cache/events';
import { getPreparedEventPayload } from './constants';

import { UPDATE_EVENT } from 'graphql/mutations/events';

import { PickFilesModalProps } from 'components/modals/PickFilesModal';
import { DjType, EventType, EventUserType, Modals } from 'types/enums';
import { EventGeneralContentProps } from './interfaces';

const EventGeneralContent: FC<EventGeneralContentProps> = ({ event }) => {
  const { t } = useTranslation();

  const { isEventDj, isEventGuest = true, isHistory = false } = useContext(EventContext);

  const eventId = event?._id;

  const { user } = useMe();

  const { openModal } = useModal();

  const [updateEvent, { loading: updateEventLoading }] = useMutation(UPDATE_EVENT, {
    onCompleted: data => {
      updateEventCache(data);
    },
    onError,
  });

  const handleUpdateEventImage = (image: UpdateEventImageInput) =>
    updateEvent({
      variables: {
        eventId,
        payload: {
          image,
        },
      },
    });

  const { formik } = useFormikAutoSave<UpdateEventForm>(
    {
      initialValues: {
        title: event.title,
        date: event.date,
        daysCountBeforeLock: event.daysCountBeforeLock,
        settings: event.settings,
        statusId: getUpcomingStatus(event),
        arrivalTime: event.arrivalTime,
        startTime: event.startTime,
        endTime: event.endTime,
        timezone: event.timezone,
      },
      validationSchema: Yup.object().shape({
        title: Yup.string()
          .max(
            MAX_EVENT_TITLE_LENGTH,
            t('eventIitleShouldBeCharactersMax', { maxLength: MAX_EVENT_TITLE_LENGTH })
          )
          .trim()
          .required(t('provideEventTitle')),
      }),
      onSubmit: payload => {
        const preparedEventPayload = getPreparedEventPayload(payload, event.role as EventUserType);

        updateEvent({
          variables: {
            eventId,
            payload: preparedEventPayload,
          },
        });
      },
    },
    3000
  );

  const defaultViewProps = {
    isHistory,
    isEventGuest,
    updateEventLoading,
    event,
    user,
  };

  const canEditEventPhoto = useMemo(
    () =>
      !isHistory &&
      !isEventGuest &&
      (isEventDj || (!!event.settings?.canHostChangeEventCoverPhoto && !event.isLocked)),
    [
      isHistory,
      isEventGuest,
      isEventDj,
      event.settings?.canHostChangeEventCoverPhoto,
      event.isLocked,
    ]
  );

  useEffect(() => {
    formik.setFieldValue('settings', event.settings);
  }, [event.settings]);

  return (
    <FormikProvider value={formik}>
      <EventGeneralInfoLayout
        titleInput={
          <PageContentHeader
            extra={
              <>
                <WithEventRole roles={[EventUserType.dj]}>
                  <DjNotesButton />
                </WithEventRole>
              </>
            }
          >
            <FormInput
              disabled={isHistory || !isEventDj}
              maxLength={MAX_EVENT_TITLE_LENGTH}
              name="title"
              className="bold"
              size="xlg"
              withUnderline
              showMaxLength
            />
          </PageContentHeader>
        }
        contentLeft={
          <>
            <h3>{t('eventPhoto')}</h3>
            <EventImages
              onClick={
                canEditEventPhoto
                  ? () => {
                      openModal<PickFilesModalProps>({
                        key: Modals.pickImage,
                        props: {
                          fileSrc: event?.image?.squareUrl,
                          title: t('changePhoto'),
                          onChange: async (_, square) => {
                            if (!!square) {
                              handleUpdateEventImage({
                                square,
                              } as UpdateEventImageInput);
                            } else {
                              handleUpdateEventImage({ squareUrl: '' });
                            }
                          },
                        },
                      });
                    }
                  : undefined
              }
              id={event._id}
              title={event.title}
              type={event.type as EventType}
              squareUrl={event?.image?.cropped?.medium || event?.image?.squareUrl}
              isUpdating={updateEventLoading}
            />
            <WithEventRole roles={[EventUserType.dj]}>
              <>
                <ProgressInfoBlock />
                <SaveAsTemplateButton event={event} />
                {isHistory && <RestoreEventButton event={event} />}
                {user?.djSettings?.type === DjType.child && isHistory ? null : (
                  <DeleteEventButton event={event} />
                )}
              </>
            </WithEventRole>
            <WithEventRole roles={[EventUserType.host]}>
              <ProgressInfoBlock />
            </WithEventRole>
          </>
        }
        contentRight={
          <>
            <WithEventRole roles={[EventUserType.dj]}>
              <EventGeneralDjView {...defaultViewProps} />
            </WithEventRole>
            <WithEventRole roles={[EventUserType.host, EventUserType.guest]}>
              <EventGeneralUserView {...defaultViewProps} />
            </WithEventRole>
          </>
        }
      />
    </FormikProvider>
  );
};

export default EventGeneralContent;
