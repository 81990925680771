import React, { FC, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Image from 'vibo-ui/Image';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { PickFilesModalProps } from 'components/modals/PickFilesModal';
import { ImagePreviewModalProps } from 'components/modals/ImagePreviewModal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';

import useStyles from '../../common/style';

const ImageAnswer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { canAnswer } = useContext(QuestionContext);

  const { openModal, closeModal } = useModal();

  const imageUrl = useMemo(() => formik.values.answer?.images?.[0]?.imageUrl, [
    formik.values.answer?.images?.[0]?.imageUrl,
  ]);

  const openPickImageModal = () =>
    openModal<PickFilesModalProps>({
      key: Modals.pickImage,
      props: {
        title: t('addImage'),
        onChange: async (result, square) => {
          if (!!result) {
            formik.setFieldValue('answer.images[0]', square);
            closePickImageModal();
          }
        },
      },
    });
  const openImagePreviewModal = useCallback(
    () =>
      imageUrl &&
      openModal<ImagePreviewModalProps>({
        key: Modals.imagePreview,
        props: {
          imageUrl,
        },
      }),
    [imageUrl]
  );
  const openConfirmDeleteModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('deleteAnswer'),
        children: t('confirmDeleteAnswer'),
        submit: {
          onClick: handleRemoveImage,
          text: t('delete'),
        },
      },
    });
  const closePickImageModal = useCallback(() => closeModal(Modals.pickImage), []);

  const handleRemoveImage = useCallback(() => {
    formik.setFieldValue('answer.images', []);
  }, []);

  return (
    <div
      className={classNames('imageAnswer', classes.fileAnswer, {
        withAnswer: !!imageUrl,
      })}
    >
      {!!imageUrl ? (
        <>
          <div className="label">
            <Icon icon={IconmoonFont['image-16']} />
            {t('imageAnswer')}
            <Button
              onClick={openConfirmDeleteModal}
              prefixIcon={IconmoonFont['delete-16']}
              className={ButtonDecor.danger}
              displayType="link"
            />
          </div>
          <Image onClick={openImagePreviewModal} src={imageUrl} />
        </>
      ) : (
        <Button
          onClick={openPickImageModal}
          disabled={!canAnswer}
          prefixIcon={IconmoonFont['image-16']}
          displayType="bordered"
          shape="round"
        >
          {t('addImage')}
        </Button>
      )}
    </div>
  );
};

export default ImageAnswer;
