import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  like: {
    width: '37px',

    '& .likeContent': {
      maxWidth: 'none',
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '2px',

      //for gtm tracking propper target
      '& *': {
        pointerEvents: 'none',
      },
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.background.paper,
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '44px',
    },
  },

  likedByMe: {
    color: theme.palette.error.light,
  },

  defaultLiked: {
    color: theme.palette.text.hint,
  },

  likesCount: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
}));

export default useStyles;
