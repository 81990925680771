import React, { FC, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import classNames from 'classnames';
import findIndex from 'lodash/findIndex';
import { useTranslation } from 'react-i18next';
import { OperationVariables, useQuery } from '@apollo/client';

import Modal from 'vibo-ui/Modal';
import Tooltip from 'vibo-ui/Tooltip';
import NoSongs from 'components/emptyStates/NoSongs';
import PlaylistSongHeader from './PlaylistSongHeader';
import TimelineSongModalHeader from './TimelineSongModalHeader';
import SongPlayerIframe from 'components/songs/SongLinks/SongPlayerIframe';
import SongLinksWithMetadata from 'components/songs/SongLinks/SongLinksWithMetadata';
import YoutubePlayerPlaceholder from 'components/Placeholders/YoutubePlayerPlaceholder';

import { usePrevious } from 'services/common/dataProcessingHelpers';

import { Modals } from 'types/enums';
import { SongModalProps } from './interfaces';

import useStyles from './style';

const SongModal: FC<SongModalProps> = ({
  removeSong,
  onPlaySong,
  renderCustomButton,
  songId,
  sectionName,
  setSong,
  variables,
  skip,
  query,
  queryKey,
  canEdit = true,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentSong, setCurrentSong] = useState<Nullable<SectionSong>>(null);
  const [songIndex, setSongIndex] = useState<number>(0);

  const extendedVariables = variables as OperationVariables;

  const { data, loading } = useQuery<SectionSongsResponse>(query, {
    variables: extendedVariables,
    skip,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const songs: SectionSong[] = get(data, `${queryKey}.songs`, undefined) || get(data, queryKey, []);
  const prevSongsCount = usePrevious(songs.length);
  const isTimelineSong = useMemo(() => !!currentSong?._id, [currentSong?._id]);

  const updateCurrentSong = (id: string) => {
    const newSong = songs.find(({ viboSongId }) => viboSongId === id);

    if (!!newSong) {
      setCurrentSong(newSong);
    }
  };

  const handleSwitchSong = (idx: number) => {
    const newSong = songs[idx];

    if (!!newSong) {
      setCurrentSong(newSong);
    }
  };

  useEffect(() => {
    if (prevSongsCount !== songs.length) {
      const newSog = songs[songIndex] || songs[songIndex - 1];
      const firstSong = songs[0];

      if (!!newSog || !!firstSong) {
        updateCurrentSong(newSog?.viboSongId || firstSong?.viboSongId);
      }
    }
  }, [songs.length, prevSongsCount]);

  useEffect(() => {
    if (!loading && !!songId) {
      if (songs.some(({ viboSongId, _id }) => viboSongId === songId || songId === _id)) {
        updateCurrentSong(songId);
      } else {
        const otherSongId = songs[songIndex]?.viboSongId;

        !!otherSongId && updateCurrentSong(otherSongId);
      }
    }
  }, [songId, loading]);

  useEffect(() => {
    const currentSongId = currentSong?.viboSongId;
    const index = findIndex(songs, ({ viboSongId }) => viboSongId === currentSongId);

    setSongIndex(index);
    onPlaySong?.(currentSongId);
  }, [currentSong?.viboSongId]);

  return (
    <Modal
      title={
        <div className={classes.songModalTitle}>
          {!!sectionName ? <span className="sectionName">{sectionName}</span> : null}
          {!!songs.length ? (
            <span>
              {t('song')} {songIndex + 1}/{songs.length}
            </span>
          ) : null}
        </div>
      }
      size="xlg"
      {...rest}
      modalName={Modals.song}
      className={classNames(rest.className, classes.songModal, {
        mustPlay: currentSong?.isMustPlay,
      })}
    >
      <Modal.Body noScroll autoStaticHeight>
        {loading && !currentSong ? (
          <YoutubePlayerPlaceholder />
        ) : !!currentSong && !!songs.length ? (
          <>
            {isTimelineSong ? (
              <TimelineSongModalHeader song={currentSong} variables={extendedVariables} />
            ) : (
              <PlaylistSongHeader
                song={currentSong}
                canEdit={canEdit}
                removeSong={removeSong}
                renderCustomButton={renderCustomButton}
              />
            )}
            <SongPlayerIframe
              song={currentSong}
              setSong={handleSwitchSong}
              currentIndex={songIndex}
              songsCount={songs.length}
              key={`player-${currentSong.viboSongId}`}
            />
            <SongLinksWithMetadata
              links={omit(currentSong?.links, 'youtube')}
              renderLink={link => (
                <Tooltip
                  overlay={link.text}
                  placement="top"
                  type="primary"
                  align={{ offset: [0, -6] }}
                  key={link.url}
                  fillOverlay
                >
                  <link.Icon
                    className={classNames(link.key, classes.linkIcon)}
                    onClick={() => {
                      window.open(link.url, '_blank');
                    }}
                  />
                </Tooltip>
              )}
            />
          </>
        ) : loading ? (
          <YoutubePlayerPlaceholder />
        ) : (
          <NoSongs>{t('addSongs')}</NoSongs>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SongModal;
