import React, { useContext } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useTimeoutFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { StringParam, useQueryParam } from 'use-query-params';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import Scrollbar from 'vibo-ui/Scrollbar';
import LoadingOverlay from 'components/common/LoadingOverlay';
import NoSectionsYet from 'components/emptyStates/NoSectionsYet';
import NoSearchResults from 'components/emptyStates/NoSearchResults';
import AddSectionInMiddle from '../TimelineSection/AddSectionInMiddle';
import CreateSectionButton from 'components/buttons/CreateSectionButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import client from 'graphql/client';
import { isLoadingVar } from 'graphql/cache';
import { useActiveDnd } from 'graphql/hooks/blocks';
import { hasSectionsFilterValue } from 'services/sections/helpers';
import { getDestination, reorder } from 'services/common/reorderHelper';

import { DndIndex, SectionType } from 'types/enums';

import { SectionsProps } from './interfaces';

import useStyles from './style';

const Sections = <TSection extends SectionBase, TReorderVariables = any>({
  sections,
  reorderProps,
  timelineClassName,
  isSectionsLoading,
  renderHeader,
  renderSection,
  renderTimelineItem,
}: SectionsProps<TSection, TReorderVariables>): Nullable<React.ReactElement> => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isLoading = useReactiveVar(isLoadingVar);

  function handleDragEnd() {
    cancel();
    isReady();
  }

  const [isReady, cancel, reset] = useTimeoutFn(handleDragEnd, 500);

  const handleShowTimeline = () => {
    reset();
  };

  const filterContext = useContext(SectionsFilterableContext);
  const {
    isLoading: isEventLoading,
    event,
    isFavoriteSections,
    canAddSection = false,
    canReorderSections = false,
  } = useContext(EventContext);

  const [sectionsQ] = useQueryParam('sectionsQ', StringParam);

  const { clearActiveDnd, handleActiveDnd } = useActiveDnd();

  const onDragEnd = (result: DropResult) => {
    handleDragEnd();
    clearActiveDnd();

    if (
      hasSectionsFilterValue(
        get(reorderProps, 'variables.filter') as Maybe<Nullable<SectionsFilter>>
      )
    ) {
      return;
    }

    const { destination, source } = result;

    if (!destination || !source || !reorderProps) {
      return;
    }

    const { onReorder: reorderSections, listQuery, listCacheKey, variables } = reorderProps;

    client.writeQuery({
      variables,
      query: listQuery,
      data: {
        [listCacheKey]: reorder<TSection>(sections, source.index, destination.index),
      },
    });

    reorderProps.onDragEnd && reorderProps.onDragEnd(sections[source.index]);

    const destinationIndex = getDestination(source.index, destination.index);

    return reorderSections({
      variables: {
        ...variables,
        sourceSectionId: sections[source.index]._id,
        targetSectionId: destinationIndex < 0 ? null : sections[destinationIndex]._id,
      },
    });
  };

  const onDragStart = () => {
    handleActiveDnd(DndIndex.section);
  };

  return (
    <div
      className={classNames('sections', classes.sections, {
        [classes.nonEmptyList]: !!sections.length,
        [classes.emptyList]: !sections.length,
      })}
    >
      {renderHeader()}
      {(isSectionsLoading || isEventLoading) && !sections.length ? (
        <LoadingOverlay fillOverlay={false} />
      ) : !sections.length ? (
        !!sectionsQ ? (
          <NoSearchResults />
        ) : isLoading ? null : (
          <NoSectionsYet>
            {t('noSectionsYet')}
            {canAddSection ? (
              <CreateSectionButton
                eventSettings={event?.settings as EventSettings}
                displayType="primary"
                shape="round"
                size="lg"
                key="empty-state-add-section-btn"
              >
                {t('addSection')}
              </CreateSectionButton>
            ) : null}
          </NoSectionsYet>
        )
      ) : (
        <Scrollbar>
          <DragDropContext
            onBeforeCapture={reorderProps?.onBeforeCapture}
            onDragEnd={onDragEnd as OnDragEndResponder}
            onDragStart={onDragStart}
          >
            <Droppable droppableId="sections" isDropDisabled={!!filterContext?.filter?.q}>
              {(provided: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  onMouseUp={handleShowTimeline}
                >
                  <div
                    className={classNames(classes.sectionsTimeline, timelineClassName)}
                    id="sectionsTimeline"
                  >
                    {sections.map((section: TSection, index: number) => {
                      const prevSection = sections[index - 1];
                      const nextSection = sections[index + 1];

                      const isStart = !prevSection || prevSection.type === SectionType.headline;
                      const isEnd = !nextSection || nextSection.type === SectionType.headline;

                      const isDontPlay = section.type === SectionType.dontPlay;

                      const content = (
                        <Draggable
                          key={section._id}
                          draggableId={section._id}
                          index={index}
                          isDragDisabled={!canReorderSections}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classNames('draggableItem', {
                                isStart,
                                isEnd,
                              })}
                              key={index}
                            >
                              {renderSection({ section, isDragging: snapshot.isDragging, index })}
                            </div>
                          )}
                        </Draggable>
                      );

                      return !!renderTimelineItem ? (
                        renderTimelineItem(content, section, isDontPlay)
                      ) : (
                        <div className={classNames('timelineSection')} key={section._id}>
                          {content}
                        </div>
                      );
                    })}
                    {canAddSection && !isFavoriteSections ? (
                      <AddSectionInMiddle sectionId={null} />
                    ) : null}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Scrollbar>
      )}
    </div>
  );
};

export default Sections;
