import React, { FC, useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

import Button from 'vibo-ui/Button';

import { getDomainWithProtocol } from 'services/common/stringHelpers';

import { GET_CUSTOMER_PORTAL_LINK } from 'graphql/queries/user';

import { IconmoonFont } from 'vibo-ui/Icon';

const StripeButton: FC = () => {
  const domain = useMemo(() => getDomainWithProtocol(window.location.href), [window.location.href]);

  const { data, loading: isLinkLoading } = useQuery(GET_CUSTOMER_PORTAL_LINK, {
    variables: {
      redirectUrl: domain,
    },
    fetchPolicy: 'network-only',
    skip: !domain,
  });

  const link = get(data, 'getCustomerPortalLink');

  return (
    <Button
      href={link}
      target="_blank"
      disabled={isLinkLoading || !link}
      className="stripeBtn"
      prefixIcon={IconmoonFont['stripe-16']}
      shape="round"
      size="lg"
    >
      Manage payments
    </Button>
  );
};

export default StripeButton;
