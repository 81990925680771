import React, { FC, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';

import WithEventRole from 'components/user/WithEventRole';
import Button, { ButtonProps } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { useModal } from 'vibo-ui/Modal';

import { EventUserType, Modals } from 'types/enums';
import { SettingsSteps } from 'components/sections/SectionSettings/interfaces';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { CreateSectionModalProps } from 'components/modals/CreateSectionModal';
import { AddTimelineSectionButtonProps } from './interfaces';

const AddTimelineSectionButton: FC<AddTimelineSectionButtonProps> = ({
  createSectionMutation,
  refetchQueries,
  variables,
  eventSettings,
  insertBeforeSectionId,
  children,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();

  const eventContext = useContext(EventContext);
  const { filter, setFilter } = useContext(SectionsFilterableContext);

  const canHostAddSection = useMemo(
    () =>
      (eventContext.isEventHost && eventContext.event?.settings?.canHostCreateSections) ||
      !!eventContext?.isFavoriteSections,
    [
      eventContext?.event?.settings?.canHostCreateSections,
      eventContext?.isFavoriteSections,
      eventContext?.isEventHost,
    ]
  );

  const { openModal } = useModal();

  const openCreateSectionModal = useCallback(
    (initialStep?: SettingsSteps) =>
      openModal<CreateSectionModalProps>({
        key: Modals.createSection,
        props: {
          eventContext,
          createSectionMutation,
          refetchQueries,
          variables,
          eventSettings,
          insertBeforeSectionId,
          initialStep,
        },
      }),
    []
  );
  const openConfirmClearSearchModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchCreateNewSection'),
          submit: {
            text: t('clear'),
            onClick: clearSearch,
          },
        },
      }),
    []
  );

  const [, setSectionsQ] = useQueryParam('sectionsQ');

  const gtmSuffix = useMemo(() => (!!insertBeforeSectionId ? 'middle' : 'top'), [
    insertBeforeSectionId,
  ]);

  const handleCheckSearch = useCallback(
    (action: Function) => {
      if (!!filter?.q) {
        openConfirmClearSearchModal();
      } else {
        action();
      }
    },
    [filter?.q]
  );

  const addNewSection = useCallback(() => handleCheckSearch(openCreateSectionModal), [
    handleCheckSearch,
  ]);

  const clearSearch = useCallback(() => {
    setFilter(null);
    setSectionsQ(undefined);
  }, []);

  const Toggler = (toggleProps: ButtonProps) => (
    <Button
      onClick={addNewSection}
      {...{ ...rest, ...toggleProps }}
      data-gtm-target-id={`timeline-add-new-section-${gtmSuffix}`}
    >
      {children}
    </Button>
  );

  return (
    <>
      <WithEventRole roles={[EventUserType.dj]}>
        <Toggler />
      </WithEventRole>
      <WithEventRole roles={[EventUserType.host]} extraCondition={canHostAddSection}>
        <Toggler />
      </WithEventRole>
    </>
  );
};

export default AddTimelineSectionButton;
