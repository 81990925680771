import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';

import { UPDATE_EVENT } from 'graphql/mutations/events';
import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';

const DjNotesButton: FC<ButtonProps> = ({ children, ...rest }) => {
  const { t } = useTranslation();

  const { event } = useContext(EventContext);

  const note = useMemo(() => event?.note || '', [event?.note]);

  const { openedModals, openModal } = useModal();

  const handleOpenModal = useCallback(() => {
    openModal({
      key: Modals.djNotes,
      props: {
        variables: { eventId: event?._id },
        value: note,
        mutation: UPDATE_EVENT,
      },
    });
  }, [event?._id, note, openedModals]);

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        handleOpenModal();
      }}
      prefixIcon={IconmoonFont[!!note ? 'notes-16' : 'emptyNotes-16']}
      shape="round"
      size="lg"
      {...rest}
    >
      {children || t('myNotes')}
    </Button>
  );
};

export default DjNotesButton;
