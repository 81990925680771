import React, { FC, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useCopyToClipboard } from 'react-use';
import { useTranslation } from 'react-i18next';
import { toastNotify } from 'graphql/hooks/common';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { updateTemplatePublic } from 'graphql/cache/templates';

import { GET_TEMPLATE_DEEP_LINK } from 'graphql/queries/template';
import { TOGGLE_PUBLIC_TEMPLATE } from 'graphql/mutations/template';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { ShareModalProps } from 'components/modals/ShareModal';
import { ShareTemplateButtonProps } from './interfaces';

const ShareTemplateButton: FC<ShareTemplateButtonProps> = ({ template }) => {
  const { t } = useTranslation();

  const [, copyToClipboard] = useCopyToClipboard();

  const { openModal } = useModal();

  const openShareModal = useCallback(
    () =>
      openModal<ShareModalProps>({
        key: Modals.share,
        props: {
          mutation: TOGGLE_PUBLIC_TEMPLATE,
          variables: {
            templateId: template?._id,
          },
          onCompleted: () => {
            if (!!template?._id) {
              updateTemplatePublic(template);
            }
          },
          option: template,
          title: t('shareTemplate'),
          children: t(
            template?.isPublic ? 'makeTemplatePrivateModalText' : 'makeTemplatePublicModalText'
          ),
        },
      }),
    [template?._id, template?.isPublic]
  );

  const [getTemplateDeepLink, { loading: isTemplateLinkLoading }] = useLazyQuery(
    GET_TEMPLATE_DEEP_LINK,
    {
      variables: {
        templateId: template._id,
      },
      onCompleted: res => {
        copyToClipboard(res.getTemplateDeepLink);
        toastNotify({
          text: t('tamplateLinkCopiedClipboardCanShareWithViboDjs'),
        });
      },
      onError,
    }
  );

  const handleGetTemplateDeepLink = useCallback(() => getTemplateDeepLink(), []);

  return (
    <Dropdown
      overlay={
        <Menu selectable={false}>
          <Menu.Item onClick={handleGetTemplateDeepLink} icon={IconmoonFont['link-16']} key="link">
            {t('copyLink')}
          </Menu.Item>
          <Menu.Item onClick={openShareModal} icon={IconmoonFont['public-16']} key="public">
            {t(template?.isPublic ? 'makePrivate' : 'makePublic')}
          </Menu.Item>
        </Menu>
      }
      trigger="click"
      disableToggleClass
    >
      <Button
        loading={isTemplateLinkLoading}
        prefixIcon={IconmoonFont['share-arrow-16']}
        shape="round"
        size="lg"
      >
        {t('shareTemplate')}
      </Button>
    </Dropdown>
  );
};

export default ShareTemplateButton;
