import React from 'react';
import { useSubscription, useQuery } from '@apollo/client';

import { GET_ME } from '../../../graphql/queries/user';
import { UPDATED_USER } from '../../../graphql/subscriptions/user';
import client from '../../../graphql/client';

const UserSubscription: React.FC = () => {
  const { data: userData } = useQuery<UserResponse>(GET_ME);
  const userId = userData?.me._id;

  const { data } = useSubscription<UpdatedUserResponse>(UPDATED_USER, {
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    skip: !userId,
  });

  if (data && data.userUpdated) {
    client.writeQuery({
      data: {
        me: data.userUpdated,
      },
      query: GET_ME,
    });
  }

  return null;
};

export default UserSubscription;
