import { useContext } from 'react';
import { useQueryParam } from 'use-query-params';

import { ModalWindowsContext } from 'vibo-ui/Modal/ModalWindowContext';

import { UseModalResponse, ModalWindowDescriptor } from './interfaces';

export const useModal = (): UseModalResponse => {
  const { openedModals, setOpenedModals } = useContext(ModalWindowsContext);

  const [openedModalKeys, setOpenedModalKeys] = useQueryParam<string | undefined>('mw');

  const isModalOpened = (name: string) => {
    return openedModals.some(modalOption => modalOption.key === name);
  };

  const openModal = <T extends UnknownProps>(modalProps: ModalWindowDescriptor<T>) => {
    if (!isModalOpened(modalProps.key)) {
      setTimeout(() => setOpenedModals([modalProps, ...openedModals]));
    }
  };

  const closeModal = (name: string) => {
    if (isModalOpened(name)) {
      if (openedModals.some(({ key }) => key === name)) {
        const newModals = openedModals.filter(modalOption => modalOption.key !== name);

        setOpenedModalKeys(openedModalKeys?.replace(name, ''));
        setOpenedModals(newModals);
      }
    }
  };

  return { openedModals, closeModal, openModal, isModalOpened };
};
