import { MutableRefObject } from 'react';
import get from 'lodash/get';

import { KeyCode } from 'types/enums';

export const tryScrollToElement = (elementId?: string): void => {
  if (elementId) {
    const element: Nullable<HTMLElement> = document.getElementById(elementId);

    if (element) {
      const parent = element.closest('.simplebar-content-wrapper');

      if (parent && element.offsetTop) {
        const waitExpandTimer = setTimeout(() => {
          parent.scrollTop = element.offsetTop - parent.clientHeight / 4 + element.offsetHeight / 2;
          clearTimeout(waitExpandTimer);
        }, 200);

        return;
      }

      element.scrollIntoView({
        block: 'center',
      });
    }
  }
};

export const scrollToFocusedElement = (scrollBarRef: MutableRefObject<any>) => {
  const windowHeight = 380;
  const itemHeight = 40;

  if (scrollBarRef.current?.contentWrapperEl) {
    const focusedElements: NodeListOf<HTMLElement> = document.querySelectorAll('[data-focus=true]');

    const scroll = get(scrollBarRef, 'current.contentWrapperEl.scrollTop');
    const offset: Maybe<number> = focusedElements[0]?.offsetTop;

    if (offset > scroll + windowHeight - itemHeight) {
      scrollBarRef.current.contentWrapperEl.scrollTop = offset - windowHeight + itemHeight;
    } else if (offset < scroll) {
      scrollBarRef.current.contentWrapperEl.scrollTop = offset;
    }
  }
};

export const preventScrollKeys = (e: Event) => {
  if (
    // @ts-ignore
    e.keyCode === KeyCode.space ||
    // @ts-ignore
    e.keyCode === KeyCode.arrowUp ||
    // @ts-ignore
    e.keyCode === KeyCode.arrowDown
  ) {
    e.preventDefault();
  }
};

export const handleScrollKeyPress = (collection: HTMLElement[], add: boolean) => {
  collection?.forEach((el: HTMLElement) => {
    const wrapper = el?.getElementsByClassName('viboScrollbar')[0];

    if (add) {
      wrapper?.addEventListener('keydown', preventScrollKeys);
    } else {
      wrapper?.removeEventListener('keydown', preventScrollKeys);
    }
  });
};
