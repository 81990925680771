import * as Yup from 'yup';

import i18n from 'i18n';
import { validateHex } from 'services/common/colorsHelper';
import { MAX_LIMIT_DIGITS, MAX_TITLE_LENGTH } from 'services/constants';

import { SectionType } from 'types/enums';
import { SettingsSteps } from 'components/sections/SectionSettings/interfaces';

export const SECTION_DEFAULTS = {
  type: SectionType.simple,
  name: '',
  description: '',
  time: '',
};

export const SECTION_SETTINGS_DEFAULTS = {
  visibleForGuests: false,
  visibleForHosts: true,
  timeEnabled: true,
  songsEnabled: true,
  notesEnabled: true,
  canHostsOrderSongs: true,
  canHostDeleteSection: true,
  notesVisibleForHosts: true,
  canHostChangeSectionName: true,
  canHostChangeSectionTime: true,
};

export const headlineColorValidateField = Yup.string()
  .nullable()
  .trim()
  .test({
    name: 'name',
    message: () => i18n.t('notValid'),
    test: value => (!!value ? validateHex(value) : true),
  });

export const defaultSectionValidation = Yup.object().shape({
  name: Yup.string()
    .max(MAX_TITLE_LENGTH, i18n.t('nameMustBeCharactersOrLess', { count: MAX_TITLE_LENGTH }))
    .trim()
    .required(i18n.t('sectionNameRequired')),
  settings: Yup.object().shape({
    songsLimit: Yup.string()
      .min(0)
      .max(MAX_LIMIT_DIGITS, i18n.t('mustBeLessThanNumber', { count: 1000 }))
      .nullable(),
    mustPlayLimit: Yup.string()
      .min(0)
      .max(MAX_LIMIT_DIGITS, i18n.t('mustBeLessThanNumber', { count: 1000 }))
      .nullable(),
  }),
});

export const headlineSectionValidation = Yup.object().shape({
  name: Yup.string()
    .max(MAX_TITLE_LENGTH, i18n.t('nameMustBeCharactersOrLess', { count: MAX_TITLE_LENGTH }))
    .trim()
    .required(i18n.t('sectionNameRequired')),
  headlineColor: headlineColorValidateField,
});

export const favoriteSectionsValidation = Yup.object().shape({
  favoriteSectionId: Yup.string()
    .trim()
    .required(i18n.t('required')),
});

export const getCreateSectionValidation = (tab: SettingsSteps) => {
  switch (tab) {
    case SettingsSteps.default:
      return defaultSectionValidation;
    case SettingsSteps.headline:
      return headlineSectionValidation;
    case SettingsSteps.fromFavorite:
      return favoriteSectionsValidation;
    default:
      return;
  }
};
