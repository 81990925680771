import { ReactElement } from 'react';
import padStart from 'lodash/padStart';

import moment from 'services/moment';

export const normalizeTime = (value: number | string) => padStart(value.toString(), 2, '0');

export const filterSuggestions = (inputValue: string, option: ReactElement<any>): boolean => {
  const searchRegExps = inputValue.split(' ').map(value => new RegExp(value, 'i'));

  return searchRegExps.every(
    regex => option.props.children && regex.test(option.props.children?.toString())
  );
};

export const getFutureTimestamp = (minutes: number) => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + minutes * 60000);

  return futureDate;
};

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const secToTime = (sec: number) => moment.utc(sec * 1000).format('mm:ss');
