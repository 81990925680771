import { ViboThemeEnum } from './interfaces';

export const VIBO_THEME_NAME = 'vibo_theme';

export const VIBO_APP_COLOR_NAME = 'vibo_app_color';

export const VIBO_FOLLOW_SYS_THEME = 'vibo_follow_sys_theme';

export const VIBO_THEMES = [ViboThemeEnum.light, ViboThemeEnum.dark];

export const DEFAULT_VIBO_COLOR_HUE = 210;

export const DEFAULT_VIBO_COLOR_HEX = '#1A7EE3';

export const DEFAULT_VIBO_COLOR_DARK_HEX = '#2D8FF1';
