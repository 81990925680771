import React, { FC, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Image from 'components/image/Image';
import Scrollbar from 'vibo-ui/Scrollbar';
import PublicLabel from 'components/labels/PublicLabel';
import ContentArea from 'components/common/ContentArea';
import GradientCover from 'components/image/GradientCover';
import FormTextarea from 'components/form/inputs/FormTextarea';
import ClonePlaylistButton from 'components/buttons/ClonePlaylistButton';
import Button, { ButtonDecor } from 'vibo-ui/Button';

import { ReactComponent as CameraIcon } from 'resources/img/svg/common/camera.svg';

import { useModal } from 'vibo-ui/Modal';
import { MAX_DESCRIPTION_LENGTH } from 'services/constants';
import { useParentPage } from 'graphql/hooks/navigation';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { PickFilesModalProps } from 'components/modals/PickFilesModal';
import { DeleteSongIdeasProps } from 'components/modals/DeleteSongIdeasModal';
import { SongIdeasDetailsProps } from './interfaces';

import useStyles from './style';

const SongIdeasDetailsCard: FC<SongIdeasDetailsProps> = ({ songIdeas, canEdit, currentTab }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openPickImageModal = useCallback(
    () =>
      openModal<PickFilesModalProps>({
        key: Modals.pickImage,
        props: {
          fileSrc: songIdeas.coverPhoto.url,
          title: t('playlistImage'),
          onChange: async (_, photo) => {
            formik.setFieldValue('coverPhoto', {
              photo: photo || '',
            });
          },
        },
      }),
    [songIdeas.coverPhoto.url]
  );
  const openDeletePlaylistModal = useCallback(
    () =>
      openModal<DeleteSongIdeasProps>({
        key: Modals.deletePlaylist,
        props: {
          playlistId: songIdeas._id,
          playlistTitle: songIdeas.title,
          onSubmit: () => push(getGoBackParams(`/songIdeas/${currentTab}`)),
        },
      }),
    [currentTab, songIdeas._id, songIdeas.title]
  );

  const formik = useFormikContext<UpdateSongIdeasForm>();

  const timeAgo = moment(songIdeas.updatedAt).format('MM/DD/YYYY');

  const { push } = useHistory();
  const { getGoBackParams } = useParentPage();

  const creatorName = useMemo(
    () => `${songIdeas.creator?.firstName ?? ''} ${songIdeas.creator?.lastName ?? ''}`.trim(),
    [songIdeas.creator]
  );

  const playlistPhoto = useMemo(() => songIdeas?.coverPhoto?.url || '', [
    songIdeas?.coverPhoto?.url,
  ]);

  useEffect(() => {
    formik.resetForm({
      values: { title: songIdeas.title, description: songIdeas.description || '' },
    });
  }, []);

  return (
    <Scrollbar className={classes.scrollbar}>
      <ContentArea className={classes.playlistInfoBlock} shadowed>
        <div className={classNames('detailsRow', classes.coverPhoto)}>
          {!!songIdeas.isOwner ? (
            <Image src={playlistPhoto} onClick={openPickImageModal}>
              <GradientCover>
                <CameraIcon />
              </GradientCover>
            </Image>
          ) : (
            <Image src={playlistPhoto} token={songIdeas._id}>
              <CameraIcon />
            </Image>
          )}
        </div>
        <div className={classNames('detailsRow')}>
          <div className={classes.customInputWrap}>
            <FormTextarea
              placeholder={`${t('playlistDescription')}...`}
              disabled={!canEdit}
              minRows={1}
              maxLength={MAX_DESCRIPTION_LENGTH}
              className={classes.customInput}
              name="description"
              withUnderline
            />
            {formik.values.description && (
              <div className="limit">{`${formik.values.description.length}/${MAX_DESCRIPTION_LENGTH}`}</div>
            )}
          </div>
        </div>
        {creatorName ? (
          <div className={classNames('detailsRow')}>
            <div className={classes.fieldLabel}>{t('createdBy')}</div>
            <div className={classes.fieldText}>{creatorName}</div>
          </div>
        ) : null}
        <div className={classNames('detailsRow')}>
          <div className={classes.fieldLabel}>{t('lastUpdated')}</div>
          <div className={classes.fieldText}>{timeAgo}</div>
        </div>
        {songIdeas.isPublic ? <PublicLabel className="whiteBg" /> : null}
      </ContentArea>
      {songIdeas?.isOwner ? (
        <>
          <ClonePlaylistButton
            playlist={songIdeas}
            prefixIcon={IconmoonFont['clone-24']}
            displayType="bordered"
            className="centrate"
            shape="round"
            size="lg"
          />
          <Button
            onClick={openDeletePlaylistModal}
            prefixIcon={IconmoonFont['delete-16']}
            className={classNames('centrate', ButtonDecor.danger)}
            displayType="bordered"
            shape="round"
            size="lg"
          >
            {t('deletePlaylist')}
          </Button>
        </>
      ) : null}
    </Scrollbar>
  );
};

export default SongIdeasDetailsCard;
