import { createUseStyles } from 'react-jss';
import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';
import { GetTooltipColors } from './interfaces';

const getTooltipBase = ({ textColor, fillTextColor, bgColor }: GetTooltipColors) => ({
  '& .rc-tooltip-inner': {
    color: textColor,
    border: `1px solid ${bgColor}`,
  },

  '&.fillOverlay': {
    '& .rc-tooltip-inner': {
      backgroundColor: bgColor,
      ...(!!fillTextColor && {
        color: fillTextColor,
      }),
    },
  },
});

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboTooltipOvelay: {
    opacity: 1,
    zIndex: 1000,
    maxWidth: '400px',

    '& .rc-tooltip-arrow': {
      display: 'none',
    },

    '&.fillOverlay': {
      '& .rc-tooltip-inner': {
        color: theme.palette.text.primary,
      },
    },

    '& .withSizeLimit': {
      maxWidth: '250px',
    },

    '& .rc-tooltip-inner': {
      boxShadow: theme.shadows[1],
      padding: '2px 4px',
      fontSize: '11px',
      lineHeight: '16px',
      minHeight: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      borderRadius: '4px',

      '@media screen and (min-width: 1441px)': {
        minHeight: '28px',
        padding: '2px 6px',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },

    '&.emptyDisplay': {
      backgroundColor: 'transparent',

      '& .rc-tooltip-inner': {
        backgroundColor: 'transparent',
        border: `none`,
        padding: '0',
        minHeight: 'auto',
      },
    },

    '&.defaultDisplay': {
      '& .rc-tooltip-inner': {
        border: `1px solid ${theme.palette.background.paper}`,
      },
    },

    '&.primaryDisplay': {
      ...getTooltipBase({
        fillTextColor: theme.palette.selection.whiteToBlack,
        textColor: theme.palette.text.primary,
        bgColor: theme.palette.text.primary,
      }),
    },

    '&.successDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.success.main,
        bgColor: theme.palette.success.main,
      }),
    },

    '&.dangerDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.error.main,
        bgColor: theme.palette.error.main,
      }),
    },

    '&.warningDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.warning.main,
        bgColor: theme.palette.warning.main,
      }),
    },

    '&.blackDisplay': {
      ...getTooltipBase({
        textColor: colors.black,
        bgColor: colors.black,
      }),
    },

    '&.whiteDisplay': {
      ...getTooltipBase({
        textColor: colors.black,
        bgColor: colors.white,
        fillTextColor: colors.black,
      }),
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: '600px',
    },
  },
}));

export default useStyles;
