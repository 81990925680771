import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/client';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { TOGGLE_LIKE } from 'graphql/mutations/songs';
import { onError } from 'graphql/helpers';

import { MAX_SONG_LIKES_DISPLAY } from 'services/constants';

import { LikeProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const Like = <T extends SectionSong>({ song, className, ...rest }: LikeProps<T>) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();

  const [isLiked, setIsLiked] = useState(song?.likedByMe || false);
  const [likesCount, setLikesCount] = useState(song?.likesCount || 0);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const stateIcon = useMemo(
    () => IconmoonFont[isLiked ? 'heartFilled-16' : 'heartOutlined-16'],
    []
  );
  const isLikesLimitExceeded = likesCount > MAX_SONG_LIKES_DISPLAY;

  const [toggleSongLike] = useMutation<ToggleLikeData, ToggleLikeVariables>(TOGGLE_LIKE, {
    onError,
    refetchQueries: ['getSectionSongsStats', 'getSectionSongs'],
  });

  const handleToggleLike: MouseEventHandler<HTMLDivElement> = e => {
    if (song) {
      const liked = !isLiked;
      setIsLiked(liked);

      setLikesCount(likesCount + (isLiked ? -1 : 1));

      toggleSongLike({
        variables: {
          eventId,
          sectionId,
          songId: song._id,
          liked,
        },
      });
    }
  };

  useEffect(() => {
    if (!!song && song.likedByMe !== isLiked) {
      setIsLiked(song.likedByMe);
    }
  }, [song?.likedByMe]);

  useEffect(() => {
    if (!!song && song.likesCount !== likesCount) {
      setLikesCount(song.likesCount);
    }
  }, [song?.likesCount]);

  return (
    <div
      className={classNames('like', classes.like, className)}
      title={isLikesLimitExceeded ? `${likesCount}` : ''}
    >
      <div
        className={classNames(
          'likeContent',
          decorClasses.highlighted,
          isLiked ? classes.likedByMe : `defaultLiked ${classes.defaultLiked}`
        )}
        onClick={handleToggleLike}
        {...rest}
      >
        <Icon icon={stateIcon} />
        <div className={classes.likesCount}>
          {isLikesLimitExceeded ? `${MAX_SONG_LIKES_DISPLAY}+` : likesCount}
        </div>
      </div>
    </div>
  );
};

export default Like;
