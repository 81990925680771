import React, { FC, createContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { CLONE_TEMPLATE, DELETE_TEMPLATE } from 'graphql/mutations/template';

import { TemplatesType } from 'types/enums';
import { ClonePayloadProps } from '../../modals/CloneModal/interfaces';

interface TemplatesContextProps extends TemplatesContextProviderProps {
  deleteTemplate?: (id: string) => void;
  cloneTemplate?: (id: string, payload: ClonePayloadProps) => void;
}

interface TemplatesContextProviderProps {
  type: TemplatesType;
  queryKey: TemplatesListQueryKey;
}

export const TemplatesContext = createContext<TemplatesContextProps>(undefined!);

export const TemplatesContextProvider: FC<{ value: TemplatesContextProviderProps }> = ({
  children,
  value,
}) => {
  const history = useHistory();

  const [cloneTemplate] = useMutation(CLONE_TEMPLATE, {
    onCompleted: ({ cloneTemplate }) => history.push(`/template/${cloneTemplate._id}/general`),
    refetchQueries: [
      value.queryKey,
      'getMyTemplates',
      'getTemplatesCount',
      'getTemplateTypesStats',
    ],
  });
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    refetchQueries: [value.queryKey, 'getTemplatesCount', 'getTemplateTypesStats'],
  });

  const handleCloneTemplate = (templateId: string, payload: ClonePayloadProps) =>
    cloneTemplate({ variables: { templateId, payload } });

  const handleDeleteTemplate = (id: string) => deleteTemplate({ variables: { templateId: id } });

  return (
    <TemplatesContext.Provider
      value={{
        ...value,
        deleteTemplate: handleDeleteTemplate,
        cloneTemplate: handleCloneTemplate,
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};
