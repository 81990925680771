import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import DeviceInfo from './DeviceInfo';
import Button from 'vibo-ui/Button';
import Spinner from 'vibo-ui/Spinner';
import Scrollbar from 'vibo-ui/Scrollbar';
import ContentArea from 'components/common/ContentArea';

import { getOpenScannerProtocol } from 'services/prepMode/helpers';

import { GET_SCANNER_COMPUTERS } from 'graphql/queries/user';

import useStyles from './style';

const MyDevices: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useQuery<ScannerComputersResponse>(GET_SCANNER_COMPUTERS, {
    fetchPolicy: 'cache-and-network',
  });

  const scannerComputers: Maybe<Nullable<ScannerComputer[]>> = data?.me.scannerComputers;

  return (
    <ContentArea shadowed>
      <div className="blockTitle">{t('myDevices')}</div>
      {loading && !data ? (
        <Spinner className="withSpace" />
      ) : (
        <Scrollbar className={classes.devicesScrollbar}>
          {!!scannerComputers ? (
            scannerComputers.map(computer => (
              <DeviceInfo computer={computer} key={`computer-${computer.id}`} />
            ))
          ) : (
            <div className={classes.emptyDeviceListText}>
              {t('yourScannedDevicesWillAppearHere')}
            </div>
          )}
        </Scrollbar>
      )}
      {!!scannerComputers ? (
        <Button
          onClick={() => window.open(getOpenScannerProtocol())}
          displayType="bordered"
          shape="round"
          size="lg"
        >
          {t('openScanner')}
        </Button>
      ) : null}
    </ContentArea>
  );
};

export default MyDevices;
