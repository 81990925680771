import React, { FC } from 'react';

import { useMe } from 'graphql/hooks/user';

interface WithDjTypeProps {
  types: DjType[];
  extraCondition?: boolean;
}

const WithDjType: FC<WithDjTypeProps> = ({ types, children, extraCondition = true }) => {
  const { user } = useMe();

  const djType: Maybe<DjType> = user?.djSettings?.type;

  if (!djType || !types.includes(djType) || !extraCondition) {
    return null;
  }

  return <>{children}</>;
};

export default WithDjType;
