import { QuestionType } from 'types/enums';
import client from '../client';
import {
  SECTION_DETAILS,
  EVENT_SECTION,
  TEMPLATE_SECTION,
  FAVORITE_SECTION,
  SECTION_ANSWERS_INFO,
} from '../fragments/sections';

export const updateEventSectionDetails = (sectionId: string, payload: SectionDetails): void => {
  client.writeFragment({
    fragment: SECTION_DETAILS,
    id: sectionId,
    data: {
      ...payload,
      __typename: 'Section',
    },
  });
};

export const updateSectionAnswersInfo = (sectionId: string, questions: Question[]): void => {
  const answeredCount = questions.filter(
    ({ isAnswered, settings }) => isAnswered && settings.type !== QuestionType.header
  ).length;

  client.writeFragment({
    fragment: SECTION_ANSWERS_INFO,
    id: sectionId,
    data: {
      answeredCount,
      __typename: 'Section',
    },
  });
};

export const getEventSection = (sectionId: string): Nullable<EventSection> => {
  return client.readFragment<EventSection>({
    id: sectionId,
    fragment: EVENT_SECTION,
    fragmentName: 'EventSection',
  });
};

export const getTemplateSection = (sectionId: string): Nullable<TemplateSection> => {
  return client.readFragment<TemplateSection>({
    id: sectionId,
    fragment: TEMPLATE_SECTION,
    fragmentName: 'TemplateSection',
  });
};

export const getFavoriteSection = (sectionId: string): Nullable<FavoriteSectionOption> => {
  return client.readFragment<FavoriteSectionOption>({
    id: sectionId,
    fragment: FAVORITE_SECTION,
    fragmentName: 'FavoriteSection',
  });
};

export const getSection = (
  sectionId: string,
  isEventSection: boolean,
  isFavorite?: boolean
): Nullable<EventSection | TemplateSection | FavoriteSectionOption> => {
  return isFavorite
    ? getFavoriteSection(sectionId)
    : isEventSection
    ? getEventSection(sectionId)
    : getTemplateSection(sectionId);
};
