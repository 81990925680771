import React, { FC } from 'react';
import classNames from 'classnames';

import { TimePreviewGuestProps } from './interfaces';

import useStyles from './style';

const TimePreviewGuest: FC<TimePreviewGuestProps> = ({ time, className }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames('sectionTime forGuest', classes.sectionTime, className, {
        withTime: !!time,
      })}
    >
      <div className={classNames(classes.time, classes.timePreview)}>{time}</div>
    </div>
  );
};

export default TimePreviewGuest;
