import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  loadingOverlay: {
    pointerEvents: 'none',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& .viboSpinner': {
      color: theme.palette.lightColor,
    },

    '&.fillOverlay': {
      '&:before': {
        backgroundColor: theme.palette.overlay.default,
      },
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
    },
  },
}));

export default useStyles;
