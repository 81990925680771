import client, { cache } from '../client';
import { PREP_MODE_SONG_IS_FLAGGED, SONG_FILES } from '../fragments/songs';

import {
  getFilesWithoutRemovedFile,
  getFilesWithToggledFileSelection,
} from 'services/songs/helpers';

export const toggleCachedPrepModeSongFlag = (id: string, isFlagged: boolean) => {
  const cachedSong = client.readFragment<Nullable<SongIsFlaggedFragment>>({
    id,
    fragment: PREP_MODE_SONG_IS_FLAGGED,
  });

  if (cachedSong) {
    client.writeFragment<SongIsFlaggedFragment>({
      id,
      fragment: PREP_MODE_SONG_IS_FLAGGED,
      data: {
        isFlagged,
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const toggleSelectCachedSongFile = (songId: string, fileId: string, isSelected: boolean) => {
  const cachedSong = client.readFragment<Nullable<SongFilesFragment>>({
    id: songId,
    fragment: SONG_FILES,
    fragmentName: 'SongFiles',
  });

  if (cachedSong) {
    client.writeFragment<SongFilesFragment>({
      id: songId,
      fragment: SONG_FILES,
      fragmentName: 'SongFiles',
      data: {
        files: getFilesWithToggledFileSelection(cachedSong, fileId, isSelected),
        __typename: cachedSong.__typename,
      },
    });
  }
};

export const removeCachedSongFile = (songId: string, fileId: string) => {
  const cachedSong = client.readFragment<Nullable<SongFilesFragment>>({
    id: songId,
    fragment: SONG_FILES,
    fragmentName: 'SongFiles',
  });

  if (cachedSong) {
    cache.modify({
      id: songId,
      fields: {
        files: () => getFilesWithoutRemovedFile(cachedSong, fileId),
      },
    });
  }
};
