import React, { FC, useEffect, useContext } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';
import { useReactiveVar } from '@apollo/client';

import List from 'vibo-ui/List';
import PdfSectionRow from './PdfSectionRow';
import PdfSectionsHeader from './PdfSectionsHeader';
import ContentArea from 'components/common/ContentArea';
import { SelectListContext } from 'components/context/SelectListContext';

import { useEventFileLayout } from 'graphql/hooks/event';

import { isLoadingVar } from 'graphql/cache';

import { PdfSectionProps, PdfSectionTypes } from '../interfaces';

import useStyles from './style';

const PdfSections: FC<PdfSectionProps> = ({ layout }) => {
  const classes = useStyles();

  const formik = useFormikContext<CreateEventFileWebForm>();

  const isLoading = useReactiveVar(isLoadingVar);

  const { selectedIds, ids, setSelectedIds, setIds } = useContext(SelectListContext);

  const layoutSections = get(formik.values, layout, []) as PdfSectionTypes[];

  const sectionsControl = useEventFileLayout({
    formik,
    layout,
    selectedOptionsIds: selectedIds,
  });

  useEffect(() => {
    const selectedIdList = layoutSections
      .map(({ selected, _id }) => (selected ? _id : ''))
      .filter(Boolean);
    const allSectionIds = layoutSections.map(({ _id }) => _id);

    if (!isEqual(allSectionIds, selectedIds)) {
      setIds(allSectionIds);
    }

    if (!isEqual(selectedIdList, selectedIds)) {
      setSelectedIds(selectedIdList);
    }
  }, []);

  useEffect(() => {
    const checkedIds = sectionsControl?.checkedSections.map(({ _id }) => _id);

    if (!isLoading && !isEqual(checkedIds, selectedIds) && !!ids.length) {
      sectionsControl?.handleCheckSections(selectedIds);
    }
  }, [selectedIds, isLoading]);

  return (
    <ContentArea className={classNames('pdfSections', classes.pdfSections)} shadowed>
      <PdfSectionsHeader layout={layout} sectionsControl={sectionsControl} />
      <List<PdfSectionTypes>
        data={layoutSections}
        renderItem={(section, idx) => {
          return (
            <PdfSectionRow
              sectionsControl={sectionsControl}
              section={section}
              layout={layout}
              key={`pdf-${layout}-section-${section._id}-${idx}`}
            />
          );
        }}
      />
    </ContentArea>
  );
};

export default PdfSections;
