import React, { FC } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import Checkbox from 'vibo-ui/Checkbox';
import CellName from 'components/common/CellName';

import { PdfSectionTogglerProps } from '../interfaces';

import useStyles from './style';

const PdfSectionToggler: FC<PdfSectionTogglerProps> = ({
  selected,
  className,
  content,
  name,
  onClick,
  children,
}) => {
  const classes = useStyles();

  const handleClick = debounce(e => {
    return onClick?.(e);
  });

  return (
    <CellName name={name}>
      <div
        onClick={e => {
          e?.stopPropagation();
          handleClick(e);
        }}
        className={classNames('pdfSectionToggler', classes.pdfSectionToggler, className, {
          noContent: !content,
        })}
      >
        {!!content ? <Checkbox checked={selected} onClick={e => e.stopPropagation()} /> : null}
        {content}
      </div>
      {children}
    </CellName>
  );
};

export default PdfSectionToggler;
