import React, { FC, useCallback, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useQueryParam } from 'use-query-params';

import Button from 'vibo-ui/Button';
import Scrollbar from 'vibo-ui/Scrollbar';
import PlaylistCard from './PlaylistCard';
import SearchInput from 'vibo-ui/SearchInput';
import CreateButton from 'components/buttons/CreateButton';
import NoIdeasYet from 'components/emptyStates/NoIdeasYet';
import LoadingOverlay from 'components/common/LoadingOverlay';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';
import NoIdeasSearchResults from 'components/emptyStates/NoIdeasSearchResults';
import VirtualizedGrid from 'components/common/VirtualizedList/VirtualizedGrid';

import { useModal } from 'vibo-ui/Modal';
import { DEFAULT_PAGINATION } from 'services/constants';
import { useParentPage } from 'graphql/hooks/navigation';
import { getSongIdeasData } from 'services/songIdeas/helpers';
import { usePrevious } from 'services/common/dataProcessingHelpers';
import {
  PLAYLISTS_COLS_COUNT_LG,
  PLAYLISTS_COLS_COUNT_SM,
  PLAYLISTS_COL_WIDTH_LG,
  PLAYLISTS_COL_WIDTH_SM,
  PLAYLISTS_GAP_LG,
  PLAYLISTS_GAP_SM,
  PLAYLISTS_ROW_HEIGHT_LG,
  PLAYLISTS_ROW_HEIGHT_SM,
} from './constants';

import { IconmoonFont } from 'vibo-ui/Icon';
import { Modals, SongIdeasType } from 'types/enums';
import { SongIdeasListProps } from './interfaces';

import useStyles from './style';

const SongIdeasList: FC<SongIdeasListProps> = ({ query, queryKey }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { params } = useRouteMatch<{ type: string }>();

  const { goForward } = useParentPage();

  const { openModal } = useModal();

  const openCreatePlaylistModal = useCallback(
    () =>
      openModal({
        key: Modals.createPlaylist,
      }),
    []
  );

  const [q = '', setQ] = useQueryParam<string | undefined>('q');

  const prevPlaylistsType = usePrevious(params.type);

  const { data, error, fetchMore, loading } = useQuery<SongIdeasResponse, SongIdeasListVariables>(
    query,
    {
      variables: {
        filter: { q },
        pagination: DEFAULT_PAGINATION,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );
  const songIdeasData: SongIdeasResponseData = getSongIdeasData(data);

  const songIdeasNames = useMemo(
    () => ({
      [SongIdeasType.me]: 'youHavePlaylists',
      [SongIdeasType.vibo]: 'publicPlaylistsAvailable',
    }),
    []
  );
  const pageName = useMemo(() => get(songIdeasNames, params.type), [params.type]);

  const openSongIdeas = (songIdeasId: string) => goForward(`/songIdeas/v/${songIdeasId}`);

  const loadMore = useCallback(() => {
    if (!loading && !!songIdeasData.next) {
      fetchMore({
        variables: {
          filter: { q },
          pagination: songIdeasData.next,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          return {
            [queryKey]: {
              ...prev[queryKey],
              songIdeas: [...prev[queryKey].songIdeas, ...fetchMoreResult[queryKey].songIdeas],
              next: fetchMoreResult[queryKey].next,
            },
          };
        },
      });
    }
  }, [q, loading, songIdeasData.next?.limit, songIdeasData.next?.skip]);

  useEffect(() => {
    setQ(undefined);

    return () => setQ(undefined);
  }, []);

  if (error) {
    return null;
  }

  if (loading && !data) {
    return <LoadingOverlay />;
  }

  return (
    <>
      {!songIdeasData.totalCount && !q ? null : (
        <PageContentHeader
          extra={
            <>
              <SearchInput placeholder={t('searchPlaylists')} loading={loading} />
              <Button
                onClick={openCreatePlaylistModal}
                prefixIcon={IconmoonFont['plus-16']}
                displayType="bordered"
                shape="round"
                size="lg"
              >
                {t('createNewPlaylist')}
              </Button>
            </>
          }
        >
          <EventTitle name={t(pageName, { count: songIdeasData.totalCount })} />
        </PageContentHeader>
      )}
      {!songIdeasData.totalCount ? (
        !q ? (
          <NoIdeasYet>
            <CreateButton
              onClick={openCreatePlaylistModal}
              text={t('createNewPlaylist')}
              className="centrate"
            />
            <Button
              to="/songIdeas/vibo"
              prefixIcon={IconmoonFont['plus-16']}
              className="centrate"
              displayType="bordered"
              shape="round"
              size="lg"
            >
              {t('addFromPublicPlaylist')}
            </Button>
          </NoIdeasYet>
        ) : (
          <NoIdeasSearchResults />
        )
      ) : prevPlaylistsType !== params.type && loading ? null : (
        <Scrollbar className={classes.scrollbar} id="playlists-scroll">
          <VirtualizedGrid<SongIdeasPreview>
            rowRenderer={playlist => {
              const playlistImage =
                playlist?.coverPhoto?.cropped?.medium || playlist?.coverPhoto?.cropped?.small;

              return (
                <PlaylistCard
                  onClick={() => openSongIdeas(playlist._id)}
                  playlist={playlist}
                  key={playlist._id}
                  image={playlistImage}
                  withActions
                />
              );
            }}
            data={songIdeasData.songIdeas}
            totalOptionsCount={songIdeasData.totalCount}
            loadMore={loadMore}
            className={classes.playlistCards}
            scrollId="playlists-scroll"
            columnsCount={[PLAYLISTS_COLS_COUNT_SM, PLAYLISTS_COLS_COUNT_LG]}
            rowHeights={[PLAYLISTS_ROW_HEIGHT_SM, PLAYLISTS_ROW_HEIGHT_LG]}
            columnWidths={[PLAYLISTS_COL_WIDTH_SM, PLAYLISTS_COL_WIDTH_LG]}
            gaps={[PLAYLISTS_GAP_SM, PLAYLISTS_GAP_LG]}
          />
        </Scrollbar>
      )}
    </>
  );
};

export default SongIdeasList;
