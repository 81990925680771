import React, { FC } from 'react';
import classNames from 'classnames';

import Button from 'vibo-ui/Button';

import { IconmoonFont } from 'vibo-ui/Icon';
import { CreateButtonProps } from './interfaces';

import useStyles from './style';

const CreateButton: FC<CreateButtonProps> = ({ text, className, onClick, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classNames(classes.createButton, className)}
      prefixIcon={IconmoonFont['plus-16']}
      displayType="bordered"
      shape="round"
      size="lg"
      {...rest}
    >
      {text}
    </Button>
  );
};

export default CreateButton;
