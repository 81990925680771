import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  searchInputWrapper: {
    position: 'relative',

    '& .iconPrefix': {
      color: theme.palette.text.hint,
    },
  },

  viboSearchInput: {
    '&.viboInput': {
      border: `1px solid ${theme.palette.text.hint}`,

      '&:focus, &:active': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));

export default useStyles;
