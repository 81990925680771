import React, { useMemo } from 'react';
import classNames from 'classnames';

import Image from 'components/image/Image';
import ImageDefaultPlaceholder from 'components/image/ImageDefaultPlaceholder';

import { ReactComponent as UserIcon } from 'resources/img/svg/common/user.svg';

import { getFullName } from 'services/users/helpers';

import { UserLogoProps } from './interfaces';

import useStyles from '../style';

const UserLogo = ({ user, className, onClick }: UserLogoProps) => {
  const classes = useStyles();

  const alt = useMemo(() => (!!user ? getFullName(user) : ''), [user?.firstName, user?.lastName]);

  return (
    <Image
      token={user?._id}
      src={user?.logoUrl as string}
      alt={alt}
      onClick={onClick}
      className={classNames(classes.img, className)}
    >
      <ImageDefaultPlaceholder icon={<UserIcon />} />
    </Image>
  );
};

export default UserLogo;
