import React, { FC, useCallback, useEffect, useState, memo } from 'react';
import pick from 'lodash/pick';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import PrepModeSongFile from './PrepModeSongFile';
import PrepModeSongBlock from '../PrepModeSongBlock';
import PrepModeMeidaLinks from './PrepModeMeidaLinks';
import CopyButton from 'components/buttons/CopyButton';
import SongArtistNames from 'components/songs/SongArtistNames';

import { ReactComponent as FlagFilledIcon } from 'resources/img/svg/common/flagFilled.svg';
import { ReactComponent as FlagOutlinedIcon } from 'resources/img/svg/common/flagOutlined.svg';

import { onError } from 'graphql/helpers';
import {
  removeCachedSongFile,
  toggleCachedPrepModeSongFlag,
  toggleSelectCachedSongFile,
} from 'graphql/cache/prepMode';
import { GET_PREP_MODE_SECTIONS } from 'graphql/queries/prepMode';
import { UPDATE_SECTION_SONGS } from 'graphql/mutations/songs';
import { REMOVE_SONG_FILE, TOGGLE_SELECT_SONG_FILE } from 'graphql/mutations/prepMode';

import { PREP_MODE_SONG_FILES_COUNT } from 'services/constants';

import { PrepModeSongExpansion } from 'types/enums';
import { MultiSelectChangeEvent } from 'vibo-ui/types/multiSelectOption';
import { RenderContentProps } from '../PrepModeSongBlock/interfaces';

import useStyles from './style';
import { PrepModeSongProps } from './PrepModeSong';

const PrepModeSong: FC<PrepModeSongProps> = ({
  song,
  variables,
  index,
  refetch,
  initialExpansion,
  openedSongs,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [controlRef, setControlRef] = useState<Nullable<RenderContentProps>>(null);
  const [cardExpansion, setCardExpansion] = useState<PrepModeSongExpansion>(initialExpansion);

  useEffect(() => {
    setCardExpansion(initialExpansion);
  }, [initialExpansion]);

  useEffect(() => {
    if (openedSongs.includes(song._id)) {
      controlRef?.handleCollapse(false);
    } else {
      controlRef?.handleCollapse(true);
    }
  }, [openedSongs]);

  const [updateSong] = useMutation<UpdateSectionSongsData, UpdateSectionSongsVariables>(
    UPDATE_SECTION_SONGS,
    {
      onError: error => {
        onError(error);
        return refetch();
      },
      refetchQueries: [
        {
          query: GET_PREP_MODE_SECTIONS,
          variables: pick(variables, ['eventId', 'computerId']),
        },
      ],
    }
  );

  const [toggleSelectSongFile] = useMutation<
    ToggleSelectSongFileData,
    ToggleSelectSongFileVariables
  >(TOGGLE_SELECT_SONG_FILE, {
    onError: error => {
      onError(error);
      return refetch();
    },
    refetchQueries: [
      {
        query: GET_PREP_MODE_SECTIONS,
        variables: pick(variables, ['eventId', 'computerId']),
      },
    ],
  });

  const [removeSongFile] = useMutation<RemoveSongFileData, RemoveSongFileVariables>(
    REMOVE_SONG_FILE,
    { onError }
  );

  const handleToggleFlag = (isFlagged: boolean) => {
    toggleCachedPrepModeSongFlag(song._id, isFlagged);

    return updateSong({
      variables: {
        ...variables,
        songIds: [song._id],
        payload: {
          isFlagged,
        },
      },
    });
  };

  const handleToggleFlagDebounced = useCallback(
    debounce(
      (isFlagged: boolean) => {
        return handleToggleFlag(isFlagged);
      },
      200,
      { leading: true, trailing: false }
    ),
    []
  );

  const handleToggleSelectSongFile = (fileId: string, e: MultiSelectChangeEvent) => {
    toggleSelectCachedSongFile(song._id, fileId, e.target.checked);

    return toggleSelectSongFile({
      variables: {
        ...variables,
        songId: song._id,
        fileId,
      },
    });
  };

  const handleToggleSelectSongFileDebounced = useCallback(
    debounce(
      (fileId: string, e: MultiSelectChangeEvent) => {
        return handleToggleSelectSongFile(fileId, e);
      },
      200,
      { leading: true, trailing: false }
    ),
    []
  );

  const handleRemoveFile = (fileId: string) => {
    removeCachedSongFile(song._id, fileId);

    return removeSongFile({
      variables: {
        ...variables,
        songId: song._id,
        fileId,
      },
    });
  };

  const handleRemoveFileDebounced = useCallback(
    debounce(
      (fileId: string) => {
        return handleRemoveFile(fileId);
      },
      200,
      { leading: true, trailing: false }
    ),
    []
  );

  const onlySelectedFiles = song.files.filter(file => file.isSelected);

  const preparedFiles =
    controlRef?.isCollapsed && onlySelectedFiles.length
      ? onlySelectedFiles
      : song.files.slice(
          0,
          controlRef?.isCollapsed
            ? 1
            : controlRef?.isShown
            ? song.files.length - 1
            : PREP_MODE_SONG_FILES_COUNT
        );

  return (
    <div
      className={classNames(classes.prepModeSong, {
        [`${classes.expandedSong} expandedSong`]: !controlRef?.isCollapsed && song.files.length > 0,
        [classes.clickable]: cardExpansion === PrepModeSongExpansion.less,
      })}
    >
      <PrepModeSongBlock
        song={song}
        controlRef={setControlRef}
        renderHeader={() => {
          return (
            <div className={`${classes.clickable} prepModeSong`}>
              <div className={classes.songLeft}>
                {/*TODO refactor to use ToggleableUpdateField component*/}
                {song.isFlagged ? (
                  <FlagFilledIcon
                    className={classNames('flag', classes.clickable, classes.flagFilled)}
                    onClick={e => {
                      e.stopPropagation();
                      handleToggleFlagDebounced(false);
                    }}
                  />
                ) : (
                  <FlagOutlinedIcon
                    className={classNames('flag', classes.clickable, classes.flagOutlined)}
                    onClick={e => {
                      e.stopPropagation();
                      handleToggleFlagDebounced(true);
                    }}
                  />
                )}
                <span className={classes.index}>{index + 1}</span>
                <div className={classes.copyNameWrapper}>
                  <SongArtistNames
                    className={classNames(classes.artistNames)}
                    song={song}
                    divideWithHyphen
                  />
                  <CopyButton
                    text={`${song.title}${song.artist ? ` - ${song.artist}` : ''}`}
                    tooltipTitle={t('copyTitle')}
                  />
                </div>
                <PrepModeMeidaLinks songLinks={song.links} />
              </div>
            </div>
          );
        }}
      >
        {song.files.length ? (
          preparedFiles.map(file => (
            <PrepModeSongFile
              key={file.fileId}
              file={file}
              cardExpansion={cardExpansion}
              onToggleSelect={handleToggleSelectSongFileDebounced}
              onRemoveFile={handleRemoveFileDebounced}
            />
          ))
        ) : (
          <div className={classes.noMatchesText}>{t('noMatches')}</div>
        )}
      </PrepModeSongBlock>
    </div>
  );
};

export default memo(PrepModeSong);
