import React, { FC, createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { SortContext } from '../SortContext';
import { EventsFilterableContext } from '../../events/common/EventsListContext';

import { getEventsData } from 'services/events/helpers';
import { LOAD_EVENTS_LIMIT } from './constants';

import { EventsType } from 'types/enums';
import { ListQuery } from 'types/graphql';

type TEventsContext = {
  eventsData: EventsListData;
  eventsResponse?: EventsListResponse;
  loading: boolean;
  fetchMore: Function;
  queryKey: EventsListQueryKey;
  isFilterApplied: boolean;
  isHistory: boolean;
};

type TEventsContextProvider = {
  query: ListQuery;
  queryKey: EventsListQueryKey;
};

export const EventsContext = createContext<TEventsContext>(undefined!);

export const EventsContextProvider: FC<{ value: TEventsContextProvider }> = ({
  children,
  value,
}) => {
  const { filter } = useContext(EventsFilterableContext);
  const { sort } = useContext(SortContext);

  const isFilterApplied = !!filter.q || !!filter.statusId || !!filter.djId;

  const isHistory = value.queryKey === EventsType.historyEvents;

  const { data: eventsResponse, loading, fetchMore } = useQuery<EventsListResponse>(value.query, {
    variables: {
      filter,
      sort,
      pagination: {
        skip: 0,
        limit: LOAD_EVENTS_LIMIT,
      },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const eventsData: EventsListData = getEventsData(eventsResponse);

  const handleFetchMore = () => {
    !loading &&
      eventsData.next &&
      fetchMore({
        variables: {
          filter,
          sort,
          pagination: {
            ...eventsData.next,
            limit: LOAD_EVENTS_LIMIT,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          return {
            [value.queryKey]: {
              ...prev[value.queryKey],
              events: [...prev[value.queryKey].events, ...fetchMoreResult[value.queryKey].events],
              next: fetchMoreResult[value.queryKey].next,
            },
          };
        },
      });
  };

  return (
    <EventsContext.Provider
      value={{
        eventsData,
        isHistory,
        isFilterApplied,
        loading,
        fetchMore: handleFetchMore,
        queryKey: value.queryKey,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
