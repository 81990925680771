import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import AboutMe from './AboutMe';
import Website from './Website';
import LastName from './LastName';
import NickName from './NickName';
import FirstName from './FirstName';
import Tooltip from 'vibo-ui/Tooltip';
import SocialLinks from './SocialLinks';
import ReviewLinks from './ReviewLinks';
import Image from 'components/image/Image';
import ProfileLocation from './ProfileLocation';
import ShowNameSwitcher from './ShowNameSwitcher';
import InputLabel from 'components/common/InputLabel';
import ContentArea from 'components/common/ContentArea';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';
import PhoneNumberWithPolicy from './PhoneNumber/PhoneNumberWithPolicy';
import ImageDefaultPlaceholder from 'components/image/ImageDefaultPlaceholder';
import AppColoredBox from 'components/common/AppColoredBox/AppColoredBox';
import PickBrandingColorButton from 'components/buttons/PickBrandingColorButton';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { PolicyContext, UserPolicyFields } from 'components/context/PolicyContext';

import { ReactComponent as MenuAccountIcon } from 'resources/img/svg/userRoleLg.svg';
import { ReactComponent as LogoHeadphonesIcon } from 'resources/img/svg/logoHeadphones.svg';

import { GET_USER } from 'graphql/queries/user';

import { useMe } from 'graphql/hooks/user';
import { getFullName } from 'services/users/helpers';

import { DjType } from 'types/enums';
import { InfoProps } from './interfaces';

import useStyles from './style';

const DjInfo: FC<InfoProps> = ({
  user,
  setModalProfileOpen,
  setModalLogoOpen,
  handleUpdateProfile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isFieldEditable } = useContext(PolicyContext);

  const me = useMe();

  const canEditImg = isFieldEditable(UserPolicyFields.imageUrl);
  const canEditLogo = isFieldEditable(UserPolicyFields.logoUrl);

  const { data: masterDjData } = useQuery(GET_USER, {
    variables: {
      userId: user?.djSettings?.parentId || '',
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !user?.djSettings?.parentId,
  });

  const masterDj = masterDjData?.['user'];

  const isBrandingHidden = useMemo(
    () => !me.isBrandingAllowed || me.isChildDj || !!user?.djSettings?.parentId,
    [me.isChildDj, me.isBrandingAllowed, user?.djSettings?.parentId]
  );

  return (
    <>
      <PageContentHeader>
        <EventTitle name={t(!!masterDj ? 'myDjsProfile' : 'profile')} />
      </PageContentHeader>
      <div className="content">
        <div className="photos">
          <ContentArea
            className={classNames('profilePicture', classes.profilePicture, {
              canEdit: canEditImg,
            })}
            onClick={() => canEditImg && setModalProfileOpen(true)}
            shadowed
          >
            {user ? (
              <>
                <Image
                  token={user?._id}
                  src={`${user?.imageUrl}`}
                  alt={getFullName(user)}
                  className={classNames(classes.imageCover, 'photo')}
                >
                  <ImageDefaultPlaceholder icon={<MenuAccountIcon />} text={t('addProfilePhoto')} />
                </Image>
              </>
            ) : null}
          </ContentArea>
          {user?.djSettings?.type === DjType.child ? null : (
            <ContentArea
              className={classNames('profilePicture', classes.profilePicture, {
                canEdit: canEditLogo,
              })}
              onClick={() => canEditLogo && setModalLogoOpen(true)}
              shadowed
            >
              {user ? (
                <Image
                  token={user?._id}
                  src={`${user?.logoUrl}`}
                  alt={getFullName(user)}
                  className={classNames(classes.imageCover, 'photo')}
                >
                  <ImageDefaultPlaceholder icon={<LogoHeadphonesIcon />} text={t('addDjLogo')} />
                </Image>
              ) : null}
            </ContentArea>
          )}
        </div>
        <div className={classNames('info', classes.profileInfo)}>
          <ContentArea className={classes.nameBlock} shadowed>
            <div className="row">
              <InputLabel text={t('firstName')} required>
                <FirstName handleUpdateProfile={handleUpdateProfile} user={user} />
              </InputLabel>
            </div>
            <div className="row">
              <InputLabel text={t('lastName')} required>
                <LastName handleUpdateProfile={handleUpdateProfile} user={user} />
              </InputLabel>
            </div>
            <div className="row">
              <InputLabel text={t('djNickname')}>
                <NickName handleUpdateProfile={handleUpdateProfile} user={user} />
              </InputLabel>
            </div>
            <ShowNameSwitcher handleUpdateProfile={handleUpdateProfile} user={user} />
          </ContentArea>
          <ContentArea shadowed>
            <div className="blockTitle">{t('aboutMe')}</div>
            <AboutMe handleUpdateProfile={handleUpdateProfile} user={user} />
          </ContentArea>
          {isBrandingHidden ? null : (
            <ContentArea shadowed>
              <div className="blockTitle">{t('branding')}</div>
              <div className={classes.brandingInfo}>
                <AppColoredBox />
                <PickBrandingColorButton />
              </div>
            </ContentArea>
          )}
          <ContentArea shadowed>
            <div className="row">
              <InputLabel
                iconPrefix={<Icon icon={IconmoonFont['phone-16']} />}
                text={t('phoneNumber')}
              />
              <PhoneNumberWithPolicy handleUpdateProfile={handleUpdateProfile} user={user} />
            </div>
            <div className="row">
              <InputLabel
                iconPrefix={<Icon icon={IconmoonFont['location-16']} />}
                text={t('myLocation')}
              />
              <ProfileLocation handleUpdateProfile={handleUpdateProfile} user={user} />
            </div>
            <div className="row">
              <InputLabel
                iconPrefix={<Icon icon={IconmoonFont['site-16']} />}
                text={t('website')}
              />
              <Website handleUpdateProfile={handleUpdateProfile} user={user} />
            </div>
            <div className="row">
              <InputLabel iconPrefix={<Icon icon={IconmoonFont['email-16']} />} text={t('email')} />
              <Tooltip
                overlay={t('needChangeYourEmailContactSupport')}
                placement="top"
                type="primary"
              >
                <div className="email">{user?.email}</div>
              </Tooltip>
            </div>
            {!!masterDj ? (
              <div className="row">
                <InputLabel
                  iconPrefix={<Icon icon={IconmoonFont['email-16']} />}
                  text={t('djEmail')}
                />
                <div className="email">{masterDj.email}</div>
              </div>
            ) : null}
          </ContentArea>
          <ContentArea shadowed>
            <div className="blockTitle">{t('profiles')}</div>
            <SocialLinks handleUpdateProfile={handleUpdateProfile} user={user} />
          </ContentArea>
          <ContentArea shadowed>
            <div className="blockTitle">{t('links')}</div>
            <ReviewLinks handleUpdateProfile={handleUpdateProfile} user={user} />
          </ContentArea>
        </div>
      </div>
    </>
  );
};

export default DjInfo;
