import { gql } from '@apollo/client';

export const GET_SCANNER_INFO = gql`
  query getScannerInfo {
    getScannerInfo {
      winFileSize
      winVersion
      macVersion
      macFileSize
    }
  }
`;
