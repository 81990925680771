import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  timelineQuestionsBlock: {
    '& .sectionBlockHeader': {
      top: '-4px',
    },

    '& .sectionBlockContent': {
      overflow: 'visible',
    },

    '&.noSpaces': {
      '& .draggableItemClassName': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& .draggableItemClassName, & .question': {
        margin: 0,
      },

      '& .sectionBlockContent': {
        margin: '0!important',
      },
    },
  },

  noQuestionsArea: {
    margin: '6px 0 0 0',

    '@media screen and (min-width: 1441px)': {
      margin: '8px 0 0 0',
    },
  },
}));

export default useStyles;
