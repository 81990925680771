import React from 'react';
import { useReactiveVar } from '@apollo/client';

import SignIn from '../SignIn';
import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';

import { isLoggedInVar } from 'graphql/cache';

export const AuthRequiredInline = (props: any) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  return (
    <ModalWindowContextProvider>
      {isLoggedIn ? props.children : <SignIn />}
    </ModalWindowContextProvider>
  );
};
