import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  highlighted: {
    ...theme.mixins.getStaticIconSize?.(20),
    borderRadius: '4px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',

    '&.danger': {
      color: theme.palette.error.main,
    },

    '&:hover, &.hoverable-action:hover': {
      backgroundColor: isDark ? theme.palette.selection.menuHover : theme.palette.primary.light,

      '&, & svg, & .viboIcon': {
        color: isDark ? `${colors.white}!important` : theme.palette.button.active,
      },
    },

    '@media screen and (min-width: 1441px)': {
      ...theme.mixins.getStaticIconSize?.(24),
    },
  },
}));

export default useStyles;
