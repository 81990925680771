import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  virtualizedList: {
    width: '100%!important',

    '& .ReactVirtualized__List, & .ReactVirtualized__Grid__innerScrollContainer, & .ReactVirtualized__Table__Grid, & .ReactVirtualized__Grid': {
      overflow: 'visible!important',
    },

    '& .ReactVirtualized__List': {
      width: '100%!important',

      '& .ReactVirtualized__Grid__innerScrollContainer': {
        width: '100%!important',
      },
    },
  },
}));

export default useStyles;
