import React, { FC, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import Dropdown from 'vibo-ui/Dropdown';
import Notifications from './Notifications';
import Tooltip from 'vibo-ui/Tooltip/Tooltip';
import ContentArea from 'components/common/ContentArea';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import Button, { ButtonDecor } from 'vibo-ui/Button';

import { onError } from 'graphql/helpers';
import { downgradeNotificationsCount } from 'graphql/cache/notifications';
import { MAX_NOTIFICATIONS_CROP_COUNT, TOOLTIP_ALIGN } from './contstants';

import { MARK_AS_READ } from 'graphql/mutations/notifications';
import { GET_NOTIFICATIONS_COUNT } from 'graphql/queries/notifications';

import { IconmoonFont } from 'vibo-ui/Icon';
import { GetNotificationsCountResponse } from './interface';

import useStyles from './style';

const NotificationBell: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [destroyNotifications, setDestroyNotifications] = useState(false);

  const { data: notificationsCountData } = useQuery<GetNotificationsCountResponse>(
    GET_NOTIFICATIONS_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onError,
    }
  );
  const newNotificationsCount = useMemo(
    () => notificationsCountData?.getNotificationsCount.total || 0,
    [notificationsCountData?.getNotificationsCount.total]
  );

  const [markAsRead, { loading }] = useMutation(MARK_AS_READ, {
    variables: {
      readAll: true,
    },
    onCompleted: () => downgradeNotificationsCount(true),
    refetchQueries: ['getNotifications'],
    onError,
  });

  const toggleDestroy = useCallback((destroy: boolean) => setDestroyNotifications(destroy), []);

  const handleReadAll = useCallback(() => markAsRead(), []);

  const menuContent = (
    <StopPropagationWrapper>
      <ContentArea className={classes.notificationsArea} shadowed>
        <div className={classes.notificationsHeader}>
          {t('notifications')}
          {!!newNotificationsCount ? (
            <StopPropagationWrapper>
              <Button
                onClick={handleReadAll}
                loading={loading}
                className={ButtonDecor.primaryColor}
                displayType="link"
              >
                {t('markAllRead')}
              </Button>
            </StopPropagationWrapper>
          ) : null}
        </div>
        <Notifications key={`destroy-notifications-${destroyNotifications}`} />
      </ContentArea>
    </StopPropagationWrapper>
  );

  return (
    <Dropdown
      overlay={menuContent}
      toggleClassName={classes.toggleShowBtn}
      overlayClassName={classes.notificationsMenu}
      onVisibleChange={toggleDestroy}
      trigger="click"
    >
      <Tooltip overlay={t('notifications')} type="primary" align={TOOLTIP_ALIGN} fillOverlay>
        <Button
          prefixIcon={IconmoonFont['notifications-bell-24']}
          displayType="link"
          shape="circle"
          data-gtm-target-id="header-top-notifications-bell"
        >
          {!!newNotificationsCount ? (
            <span className={classes.notificationsCount}>
              {newNotificationsCount > MAX_NOTIFICATIONS_CROP_COUNT
                ? `${MAX_NOTIFICATIONS_CROP_COUNT}+`
                : newNotificationsCount}
            </span>
          ) : null}
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

export default NotificationBell;
