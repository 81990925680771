import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { ColorfulSectionWrapperProps } from './interfaces';

import useStyles from './style';

const ColorfulSectionWrapper: FC<ColorfulSectionWrapperProps> = ({ children, headlineColors }) => {
  const light = useMemo(
    () =>
      headlineColors?.customLightModeHeadlineColor || headlineColors?.defaultLightModeHeadlineColor,
    [headlineColors?.customLightModeHeadlineColor, headlineColors?.defaultLightModeHeadlineColor]
  );
  const dark = useMemo(
    () =>
      headlineColors?.customDarkModeHeadlineColor || headlineColors?.defaultDarkModeHeadlineColor,
    [headlineColors?.customDarkModeHeadlineColor, headlineColors?.defaultDarkModeHeadlineColor]
  );

  const classes = useStyles({ light, dark });

  return (
    <div className={classNames('colorfulSectionWrapper', classes.colorfulSectionWrapper)}>
      {children}
    </div>
  );
};

export default ColorfulSectionWrapper;
