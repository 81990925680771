import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { ExportToSpotifyModalProps } from 'components/modals/ExportToSpotifyModal';

const ExportMusicButton: FC = () => {
  const { t } = useTranslation();

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const { push } = useHistory();

  const eventContext = useContext(EventContext);

  const { openedModals, openModal } = useModal();

  const openExportToSpotifyModal = useCallback(
    () =>
      !!eventContext?.event &&
      openModal<ExportToSpotifyModalProps>({
        key: Modals.exportToSpotify,
        props: {
          eventContext,
          eventId,
          sectionId,
        },
      }),
    [openedModals, eventContext, eventId, sectionId]
  );

  const goToBeatsourcePage = useCallback(() => push(`/event/${eventId}/beatsource`), [eventId]);

  return (
    <Dropdown
      overlay={
        <Menu styleModificator="asDropdown" selectable={false}>
          <Menu.Item
            onClick={openExportToSpotifyModal}
            icon={IconmoonFont['spotifyColorful-16']}
            key="spotify-btn"
          >
            Spotify
          </Menu.Item>
          <Menu.Item
            onClick={goToBeatsourcePage}
            icon={IconmoonFont['beatsourceColorful-16']}
            key="beatsource-btn"
          >
            Beatsource
          </Menu.Item>
        </Menu>
      }
      trigger="click"
      disableToggleClass
    >
      <Button prefixIcon={IconmoonFont['share-arrow-16']} shape="round" size="lg">
        {t('exportMusicTo')}
      </Button>
    </Dropdown>
  );
};

export default ExportMusicButton;
