import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  modal: {
    margin: 0,
    maxHeight: '100%',
    borderRadius: '4px',

    '& .viboTooltipOvelay': {
      zIndex: 1050,
    },

    '& .rc-dialog-content': {
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
    },

    '& .rc-dialog-header': {
      borderRadius: '4px 4px 0 0',
      color: theme.palette.selection.whiteToBalticSeaDark,
      padding: '0 16px',
      height: '36px',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      border: 'none',

      '& .rc-dialog-title': {
        ...theme.mixins.longText,
        maxWidth: '96%',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '22.5px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
          lineHeight: '27px',
        },
      },
    },

    '& .rc-dialog-body': {
      padding: '0',
      overflowX: 'hidden',
    },

    '& .rc-dialog-close': {
      opacity: 1,
      padding: '0',
      textShadow: 'none',
      color: theme.palette.selection.whiteToBalticSeaDark,
      right: '11px',
      top: '6px',
    },

    '& .lottieBox': {
      '&:not(.noGaps).isOverlay': {
        top: '36px',
        height: 'calc(100% - 36px)',
      },
    },

    '&.smModal': {
      width: 'var(--modal-sm-w)',

      '& .modalBody': {
        height: 'var(--modal-sm-h)',

        '&.autoHeight': {
          maxHeight: 'var(--modal-sm-h)',
        },
      },
    },

    '&.mdModal': {
      width: 'var(--modal-sm-w)',

      '& .modalBody': {
        height: 'var(--modal-md-h)',

        '&.autoHeight': {
          maxHeight: 'var(--modal-md-h)',
        },
      },
    },

    '&.lgModal': {
      width: 'var(--modal-lg-w)',

      '& .modalBody': {
        height: 'var(--modal-lg-h)',

        '&.autoHeight': {
          maxHeight: 'var(--modal-lg-h)',
        },
      },
    },

    '&.xlgModal': {
      width: 'var(--modal-lg-w)',

      '& .modalBody': {
        height: 'var(--modal-xlg-h)',

        '&.autoHeight': {
          maxHeight: 'var(--modal-xlg-h)',
        },
      },
    },

    '&.xxlgModal': {
      width: 'var(--modal-xxlg-w)',

      '& .modalBody': {
        backgroundColor: theme.palette.selection.hover,
        height: 'var(--modal-xxlg-h)',

        '&.autoHeight': {
          maxHeight: 'var(--modal-xxlg-h)',
        },
      },
    },
  },

  viboModalWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalHeader: {
    padding: '6px 16px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: theme.borders[0],
    minHeight: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,

    '& > .clickAwayListener': {
      width: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '48px',
    },
  },

  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    zIndex: 1,

    '&.noScroll': {
      padding: '16px',
    },

    '&.autoStaticHeight, &.autoHeight': {
      '& > .viboScrollbar': {
        height: 'fit-content!important',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    '&.autoStaticHeight': {
      height: 'fit-content!important',
    },

    '&.autoHeight': {
      height: 'auto!important',
      overflow: 'hidden',
    },

    '& > .modalBodyScroll': {
      padding: '20px 10px 20px 20px',
      width: '100%',
    },
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px 16px',

    '& .viboButton': {
      width: '50%',
      justifyContent: 'center',

      '&:not(:last-child)': {
        margin: '0 16px 0 0',
      },

      '&:only-child': {
        minWidth: '100%',
      },
    },
  },
}));

export default useStyles;
