import React, { FC, createContext } from 'react';
import pick from 'lodash/pick';
import { useMutation } from '@apollo/client';

import { UPDATE_EVENT_CONTACT } from 'graphql/mutations/events';

import { onError } from 'graphql/helpers';

type TEventContactContext = {
  contact: EventContact;
  updating: boolean;
  handleUpdateContact: (payload: Partial<EventContact>) => void;
};

type TEventContactContextProvider = {
  contact: EventContact;
};

export const EventContactContext = createContext<TEventContactContext>(undefined!);

export const EventContactContextProvider: FC<{ value: TEventContactContextProvider }> = ({
  children,
  value,
}) => {
  const [updateEventContact, { loading: updating }] = useMutation(UPDATE_EVENT_CONTACT, {
    onError,
  });

  const handleUpdateContact = (newProps: Partial<EventContact>) => {
    updateEventContact({
      variables: {
        contactId: value.contact._id,
        payload: pick(
          {
            ...value.contact,
            ...newProps,
          },
          ['email', 'role', 'lastName', 'firstName', 'phoneCode', 'phoneNumber']
        ),
      },
    });
  };

  return (
    <EventContactContext.Provider value={{ ...value, updating, handleUpdateContact }}>
      {children}
    </EventContactContext.Provider>
  );
};
