import React, { FC, createContext, useState, useEffect, useMemo, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { ThemeProvider } from 'react-jss';

import themeSettings from 'resources/styles/theme';
import { useMe } from 'graphql/hooks/user';
import { getAppColorHue, validateHex } from 'services/common/colorsHelper';

import {
  VIBO_THEME_NAME,
  VIBO_THEMES,
  DEFAULT_VIBO_COLOR_HEX,
  VIBO_APP_COLOR_NAME,
  DEFAULT_VIBO_COLOR_DARK_HEX,
  VIBO_FOLLOW_SYS_THEME,
} from './constants';

import { ModeColorsHex, TViboThemeContext, ViboThemeEnum } from './interfaces';

export const ViboThemeContext = createContext<TViboThemeContext>(undefined!);

export const ViboThemeProvider: FC = ({ children }) => {
  const localTheme = localStorage.getItem(VIBO_THEME_NAME) as ViboThemeEnum;
  const localAppColor = localStorage.getItem(VIBO_APP_COLOR_NAME) as string;
  const localFollowSysTheme = localStorage.getItem(VIBO_FOLLOW_SYS_THEME) as string;

  const { user } = useMe();

  const customAppLight = useMemo(() => user?.applicationColors?.customLightModeApplicationColor, [
    user?.applicationColors?.customLightModeApplicationColor,
  ]);
  const customAppDark = useMemo(() => user?.applicationColors?.customDarkModeApplicationColor, [
    user?.applicationColors?.customDarkModeApplicationColor,
  ]);

  const [theme, setTheme] = useState<ViboThemeEnum>(localTheme || ViboThemeEnum.light);
  const [isSysThemeUsing, setIsSysThemeUsing] = useState<boolean>(!!localFollowSysTheme || false);
  const [appColor, setAppColor] = useState<string>(localAppColor || DEFAULT_VIBO_COLOR_HEX);
  const [modeColorsHex, setModeColorsHex] = useState<ModeColorsHex>({
    light: customAppLight || DEFAULT_VIBO_COLOR_HEX,
    dark: customAppDark || DEFAULT_VIBO_COLOR_DARK_HEX,
  });

  const isDark = useMemo(() => theme === ViboThemeEnum.dark, [theme]);

  const accentColor = useMemo(() => getAppColorHue(appColor), [theme, appColor]);

  const lightColor = useMemo(() => {
    return !!modeColorsHex.light && validateHex(modeColorsHex.light)
      ? modeColorsHex.light
      : DEFAULT_VIBO_COLOR_HEX;
  }, [modeColorsHex.light]);

  const darkColor = useMemo(() => {
    return !!modeColorsHex.dark && validateHex(modeColorsHex.dark)
      ? modeColorsHex.dark
      : DEFAULT_VIBO_COLOR_DARK_HEX;
  }, [modeColorsHex.dark]);

  const appDjColor = useMemo(() => (isDark ? darkColor : lightColor), [
    lightColor,
    darkColor,
    isDark,
  ]);

  const resetToAppColor = useCallback(
    () =>
      setModeColorsHex({
        light: customAppLight || DEFAULT_VIBO_COLOR_HEX,
        dark: customAppDark || DEFAULT_VIBO_COLOR_DARK_HEX,
      }),
    [appDjColor]
  );

  const handleSyncSystemTheme = useCallback(
    ({ matches }: MediaQueryListEvent) => {
      isSysThemeUsing && setTheme(!!matches ? ViboThemeEnum.dark : ViboThemeEnum.light);
    },
    [isSysThemeUsing]
  );

  useEffect(() => {
    if (!!localTheme && VIBO_THEMES.includes(localTheme)) {
      return;
    }

    if (!localTheme) {
      setTheme(ViboThemeEnum.light);
    }
  }, [localTheme]);

  useEffect(() => {
    setAppColor(appDjColor);
  }, [appDjColor]);

  useEffect(() => {
    !isEqual(customAppLight, modeColorsHex.light) &&
      setModeColorsHex({
        light: customAppLight || DEFAULT_VIBO_COLOR_HEX,
        dark: customAppDark || DEFAULT_VIBO_COLOR_DARK_HEX,
      });
  }, [customAppLight, customAppDark]);

  useEffect(() => {
    localStorage.setItem(VIBO_THEME_NAME, `${theme}`);
  }, [theme]);

  useEffect(() => {
    if (isSysThemeUsing) {
      localStorage.setItem(VIBO_FOLLOW_SYS_THEME, 'true');
    } else {
      localStorage.removeItem(VIBO_FOLLOW_SYS_THEME);
    }

    if (isSysThemeUsing) {
      setTheme(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? ViboThemeEnum.dark
          : ViboThemeEnum.light
      );
    }
  }, [isSysThemeUsing]);

  useEffect(() => {
    localStorage.setItem(VIBO_APP_COLOR_NAME, `${appDjColor}`);
  }, [appDjColor]);

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleSyncSystemTheme);

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleSyncSystemTheme);
    };
  }, []);

  return (
    <ViboThemeContext.Provider
      value={{
        modeColorsHex,
        lightColor,
        darkColor,
        appColor,
        isDark,
        isSysThemeUsing,
        setTheme,
        setModeColorsHex,
        resetToAppColor,
        setIsSysThemeUsing,
      }}
    >
      {/* @ts-ignore */}
      <ThemeProvider
        theme={themeSettings[theme]({
          accentColor,
          lightColor,
          darkColor,
        })}
      >
        {children}
      </ThemeProvider>
    </ViboThemeContext.Provider>
  );
};
