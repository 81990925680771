import React, { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import DjCard from './DjCard';
import DjCardPlaceholder from 'components/Placeholders/DjCardPlaceholder';

import { GET_CHILD_DJS } from 'graphql/queries/user';

import { ChildDjsProps } from './interfaces';

import useStyles from './style';

const ChildDjs: FC<ChildDjsProps> = ({ onDjClick }) => {
  const classes = useStyles();

  const { data, loading } = useQuery<ChildDjResponse>(GET_CHILD_DJS, {
    fetchPolicy: 'cache-and-network',
  });
  const childDjs = useMemo(() => data?.getChildDjs.djs || [], [loading]);
  const previewDjs = childDjs?.length > 0 ? childDjs : new Array(10).fill('');

  return (
    <div className={classes.djsGrid}>
      {loading
        ? previewDjs.map((_, idx) => <DjCardPlaceholder key={`dj-card-preview-${idx}`} />)
        : childDjs.map(childDj => (
            <DjCard onClick={onDjClick} dj={(childDj as unknown) as ChildDj} key={childDj._id} />
          ))}
    </div>
  );
};

export default ChildDjs;
