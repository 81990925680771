import React, { FC } from 'react';
import classNames from 'classnames';

import Spinner from 'vibo-ui/Spinner';

import { LoadingOverlayProps } from './interfaces';

import useStyles from './style';

const LoadingOverlay: FC<LoadingOverlayProps> = ({ className, fillOverlay = true }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames('loadingOverlay', classes.loadingOverlay, className, {
        fillOverlay,
      })}
    >
      <Spinner size={40} />
    </div>
  );
};

export default LoadingOverlay;
