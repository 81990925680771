import React, { FC, useCallback, useContext, useMemo } from 'react';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import SortFilterPopover from '../SortFilterPopover';
import Select, { SimpleOption } from 'vibo-ui/Select';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { gtmTriggerClick } from 'services/googleAnalytics';
import { TIMELINE_SONGS_FILTER_OPTIONS } from 'services/constants';
import { getSongsFilterOptionsLabel } from 'services/common/getTransaltedLabel';

import { GET_SECTION_SONGS_STATS } from 'graphql/queries/songs';

const Filter: FC = () => {
  const { t } = useTranslation();

  const { songsOptions } = useContext(TimelineSongsContext);

  const filter = useMemo(() => pick(songsOptions.songsFilter, 'q'), [songsOptions.songsFilter.q]);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const { data: songsStatsData } = useQuery<GetSectionSongsStatsResponse>(GET_SECTION_SONGS_STATS, {
    variables: {
      eventId,
      sectionId,
      filter,
    },
    skip: !sectionId,
    fetchPolicy: 'cache-and-network',
  });

  const isSame = (option: TimelineSongsFilterOption) =>
    `${Object.keys(songsOptions?.songsFilter)[0] || ''}` ===
    `${Object.keys(option.filter)[0] || ''}`;
  const currentOption =
    TIMELINE_SONGS_FILTER_OPTIONS.find(option => isSame(option)) ||
    TIMELINE_SONGS_FILTER_OPTIONS[0];

  const options = useMemo(
    () =>
      TIMELINE_SONGS_FILTER_OPTIONS.map(option => {
        return {
          label: t(getSongsFilterOptionsLabel(option.key)),
          icon: option.Icon,
          value: option.key,
        };
      }),
    []
  );

  const handleFilter = useCallback(
    option => {
      const { value } = option as SimpleOption;

      const filterOption = TIMELINE_SONGS_FILTER_OPTIONS.find(({ key }) => key === value);

      gtmTriggerClick(`timeline-section-details-songs-filter-${value}`);

      if (!!filterOption) {
        const count = get(songsStatsData?.getSectionSongsStats, value, 0);

        songsOptions.setTotalCount(count);
        songsOptions.setSongsFilter(filterOption.filter);
      }
    },
    [songsOptions.songsList.length]
  );

  return (
    <SortFilterPopover
      value={currentOption?.key}
      options={options}
      onChange={handleFilter}
      prefixText={t('filter')}
      renderOption={optionProps => {
        const key = get(optionProps.data, 'value', '');
        const count = get(songsStatsData?.getSectionSongsStats, key, 0);

        return (
          <Select.Option {...optionProps}>
            {t(getSongsFilterOptionsLabel(key as keyof SectionSongsStats, true), {
              count,
            })}
          </Select.Option>
        );
      }}
    />
  );
};

export default Filter;
