import React, { FC, useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import StatusBarView from './StatusBarView';

import { GET_ME } from 'graphql/queries/user';

import useStyles from './style';

const DjFinanceStatusBar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [remind, setRemind] = useState<boolean>(false);

  const { data } = useQuery<UserResponse>(GET_ME, {
    fetchPolicy: 'cache-and-network',
  });
  const user: Maybe<User> = get(data, 'me');
  const financeStatus: Maybe<DjFinanceStatus> = user?.djSettings?.financeStatus;

  const handleRemind = () => setRemind(true);

  if (financeStatus === 'limited' && !remind) {
    return <StatusBarView text={t('yourAccountStatusLimited')} handleClose={handleRemind} />;
  }

  if (financeStatus === 'failedCharge' && !remind) {
    return (
      <StatusBarView
        className={classes.paymentFailed}
        text={t('paymentFailed')}
        handleClose={handleRemind}
      />
    );
  }

  return null;
};

export default DjFinanceStatusBar;
