import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SectionBlockInfo from 'components/sections/blocks/SectionBlock/SectionBlockInfo';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';
import { EditableContext } from 'components/common/EditableContext';
import { TimelineNotesContext, EditingNotesContext } from '../TimelineNotesContext';

import { strippString } from 'services/common/stringHelpers';
import { sectionVisibility } from 'services/sections/constants';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionVisibilityRoles } from 'types/enums';

import useStyles from './style';

const NotesBlockHeader: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventDj } = useContext(EventContext);
  const { canEdit } = useContext(EditableContext);
  const { section, updateSection, variables } = useContext(TimelineNotesContext);
  const { isEditing, setIsEditing, notesText } = useContext(EditingNotesContext);

  const strippedNoteText = useMemo(() => strippString(section?.note), [section?.note]);

  const handleFinishEditing = () => {
    updateSection({
      variables: {
        ...variables,
        payload: {
          note: notesText,
        },
      },
    });
    setIsEditing(false);
  };

  const notesVisibility = useMemo(
    () =>
      section.settings.notesVisibleForHosts
        ? SectionVisibilityRoles.host
        : SectionVisibilityRoles.dj,
    [section.settings.notesVisibleForHosts]
  );
  const role = useMemo(() => sectionVisibility.find(option => option.id === notesVisibility), [
    sectionVisibility,
    notesVisibility,
  ]);

  return (
    <div className={classNames('notesBlockHeader', classes.notesBlockHeader)}>
      {children}
      <div className={classes.info}>
        <SectionBlockInfo title={`${t('notes')}${isEventDj ? ` - ${role?.name}` : ''}`} />
        {strippedNoteText?.length ? <span className={classes.notesPresentIndicator} /> : null}
      </div>
      {canEdit ? (
        <>
          {isEditing ? (
            <Button
              onClick={e => {
                e.stopPropagation();
                handleFinishEditing();
              }}
              prefixIcon={IconmoonFont['editPencil-16']}
              className={classNames('saveNotes', ButtonDecor.success)}
              displayType="primary"
              shape="round"
            >
              {t('saveChanges')}
            </Button>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default NotesBlockHeader;
