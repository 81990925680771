import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    margin: '0',
    color: 'inherit',

    '&:not(.withTime)': {
      color: theme.palette.text.hint,
    },

    '& .viboButton': {
      lineHeight: '15px',
      color: 'inherit!important',
      gap: '4px',

      //for gtm tracking propper target
      '& > div': {
        pointerEvents: 'none',
      },
    },

    '& .viboInput': {
      border: '1px solid transparent',
      textTransform: 'none',
    },

    '& .clearIcon': {
      right: '2px',
    },
  },

  timeIcon: {
    margin: '0 4px 0 0',
  },

  time: {
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    //for gtm tracking propper target
    '& *': {
      pointerEvents: 'none',
    },

    '& > div:not(:last-child)': {
      margin: '0 2px 0 0',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  timePreview: {
    right: '10px',
    whiteSpace: 'nowrap',
  },

  timePreviewButton: {
    '&.linkDisplay': {
      border: '1px solid transparent',

      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

export default useStyles;
