import { Dispatch } from 'react';

export type TViboThemeContext = {
  setTheme: Dispatch<ViboThemeEnum>;
  setModeColorsHex: Dispatch<ModeColorsHex>;
  resetToAppColor: () => void;
  setIsSysThemeUsing: Dispatch<boolean>;
  isDark: boolean;
  isSysThemeUsing: boolean;
  appColor: string;
  lightColor: string;
  darkColor: string;
  modeColorsHex: ModeColorsHex;
};

export type ModeColorsHex = {
  light: string;
  dark: string;
};

export enum ViboThemeEnum {
  'light' = 'light',
  'dark' = 'dark',
}
