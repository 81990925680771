import DefaultAvatar from '../../resources/img/svg/common/defaultAvatar.svg';

import { ChangeUserImageStep, DjType, EventUserType, UserRole } from '../../types/enums';

export const isChildDj = (user: Maybe<User>): boolean => user?.djSettings?.type === DjType.child;

export const isGuest = (event?: Nullable<EventItem>) =>
  event ? event?.role === EventUserType.guest : true;

export const isHost = (event?: Nullable<EventItem>) =>
  event ? event?.role === EventUserType.host : false;

export const getInitialChangeUserImageStep = (currentImg: Maybe<string>): ChangeUserImageStep => {
  if (currentImg) {
    return ChangeUserImageStep.view;
  }

  return ChangeUserImageStep.select;
};

export const getUserImageUrl = (user: UserBase): string =>
  user?.image?.cropped?.small || user.imageUrl || DefaultAvatar;

export const getFullName = <TUser extends { firstName: string; lastName: string }>(
  user: Nullable<Maybe<TUser>>
): string => (user ? `${user.firstName || ''} ${user.lastName || ''}`.trim() : '');

export const getDjName = (user: UserPreview): string => {
  if (!user.showName && !!user.nick) {
    return user.nick;
  }

  return getFullName(user);
};

export const getShortenFullName = <TUser extends { firstName: string; lastName: string }>(
  user: TUser
): string => `${user.firstName} ${user.lastName.substr(0, 1)}.`.trim();

export const isDjLimited = (user: Maybe<User>) =>
  user?.role === UserRole.dj && user?.djSettings?.financeStatus === 'limited';

export const isAdmin = (user: Maybe<User>): boolean => Boolean(user?.djSettings?.isAdmin);

export const isProfileEditable = (user: Maybe<User>): boolean =>
  !isChildDj(user) || Boolean(user?.djSettings?.allowUpdateProfileForChild);

export const isMe = (candidate: Maybe<Pick<User, '_id'>>, me: Maybe<User>): boolean =>
  candidate?._id === me?._id;

export const getSmallerUserImage = (user: Partial<UserPreview>): string =>
  user?.image?.cropped?.small || user?.image?.cropped?.medium || user?.imageUrl || '';
