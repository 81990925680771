import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import ContentArea from 'components/common/ContentArea';

import { ToggleComponentProps } from './interfaces';

import useStyles from './style';

const Toggle: FC<PropsWithChildren<ToggleComponentProps>> = ({
  disableToggleClass,
  toggleClassName,
  className,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ContentArea
      {...rest}
      className={classNames(
        'viboToggleDropdown',
        className,
        classes.viboToggleDropdownDefault,
        {
          [classes.viboToggleDropdown]: !disableToggleClass,
        },
        toggleClassName
      )}
    >
      {children}
    </ContentArea>
  );
};

export default Toggle;
