import React, { ReactElement } from 'react';
import classNames from 'classnames';

import Image from 'components/image/Image';
import SongArtistNames from 'components/songs/SongArtistNames';

import { getSmallestAvailableThumbnail } from 'services/songs/helpers';
import { getSectionSongsInfo } from 'services/sections/helpers';

import { SectionSongPreviewProps } from './interfaces';

import useStyles from './style';

const SectionSongPreview = <TSection extends SectionBase & SectionWithSongsInfoBase>({
  section,
  className,
}: SectionSongPreviewProps<TSection>): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames('songPreview', classes.sectionSongPreview, className)}>
      {section.coverSong ? (
        <>
          <Image
            src={getSmallestAvailableThumbnail(section.coverSong.thumbnails)}
            alt={getSectionSongsInfo(section)}
            token={getSmallestAvailableThumbnail(section.coverSong.thumbnails)}
            className={classNames('imageContainer', classes.imageContainer)}
          />
          <SongArtistNames song={section.coverSong} />
        </>
      ) : null}
    </div>
  );
};

export default SectionSongPreview;
