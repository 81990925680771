import client from '../client';

import { GET_NOTIFICATIONS_COUNT } from 'graphql/queries/notifications';
import { NOTIFICATION_READ } from 'graphql/fragments/notifications';

import { GetNotificationsCountResponse } from 'components/common/Header/TopBar/interface';

export const readNotification = (id: string): void => {
  client.writeFragment({
    fragment: NOTIFICATION_READ,
    id: `Notification:${id}`,
    data: {
      isRead: true,
      __typename: 'Notification',
    },
  });
};

export const downgradeNotificationsCount = (readAll?: boolean): void => {
  const currentCount =
    client.readQuery<GetNotificationsCountResponse>({
      query: GET_NOTIFICATIONS_COUNT,
    })?.getNotificationsCount.total || 0;

  client.writeQuery<GetNotificationsCountResponse>({
    query: GET_NOTIFICATIONS_COUNT,
    data: {
      getNotificationsCount: {
        total: readAll || currentCount <= 0 ? 0 : currentCount - 1,
        __typename: 'NotificationsTotal',
      },
    },
  });
};
