import { gql } from '@apollo/client';
import { SONG_IDEAS_RESPONSE } from '../fragments/songIdeas';
import { SONG_LINKS, THUMBNAILS } from '../fragments/songs';

export const GET_VIBO_SONG_IDEAS = gql`
  query getViboSongIdeas($pagination: PaginationInput, $filter: SongIdeasFilterInput) {
    getViboSongIdeas(pagination: $pagination, filter: $filter) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_COMPANY_SONG_IDEAS = gql`
  query getCompanySongIdeas($pagination: PaginationInput, $filter: SongIdeasFilterInput) {
    getCompanySongIdeas(pagination: $pagination, filter: $filter) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_USER_SONG_IDEAS = gql`
  query getUserSongIdeas($pagination: PaginationInput, $filter: SongIdeasFilterInput) {
    getUserSongIdeas(pagination: $pagination, filter: $filter) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_SONG_IDEAS_STATS = gql`
  query getSongIdeasStats($filter: SongIdeasFilterInput) {
    getUserSongIdeas(pagination: { limit: 0, skip: 0 }, filter: $filter) {
      totalCount
    }

    getViboSongIdeas(pagination: { limit: 0, skip: 0 }, filter: $filter) {
      totalCount
    }
  }
`;

export const GET_CHILD_SONG_IDEAS_STATS = gql`
  query getSongIdeasStats($filter: SongIdeasFilterInput) {
    getUserSongIdeas(pagination: { limit: 0, skip: 0 }, filter: $filter) {
      totalCount
    }

    getCompanySongIdeas(pagination: { limit: 0, skip: 0 }, filter: $filter) {
      totalCount
    }

    getViboSongIdeas(pagination: { limit: 0, skip: 0 }, filter: $filter) {
      totalCount
    }
  }
`;

export const GET_SONG_IDEAS_BY_ID = gql`
  query getSongIdeasById($songIdeasId: ID!) {
    getSongIdeasById(songIdeasId: $songIdeasId) {
      _id
      creator {
        firstName
        lastName
      }
      coverPhoto {
        url
        cropped {
          small
          medium
        }
      }
      title
      description
      updatedAt
      isOwner
      deepLink
      tags
      songsCount
      isPublic
    }
  }
`;

export const GET_PLAYLIST_PREVIEW_BY_ID = gql`
  query getPlaylistPreviewById($songIdeasId: ID!) {
    getSongIdeasById(songIdeasId: $songIdeasId) {
      title
      description
    }
  }
`;

export const GET_SONG_IDEAS_SONGS = gql`
  query getSongIdeasSongs(
    $songIdeasId: ID!
    $pagination: PaginationInput
    $filter: SongIdeasFilterInput!
  ) {
    getSongIdeasSongs(songIdeasId: $songIdeasId, pagination: $pagination, filter: $filter) {
      songs {
        viboSongId
        title
        artist
        thumbnails {
          ...Thumbnails
        }
        links {
          ...SongLinks
        }
      }
      totalCount
      next {
        skip
        limit
      }
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_EVENT_SECTION_SONG_IDEAS_SONGS = gql`
  query getEventSectionSongIdeasSongs(
    $eventId: ID!
    $sectionId: ID!
    $songIdeasId: ID!
    $filter: SongIdeasFilterInput
    $pagination: PaginationInput
  ) {
    getEventSectionSongIdeasSongs(
      eventId: $eventId
      sectionId: $sectionId
      songIdeasId: $songIdeasId
      filter: $filter
      pagination: $pagination
    ) {
      songs {
        sectionSongId
        viboSongId
        artist
        title
        songUrl
        canRemove
        isDontPlay
        isInSection

        thumbnails {
          ...Thumbnails
        }
        links {
          ...SongLinks
        }
      }
      next {
        limit
        skip
      }
      totalCount
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_TEMPLATE_SECTION_SONG_IDEAS_SONGS = gql`
  query getTemplateSectionSongIdeasSongs(
    $templateId: ID!
    $sectionId: ID!
    $songIdeasId: ID!
    $filter: SongIdeasFilterInput
    $pagination: PaginationInput
  ) {
    getTemplateSectionSongIdeasSongs(
      templateId: $templateId
      sectionId: $sectionId
      songIdeasId: $songIdeasId
      filter: $filter
      pagination: $pagination
    ) {
      songs {
        sectionSongId
        viboSongId
        artist
        title
        songUrl
        canRemove
        isDontPlay
        isInSection
        thumbnails {
          ...Thumbnails
        }
        links {
          ...SongLinks
        }
      }
      next {
        limit
        skip
      }
      totalCount
    }
  }
  ${THUMBNAILS}
  ${SONG_LINKS}
`;

export const GET_TAGS = gql`
  query getTags($pagination: PaginationInput) {
    getTags(pagination: $pagination) {
      tags {
        _id
        name
        createdAt
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
`;

export const GET_PLAYLIST_DEEP_LINK_PREVIEW = gql`
  query getSongIdeasDeepLinkPreview($songIdeasId: ID!) {
    getSongIdeasDeepLinkPreview(songIdeasId: $songIdeasId) {
      _id
      title
      coverPhoto {
        url
        cropped {
          small
          medium
        }
      }
    }
  }
`;

export const GET_FAVORITE_SECTION_SONG_IDEAS_SONGS = gql`
  query getFavoriteSectionSongIdeas(
    $favoriteSectionId: ID!
    $filter: SectionSongIdeasFilter
    $pagination: PaginationInput
  ) {
    getFavoriteSectionSongIdeas(
      favoriteSectionId: $favoriteSectionId
      filter: $filter
      pagination: $pagination
    ) {
      songIdeas {
        _id
        creatorId
        title
        description
        updatedAt
        songsCount
        icon
        isOwner
        coverPhoto {
          url
          cropped {
            small
            medium
          }
        }
        deepLink
        isPublic
      }
      next {
        limit
        skip
      }
      totalCount
    }
  }
`;

export const GET_SONG_IDEAS_DEEP_LINK = gql`
  query getSongIdeasDeepLink($songIdeasId: ID!) {
    getSongIdeasDeepLink(songIdeasId: $songIdeasId)
  }
`;
