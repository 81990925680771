import React, { FC, useMemo, useState } from 'react';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';

import { IconmoonFont } from 'vibo-ui/Icon';
import { AssignPlaylistModalProps } from 'components/modals/AssignPlaylistModal';
import { AssigneType } from './interfaces';
import { getPlaylistAssigneLabel } from 'services/common/getTransaltedLabel';

const AssignPlaylistModal = loadable(() => import('components/modals/AssignPlaylistModal')) as FC<
  AssignPlaylistModalProps
>;

const AssignPlaylistButton: FC = () => {
  const { t } = useTranslation();

  const [assigneType, setAssigneType] = useState<Nullable<AssigneType>>(null);

  const menuContent = useMemo(
    () => (
      <Menu>
        {Object.values(AssigneType).map(key => (
          <Menu.Item onClick={() => setAssigneType(key)} key={`"assign-to-${key}"`}>
            {t(getPlaylistAssigneLabel(key))}
          </Menu.Item>
        ))}
      </Menu>
    ),
    []
  );

  return (
    <>
      <Dropdown overlay={menuContent} trigger="click">
        <Button prefixIcon={IconmoonFont['plus-24']} shape="round" size="lg">
          {t('assignPlaylistTo')}
        </Button>
      </Dropdown>
      {!!assigneType ? (
        <AssignPlaylistModal
          assigneType={assigneType}
          onClose={() => setAssigneType(null)}
          visible
        />
      ) : null}
    </>
  );
};

export default AssignPlaylistButton;
