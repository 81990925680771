import { colors } from './colors';
import { mixins } from './mixins';
import { DEFAULT_VIBO_COLOR_HUE } from 'components/context/ViboThemeContext/constants';

import { ThemeColors, ViboPalette, ViboThemeOptions } from 'types/theme';
import { ViboThemeEnum } from 'components/context/ViboThemeContext/interfaces';

const commonPaletteSettings: (value: ThemeColors) => Partial<ViboPalette> = ({
  accentColor,
}: ThemeColors) => ({
  warning: {
    main: colors.oldGold,
  },
  action: {
    hover: colors.toryBlue(accentColor),
    active: colors.endeavour,
    disabled: colors.frenchPass,
  },
  success: {
    main: colors.malachiteLight,
    light: colors.malachiteLighty,
    lighty: colors.fetaLight,
  },
  info: {
    main: colors.yellow,
    light: colors.shalimar,
    active: colors.texas,
  },
  error: {
    main: colors.pomegranate,
    light: colors.carnation,
  },
  progress: {
    low: colors.carnation,
    middle: colors.yellow,
    full: colors.malachiteLight,
  },
});
const commonSettings = {
  shadows: [
    'none', // 0
    '0px 0.5px 3px 0px rgba(0, 0, 0, 0.45)', // 1
    '0 3.5px 4px -2px rgb(0 0 0 / 45%)', // 2 as 3 but only bottom
    '0px .5px 3px 0px rgba(0,0,0,.45)', // 3
    '0px 0.5px 2px rgba(0, 0, 0, 0.35)', // 4
    '0 0.5px 4px 0 rgba(0, 0, 0, 0.1)', // 5
    '0px 2px 6px rgba(0, 0, 0, 0.1)', // 6
    '0px 1px 6px rgba(0, 0, 0, 0.3)', // 7
    '0px 2px 6px rgba(0, 0, 0, 0.3)', // 8
    'inset 0px 0.5px 3px 0px rgba(0, 0, 0, .45)', // 9
    'inset 0px 1px 6px rgba(0, 0, 0, 0.3)', // 10
    '', // 11
    '', // 12
    '', // 13
    '', // 14
    '', // 15
    '', // 16
    '', // 17
    '', // 18
    '', // 19
    '', // 20
    '', // 21
    '', // 22
    '', // 23
    '', // 24
  ],
  mixins,
};

export const light = (themeColors: ThemeColors) => {
  const { accentColor } = themeColors;

  return {
    palette: {
      lightColor: themeColors.lightColor,
      darkColor: themeColors.darkColor,
      spotify: colors.mountainMeadow,
      vibo: colors.curiousBlue(DEFAULT_VIBO_COLOR_HUE),
      selection: {
        color: colors.white,
        bgColor: themeColors.lightColor,
        hover: colors.hawkesBlue(accentColor),
        inputsHover: colors.selago(accentColor),
        iconsHover: colors.tropicalBlue(accentColor),
        menuHover: themeColors.lightColor,
        radioPseudo: colors.tropicalBlue(accentColor),
        filterHover: colors.hawkesBlue(accentColor),
        menuItemSelectedBg: colors.hawkesBlue(accentColor),
        menuItemHoverBg: colors.toryBlue(accentColor),
        whiteToBalticSeaDark: colors.white,
        whiteToBlack: colors.white,
        sectionHover: colors.wildSand,
      },
      headline: {
        empty: 'transparent',
        blue: themeColors.lightColor,
        violet: colors.electricViolet,
        rose: colors.razzleDazzleRose,
        indochine: colors.indochine,
        gold: colors.gold,
        green: colors.buddhaGold,
      },
      button: {
        disabled: colors.tropicalBlue(accentColor),
        active: colors.toryBlue(accentColor),
      },
      primary: {
        main: themeColors.lightColor,
        light: colors.tropicalBlue(accentColor),
        lighty: colors.hawkesBlue(accentColor),
        contrastText: colors.white,
      },
      text: {
        primary: colors.black,
        secondary: colors.emperor,
        hint: colors.dustyGray,
        disabled: colors.silver,
      },
      mustPlay: {
        main: colors.shalimar,
        active: colors.texas,
      },
      background: {
        default: colors.selago(accentColor),
        paper: colors.white,
        photo: colors.tropicalBlue(accentColor),
        dropDown: colors.white,
        input: colors.selago(accentColor),
      },
      overlay: {
        accent: colors.overlayCuriousBlue(accentColor),
        default: 'rgba(255, 255, 255, 0.7)',
        skeleton: 'linear-gradient(90deg, #ddd 0%, #eee 100%)',
      },
      ...commonPaletteSettings(themeColors),
    },
    borders: [`1px solid ${colors.tropicalBlue(accentColor)}`],
    ...commonSettings,
  } as ViboThemeOptions;
};

export const dark = (themeColors: ThemeColors) => {
  const { accentColor } = themeColors;

  return {
    palette: {
      lightColor: themeColors.lightColor,
      darkColor: themeColors.darkColor,
      spotify: colors.mountainMeadow,
      vibo: colors.dodgerBlue(DEFAULT_VIBO_COLOR_HUE),
      selection: {
        color: colors.silver,
        bgColor: themeColors.darkColor,
        hover: colors.shipGray,
        inputsHover: colors.balticSea,
        iconsHover: colors.balticSeaDark,
        menuHover: colors.dodgerBlueLight(accentColor),
        radioPseudo: colors.shipGray,
        filterHover: colors.emperor,
        menuItemSelectedBg: colors.toryBlue(accentColor),
        menuItemHoverBg: colors.dodgerBlueLight(accentColor),
        whiteToBalticSeaDark: colors.balticSeaDark,
        whiteToBlack: colors.black,
        sectionHover: colors.shipGray,
      },
      headline: {
        empty: 'transparent',
        blue: colors.malibuLight,
        violet: colors.heliotrope,
        rose: colors.persianPink,
        indochine: colors.westSide,
        gold: colors.gorse,
        green: colors.aquamarine,
      },
      button: {
        disabled: colors.toryBlue(accentColor),
        active: colors.dodgerBlueLight(accentColor),
      },
      primary: {
        main: themeColors.darkColor,
        light: colors.dodgerBlueLight(accentColor),
        lighty: colors.shipGray,
        contrastText: colors.white,
      },
      text: {
        primary: colors.white,
        secondary: colors.white,
        hint: colors.gray,
        disabled: colors.silver,
      },
      mustPlay: {
        main: colors.lisbonBrown,
        active: colors.costaDelSol,
      },
      background: {
        default: colors.balticSeaDark,
        paper: colors.balticSea,
        photo: colors.shipGray,
        dropDown: colors.balticSeaDark,
        input: colors.shipGray,
      },
      overlay: {
        accent: colors.overlayCuriousBlue(accentColor),
        default: 'rgba(42, 41, 45, 0.7)',
        skeleton: 'linear-gradient(90deg, rgba(42,41,45,1) 0%, rgba(62,59,67,1) 100%)',
      },
      ...commonPaletteSettings(themeColors),
    },
    borders: [`1px solid ${colors.toryBlue(accentColor)}`],
    isDark: true,
    ...commonSettings,
  } as ViboThemeOptions;
};

const themes = {
  [ViboThemeEnum.light]: light,
  [ViboThemeEnum.dark]: dark,
};

export default themes;
