import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Switch from 'vibo-ui/Switch';
import Tooltip from 'vibo-ui/Tooltip';
import TimePicker from 'vibo-ui/TimePicker';
import DatePicker from 'vibo-ui/DatePicker';
import ProLabel from 'components/labels/ProLabel';
import Location from 'components/common/Location';
import DjsPreview from 'components/common/DjsPreview';
import ContentArea from 'components/common/ContentArea';
import WithEventRole from 'components/user/WithEventRole';
import FormSwitch from 'components/form/inputs/FormSwitch';
import AppColoredBox from 'components/common/AppColoredBox';
import WithEventFeature from 'components/user/WithEventFeature';
import StatusSelect from 'components/events/fields/StatusSelect';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import FormNumberInput from 'components/form/inputs/FormNumberInput';
import PickEventColorButton from 'components/buttons/PickEventColorButton';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import Button, { ButtonDecor } from 'vibo-ui/Button';

import { useEventColor } from 'graphql/hooks/event';

import googleCalendar from 'services/googleCalendar';
import { useModal } from 'vibo-ui/Modal';
import { getTimezone } from 'services/common/timeHelper';
import { getDateWithTimezone } from 'services/common/dateHelpers';
import { EVENT_TIME_FIELDS, MAX_DAYS_BEFORE_LOCKING, MAX_SONG_LIMIT } from 'services/constants';

import { EventType, EventUserType, Modals, TierFeatueName } from 'types/enums';
import { ManageDjsModalProps } from 'components/modals/ManageDjsModal/interfaces';
import { ChangeEventTemplateModalProps } from 'components/modals/ChangeEventTemplateModal';
import { EventGeneralViewProps } from '../interfaces';

import useStyles from '../style';

const EventGeneralDjView: FC<EventGeneralViewProps> = ({
  user,
  event,
  isHistory,
  updateEventLoading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<UpdateEventForm>();

  const { id: eventId } = useParams<EventPageRouteParams>();

  const { eventColor } = useEventColor({ event });

  const { openedModals, openModal } = useModal();

  const openManageDjsModal = useCallback(
    () =>
      openModal<ManageDjsModalProps>({
        key: Modals.manageDjs,
        props: {
          eventId,
        },
      }),
    [eventId, openedModals]
  );
  const openChangeEventTemplatModal = () => {
    !!event.template?._id &&
      openModal<ChangeEventTemplateModalProps>({
        key: Modals.changeEventTemplate,
        props: {
          eventId: event._id,
          template: event.template,
        },
      });
  };

  const eventDate = useMemo(() => getDateWithTimezone(values.date, event.timezone), [
    values.date,
    event.timezone,
  ]);

  return (
    <>
      <h3>{t('generalSettings')}</h3>
      <ContentArea shadowed>
        <div className={classes.labels}>{event.isPro ? <ProLabel size="lg" /> : null}</div>
        {values.statusId ? (
          <StatusSelect
            isDeleted={event.isDeleted as boolean}
            isPast={event.isPast}
            statusId={values.statusId}
            className={classes.status}
            onChange={statusId => setFieldValue('statusId', statusId)}
          />
        ) : null}
        <div className={classes.location}>
          <Icon icon={IconmoonFont['location-24']} className={classes.fieldIcon} />
          <Location
            locationValue={event.location}
            onLocationChange={location => setFieldValue('location', location)}
            placeholder={t('eventLocation')}
            disabled={isHistory}
            withUnderline
          />
        </div>
        <div className={classNames('eventType', classes.eventType)}>
          <EventTypeIcon className={classes.fieldIcon} type={event.type as EventType} />
          <p>{event.type}</p>
        </div>
        {event.template?.title ? (
          <div className={classes.template}>
            <Icon className={classes.fieldIcon} icon={IconmoonFont['templates-24']} />
            <p>{event.template.title}</p>
            <WithEventRole roles={[EventUserType.dj]}>
              <Button
                onClick={openChangeEventTemplatModal}
                className={ButtonDecor.primaryColor}
                displayType="link"
                size="xs"
              >
                {t('changeTemplate')}
              </Button>
            </WithEventRole>
          </div>
        ) : null}
        {user?.djSettings?.type === 'company' ? (
          <div className={classes.selectedDj}>
            <Icon className={classes.fieldIcon} icon={IconmoonFont['headphones-24']} />
            <DjsPreview djs={event.djs} showDetails>
              <Button onClick={openManageDjsModal} displayType="link" size="xs">
                {t('manageDjs')}
              </Button>
            </DjsPreview>
          </div>
        ) : null}
        <div className="settingsRow">
          <Icon icon={IconmoonFont['bell-24']} className={classes.fieldIcon} />
          <div>{t('notifications')}</div>
          <Switch
            checked={!values.settings?.disableAllPushNotifications}
            onClick={newChecked =>
              setFieldValue('settings.disableAllPushNotifications', !newChecked)
            }
            disabled={isHistory}
          />
        </div>
      </ContentArea>
      <h3>{t('dateAndTime')}</h3>
      <ContentArea shadowed>
        <div className={classes.date}>
          <DatePicker
            value={eventDate as string}
            onChange={date => {
              setFieldValue('date', date);
              setFieldValue('timezone', getTimezone());
            }}
            disabled={isHistory}
            disablePast
            withUnderline
          />
          <Button
            onClick={() => googleCalendar.create(event)}
            className={ButtonDecor.primaryColor}
            displayType="link"
          >
            {t('addToCalendar')}
          </Button>
        </div>
        <label className={classNames('daysBeforeLock', classes.daysBeforeLock)}>
          <Icon className={classes.fieldIcon} icon={IconmoonFont['lockedCalendar-24']} />
          <span>{t('numberDaysBeforeLockingEvent')}</span>
          <Tooltip
            overlay={event.date ? null : t('chooseEventDate')}
            type="primary"
            placement="top"
          >
            <span>
              <FormNumberInput
                max={MAX_DAYS_BEFORE_LOCKING}
                disabled={!event.date || isHistory}
                name="daysCountBeforeLock"
              />
            </span>
          </Tooltip>
        </label>
        {EVENT_TIME_FIELDS.map(field => (
          <div className={classes.timeInputWrapper} key={field.key}>
            <Icon className={classes.fieldIcon} icon={field.icon} />
            <p>{t(field.translationKey)}:</p>
            <TimePicker
              value={event[field.key]}
              onChangeTime={time => setFieldValue(field.key, time)}
              disabled={isHistory || updateEventLoading}
              className={classes.timePicker}
              placeholder="hh:mm"
            />
          </div>
        ))}
      </ContentArea>
      <h3>{t('branding')}</h3>
      <ContentArea shadowed>
        <WithEventFeature feature={TierFeatueName.branding}>
          <>
            <div className="settingsRow">
              <AppColoredBox defaultColor={eventColor} />
              <PickEventColorButton
                eventColors={event.eventColors}
                handleUpdateColor={newColor => {
                  setFieldValue('eventColor', newColor);
                }}
                modalClassName="pickEventColorModal"
              />
            </div>
            <div className="settingsRow">
              <Icon
                icon={IconmoonFont['hostChangeHeadlineColor-24']}
                className={classes.fieldIcon}
              />
              <div>{t('hostsCanChangeEventColor')}</div>
              <FormSwitch disabled={isHistory} name="settings.canHostChangeEventColor" />
            </div>
          </>
        </WithEventFeature>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostAddSectionPhoto-24']} className={classes.fieldIcon} />
          <div>{t('hostCanAddEventPhoto')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostChangeEventCoverPhoto" />
        </div>
        <WithEventFeature feature={TierFeatueName.image_to_section}>
          <div className="settingsRow">
            <Icon icon={IconmoonFont['hostAddSectionPhoto-24']} className={classes.fieldIcon} />
            <div>{t('hostsCanAddPhotoNewSections')}</div>
            <FormSwitch disabled={isHistory} name="settings.canHostAddSectionImages" />
          </div>
        </WithEventFeature>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostChangeHeadlineColor-24']} className={classes.fieldIcon} />
          <div>{t('canHostChangeHeadlineColor')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostChangeHeadlineColors" />
        </div>
      </ContentArea>
      <h3>{t('permissions')}</h3>
      <ContentArea shadowed>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsInviteGuests-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanInviteGuests')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostsInviteGuests" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['guestsInviteGuests-24']} className={classes.fieldIcon} />
          <div>{t('guestsCanInviteGuests')}</div>
          <FormSwitch disabled={isHistory} name="settings.canGuestsInviteGuests" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsAddSections-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanAddNewSections')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostCreateSections" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsReorderSections-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanReorderSections')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostReorderSections" />
        </div>
      </ContentArea>
      <h3>{t('songsSettings')}</h3>
      <ContentArea shadowed>
        <div className="settingsRow">
          <Icon className={classes.fieldIcon} icon={IconmoonFont['musicNoteDoubleOutlined-24']} />
          <div>{t('songLimitForNewSections')}</div>
          <FormNumberInput
            disabled={isHistory}
            max={MAX_SONG_LIMIT}
            name="settings.sectionSongsLimit"
          />
        </div>
        <div className="settingsRow">
          <Icon className={classes.fieldIcon} icon={IconmoonFont['crown-24']} />
          <div>{t('mustPlaysLimitForNewSections')}</div>
          <FormNumberInput
            disabled={isHistory}
            max={MAX_SONG_LIMIT}
            name="settings.sectionMustPlayLimit"
          />
        </div>
      </ContentArea>
    </>
  );
};

export default EventGeneralDjView;
