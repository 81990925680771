import React, { FC, useState } from 'react';

import Like from '../Like';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import Delete from '../SongActions/Delete';
import SongActionsMenu from '../SongActionsMenu';
import IconButton from 'components/buttons/IconButton';
import WithEventRole from 'components/user/WithEventRole';
import SongCreatorImage from 'components/image/SongCreatorImage';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType, EventUserType } from 'types/enums';
import { SongItemActionsGroupProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const SongItemActionsGroup: FC<SongItemActionsGroupProps> = ({ song, sections, variables }) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  return (
    <div className={classes.songItemActionsGroup}>
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <Dropdown
          visible={isDropdownVisible}
          onVisibleChange={visible => setIsDropdownVisible(visible)}
          trigger={['click']}
          overlay={
            <SongActionsMenu
              song={song}
              sections={sections}
              // @ts-ignore
              variables={variables}
              onMenuItemClick={() => setIsDropdownVisible(false)}
            />
          }
          getPopupContainer={() => document.body}
          disableToggleClass
        >
          <Button prefixIcon={IconmoonFont['threeDots-16']} shape="circle" />
        </Dropdown>
      </WithEventRole>
      <WithEventRole roles={[EventUserType.guest]} extraCondition={song.canRemove}>
        <Delete
          songs={[song]}
          render={() => (
            <IconButton
              type={ButtonIconType.delete}
              className={decorClasses.highlighted}
              placement="top"
            />
          )}
        />
      </WithEventRole>
      <SongCreatorImage className={classes.creatorImage} song={song} />
      <Like song={song} />
    </div>
  );
};

export default SongItemActionsGroup;
