import React, { FC, memo, useContext, useRef, useMemo, useEffect } from 'react';
import pick from 'lodash/pick';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { FormikProvider } from 'formik';
import { useReactiveVar } from '@apollo/client';

import QuestionHeader from '../QuestionHeader';
import AddQuestionInMiddle from '../AddQuestionInMiddle';
import WithEventRole from 'components/user/WithEventRole';
import QuestionMediaPreview from '../QuestionMediaPreview';
import ContentArea from 'components/common/ContentArea/ContentArea';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import {
  TimelineQuestionsContext,
  QuestionActionsContext,
  QuestionContext,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { isLoadingVar } from 'graphql/cache';
import { useFormikAutoSave } from 'graphql/hooks/form';
import { useNewQuestion } from 'graphql/hooks/questions';
import { getQuestionBody } from 'services/questions/getQuestionBody';
import { ALLOWED_FIELDS_TO_ANSWER, ALLOWED_FIELDS_TO_UPDATE } from './constants';

import { EventUserType } from 'types/enums';
import { QuestionProps } from './interfaces';

import useStyles from './style';

const QuestionComponent: FC<QuestionProps> = ({ question }) => {
  const classes = useStyles();

  const { answerQuestion, updateQuestion } = useContext(QuestionActionsContext);
  const { canAnswer } = useContext(TimelineQuestionsContext);

  const isLoading = useReactiveVar(isLoadingVar);

  const { newQuestionIdx, clearAddingNew } = useNewQuestion();

  const QuestionBody = useMemo(() => getQuestionBody(question.settings.type), [question]);

  const questionRef = useRef<Nullable<HTMLDivElement>>(null);

  const { formik } = useFormikAutoSave<UpdateQuestionForm>(
    {
      initialValues: question,
      onSubmit: values => {
        if (!isEqual(values.answer, question.answer)) {
          return answerQuestion(question._id)(pick(values, ALLOWED_FIELDS_TO_ANSWER));
        }

        if (!isEqual(values.question, question.question)) {
          return updateQuestion(question._id)(pick(values, ALLOWED_FIELDS_TO_UPDATE));
        }
      },
    },
    500
  );

  useEffect(() => {
    if (newQuestionIdx === question._id && questionRef.current) {
      questionRef?.current?.focus();
      clearAddingNew();
    }
  }, [newQuestionIdx]);

  useEffect(() => {
    !isLoading && formik.setValues(question);
  }, [
    question.title,
    question.settings.type,
    question.settings.hasOther,
    question.question.youtubeLink?.link,
    question.isAnswered,
    question.question.images?.[0]?.imageUrl,
    question.question.files?.[0]?.fileUrl,
    question.answer?.images?.[0]?.imageUrl,
    question.answer?.files?.[0]?.fileUrl,
    question.answer?.selectedOptions,
    question.question.options,
    isLoading,
  ]);

  return (
    <QuestionContext.Provider
      value={{
        question,
        canAnswer,
      }}
    >
      <FormikProvider value={formik}>
        <ContentArea
          className={classNames('question', `${question.settings.type}Question`, classes.question)}
          ref={questionRef}
          tabIndex={0}
        >
          <WithEventRole roles={[EventUserType.dj]}>
            <Icon icon={IconmoonFont['dnd-16']} className="dndIcon draggableItem" />
          </WithEventRole>
          <div className={classNames('content', classes.content)}>
            <QuestionHeader />
            <QuestionMediaPreview />
            {!!QuestionBody ? <QuestionBody /> : null}
          </div>
        </ContentArea>
        <AddQuestionInMiddle questionId={question._id} />
      </FormikProvider>
    </QuestionContext.Provider>
  );
};

export default memo(QuestionComponent);
